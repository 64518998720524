import React from 'react';

import {
    CitySelectionInput,
    StationInstitutionSelectionInput,
} from '../../../common/custom-inputs';
import NewBtn from '../../common/new-btn';
import StationBox from '../station-box';

import PostBtn from '../../common/post-btn';

import StationExportPopup from './export-station-popup';

import { useStationsListStates } from './states';

import './styles.css';

function StationsList() {
    const {
        addNewStation,
        changeSortOptions,
        exportListStation,
        filterActive,
        filterCity,
        filterCitiesIds,
        filterNeighborhoodId,
        importFile,
        saveStations,
        selectStationInstitution,
        selectedStationInstitutionId,
        shouldSave,
        setFilterActive,
        setFilterCity,
        setFilterNeighborhoodId,
        sortOptions,
        stations,
        stationsNeighborhoods,
    } = useStationsListStates();

    return (
        <>
        <div className="header">
            <h3>Estações Meteorológicas</h3>
            {selectedStationInstitutionId && (
                <>
                    <div className="station-btn">
                        <NewBtn
                            label="Nova"
                            newFunction={addNewStation}
                        />
                        <StationExportPopup stations={stations} exports={exportListStation}/>
                        <NewBtn
                            newFunction={importFile}
                            label="Importar"
                        />
                        <PostBtn
                            shouldPost={shouldSave}
                            postFunction={saveStations}
                            label="Salvar todas"
                        />
                    </div>
                </>
            )}
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="select-station-institution">Instituição:</label>
                <StationInstitutionSelectionInput
                    defaultValue={selectedStationInstitutionId}
                    setValue={selectStationInstitution}
                    inputId={"select-station-institution"}
                />
            </div>

            <div className="form-group">
                <label htmlFor="filter-active">Habilitadas: </label>
                <select
                    id="filter-active"
                    value={filterActive}
                    onChange={(event) => setFilterActive(Number(event.target.value))}
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                    <option value="-1">Todas</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="filter-city-id">Cidade: </label>
                <CitySelectionInput
                    defaultStateOption={{ value: 0, label: 'Todos' }}
                    isStateNullable={true}
                    defaultCityOption={{ value: 0, label: 'Todas' }}
                    selectCity={([state_id, city_id]) => setFilterCity({ state_id, city_id })}
                    inputId="filter-city-id"
                />
            </div>

            <div className="form-group">
                <label htmlFor="filter-neighborhood-id">Bairro: </label>
                <select
                    id="filter-neighborhood-id"
                    value={filterNeighborhoodId}
                    onChange={(event) => setFilterNeighborhoodId(Number(event.target.value))}
                >
                    <option value="0">Todos</option>
                    {stationsNeighborhoods.map(({ id, name }) => (
                        <option
                            key={id}
                            value={id}
                        >
                            {name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="sort-options">Ordenar: </label>
                <select
                    id="sort-options"
                    value={sortOptions}
                    onChange={(event) => changeSortOptions(event.target.value)}
                >
                    <option value="name=asc">Nome (crescente)</option>
                    <option value="name=">Nome (decrescente)</option>
                    <option value="code=asc">Código (crescente)</option>
                    <option value="code=">Código (decrescente)</option>
                </select>
            </div>
        </div>
        {stations && stations.filter(({ inactive, neighborhood_id, city_id }) => {
            if (filterActive !== -1
                && inactive !== [true, false][filterActive]
            ) {
                return false;
            }
            if (filterNeighborhoodId > 0
                && filterNeighborhoodId !== neighborhood_id
            ){
                return false;
            }
            const { state_id, city_id: filterCityId } = filterCity;
            if (
                (filterCityId > 0 && filterCityId !== city_id)
                || (state_id > 0 && !filterCitiesIds.includes(city_id))
            ) {
                return false;
            }
            return true;
        })
        .map(station => (
            <StationBox
                key={station.id || station.tempId}
                station={station}
                institution_id={Number(selectedStationInstitutionId)}
            />
        ))}
    </>);
}

export default StationsList;
