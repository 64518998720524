import { useCallback, useState, useMemo, useEffect } from 'react';

import {
    getBasins,
    postManageBasins,
    putManageBasins,
    patchBasinStages,
} from '../../../../services/api';
import { useSocket as useSocketBase } from '../../../../hooks/use-socket';

export function useManageBasinsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.name) return -1;
            if (!b.name) return 1;
            return a.name.localeCompare(b.name);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const basins = await getBasins();
            setValue(basins);
            setLoading(false);
        },
        [value, loading],
    );

    function useSocket() {
        useSocketBase('basins', (basins) => {
            setValue(old => {
                const newValue = [...old];
                basins.forEach(basin => {
                    const index = newValue.findIndex(({ id }) => id === basin.id);
                    if (index === -1) {
                        newValue.push(basin);
                    }
                    else {
                        newValue[index] = {
                            ...newValue[index],
                            ...basin,
                        };
                    }
                });
                return newValue;
            });
        });
    }

    function useFetchAndSocket() {
        useEffect(() => {
            fetch();
        }, []);

        useSocket();
    }

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(basinId, basin) {
        const index = value.findIndex(({ id }) => id === basinId);
        // creating
        if (!basin.id) {
            basin = await postManageBasins(basin);
        }
        // updating
        else {
            await putManageBasins(basin);
        }
        const newValue = [...value];
        newValue[index] = basin;
        setValue(newValue);
    }

    async function changeStages(basinsToSend) {
        await patchBasinStages(basinsToSend);
        const newValue = [...value];
        basinsToSend.forEach(({
            id: basinId,
            precipitation_stage_id,
            slip_stage_id,
        }) => {
            const index = value.findIndex(({ id }) => id === basinId);
            const newBasin = { ...value[index] };
            if (precipitation_stage_id) {
                newBasin.precipitation_stage_id = precipitation_stage_id;
            }
            if (slip_stage_id) {
                newBasin.slip_stage_id = slip_stage_id;
            }
            newValue[index] = newBasin;
        });
        setValue(newValue);
    }

    return {
        add,
        changeStages,
        fetch,
        loading,
        save,
        useSocket,
        useFetchAndSocket,
        value: valueSorted,
    };
};
