import React from 'react';

import ZoneMapBox from '../../components/zone-map-box';
import ForecastDescriptionBox from '../../components/forecast-description-box';
import ForecastChartBox from '../../components/legacy-forecast-chart-box';
import StatisticsBox from '../../components/statistics-box';

function RainForecast() {
    return (
        <div className="dashboard-flex-parent">
            <ZoneMapBox />

            <ForecastDescriptionBox />

            <ForecastChartBox />

            <StatisticsBox />
        </div>
    );
}

export default RainForecast;
