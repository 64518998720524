import {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

import {
    getBulletinForecastsOptions,
    getExtendedForecasts,
    postExtendedForecasts,
} from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import datetimeUtils from '../../../../utils/datetime';
import { useSocket } from '../../../../hooks/use-socket';

export function useExtendedForecastsStates() {
    const {
        forecastRegions,
        retrieveForecastRegions,
    } = useApiData();
    const initialExtendedForecasts = useRef();
    const [bulletinForecastsOptions, setBulletinForecastsOptions] = useState(null);
    const [extendedForecasts, setExtendedForecasts] = useState(null);
    const [forecastRegionId, setForecastRegionId] = useState(null);
    const lastUpdateDatetimeString = useMemo(() => {
        if (!extendedForecasts?.forecasts?.lastUpdate) {
            return null;
        }
        return datetimeUtils.getFormatDatetimeFromDatetime(extendedForecasts.forecasts.lastUpdate);
    }, [extendedForecasts]);

    const NULL_VALUE = 'NULL_VALUE';

    const regionsOptions = useMemo(() => {
        const options = forecastRegions
            ?.filter(({ alias }) => alias.startsWith('boletim_'))
            ?.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
        if (options?.length) {
            setForecastRegionId(old => old || options[0].value);
        }
        return options;
    }, [forecastRegions]);

    useEffect(() => {
        retrieveForecastRegions();
    }, [retrieveForecastRegions]);

    function copyExtendedForecasts({
        forecast_region_id,
        forecasts,
    }) {
        const {
            lastUpdate,
            data,
        } = { ...forecasts };
        return {
            forecast_region_id,
            forecasts: {
                lastUpdate,
                data: data?.map(f => ({ ...f })) || [],
            },
        };
    }

    useEffect(() => {
        if (!forecastRegionId) {
            return;
        }
        (async function() {
            const data = await getBulletinForecastsOptions(forecastRegionId);
            Object.keys(data?.options || {}).forEach(key => {
                if (key === 'period') {
                    return;
                }
                data.options[key] = data.options[key].sort((a, b) => a.label.localeCompare(b.label));
            });
            setBulletinForecastsOptions(data);
        })();
        (async function() {
            const data = (await getExtendedForecasts(forecastRegionId)) || ({
                forecast_region_id: forecastRegionId,
            });
            initialExtendedForecasts.current = copyExtendedForecasts(data);
            setExtendedForecasts(data);
        })();
    }, [forecastRegionId]);

    function addForecast() {
        if (!extendedForecasts?.forecasts) {
            extendedForecasts.forecasts = {
                data: [],
            };
        }
        extendedForecasts.forecasts.data.push({
            id: Date.now(),
        });
        setExtendedForecasts({ ...extendedForecasts });
    }

    function changeForecast(id, data) {
        const oldForecastIndex = extendedForecasts.forecasts.data.findIndex(f => f.id === id);
        if (oldForecastIndex === -1) {
            return;
        }
        if (data.date) {
            const dayOfTheWeek = datetimeUtils.getDayOfTheWeek(`${data.date}T00:00`);
            data.dayOfTheWeekString = `(${dayOfTheWeek})`;
        }
        const oldForecast = extendedForecasts.forecasts.data[oldForecastIndex];
        extendedForecasts.forecasts.data[oldForecastIndex] = {
            ...oldForecast,
            ...data,
        };
        setExtendedForecasts({ ...extendedForecasts });
    }

    useEffect(() => {
        if (!extendedForecasts?.forecasts?.data || !bulletinForecastsOptions) {
            return;
        }
        let changedAnyIcon = false;
        extendedForecasts.forecasts.data.forEach(forecast => {
            const periodOption = bulletinForecastsOptions.options.extendedPeriod[forecast.period];
            if (!periodOption) {
                return;
            }
            const newIconPath = `${periodOption.pathIcon}${forecast.icon}`.replace('.gif', '');
            if (newIconPath !== forecast.iconPath) {
                forecast.iconPath = newIconPath;
                changedAnyIcon = true;
            }
        })
        
        if (changedAnyIcon) {
            setExtendedForecasts({ ...extendedForecasts });
        }
    }, [extendedForecasts, bulletinForecastsOptions]);
    
    function shouldSave() {
        const extendedForecastsChanged = JSON.stringify(extendedForecasts?.forecasts?.data || '')
            !== JSON.stringify(initialExtendedForecasts.current?.forecasts?.data || '');
        const allValuesSet = extendedForecasts?.forecasts?.data?.length === 0
            || extendedForecasts?.forecasts?.data?.slice(-4)?.every(f => (
                [
                    'date',
                    'period',
                    'sky',
                    'precipitation',
                    'icon',
                    'windSpeed',
                    'windDirection',
                    'maxTemperature',
                    'minTemperature',
                ].every(key => ![NULL_VALUE, null, undefined, ''].includes(f[key]))
            ));
        return allValuesSet && extendedForecastsChanged
    }

    async function save() {
        if (!shouldSave()) {
            return;
        }
        extendedForecasts.forecasts.lastUpdate = Date.now();
        extendedForecasts.forecasts.data = extendedForecasts?.forecasts?.data?.slice(-4);
        await postExtendedForecasts(extendedForecasts);
        initialExtendedForecasts.current = copyExtendedForecasts(extendedForecasts);
        setExtendedForecasts({ ...extendedForecasts });
    }

    useSocket(
        'extended-forecasts',
        useCallback((data) => {
            if (data.forecast_region_id !== forecastRegionId) {
                return;
            }
            initialExtendedForecasts.current = copyExtendedForecasts(data);
            setExtendedForecasts(data);
        }, [forecastRegionId]),
    );

    function removeForecast(id) {
        extendedForecasts.forecasts.data = extendedForecasts.forecasts.data.filter(f => f.id !== id);
        setExtendedForecasts({ ...extendedForecasts });
    }

    return {
        NULL_VALUE,
        addForecast,
        changeForecast,
        extendedForecasts: extendedForecasts?.forecasts?.data?.slice(-4),
        forecastsOptions: bulletinForecastsOptions?.options,
        lastUpdateDatetimeString,
        regionsOptions,
        removeForecast,
        save,
        setForecastRegionId,
        shouldSave,
    };
};
