import {
    InitialPageSelectionInput,
    MultipleForecastModelSelectionInput,
    HarborSelectionInput,
    MultipleForecastRegionSelectionInput,
    MultipleStateAndCitySelectionInput,
    MultipleStaticPositionTypeSelectionInput,
    MultipleStationInstitutionsSelectionInput,
    MultiplePagesSelectionInput,
    MultipleRadarsSelectionInput,
    RadarSelectionInput,
    VariableByModelInput,
    VariableUnitInput,
    MultipleSatelliteSelectionInput,
    MultipleSatelliteProductTypesSelectionInput
} from '../../common/custom-inputs';

import {
    ArraySetting,
    DatetimePeriodSetting,
    StringSetting,
} from '../settings';

const mapsSettings = {
    RADAR_ALIASES: {
        label: 'Radares',
        SettingType: MultipleRadarsSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    SATELLITE_ALIASES: {
        label: 'Satélites',
        SettingType: MultipleSatelliteSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    SATELLITE_PRODUCT_TYPES_ID: {
        label: 'Produtos de satélite',
        SettingType: MultipleSatelliteProductTypesSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    STATIC_POSITION_TYPE_ALIASES: {
        label: 'Camadas estáticas',
        SettingType: MultipleStaticPositionTypeSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    STATION_INSTITUTION_ALIASES: {
        label: 'Instituições de estações',
        SettingType: MultipleStationInstitutionsSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    STATION_CITY_IDS: {
        label: 'Cidades para visualização de estações',
        SettingType: MultipleStateAndCitySelectionInput,
        isOptional: true,
    },
    LIGHTNINGS_VIEW_AREAS: {
        label: 'Áreas de visualização de raios',
        SettingType: ArraySetting(StringSetting),
        placeholder: 'lat (N), lon (O), lat (S), lon (L)',
        isOptional: true,
    },
    LIGHTNINGS_DATETIME_RANGE: {
        label: 'Período de visualização de raios',
        SettingType: DatetimePeriodSetting,
        inlineDisplay: true,
        isOptional: true,
    },
    ATTENTION_PERIMETERS: {
        label: 'Perímetros de atenção',
        SettingType: ArraySetting(StringSetting),
        placeholder: 'latitude, longitude, distância em km',
        isOptional: true,
    },
};

const forecastsSettings = {
    FORECAST_REGIONS: {
        label: 'Regiões',
        SettingType: MultipleForecastRegionSelectionInput,
        inlineDisplay: true,
    },
    ASTRONOMIC_FORECASTS_HARBOR: {
        label: 'Porto para previsões astronômicas',
        SettingType: HarborSelectionInput,
        inlineDisplay: true,
        isOptional: true,
    },
    CALENDAR_CODES: {
        label: 'Códigos de alerta de agenda',
        SettingType: ArraySetting(StringSetting),
        isOptional: true,
    },
    REAL_TIME_MESSAGE_CODES: {
        label: 'Códigos de alerta em tempo real',
        SettingType: ArraySetting(StringSetting),
        isOptional: true,
    },
};

const climateForecastsSettings = {
    CLIMATE_FORECAST_REGIONS: {
        label: 'Regiões',
        SettingType: MultipleForecastRegionSelectionInput,
        inlineDisplay: true,
    },
    CLIMATE_FORECAST_WEEKS: {
        label: 'Quantidade de semanas',
        SettingType: StringSetting,
        inlineDisplay: true,
    },
    CLIMATE_FORECAST_MODELS_WEIGHTS: {
        label: 'Pesos dos modelos',
        SettingType: ArraySetting(StringSetting),
        placeholder: 'ID do modelo, peso',
    },
};

const downloadsSettings = {
    DOWNLOAD_FORECAST_MODELS: {
        label: 'Modelos para download',
        SettingType: MultipleForecastModelSelectionInput,
        inlineDisplay: true,
    },
};

const radarDashboardSettings = {
    DASHBOARD_RADAR: {
        label: 'Radar para visualização de dados',
        SettingType: RadarSelectionInput,
        inlineDisplay: true,
    },
};

export const generalSettings = {
    INITIAL_PAGE: {
        label: 'Dashboard inicial',
        SettingType: InitialPageSelectionInput,
        inlineDisplay: true,
    },
    PAGES: {
        label: 'Páginas para visualização',
        SettingType: MultiplePagesSelectionInput,
        inlineDisplay: true,
    },
    INITIAL_MAP_ZOOM: {
        label: 'Zoom inicial do mapa',
        SettingType: StringSetting,
        placeholder: '1 até 18',
        inlineDisplay: true,
    },
    INITIAL_MAP_POSITION: {
        label: 'Posição inicial no mapa',
        SettingType: StringSetting,
        placeholder: 'latitude, longitude',
        inlineDisplay: true,
    },
    VARIABLES_BY_FORECAST_MODELS: {
        label: 'Variáveis por modelo',
        SettingType: ArraySetting(VariableByModelInput),
    },
    VARIABLES_UNITS: {
        label: 'Unidades das variáveis',
        SettingType: ArraySetting(VariableUnitInput),
    },
};

export const pagesSettings = [
    {
        label: 'Mapas e Alertas',
        settings: mapsSettings,
    },
    {
        label: 'Previsão',
        settings: forecastsSettings,
    },
    {
        label: 'Previsão Climática',
        settings: climateForecastsSettings,
    },
    {
        label: 'Downloads',
        settings: downloadsSettings,
    },
    {
        label: 'Dashboard de Radar',
        settings: radarDashboardSettings,
    },
];
