import React from 'react';
import { Link } from 'react-router-dom';
import {
    FaPlus,
    FaTrash,
} from 'react-icons/fa';

import './styles.css';

function ArrayInput(InputType) {
    return function ({ defaultValue, setValue, placeholder }) {
        function changeValue(newValue, id) {
            const newArray = [ ...(defaultValue || []) ];
            const index = newArray.findIndex(item => item.id === id);
            newArray[index] = newValue;
            setValue(newArray);
        }

        function removeValue(id) {
            const newArray = [ ...(defaultValue || []) ];
            const index = newArray.findIndex(item => item.id === id);
            newArray.splice(index, 1);
            setValue(newArray);
        }

        function addSlot() {
            const newArray = [
                ...(defaultValue || []),
                { id: Date.now(), creating: true },
            ];
            setValue(newArray);
        }

        return (<>
            {(defaultValue || []).map((value) => (
                <div
                    key={value.id}
                    className="array-input-item"
                >
                    <InputType
                        defaultValue={value}
                        setValue={newValue => changeValue(newValue, value.id)}
                        placeholder={placeholder}
                    />
                    <Link
                        to="#"
                        onClick={() => removeValue(value.id)}
                    >
                        <FaTrash />
                    </Link>
                </div>
            ))}
            <Link
                to="#"
                onClick={addSlot}
                className="array-input-add-btn"
            >
                <FaPlus />
            </Link>
        </>);
    };
}

export default ArrayInput;
