import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useStationInstitutionsSelectionInputState } from './states';

function MultipleStationInstitutionsSelectionInput({ defaultValue, setValue }) {
    const stationsInstitutionOptions = useStationInstitutionsSelectionInputState();
    return stationsInstitutionOptions ? (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={stationsInstitutionOptions}
        />
    ) : null;
}

export default MultipleStationInstitutionsSelectionInput;
