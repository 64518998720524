import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import SatelliteInstitutionBox from '../satellite-institution-box';
import {
    getSatelliteInstitutions
} from '../../../../services/api';

function SatelliteInstitutionsList() {
    const [satelliteInstitutions, setSatelliteInstitutions] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await getSatelliteInstitutions();
            setSatelliteInstitutions(response);
        })();
    }, []);

    function addNewInstitution() {
        const id = Math.max(...satelliteInstitutions.map(({ id }) => id)) + 1;
        setSatelliteInstitutions([ ...satelliteInstitutions, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Instituições de Satélites</h3>
            <NewBtn
                label="Nova"
                newFunction={addNewInstitution}
            />
        </div>
        {satelliteInstitutions && satelliteInstitutions.sort((a, b) => a.id - b.id).map(institution => (
            <SatelliteInstitutionBox
                key={institution.id}
                institution={institution}
            />
        ))}
    </>);
}

export default SatelliteInstitutionsList;
