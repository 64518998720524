import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../../contexts/api-data';

export function useAlertTemplateBoxStates(template) {
    const {
        manageAlertTemplates: {
            save: saveAlertTemplate,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            name: template.name || '',
            format: template.format || '',
        },
    });
    const name = watch('name');
    const format = watch('format');
    const creating = !template.name;
    const title = useMemo(() => name || format || '', [format, name]);

    function shouldSave() {
        const nameAndFormatSet = name.length > 0 && format.length > 0;
        if (creating) {
            return nameAndFormatSet;
        }
        const nameChanged = (name || '') !== (template.name || '');
        const formatChanged = (format || '') !== (template.format || '');
        return nameAndFormatSet && (nameChanged || formatChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = template;
            const templateToSend = {
                name,
                format,
            };
            if (!creating) {
                templateToSend.id = id;
            }
            saveAlertTemplate(id, templateToSend);
        }
    }

    return {
        creating,
        register,
        save,
        shouldSave,
        title,
    };
};
