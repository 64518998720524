import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../contexts/api-data';

export function useRoleBoxStates(role) {
    const {
        manageRoles: {
            save: saveRole,
            savePermissions: saveRolePermissions,
        },
        managePermissions: {
            value: managePermissions,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            key: role.key || '',
            description: role.description || '',
        },
    });
    const key = watch('key');
    const description = watch('description');
    const creating = !role.key;
    const title = useMemo(() => description || key || '', [description, key]);

    const rolePermissions = role.permissions?.map(({ permission_id }) => permission_id)?.sort() || [];

    const [permissions, setPermissions] = useState(rolePermissions);
    function updatePermissions(newPermissions) {
        setPermissions(newPermissions.sort());
    }

    const isMaster = role.key === 'MASTER';

    function shouldSaveBaseRole() {
        const keyAndDescriptionSet = key.length > 0 && description.length > 0;
        if (creating) {
            return keyAndDescriptionSet;
        }
        const keyChanged = (key || '').toUpperCase() !== (role.key || '');
        const descriptionChanged = (description || '') !== (role.description || '');
        return keyAndDescriptionSet && (keyChanged || descriptionChanged);
    }

    function shouldSavePermissions() {
        return rolePermissions.join(',') !== permissions.join(',');
    }

    function shouldSave() {
        return shouldSaveBaseRole() || shouldSavePermissions();
    }

    async function save() {
        if (shouldSaveBaseRole()) {
            const { id } = role;
            const roleToSend = {
                key: key.toUpperCase(),
                description,
            };
            if (!creating) {
                roleToSend.id = id;
            }
            await saveRole(id, roleToSend);
        }
        if (shouldSavePermissions()) {
            const { id } = role;
            await saveRolePermissions(id, permissions);
        }
    }

    return {
        creating,
        isMaster,
        managePermissions,
        permissions,
        register,
        save,
        shouldSave,
        title,
        updatePermissions,
    };
};
