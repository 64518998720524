import baseApi from '../../base';

export async function getManageCitiesByState(stateId) {
    const result = await baseApi.get(`/manage/states/${stateId}/cities`);
    return result.data.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
};
