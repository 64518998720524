import React, { useState } from 'react';

import { FaPhoneAlt } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import {
    RiMailFill,
    RiPhoneFill,
} from 'react-icons/ri';

import { Popup } from 'reactjs-popup';

import CopyButton from '../copy-button';

import './styles.css';

function ContactPopup({ onLeft=false }) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleClosePopup = () => {
        setIsOpen(false);
    };

    return (
        <Popup
            open={isOpen}
            onOpen={togglePopup}
            onClose={handleClosePopup}
            closeOnDocumentClick={true}
            closeOnEscape={true}
            className="popup-contact"
            position={`top ${onLeft ? 'left' : 'right'}`}
            trigger={isOpen ? (
                <div className={`popup-contact-toggle-btn active ${onLeft ? 'left' : ''}`}>
                    <MdClose color="#333" size={44}/>
                </div>)
                : (
                <div className={`popup-contact-toggle-btn ${onLeft ? 'left' : ''}`}>
                    <FaPhoneAlt />
                </div>)
            }
        >
            <div class="popup-contact-container">
                <div className="title">Fale com nossos Meteorologistas</div>
                <div className="body">
                    <span>Todos os dias de 06h às 18h</span>
                    <div className="info-line">
                        <RiPhoneFill color="#f0ad4e"/>
                        <span>+55 11 3136-0078</span>
                        <CopyButton value="1131360078"/>
                    </div>
                    <div className="info-line mail">
                        <RiMailFill color="#f0ad4e"/>
                        <span>operacional@nimbusmeteorologia.com.br</span>
                        <CopyButton value="operacional@nimbusmeteorologia.com.br"/>
                    </div>
                </div>
            </div>
        </Popup>
    );
  };
  
export default ContactPopup;
