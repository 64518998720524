import { useEffect, useState, useCallback, useMemo } from 'react';

import { useAuth } from '../../../../contexts/auth';
import { useApiData } from '../../../../contexts/api-data';
import {
    filterRadarTelemetryMeasures,
    getRadios,
} from '../../../../services/api';

export function useTelemetryMeasuresBoxState() {
    const {
        manageTelemetryVariables: {
            fetch: fetchTelemetryVariables,
            value: telemetryVariables,
        },
    } = useApiData();
    const {
        settingsSet,
    } = useAuth();
    const {
        DASHBOARD_RADAR,
    } = { ...settingsSet };
    const [measures, setMeasures] = useState(null);
    const [loadingMeasures, setLoadingMeasures] = useState(false);
    const [radios, setRadios] = useState(null);
    const [loadingRadios, setLoadingRadios] = useState(false);
    const radarId = useMemo(() => DASHBOARD_RADAR, [DASHBOARD_RADAR]);

    useEffect(() => {
        fetchTelemetryVariables();
    }, [fetchTelemetryVariables]);

    useEffect(() => {
        if (radios || loadingRadios) {
            return;
        }
        (async function () {
            setLoadingRadios(true);
            const newRadios = await getRadios();
            setRadios(newRadios);
            setLoadingRadios(false);
        })();
    }, [radios, loadingRadios]);

    const fetchMeasures = useCallback(({
        radarId,
        radioId,
        datetimeStart,
        datetimeEnd,
        variables,
    }) => {
        if (loadingMeasures || !telemetryVariables || !datetimeStart || !datetimeEnd || !radios || !radarId) {
            return;
        }
        (async function () {
            setLoadingMeasures(true);
            const newMeasures = await filterRadarTelemetryMeasures({
                radar_id: radarId,
                radio_id: radioId,
                datetimeStart,
                datetimeEnd,
                variables,
            });
            newMeasures.sort((a, b) => b.datetime - a.datetime);
            setMeasures(newMeasures);
            setLoadingMeasures(false);
        })();
    }, [loadingMeasures, telemetryVariables, radios]);

    return {
        fetchMeasures,
        measures,
        radios,
        telemetryVariables,
        radarId,
    };
};
