import baseApi from '../../base';

export async function getBasinPrecipitationStages() {
    const result = await baseApi.get('/basin-precipitation-stages');
    return result.data;
};

export async function postManageBasinPrecipitationStages(stage) {
    const result = await baseApi.post('/manage/basin-precipitation-stages', stage);
    return result.data;
};

export async function putManageBasinPrecipitationStages(stage) {
    const result = await baseApi.put(`/manage/basin-precipitation-stages/${stage.id}`, stage);
    return result.data;
};

export async function deleteManageBasinPrecipitationStages(stageId) {
    const result = await baseApi.delete(`/manage/basin-precipitation-stages/${stageId}`);
    return result.data;
};
