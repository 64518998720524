import React from 'react';

import './styles.css';

function EntityCard({
    title,
    children,
}) {
    return (
        <div className="entity-card">
            <div className="entity-card-header">
                {title}
            </div>
            <div className="entity-card-content">
                {children}
            </div>
        </div>
    );
}

export default EntityCard;
