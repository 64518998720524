import baseApi from '../../base';

export async function getManageCustomers() {
    const result = await baseApi.get('/manage/customers');
    return result.data;
};

export async function postManageCustomers(customer) {
    const result = await baseApi.post('/manage/customers', customer);
    return result.data;
};

export async function putManageCustomers(customer) {
    const result = await baseApi.put(`/manage/customers/${customer.id}`, customer);
    return result.data;
};

export async function getManageCustomersForecastRegions(customerId) {
    const result = await baseApi.get(`/manage/customers/${customerId}/forecast-regions`);
    return result.data;
};
