import React from 'react';
import { Circle } from 'react-leaflet';

function RadarCircle({ selectedRadarOrSatellite }) {
    return (
        <Circle
            center={[selectedRadarOrSatellite.central_latitude, selectedRadarOrSatellite.central_longitude]}
            radius={selectedRadarOrSatellite.radius * 1000}
            pathOptions={{
                color: 'blue',
                weight: 2,
                fill: false,
                dashArray: '10',
            }}
        />
    );
}

export default RadarCircle;
