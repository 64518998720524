import { useCallback, useRef, useState } from 'react';

import { getForecastModels } from '../../../../services/api';

export function useForecastModelsState() {
    const [value, setValue] = useState([]);
    const loading = useRef(false);

    const fetch = useCallback(async function() {
        return await getForecastModels();
    }, []);

    const fetchAndSave = useCallback(async function() {
        if (loading.current || value.length > 0) {
            return;
        }
        loading.current = true;
        setValue(await getForecastModels());
        loading.current = false;
    }, [value]);

    return {
        fetch,
        fetchAndSave,
        value,
    };
};
