import React, { useEffect } from 'react';

import VariableTypeBox from '../variable-type-box';
import NewBtn from '../common/new-btn';
import { useApiData } from '../../../contexts/api-data';

import './styles.css';

function VariableTypesList() {
    const {
        manageVariableTypes: {
            add: addNewVariableType,
            fetch: fetchVariableTypes,
            value: variableTypes,
        },
    } = useApiData();

    useEffect(() => {
        fetchVariableTypes();
    }, [fetchVariableTypes]);

    return (<>
        <div className="variable-types-list-header">
            <h3>Tipos de variáveis</h3>
            <NewBtn
                newFunction={addNewVariableType}
                label="Novo"
            />
        </div>
        {variableTypes && variableTypes.map(variableType => (
            <VariableTypeBox
                key={variableType.id}
                variableType={variableType}
            />
        ))}
    </>);
}

export default VariableTypesList;
