import { useEffect, useState, useRef, useCallback } from 'react';

import api from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';

const getRegionModelsWithDraws = (regionModels, draws) => {
    const updatedRegionModels = regionModels.map((rm) => {
        const draw = draws?.find((d) => d.model_id === rm.model_id);
        if (draw) {
            return {
                ...rm,
                initial_latitude: draw.data[0]?._northEast.lat.toString(),
                initial_longitude: draw.data[0]?._southWest.lng.toString(),
                final_latitude: draw.data[0]?._southWest.lat.toString(),
                final_longitude: draw.data[0]?._northEast.lng.toString(),
            };
        } else {
            return {
                ...rm,
                initial_latitude: '0',
                initial_longitude: '0',
                final_latitude: '0',
                final_longitude: '0',
            };
        }
    });

    return updatedRegionModels;
}

export function useForecastRegionBoxState({ forecastRegion }) {
    const {
        forecastModels: {
            value: forecastModels,
        },
        manageForecastRegions: {
            update: updateRegion,
        },
    } = useApiData();

    const [updatedRegion, setUpdatedRegion] = useState(forecastRegion);
    const initialRegionModels = useRef([]);
    const [regionModels, setRegionModels] = useState([]);

    const creating = !updatedRegion.name;
    const [name, setName] = useState(updatedRegion.name || '');
    const [alias, setAlias] = useState(updatedRegion.alias || '');
    const [inactive, setInactive] = useState(!!updatedRegion.inactive);
    const [stationId, setStationId] = useState(updatedRegion.station_id);
    const [state_id, setStateId] = useState(updatedRegion.state_id);
    const [drawsToSave, setDrawsToSave] = useState([]);

    useEffect(() => {
        const updatedRegionModels = getRegionModelsWithDraws(
            regionModels,
            drawsToSave
        );

        if (
            JSON.stringify(regionModels) !== JSON.stringify(updatedRegionModels)
        ) {
            setRegionModels(updatedRegionModels);
        }
    }, [drawsToSave, regionModels]);

    function shouldSaveRegionBaseData() {
        if (creating) {
            return name?.length > 0 && alias?.length > 0;
        }
        const nameChanged = name !== updatedRegion.name;
        const aliasChanged = alias !== updatedRegion.alias;
        const inactiveChanged = inactive !== updatedRegion.inactive;
        const stationIdChanged = stationId !== updatedRegion.station_id;
        const stateIdChanged = state_id !== updatedRegion.state_id;
        return nameChanged
            || aliasChanged
            || inactiveChanged
            || stationIdChanged
            || stateIdChanged;
    }

    const shouldSaveRegionModels = useCallback (() => {
        const contentChanged = 
            JSON.stringify(initialRegionModels.current) !== JSON.stringify(regionModels);
        
            const lengthChanged = 
                initialRegionModels.current.length !== regionModels.length;

        return contentChanged || lengthChanged;
    }, [regionModels]);
    

    function shouldSaveImportRegionModels(){
        const importRegionModels = forecastRegion.regionModels;
        return importRegionModels;
    }

    function shouldSave() {
        if (shouldSaveImportRegionModels()){
            return;
        }else{
            return shouldSaveRegionBaseData() || shouldSaveRegionModels();
        }
    }

    function updateRegionModels(rawRegionModels) {
        const modelsIds = rawRegionModels.map(rm => rm.model_id);
        const newRegionModels = forecastModels.map(model =>
            modelsIds.includes(model.id)
                ? {
                    ...rawRegionModels.find(rm => rm.model_id === model.id),
                    model_name: model.name,
                    model_alias: model.alias,
                }
                : {
                    region_id: updatedRegion.id,
                    model_id: model.id,
                    model_name: model.name,
                    model_alias: model.alias,
                    initial_latitude: 0,
                    initial_longitude: 0,
                    final_latitude: 0,
                    final_longitude: 0,
                }
        );
        setRegionModels(newRegionModels);
        return newRegionModels;
    }

    async function saveRegion() {
        if (shouldSaveRegionModels()) {
            initialRegionModels.current = regionModels;
            api.post(`/manage/forecast-regions/${updatedRegion.id}/models`, {
                data: regionModels,
            });
        }
        if (shouldSaveRegionBaseData()) {
            const newName = name.trim();
            const newAlias = alias.trim();
            const regionToSend = {
                id: updatedRegion.id,
                name: newName,
                alias: newAlias,
                inactive,
                station_id: stationId,
                state_id,
            };
            const newId = await updateRegion(regionToSend);
            if (newId === null) {
                return;
            }
            regionToSend.id = newId;
            setUpdatedRegion(regionToSend);
            if (creating) {
                updateRegionModels([]);
            }
            setName(newName);
            setAlias(newAlias);
        }
    }

    async function getRegionModels() {
        const importRegionModels = forecastRegion.regionModels;
        if(importRegionModels){
            updateRegionModels(importRegionModels);
            initialRegionModels.current = importRegionModels;
        }else{
            const response = await api.get(`/manage/forecast-regions/${forecastRegion.id}/models`);
            const dbRegionModels = updateRegionModels(response.data);
            initialRegionModels.current = dbRegionModels;
        }
    }

    function getExtraInformation() {
        getRegionModels();
    }

    function setModelBoundary(model_id, property, event) {
        const newRegionModels = regionModels.map(rm => ({ ...rm }));
        const regionModel = newRegionModels.find(rm => rm.model_id === model_id);
        regionModel[property] = event.target.value;
        setRegionModels(newRegionModels);
    }

    return {
        creating,
        name,
        getExtraInformation,
        inactive,
        updatedRegion,
        setName,
        alias,
        setAlias,
        saveRegion,
        shouldSave,
        state_id,
        setStateId,
        stationId,
        regionModels,
        setInactive,
        setModelBoundary,
        setStationId,
        setDrawsToSave,
        initialRegionModels,
    };
};
