import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import BroadcastChannelBox from '../broadcast-channel-box';
import api from '../../../../services/api';

function BroadcastChannelsList() {
    const [broadcastChannels, setBroadcastChannels] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/broadcast-channels');
            setBroadcastChannels(response.data);
        })();
    }, []);

    function addNewBroadcastChannels() {
        const id = broadcastChannels?.length ?
            Math.max(...broadcastChannels.map(broadcastChannel => broadcastChannel.id)) + 1
            : 1;
        setBroadcastChannels([ ...broadcastChannels, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Canais de transmissão</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewBroadcastChannels}
            />
        </div>
        {broadcastChannels && broadcastChannels.map(broadcastChannel => (
            <BroadcastChannelBox
                key={broadcastChannel.id}
                broadcastChannel={broadcastChannel}
            />
        ))}
    </>);
}

export default BroadcastChannelsList;
