import React from 'react';

import SelectionInput from '../selection-input';

import { pageSelectionOptions } from './states';

function InitialPageSelectionInput({ defaultValue, setValue }) {
    return (
        <SelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={pageSelectionOptions}
        />
    );
}

export default InitialPageSelectionInput;
