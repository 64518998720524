import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../contexts/api-data';

function unitsAsString(units) {
    return units.map(({ label, formula }) => `${label}${formula}`).join(',');
}

export function useVariableTypeBoxStates(variableType) {
    const {
        manageVariableTypes: {
            save: saveVariableType,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            name: variableType.name || '',
        },
    });
    const name = watch('name');
    const [units, setUnits] = useState([]);
    const creating = !variableType.name;

    useEffect(() => {
        setUnits(variableType.units);
    }, [variableType]);
    
    function shouldSave() {
        const nameSet = name.length > 0;
        if (creating) {
            return nameSet;
        }
        const nameChanged = name !== variableType.name;
        const unitsChanged = unitsAsString(units) !== unitsAsString(variableType.units || []);
        return nameSet && (nameChanged || unitsChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = variableType;
            const variableTypeToSend = {
                name,
                units,
            };
            if (!creating) {
                variableTypeToSend.id = id;
            }
            saveVariableType(id, variableTypeToSend);
        }
    }

    return {
        creating,
        register,
        save,
        setUnits,
        shouldSave,
        title: name || '*Nome',
        units,
    };
};
