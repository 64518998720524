import React from 'react';

import { useSearchFormStates } from './states';

import './styles.css';

function SearchForm({
    fetchEvents,
    events,
    radarId,
}) {
    const {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchEvents,
    } = useSearchFormStates({
        fetchEvents,
        events,
        radarId,
    });

    return (
        <form
            className="radar-energy-events-search-form"
            onSubmit={searchEvents}
        >
            <div className="form-group">
                <label htmlFor="energy-events-datetime-start">Início:</label>
                <input
                    id="energy-events-datetime-start"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeStartValue}
                    onChange={event => updateDatetimeStart(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="energy-events-datetime-end">Fim:</label>
                <input
                    id="energy-events-datetime-end"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeEndValue}
                    onChange={event => updateDatetimeEnd(event.target.value)}
                />
            </div>

            <button
                className="search-form-btn"
                type="submit"
            >
                Pesquisar
            </button>
        </form>
    );
}

export default SearchForm;
