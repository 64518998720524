import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { pageSelectionOptions } from './states';

function MultiplePagesSelectionInput({ defaultValue, setValue }) {
    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={pageSelectionOptions}
        />
    );
}

export default MultiplePagesSelectionInput;
