import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../../contexts/api-data';

export function useBasinBoxStates(basin) {
    const {
        manageBasins: {
            save: saveBasin,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            name: basin.name || '',
            alias: basin.alias || '',
            inactive: basin.inactive || false,
        },
    });
    const name = watch('name');
    const alias = watch('alias');
    const inactive = watch('inactive');
    const creating = !basin.name;
    const title = useMemo(() => `${basin.name || ''} (${basin.alias || ''})`, [basin]);

    function shouldSave() {
        const nameAndAliasSet = name.length > 0 && alias.length > 0;
        if (creating) {
            return nameAndAliasSet;
        }
        const nameChanged = (name || '') !== (basin.name || '');
        const aliasChanged = (alias || '') !== (basin.alias || '');
        const inactiveChanged = inactive !== basin.inactive;
        return nameAndAliasSet && (aliasChanged || nameChanged || inactiveChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = basin;
            const basinToSend = {
                name,
                alias,
                inactive,
            };
            if (!creating) {
                basinToSend.id = id;
            }
            saveBasin(id, basinToSend);
        }
    }

    return {
        creating,
        register,
        save,
        shouldSave,
        title,
    };
};
