import React from 'react';

import SelectionInput from '../selection-input';

import { useRadarSelectionInputState } from './states';

function RadarSelectionInput({ defaultValue, setValue }) {
    const {
        radarsOptions,
    } = useRadarSelectionInputState();

    return radarsOptions ? (
        <SelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={radarsOptions}
        />
    ) : null;
}

export default RadarSelectionInput;
