import { useMemo, useEffect, useState, useCallback } from 'react';

import { getStationExtraDataByInstitution } from '../../../../services/api';
import { useSocket } from '../../../../hooks/use-socket';

export function useImpactWaysTableStates(institutionId, stations) {
    const [extraData, setExtraData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (extraData || loading || !institutionId) {
            return;
        }
        setLoading(true);
        (async function() {
            const newExtraData = await getStationExtraDataByInstitution(institutionId);
            setExtraData(newExtraData);
            setLoading(false);
        })();
    }, [extraData, loading, institutionId]);

    useSocket(
        'station-extra-data',
        useCallback((stationId, data) => {
            setExtraData(old => ({
                ...old,
                [stationId]: {
                    ...old?.[stationId],
                    ...data,
                },
            }));
        }, []),
    );

    const tableData = useMemo(() => {
        if (!stations || !extraData) {
            return null;
        }
        const tableHeader = ['N°', 'Estação', 'Via Urbana'];
        const tableContent = Object.keys(extraData).map(stationId => {
            const impactWay = extraData[stationId]['IMPACT_WAY'];
            if (!impactWay) {
                return null;
            }
            const station = stations.find(({ id }) => Number(stationId) === Number(id));
            if (!station) {
                return null;
            }
            const { name, code } = station;
            return [
                Number(code),
                name,
                impactWay,
            ];
        }).filter(Boolean);
        
        return [
            tableHeader,
            ...tableContent,
        ];
    }, [stations, extraData]);

    return {
        tableData,
    };
};
