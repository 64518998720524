import React from 'react';

import MessagesList from '../messages-list';

function MessagesBox() {
    return (
        <div className="dashboard-box6">
            <MessagesList />
        </div> 
    );
}

export default MessagesBox;
