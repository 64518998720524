import React from 'react';
import './styles.css';

export const Switch = ({ isOn, handleToggle, id = 1 }) => {
    return (
        <div className="react-switch-container">
            <input
                className="react-switch-checkbox"
                id={`react-switch-new-${id}`}
                type="checkbox"
                checked={isOn}
                onChange={handleToggle}
            />
            <label
                className={`react-switch-label ${isOn? 'switch-on' : 'switch-off'}`}
                htmlFor={`react-switch-new-${id}`}
            >
                <span className={`react-switch-button`} />
            </label>
        </div>
    );
};
