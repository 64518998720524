import {
    postManageStationMeasures,
} from '../../../../services/api';

export function useManageStationMeasuresState() {
    async function update(institutionId, measures) {
        const stationMeasures = {data: [measures]}
        await postManageStationMeasures(institutionId, stationMeasures);
    }
    return { update }
};
