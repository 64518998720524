import React from 'react';
import { Link } from 'react-router-dom';
import {
    FaPlus,
    FaTrash,
} from 'react-icons/fa';

import './styles.css';

function ArraySetting(SettingType) {
    return function ({ defaultValue, setValue, placeholder }) {
        function changeValue(newValue, index) {
            const newArray = [ ...(defaultValue || []) ];
            newArray[index] = newValue;
            setValue(newArray);
        }

        function removeValue(index) {
            const newArray = [ ...(defaultValue || []) ];
            newArray.splice(index, 1);
            setValue(newArray);
        }

        function addSlot() {
            const newArray = [ ...(defaultValue || []), null ];
            setValue(newArray);
        }

        return (<>
            {(defaultValue || []).map((value, index) => (
                <div
                    key={index}
                    className="array-setting-item"
                >
                    <SettingType
                        defaultValue={value}
                        setValue={newValue => changeValue(newValue, index)}
                        placeholder={placeholder}
                    />
                    <Link
                        to="#"
                        onClick={() => removeValue(index)}
                    >
                        <FaTrash />
                    </Link>
                </div>
            ))}
            <Link
                to="#"
                onClick={addSlot}
                className="array-setting-add-btn"
            >
                <FaPlus />
            </Link>
        </>);
    };
}

export default ArraySetting;
