import React from 'react';
import { FaHourglassHalf } from 'react-icons/fa';

import './styles.css';

function CronosLogo({ lightColor, fontSize }) {
    return (
        <div
            className={`cronos-logo ${lightColor ? 'cronos-logo-light-color' : ''}`}
            style={{ fontSize }}
        >
            <FaHourglassHalf className="cronos-logo-icon" />
            <span className="cronos-logo-bolder">CRONOS</span>
            <span className="cronos-logo-lighter">Web</span>
        </div>
    );
}

export default CronosLogo;
