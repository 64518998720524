import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import {
    getManageForecastRegions,
    postManageForecastRegions,
    putManageForecastRegions,
} from '../../../../services/api';

export function useManageForecastRegionsState() {
    const [fetched, setFetched] = useState(false);
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function() {
        if (fetched || loading) {
            return;
        }
        setLoading(true);
        const settings = await getManageForecastRegions();
        setValue(settings);
        setFetched(true);
        setLoading(false);
    }, [fetched, loading]);

    function newRegion() {
        const maxId = Math.max(...value.map(region => region.id));
        setValue([
            ...value,
            {
                id: maxId + 1,
            },
        ]);
    }

    async function update(region) {
        const sameName = value.find(({ id, name }) => id !== region.id && name === region.name);
        const sameAlias = value.find(({ id, alias }) => id !== region.id && alias === region.alias);
        if (sameName || sameAlias) {
            const fields = [
                sameName ? 'nome' : null,
                sameAlias ? 'identificador' : null,
            ].filter(Boolean);
            const errorMessageFields = fields.join(' e ');
            toast(`Erro! ${errorMessageFields} da região duplicado${fields.length > 1 ? 's' : ''}`, {
                type: 'error',
            });
            return null;
        }
        const newValue = [...value];
        const oldRegionIndex = newValue.findIndex(({ id }) => id === region.id);
        const oldRegion = newValue[oldRegionIndex];
        const newRegion = { ...region };
        if (!oldRegion.name) { // creating
            const response = await postManageForecastRegions(region);
            newRegion.id = response.id;
        } else { // updating
            await putManageForecastRegions(region);
        }
        newValue[oldRegionIndex] = newRegion;
        setValue(newValue);
        return newRegion.id;
    }

    return {
        fetch,
        new: newRegion,
        set: setValue,
        update,
        value,
    };
};
