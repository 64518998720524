import AlertTemplatesList from '../../../components/manage/alerts/alert-templates-list';
import AlertRealTimeTemplatesList from '../../../components/manage/alerts/real-time-alert-templates-list';
import BasinsList from '../../../components/manage/basins/basins-list';
import BasinPrecipitationStagesList from '../../../components/manage/basins/precipitation-stages-list';
import BasinSlipStagesList from '../../../components/manage/basins/slip-stages-list';
import RolesList from '../../../components/manage/roles-list';
import CustomersList from '../../../components/manage/customers-list';
import UsersList from '../../../components/manage/users-list';
import RealTimeMessagesList from '../../../components/manage/alerts/real-time-messages-list';
import ForecastModelsList from '../../../components/manage/forecasts/models-list';
import ForecastRegionsList from '../../../components/manage/forecasts/regions-list';
import CalendarMessagesList from '../../../components/manage/alerts/calendar-messages-list';
import PermissionsList from '../../../components/manage/permissions-list';
import RadarInstitutionsList from '../../../components/manage/radars/institutions-list';
import RadarsList from '../../../components/manage/radars/radars-list';
import RadiosList from '../../../components/manage/radars/radios-list';
import StaticPositionTypesList from '../../../components/manage/static/types-list';
import StaticPositionsList from '../../../components/manage/static/positions-list';
import StationInstitutionsList from '../../../components/manage/stations/institutions-list';
import StationsList from '../../../components/manage/stations/stations-list';
import AlertaRioStationsList from '../../../components/manage/alertario/stations-list';
import SystemSettings from '../../../components/manage/system/system-settings';
import SystemMonitor from '../../../components/manage/system/system-monitor';
import TelemetryVariablesList from '../../../components/manage/telemetry-variables-list';
import VariableTypesList from '../../../components/manage/variable-types-list';
import WeatherVariablesList from '../../../components/manage/weather-variables-list';
import CalendarMessagesSearch from '../../../components/manage/data/alerts/calendar-messages-search';
import StationConfigurationsList from '../../../components/manage/stations/configurations-list';
import StationProtocolsList from '../../../components/manage/stations/protocol-list';
import StationMeasuresWithSensorList from '../../../components/manage/alertario/station-measures-with-sensor-list';
import BroadcastChannelsList from '../../../components/manage/alertario/broadcast-channels-list';
import PhoneOperatorsList from '../../../components/manage/alertario/phone-operators-list';
import SensorsList from '../../../components/manage/alertario/sensors-list';
import SatelliteInstitutionsList from '../../../components/manage/satellites/satellite-institutions-list';
import SatellitesList from '../../../components/manage/satellites/satellite-list';
import SatelliteProductTypeList from '../../../components/manage/satellites/satellite-product-type-list'
import StationsMonitorList from '../../../components/manage/system/stations-monitor/stations-monitor-list';

export const manageSections = [
    {
        id: 'system',
        section: 'Sistema',
        pages: [
            {
                id: 'system-settings',
                title: '- Configurações',
                component: SystemSettings,
                permission: 'MANAGE_SYSTEM_SETTINGS',
            },
            {
                id: 'system-monitoring',
                title: '- Monitoramento',
                component: SystemMonitor,
                permission: 'VIEW_SYSTEM_MONITOR',
            },
            {
                id: 'delayed-stations-monitoring',
                title: '- Estações atrasadas',
                component: StationsMonitorList,
                permission: 'VIEW_DELAYED_STATIONS_MONITOR',
            },
        ],
    },
    {
        id: 'auth',
        section: 'Permissionamento',
        pages: [
            {
                id: 'permissions',
                title: '- Permissões',
                component: PermissionsList,
                permission: 'MANAGE_PERMISSIONS',
            },
            {
                id: 'roles',
                title: '- Papéis',
                component: RolesList,
                permission: 'MANAGE_ROLES',
            },
            {
                id: 'customers',
                title: '- Clientes',
                component: CustomersList,
                permission: 'MANAGE_CUSTOMERS',
            },
            {
                id: 'users',
                title: '- Usuários',
                component: UsersList,
                permission: 'MANAGE_USERS',
            },
        ],
    },
    {
        id: 'alerts',
        section: 'Alertas',
        pages: [
            {
                id: 'alert-templates',
                title: '- Templates Agenda',
                component: AlertTemplatesList,
                permission: 'MANAGE_CALENDAR_MESSAGES',
            },
            {
                id: 'real-time-alert-templates',
                title: '- Templates tempo real',
                component: AlertRealTimeTemplatesList,
                permission: 'MANAGE_REAL_TIME_MESSAGES',
            },
            {
                id: 'real-time-messages',
                title: '- Tempo real',
                component: RealTimeMessagesList,
                permission: 'MANAGE_REAL_TIME_MESSAGES',
            },
            {
                id: 'calendar-messages',
                title: '- Agenda',
                component: CalendarMessagesList,
                permission: 'MANAGE_CALENDAR_MESSAGES',
            },
        ],
    },
    {
        id: 'forecasts',
        section: 'Previsões',
        pages: [
            {
                id: 'forecast-models',
                title: '- Modelos',
                component: ForecastModelsList,
                permission: 'MANAGE_FORECAST_MODELS',
            },
            {
                id: 'forecast-regions',
                title: '- Regiões',
                component: ForecastRegionsList,
                permission: 'MANAGE_FORECAST_REGIONS',
            },
        ],
    },
    {
        id: 'static',
        section: 'Pontos Estáticos',
        permission: 'MANAGE_STATIC_POSITIONS',
        pages: [
            {
                id: 'static-position-types',
                title: '- Tipos',
                component: StaticPositionTypesList,
            },
            {
                id: 'static-positions',
                title: '- Posições',
                component: StaticPositionsList,
            },
        ],
    },
    {
        id: 'station',
        section: 'Estações',
        pages: [
            {
                id: 'station-institutions',
                title: '- Instituições',
                component: StationInstitutionsList,
                permission: 'MANAGE_STATION_INSTITUTIONS',
            },
            {
                id: 'stations',
                title: '- Todas as Estações',
                component: StationsList,
                permission: 'MANAGE_STATIONS',
            },
            {
                id: 'alertario-stations',
                title: '- Estações',
                component: AlertaRioStationsList,
                permission: 'MANAGE_ALERTARIO_STATIONS',
            },
            {
                id: 'station-configurations',
                title: '- Configurações',
                component: StationConfigurationsList,
                permission: 'MANAGE_STATION_CONFIGURATIONS',
            },
            {
                id: 'station-protocols',
                title: '- Protocolos',
                component: StationProtocolsList,
                permission: 'MANAGE_STATION_PROTOCOLS',
            },
        ],
    },
    {
        id: 'basin',
        section: 'Bacias',
        permission: 'MANAGE_BASINS',
        pages: [
            {
                id: 'basins',
                title: '- Bacias',
                component: BasinsList,
            },
            {
                id: 'precipitation-stages',
                title: '- Estágios de chuva',
                component: BasinPrecipitationStagesList,
            },
            {
                id: 'basin-slip-stages',
                title: '- Estágios de escorregamento',
                component: BasinSlipStagesList,
            },
        ],
    },
    {
        id: 'alertario',
        section: 'Sensores',
        permission: 'MANAGE_SENSORS',
        pages: [
            {
                id: 'station-sensors-measures',
                title: '- Leituras',
                component: StationMeasuresWithSensorList,
            },
            {
                id: 'sensors',
                title: '- Sensores',
                component: SensorsList,
            },
            {
                id: 'broadcast-channels',
                title: '- Canais',
                component: BroadcastChannelsList,
            },
            {
                id: 'phone-operators',
                title: '- Operadoras',
                component: PhoneOperatorsList,
            },
        ],
    },
    {
        id: 'radar',
        section: 'Radares',
        pages: [
            {
                id: 'radar-institutions',
                title: '- Instituições',
                component: RadarInstitutionsList,
                permission: 'MANAGE_RADARS',
            },
            {
                id: 'radars',
                title: '- Radares',
                component: RadarsList,
                permission: 'MANAGE_RADARS',
            },
            {
                id: 'radios',
                title: '- Rádios',
                component: RadiosList,
                permission: 'MANAGE_RADIOS',
            },
        ],
    },
    {
        id: 'satellite',
        section: 'Satélites',
        permission: 'MANAGE_SATELLITES',
        pages: [
            {
                id: "satellites-institutions",
                title: "- Instituições",
                component: SatelliteInstitutionsList,
            },
            {
                id: "satellites",
                title: "- Satélites",
                component: SatellitesList,
            },
            {
                id: "satellite-product-types",
                title: "- Tipos de Produtos",
                component: SatelliteProductTypeList,
            },
        ],
    },
    {
        id: 'data',
        section: 'Dados',
        pages: [
            {
                id: 'calendar-messages-search',
                title: '- Agenda',
                component: CalendarMessagesSearch,
                permission: 'VIEW_DATA_CALENDAR_MESSAGES',
            },
        ],
    },
    {
        id: 'variables',
        section: 'Variáveis',
        pages: [
            {
                id: 'variable-types',
                title: '- Tipos',
                component: VariableTypesList,
                permission: 'MANAGE_VARIABLE_TYPES',
            },
            {
                id: 'weather-variables',
                title: '- Climáticas',
                component: WeatherVariablesList,
                permission: 'MANAGE_WEATHER_VARIABLES',
            },
            {
                id: 'telemetry-variables',
                title: '- Telemétricas',
                component: TelemetryVariablesList,
                permission: 'MANAGE_TELEMETRY_VARIABLES',
            },
        ],
    },
];
