import React from 'react';

import { useAuth } from '../../../../contexts/auth';
import { useApiData } from '../../../../contexts/api-data';

function StationInstitutionSelection() {
    const { settingsSet } = useAuth();
    const apiData = useApiData();

    const {
        STATION_INSTITUTION_ALIASES: stationInstitutionAliases = [],
    } = { ...settingsSet };

    const {
        manageStationInstitutions: {
            value: stationInstitutions,
        },
        selectStationInstitution,
        selectedStationInstitution,
    } = apiData;

    const stationInstitutionAlias = selectedStationInstitution?.alias;

    function toggleStations(alias) {
        selectStationInstitution(stationInstitutionAlias !== alias
            ? alias
            : null
        );
    }

    return stationInstitutionAliases.length > 0 && stationInstitutions && (<>
        <div className="leftbar-title">Estações Meteorológicas</div>
        <ul className="list-group">
            {stationInstitutions
                .filter(({alias}) => stationInstitutionAliases.includes(alias))
                .map(({name, alias}) => (
                <li 
                    key={`station-institution-${alias}`}
                    className={`list-group-item list-group-item-secondary
                    ${stationInstitutionAlias === alias ? 'li-selected' : ''}`}
                    onClick={() => toggleStations(alias)}
                >
                    {name}
                </li>
            ))}
        </ul>
    </>)
}

export default StationInstitutionSelection;
