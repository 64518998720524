import DownloadForecasts from '../../components/download/forecasts/page';
import DownloadStationMeasurements from '../../components/download/station-measurements/page';

export const subpages = [
    {
        id: 'previsoes',
        title: 'Previsões',
        component: DownloadForecasts,
        setting: 'FORECASTS_DOWNLOAD',
    },
    {
        id: 'medidas-estacoes',
        title: 'Estações',
        component: DownloadStationMeasurements,
        setting: 'STATION_MEASUREMENTS_DOWNLOAD',
    },
];
