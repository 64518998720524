import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    FaPlus,
    FaTrash,
} from 'react-icons/fa';

import './styles.css'
export function RadiusInput({radiusValues, handleChangeRadius, addRadius, removeRadius }) {
    return (
        <Fragment>
            <span style={{ display: 'block' }}>Raios para perímetros de atenção (km)</span>
            {radiusValues.map((item, index) => (
                <div key={index} className="radius-input-container">
                    <input
                        value={item}
                        placeholder="Distância em km"
                        onChange={(event) => handleChangeRadius(index, event.target.value)}
                    />
                      <Link
                        to="#"
                        onClick={() => removeRadius(index)}
                    >
                        <FaTrash />
                    </Link>
                </div>
            ))}
            <Link
                to="#"
                onClick={() =>addRadius()}
                className="array-setting-add-btn"
            >
                <FaPlus />
            </Link>
        </Fragment>
    );
}