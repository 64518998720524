import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ImageOverlay, useMap } from 'react-leaflet';

import { useApiData } from '../../contexts/api-data';

function RadarImage() {
    const apiData = useApiData();
    const map = useMap();
    const [productId, setProductId] = useState(0);
    const [products, setProducts] = useState([]);
    const [bounds, setBounds] = useState(null);

    const imageRef = useRef(null);

    const {
        selectedRadarOrSatellite,
        selectedRadarOrSatelliteProduct
    } = apiData

    const onTimeLoad = useCallback(() => {
        setProductId(map.timeDimension._currentTimeIndex);
    }, [map.timeDimension]);

    useEffect(() => {
        if (!map.timeDimension?._events) {
            return;
        }

        map.timeDimension._events.timeload.push({ fn: onTimeLoad });
    }, [map.timeDimension, onTimeLoad]);

    useEffect(() => {
        setProducts(selectedRadarOrSatelliteProduct || []);
        setProductId(0);

        const option = selectedRadarOrSatellite;

        const bounds = option && [
            [option?.minor_latitude, option?.minor_longitude],
            [option?.major_latitude, option?.major_longitude],
        ];
        setBounds(bounds);
        if (imageRef.current) {
            imageRef.current.setBounds(bounds);
        }
    }, [selectedRadarOrSatellite, selectedRadarOrSatelliteProduct]);

    return bounds && products.length && (
        <ImageOverlay
            url={`${process.env.REACT_APP_BASE_API_URL}${products[productId].images[0].path}`}
            bounds={bounds}
            ref={imageRef}
        />
    );
}

export default RadarImage;