import React from 'react';

import AllowedBtn from '../allowed-btn';

import './styles.css';

function GetBtn(properties, ref) {
    const {
        label,
        className,
        ...rest
    } = properties;

    return (
        <AllowedBtn
            {...rest}
            className={`${className} get-btn`}
            label={label || 'Get'}
            ref={ref}
        />
    );
}

export default React.forwardRef(GetBtn);
