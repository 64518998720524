import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../contexts/api-data';

export function useTelemetryVariableBoxStates(variable) {
    const {
        manageTelemetryVariables: {
            save: saveTelemetryVariable,
        },
    } = useApiData();

    const { register, watch } = useForm({
        defaultValues: {
            key: variable.key || '',
            label: variable.label || '',
            is_numeric: !!variable.is_numeric,
        },
    });
    const key = watch('key');
    const label = watch('label');
    const is_numeric = watch('is_numeric');
    const creating = !variable.key;
    const title = useMemo(() => label || key || '', [label, key]);
    const isNumericInputId = `is-numeric-checkbox-${variable.id}`;

    function shouldSave() {
        const keyAndLabelSet = key.length > 0 && label.length > 0;
        if (creating) {
            return keyAndLabelSet;
        }
        const keyChanged = key !== variable.key;
        const labelChanged = label !== variable.label;
        const isNumericChanged = is_numeric !== variable.is_numeric;
        return keyAndLabelSet
            && (keyChanged || labelChanged || isNumericChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = variable;
            const variableToSend = {
                key,
                label,
                is_numeric,
            };
            if (!creating) {
                variableToSend.id = id;
            }
            saveTelemetryVariable(id, variableToSend);
        }
    }

    return {
        creating,
        isNumericInputId,
        register,
        save,
        shouldSave,
        title,
    };
};
