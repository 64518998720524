import React, { useMemo, useState, useRef, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    FaChartPie,
    FaChevronDown,
    FaChevronRight,
    FaCloudDownloadAlt,
    FaHome,
    FaMapMarkedAlt,
    FaSignOutAlt,
} from 'react-icons/fa';
import {
    MdSettings,
} from 'react-icons/md';
import {
    IoWaterOutline,
} from 'react-icons/io5';
import {
    FiRadio,
} from 'react-icons/fi';
// import {
//     WiDaySleetStorm,
//     WiRain,
// } from 'react-icons/wi';
import { VscGraph } from 'react-icons/vsc';
import {
    FiMenu,
    FiX,
} from 'react-icons/fi';
import {
    CiPaperplane,
} from 'react-icons/ci';

import {
    CLIMATE_FORECASTS,
    FORECASTS,
    FORECASTS_DOWNLOAD,
    LEGACY_FORECASTS,
    MAP_ALERT,
    OPERATION,
    RADAR_DASHBOARD,
    STATION_MEASUREMENTS_DOWNLOAD,
    STATION_PRECIPITATION_HISTORY,
    STATION_WEATHER_HISTORY,
    STATION_TELEMETRY_HISTORY,
} from '../common/custom-inputs/multiple-pages-selection-input/states';
import { useAuth } from '../../contexts/auth';

import './styles.css';

const sections = [
    {
        id: '/estacoes',
        title: 'Estações',
        icon: IoWaterOutline,
        pages: [
            {
                id: '/pluviometricos',
                key: STATION_PRECIPITATION_HISTORY,
                title: 'Histórico pluviométrico recente',
            },
            {
                id: '/meteorologicos',
                key: STATION_WEATHER_HISTORY,
                title: 'Histórico meteorológico recente',
            },
            {
                id: '/telemetricos',
                key: STATION_TELEMETRY_HISTORY,
                title: 'Telemetria',
            },
        ],
    },
    {
        id: '/radar',
        key: RADAR_DASHBOARD,
        title: 'Informações técnicas do Radar',
        icon: FiRadio,
    },
    {
        id: '/mapa',
        key: MAP_ALERT,
        title: 'Mapas e Alertas',
        icon: FaMapMarkedAlt,
    },
    {
        id: '/classificacao',
        key: LEGACY_FORECASTS,
        title: 'Previsão de chuva',
        icon: FaChartPie,
    },
    {
        id: '/previsoes',
        key: FORECASTS,
        title: 'Previsões',
        icon: FaChartPie,
    },
    {
        id: '/previsao-climatica',
        key: CLIMATE_FORECASTS,
        title: 'Previsão climática',
        icon: VscGraph,
    },
    {
        id: '/download',
        title: 'Download',
        icon: FaCloudDownloadAlt,
        pages: [
            {
                id: '/medidas-estacoes',
                key: STATION_MEASUREMENTS_DOWNLOAD,
                title: 'Estações',
            },
            {
                id: '/previsoes',
                key: FORECASTS_DOWNLOAD,
                title: 'Previsões',
            },
        ],
    },
    {
        id: '/operacao',
        key: OPERATION,
        title: 'Operação',
        icon: CiPaperplane,
    },
];

function NavigationMenu() {
    const location = useLocation();
    const {
        isManager,
        logout,
        settingsSet,
    } = useAuth();

    const [openSection, setOpenSection] = useState(null);
    const menuTimeout = useRef(null);
    const [retracted, setRetracted] = useState(true);
    const [menuOpenMobile, setMenuOpenMobile] = useState(false);

    const { PAGES = [] } = { ...settingsSet };

    const menuItems = useMemo(() => {
        return sections.reduce((result, section) => {
            const pages = (section.pages || [section]).filter(({ key }) => PAGES.includes(key));
            if (pages.length === 0) {
                return result;
            }
            if (!section.pages) {
                return result.concat(pages);
            }
            return result.concat([{
                ...section,
                pages,
            }]);
        }, []);
    }, [PAGES]);

    function selectSection(id) {
        if (id === openSection) {
            setOpenSection(null);
            return;
        }
        setOpenSection(id);
    }

    function retract() {
        if (menuTimeout.current) {
            clearTimeout(menuTimeout.current);
        }
        menuTimeout.current = setTimeout(() => {
            setRetracted(true);
            setOpenSection(null);
        }, 500);
    }
    function expand() {
        if (menuTimeout.current) {
            clearTimeout(menuTimeout.current);
        }
        menuTimeout.current = setTimeout(() => {
            setRetracted(false);
        }, 500);
    }

    return (
        <>
            <div
                className="navigation-menu-toggle-button"
                onClick={() => setMenuOpenMobile(!menuOpenMobile)}
            >
                {menuOpenMobile ? <FiX /> : <FiMenu />}
            </div>
            <div
                className={`
                    navigation-menu-container
                    ${menuOpenMobile ? 'open' : ''}
                    ${retracted ? 'retracted' : ''}
                `}
                onMouseLeave={retract}
                onMouseEnter={expand}
            >
                <div className="navigation-menu">
                    <Link
                        to="/"
                        className={`page-link-item ${location.pathname === '/' ? 'selected' : ''}`}
                        onClick={() => setMenuOpenMobile(false)}
                    >
                        <div>
                            <FaHome />
                            <span>Início</span>
                        </div>
                    </Link>
                    <span className="separator"></span>
                    {menuItems.map(({
                        id,
                        icon,
                        title,
                        pages,
                    }) => {
                        if (!pages) {
                            return (<Fragment key={id}>
                                <Link
                                    to={id}
                                    className={`page-link-item ${location.pathname.startsWith(id) ? 'selected' : ''}`}
                                    onClick={() => setMenuOpenMobile(false)}
                                >
                                    <div>
                                        {icon && icon({ size: '1rem' })}
                                        <span>{title}</span>
                                    </div>
                                </Link>
                                <span className="separator"></span>
                            </Fragment>);
                        }
                        return (<Fragment key={id}>
                            <div
                                className="pages-section-item"
                            >
                                <div
                                    className={`
                                        page-section-toggle
                                        ${location.pathname.startsWith(id) && openSection === null ? 'selected' : ''}
                                    `}
                                    onClick={() => selectSection(id)}
                                >
                                    <div>
                                        {icon && icon({ size: '1rem' })}
                                        <span>{title}</span>
                                        {openSection === id
                                            ? <FaChevronDown />
                                            : <FaChevronRight />
                                        }
                                    </div>
                                </div>
                                <div
                                    className="pages-link-items"
                                    style={{
                                        height: openSection === id ? 'max-content' : 0,
                                    }}
                                >
                                    {pages.map(({
                                        id: pageId,
                                        title,
                                    }) => (
                                        <Link
                                            key={pageId}
                                            to={`${id}${pageId}`}
                                            className={`
                                                page-link-item
                                                ${location.pathname.startsWith(`${id}${pageId}`) ? 'selected' : ''}
                                            `}
                                            onClick={() => setMenuOpenMobile(false)}
                                        >
                                            <div>
                                                - {title}
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <span className="separator"></span>
                        </Fragment>);
                    })}
                    {/* <Link to="/classificacao">Previsão de Chuva <WiRain /></Link>
                    <Link to="/previsoes">Previsões <WiDaySleetStorm /></Link>
                    <Link to="#">Previsão Climática <WiHumidity /></Link> */}
                    {isManager && (<>
                        <Link
                            to="/manage"
                            className={`page-link-item ${location.pathname.startsWith('/manage') ? 'selected' : ''}`}
                            onClick={() => setMenuOpenMobile(false)}
                        >
                            <div>
                                <MdSettings />
                                <span>Gerenciamento</span>
                            </div>
                        </Link>
                        <span className="separator"></span>
                    </>)}
                    <Link
                        to="#"
                        className="page-link-item"
                        onClick={logout}
                    >
                        <div>
                            <FaSignOutAlt />
                            <span>Sair</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default NavigationMenu;
