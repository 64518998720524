import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useStationMeasureWithSensorBoxStates } from './states';

import './styles.css';

function StationMeasureWithSensorBox({
        selectedStation,
        stationMeasure,
        phoneOperators,
        broadcastChannels,
        institutionId,
    }) {
    const {
        channel,
        FalseIcon,
        formatDatetime,
        formatDatetimeSent,
        formatDatetimeReceived,
        operator,
        reorder,
        ssmDatetimeReceivedInputId,
        ssmDatetimeSentInputId,
        ssmDatetimeInputId,
        ssmChannelInputId,
        ssmOperatorInputId,
        ssmReorderInputId,
        stationSensorValueInputId,
        save,
        sensorDatetimes,
        setMeasures,
        shouldSave,
        title,
        TrueIcon,
        updatedPrecipitationMeasures,
        updatedTelemetryMeasures,
        updatedWeatherMeasures,
    } = useStationMeasureWithSensorBoxStates(
        selectedStation,
        stationMeasure,
        phoneOperators,
        broadcastChannels,
        institutionId,
    );

    return (
        <EntityBox title={title}>
            <div className="entity-box-form station-sensor-measure-box-form">
                <div className="form-group">                    
                    <span htmlFor={ssmDatetimeInputId}>
                        <span className='label'>Data de Leitura:</span> {formatDatetime}
                    </span>
                </div>

                <div className="form-group">                    
                    <span htmlFor={ssmDatetimeSentInputId}>
                        <span className='label'>Data de Envio:</span> {formatDatetimeSent}
                    </span>
                </div>

                <div className="form-group">                    
                    <span htmlFor={ssmDatetimeReceivedInputId}>
                        <span className='label'>Data de Recepção:</span> {formatDatetimeReceived}
                    </span>
                </div>

                <div className="form-group">                    
                    <span htmlFor={ssmChannelInputId}>
                        <span className='label'>Canal:</span> {channel?.name}
                    </span>
                </div>

                <div className="form-group">                    
                    <span htmlFor={ssmOperatorInputId}>
                        <span className='label'>Operadora:</span> {operator?.name}
                    </span>
                </div>

                <div className="form-group">                    
                    <span htmlFor={ssmReorderInputId}>
                        <span className='label'>Repedida:</span> {reorder || 0}
                    </span>
                </div>

                <div className="form-group checkbox-input">
                    <span className='label'> Estatística:</span>
                    {sensorDatetimes.statistic ? <TrueIcon /> : <FalseIcon />}
                </div>

                <div className="form-group checkbox-input">
                    <span className='label'> É válido:</span>
                    {sensorDatetimes.is_valid ? <TrueIcon /> : <FalseIcon />}
                </div>

                {[
                    updatedPrecipitationMeasures,
                    updatedWeatherMeasures,
                    updatedTelemetryMeasures,
                ].map(measures => {
                    return measures?.length ? (
                        <div
                            key={`${stationSensorValueInputId}-${measures[0].key}-container`}
                            className="values-container"
                        >
                            {measures.map(({ key, value, label }) => {
                                return (
                                    <div
                                        key={`${stationSensorValueInputId}-${key}`}
                                        className="form-group"
                                    >
                                        <label htmlFor={`${stationSensorValueInputId}-${key}`}>
                                            {label}
                                        </label>
                                        <input
                                            id={`${stationSensorValueInputId}-${key}`}
                                            value={key.includes('precipitationtx') ? value : null}
                                            defaultValue={key.includes('precipitationtx') ? null : value}
                                            placeholder="Valor"
                                            type="number"
                                            disabled={key.includes('precipitationtx')}
                                            onChange={(event) => setMeasures(key, event.target.value)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    ) : null;
                })}

                <div className="form-group btn">
                    <PostBtn
                        postFunction={save}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default StationMeasureWithSensorBox;
