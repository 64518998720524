import React from 'react';
import { Link } from 'react-router-dom';

import CustomerBox from '../customer-box';
import './styles.css';

import { useCustomersListPageState } from './states';

function CustomersList() {
    const {
        addNewCustomer,
        customers,
        roles,
    } = useCustomersListPageState();
    
    return (<>
        <div className="customers-list-header">
            <h3>Clientes</h3>
            <Link
                to="#"
                className="new-customer-btn"
                onClick={addNewCustomer}
            >
                Novo
            </Link>
        </div>
        {customers && customers.map(customer => (
            <CustomerBox
                key={customer.id}
                customer={customer}
                rolesList={roles}
            />
        ))}
    </>);
}

export default CustomersList;
