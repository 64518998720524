import React from 'react';

import { Chart } from 'react-google-charts';

import datetimeUtils from '../../../../../../utils/datetime';

import './styles.css';

const tableHeader = [
    'Região',
    'Código',
    'Data de postagem',
    'Data de previsão',
    'Texto',
];

function CalendarMessagesSearchTable({ messages, forecastRegions }) {
    const messagesItems = messages.map(({
        forecast_region_id,
        code,
        run_datetime,
        datetime,
        text,
    }) => {
        const textValue = text || '[Mensagem Apagada]';
        const runDatetimeValue = datetimeUtils.getInputFormatDateFromDatetime(run_datetime, true);
        const datetimeValue = datetimeUtils.getInputFormatDateFromDatetime(datetime, true);
        const region = forecastRegions.find(({ id }) => id === forecast_region_id);
        return [region?.name, code, runDatetimeValue, datetimeValue, textValue];
    });

    const tableData = [
        tableHeader,
        ...messagesItems,
    ];

    return messages && messages.length ? 
        (<div className='calendar-messages-result'>
            <Chart
                chartType={'Table'}
                data={tableData}
                options={{
                    width: '100%',
                    allowHtml: true,
                }}
                className="calendar-messages-table"
            />
        </div>)
        : null;
}

export default CalendarMessagesSearchTable;
