import React from 'react';

import EntityBox from '../auth/entity-box';
import PostBtn from '../common/post-btn';
import { VariableTypeSelectionInput } from '../../common/custom-inputs';

import { useWeatherVariableBoxStates } from './states';

import './styles.css';

function WeatherVariableBox({ variable }) {
    const {
        creating,
        isNumericInputId,
        register,
        save,
        setTypeId,
        shouldSave,
        title,
        type_id,
        variableTypeInputId,
    } = useWeatherVariableBoxStates(variable);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="weather-variable-box-form">
                <input
                    placeholder="Propriedade"
                    {...register('key')}
                />
                <input
                    placeholder="Nome de exibição"
                    {...register('label')}
                />
                <label htmlFor={isNumericInputId}>
                    Numérica
                </label>
                <input
                    id={isNumericInputId}
                    type="checkbox"
                    {...register('is_numeric')}
                />
                <label htmlFor={variableTypeInputId}>
                    Tipo
                </label>
                <VariableTypeSelectionInput
                    defaultValue={type_id}
                    setValue={setTypeId}
                    isNullable={false}
                    inputId={variableTypeInputId}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default WeatherVariableBox;
