import { useApiData } from '../../../../contexts/api-data';

export function useSensorSelectionInputState() {
    const {
        sensors: {
            value: sensors,
        }
     } = useApiData();

    const sensorSelectionOptions = sensors
        .filter(sensor => !sensor.inactive)
        .map(({ name, id }) => {
            return {
                label: name,
                value: id,
            }
    });

    return sensorSelectionOptions;
};
