import downloadFile from '../../../download-file';
import baseApi from '../../base';

import datetimeUtils from '../../../../utils/datetime';

export async function getForecastModels() {
    const result = await baseApi.get('/forecast-models');
    return result.data;
};

function getDownloadQuery({
    region,
    run_datetime,
    frequency,
    datetimeStart,
    datetimeEnd,
    modelVariables,
}) {
    const queryObject = {
        region_id: region.id,
        run_datetime,
        frequency,
        datetimeStart,
        datetimeEnd,
        csv: true,
        ...modelVariables,
    };
    return Object.keys(queryObject).reduce((result, param) => {
        return result.concat(`${param}=${queryObject[param]}`);
    }, []).join('&');
}

function getDownloadFilename({
    run_datetime,
    datetimeStart,
    datetimeEnd,
    region,
}) {
    const [run, start, end] = [
        ['r', run_datetime],
        ['i', datetimeStart],
        ['f', datetimeEnd],
    ].map(([prefix, datetimeInMs]) => {
        const datetime = datetimeUtils.getFilenameFormatDatetimeFromDatetime(datetimeInMs, true);
        return `${prefix}${datetime}`;
    });
    return `${run}_${start}_${end}_${region.name}.csv`;
}

export async function downloadModel({
    model_id,
    ...queryParams
}) {
    const query = getDownloadQuery(queryParams);
    const filename = getDownloadFilename(queryParams);
    const result = await baseApi.get(
        `/forecast-models/${model_id}/download?${query}`,
        { responseType: 'blob' },
    );
    downloadFile(result.data, filename);
};

export async function manageDownloadModel({
    model_id,
    ...queryParams
}) {
    const query = getDownloadQuery(queryParams);
    const filename = getDownloadFilename(queryParams);
    const result = await baseApi.get(
        `/manage/forecast-models/${model_id}/download?${query}`,
        { responseType: 'blob' },
    );
    downloadFile(result.data, filename);
};
