import { useApiData } from '../../../../contexts/api-data';

export function useStaticPositionTypeSelectionInputState() {
    const { staticPositionTypes } = useApiData();

    const staticPositionTypeOptions = staticPositionTypes.map(({ name, alias }) => {
        return {
            label: name,
            value: alias,
        }
    });

    return staticPositionTypeOptions;
};
