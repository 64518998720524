import baseApi from '../../base';

export async function getManageAlertTemplates() {
    const response = await baseApi.get('/manage/alert-templates');
    return response.data;
};

export async function postManageAlertTemplate(template) {
    const response = await baseApi.post('/manage/alert-templates', template);
    return response.data;
};

export async function putManageAlertTemplate(template) {
    const response = await baseApi.put(`/manage/alert-templates/${template.id}`, template);
    return response.data;
};
