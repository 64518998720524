import React, { useMemo } from 'react';

import datetimeUtils from '../../../../utils/datetime';

function translateToInputFormat(datetime) {
    const date = datetimeUtils.getInputFormatDateFromDatetime(datetime || Date.now());
    const time = datetimeUtils.getInputFormatTimeFromDatetime(datetime || Date.now()).substring(0, 5);
    return [date, time].join('T');
}

function DatetimeLocalInput({
    value,
    setValue,
    inputId,
    disabled,
    min,
    max,
}) {
    const inputValue = useMemo(() => translateToInputFormat(value), [value]);
    const minInputValue = useMemo(() => translateToInputFormat(min), [min]);
    const maxInputValue = useMemo(() => translateToInputFormat(max), [max]);

    function translateToDatetimeAndChangeValue(event) {
        const inputDatetimeObject = new Date(`${event.target.value}:00`);
        setValue(inputDatetimeObject.getTime());
    }

    function disableTyping(event) {
        // only allow arrowing on input for changing with keyboard
        if (!event.key.startsWith('Arrow')) {
            event.preventDefault();
        }
    }

    return (
        <input
            type="datetime-local"
            id={inputId}
            value={inputValue}
            onChange={translateToDatetimeAndChangeValue}
            disabled={disabled}
            onKeyDown={disableTyping}
            min={minInputValue}
            max={maxInputValue}
        />
    );
}

export default DatetimeLocalInput;
