import baseApi from '../../base';

export async function getBasins() {
    const result = await baseApi.get('/basins');
    return result.data;
};

export async function postManageBasins(basin) {
    const result = await baseApi.post('/manage/basins', basin);
    return result.data;
};

export async function putManageBasins(basin) {
    const result = await baseApi.put(`/manage/basins/${basin.id}`, basin);
    return result.data;
};

export async function patchBasinStages(basins) {
    const result = await baseApi.patch(`/basins/stages`, basins);
    return result.data;
};
