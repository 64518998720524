import { useCallback, useState, useMemo } from 'react';

import { getImagesAmount } from '../../../services/api';

export function useSystemMonitorState() {
    const [imagesAmount, setImagesAmount] = useState(null);
    const value = useMemo(() => ({
        uploads: {
            imagesAmount,
        },
    }), [imagesAmount]);
    const [loading, setLoading] = useState(false);

    const fetchImagesAmount = useCallback(
        async function () {
            if (imagesAmount !== null || loading) {
                return;
            }
            setLoading(true);
            const newImagesAmount = await getImagesAmount();
            setImagesAmount(newImagesAmount);
            setLoading(false);
        },
        [imagesAmount, loading]
    );

    return {
        fetchImagesAmount,
        loading,
        value,
    };
};
