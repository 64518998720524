import { useEffect, useMemo, useState } from 'react';
import { useApiData } from '../../../../contexts/api-data';
import api from '../../../../services/api/base';

export function useMultipleRealTimeMessageCodeSelectionInputState({
    isReset,
    selectRealTimeCodes,
}) {
    const [selectedCustomerId, setSelectedCustomerId] = useState([]);
    const [customersByCodes, setCustomersByCodes] = useState([]);

    const {
        manageCustomers: { 
            fetch: fetchManageCustomers, 
            value: customers,
        }
    } = useApiData();

    useEffect(() => {
        fetchManageCustomers();
    }, [fetchManageCustomers]);

    useEffect(() => {
        const fetchCustomersByCalendarCodes = async () => {
            if (customers) {
                const customerPromises = customers
                    .filter(({ inactive }) => !inactive)
                    .map(async ({ id, name }) => {
                        const response = await api.get(
                            `/manage/customers/${id}/settings`
                        );
                        const customerSettingsCodes =
                            response.data.REAL_TIME_MESSAGE_CODES;
                        return {
                            name,
                            id,
                            realTimeCodes: customerSettingsCodes,
                        };
                    });
                const realTimeCodesByCustomer = await Promise.all(
                    customerPromises
                );

                setCustomersByCodes(realTimeCodesByCustomer);
            }
        };

        if (isReset) {
            setSelectedCustomerId([]);
            setCustomersByCodes([]);
        }

        fetchCustomersByCalendarCodes();
    }, [isReset, customers]);

    const realTimeMessageCodeOptions = useMemo(() => {
        const newSelectedCustomer = customersByCodes.filter((customer) =>
            selectedCustomerId.includes(customer.id)
        );

        if (newSelectedCustomer?.length) {
            const allCustomersCodes = Array.from(
                new Set(
                    newSelectedCustomer
                        ?.flatMap((customer) => customer.realTimeCodes || [])
                        .filter((code) => code)
                        .sort()
                )
            );

            return allCustomersCodes.map((code) => ({
                key: code,
                value: code,
                label: code,
            }));
        }
    }, [customersByCodes, selectedCustomerId]);

    const customerOptions = useMemo(() => {
        return (
            customersByCodes.map(({ id, name }) => ({
                key: id,
                value: id,
                label: `${name}`,
            })) || []
        );
    }, [customersByCodes]);

    const handleCustomerChange = (customerId) => {
        setSelectedCustomerId(customerId);
    };

    const handleRealTimeMessageCodesChange = (calendarCodes) => {
        selectRealTimeCodes(calendarCodes);
    };

    return {
        realTimeMessageCodeOptions,
        customerOptions,
        handleCustomerChange,
        handleRealTimeMessageCodesChange,
        selectedCustomerId,
        customersByCodes,
    };
};
