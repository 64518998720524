export const mockApiOptions = [
    {
        value: 'MANAGE_ALERT_TEMPLATES',
        label: 'Gerenciamento de templates de mensagens',
    },
    {
        value: 'MANAGE_CALENDAR_MESSAGES',
        label: 'Gerenciamento de mensagens de agenda',
    },
].sort((a, b) => a.label.localeCompare(b.label));

export function getMockApiSetting() {
    const value = localStorage.getItem('MOCK_API_SETTING');
    return value ? JSON.parse(value) : [];
};

export function setMockApiSetting(keys) {
    localStorage.setItem('MOCK_API_SETTING', JSON.stringify(keys));
};
