import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.css';
import 'leaflet-timedimension/dist/leaflet.timedimension.min';

import './styles/reset.css';
import './styles/index.css';
import './styles/main.css';

ReactDOM.render(<App />, document.getElementById('root'));
