import { useEffect, useRef, useState} from 'react';
import { useAuth } from '../../../auth';
import * as userUtils from '../../../../utils/user';
import { 
    getRadarProducts,
} from '../../../../services/api'; 
import {  useRadarsState } from '../radars/radar'; 

export function useSelectedRadar(){
    const [selectedRadar, setSelectedRadar] = useState(null);
    const [radarProducts, setRadarProducts] = useState(null);
    const radarProductsTimeoutIndex = useRef();
    const { settingsSet } = useAuth();

    const {
      fetchAndSave,
      value: radars  
    } = useRadarsState();

    async function selectRadar(alias) {
        const { RADAR_ALIASES = [] } = { ...settingsSet };

        if (!alias) {
            const selectedAlias = userUtils.getSelectedRadarsOrSatellite(alias);
            const selectedRadar = radars.find(({ alias }) => alias === selectedAlias);
            const firstActiveRadarAlias = RADAR_ALIASES.find(radarAlias => {
                const radar = radars.find(({ alias }) => alias === radarAlias);
                return radar ? !radar.inactive : null;
            });
            alias = (!selectedRadar || selectedRadar.inactive)
                ? firstActiveRadarAlias
                : selectedAlias;
        }

        const radar = radars.find(radar => radar.alias === alias);
        if (!radar) {
            return;
        }
        userUtils.setSelectedRadarsOrSatellite(alias);
        if (radarProductsTimeoutIndex.current) {
            clearTimeout(radarProductsTimeoutIndex.current);
        }
        await listRadarProducts(radar);

        setSelectedRadar(radar);
    }

    async function listRadarProducts(radar) {
        const result = await getRadarProducts(radar);
        setRadarProducts(result.reverse());
        const timeout = Number(radar.time_frequency) * 60000; // in minutes
        radarProductsTimeoutIndex.current = setTimeout(() => listRadarProducts(radar), timeout);
    }

    function resetRadar() {
        setSelectedRadar(null);
        setRadarProducts(null)
    }

    useEffect(() => {
        if (radars && radars.length && settingsSet) {
            selectRadar(); 
        };
        fetchAndSave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radars, settingsSet,fetchAndSave]);

    return {
        radars,
        selectedRadar,
        radarProducts,
        selectRadar,
        resetRadar,
    }
}