import React from 'react';
import Popup from 'reactjs-popup';

import BaseBtn from '../../../common/btn/base-btn';
import GetBtn from '../../../common/btn/get-btn';
import {
    DatetimeLocalInput,
    MultipleWeatherVariablesSelectionInput,
    StationSelectionInput,
} from '../../../common/custom-inputs';

import { useStationInstitutionDownloadPopupState } from './states';

import './styles.css';

function StationInstitutionDownloadPopup({ institution }) {
    const {
        datetimeEnd,
        datetimeStart,
        executeDownload,
        maximumDatetime,
        minimumDatetime,
        selectStations,
        setDatetimeEnd,
        setDatetimeStart,
        setStationId,
        setVariables,
        shouldDownload,
        stationId,
        variables,
    } = useStationInstitutionDownloadPopupState({ institution });

    return (
        <Popup
            trigger={<GetBtn label="Download" className="station-institution-download-trigger" />}
            position="center center"
            className="station-institution-download-modal"
            onOpen={selectStations}
            modal
            closeOnDocumentClick={false}
        >
            {close => (
                <div className="station-institution-download-popup-container">
                    <h5>Download da Instituição: {institution.name}</h5>
                    <div className="station-institution-download-options">
                        <div className="form-group">
                            <label htmlFor={'station-institution-download-station'}>
                                Estação
                            </label>
                            <StationSelectionInput
                                defaultValue={stationId}
                                isNullable={false}
                                inputId={'station-institution-download-station'}
                                setValue={value => setStationId(Number(value))}
                                stationsInstitutionId={institution.id}
                            />
                        </div>

                        <div className="form-group">
                            <span className="variables-title">Variáveis</span>
                            <MultipleWeatherVariablesSelectionInput
                                defaultValue={variables}
                                setValue={setVariables}
                                showOnlyItemsAmount={true}
                            />
                        </div>
                    </div>

                    <div className="station-institution-download-options">
                        <div className="form-group">
                            <label htmlFor="datetimeStart">Início</label>
                            <DatetimeLocalInput
                                inputId="datetimeStart"
                                value={datetimeStart}
                                setValue={setDatetimeStart}
                                disabled={!minimumDatetime}
                                min={minimumDatetime}
                                max={datetimeEnd}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="datetimeEnd">Fim</label>
                            <DatetimeLocalInput
                                inputId="datetimeEnd"
                                value={datetimeEnd}
                                setValue={setDatetimeEnd}
                                disabled={!minimumDatetime}
                                min={datetimeStart}
                                max={maximumDatetime}
                            />
                        </div>
                    </div>

                    <div className="station-institution-download-actions">
                        <BaseBtn
                            className="cancel-btn"
                            onClick={close}
                            label="Cancelar"
                        />
                        <GetBtn
                            onClick={executeDownload}
                            isAllowed={shouldDownload}
                            label="Baixar"
                        />
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default StationInstitutionDownloadPopup;
