import React, { useState } from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function StationInstitutionBox({ stationInstitution }) {
    const {
        manageStationInstitutions: {
            save: saveStationInstitution,
        },
    } = useApiData();

    const creating = !stationInstitution.name;
    const [name, setName] = useState(stationInstitution.name || '');
    const [alias, setAlias] = useState(stationInstitution.alias || '');

    function shouldSave() {
        const nameAndAliasAreSet = name?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndAliasAreSet;
        }
        const nameChanged = name !== stationInstitution.name;
        const aliasChanged = alias !== stationInstitution.alias;
        return nameAndAliasAreSet && (nameChanged || aliasChanged);
    }

    async function saveInstitution() {
        if (shouldSave()) {
            const { id } = stationInstitution;
            const institutionToSend = {
                name,
                alias,
            };
            if (!creating) {
                institutionToSend.id = id;
            }
            saveStationInstitution(id, institutionToSend);
        }
    }

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form institution-box-form">
                <div className="form-group">
                    <label htmlFor={`institution-name-${stationInstitution.id}`}>Nome</label>
                    <input
                        id={`institution-name-${stationInstitution.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`institution-alias-${stationInstitution.id}`}>Identificador</label>
                    <input
                        id={`institution-alias-${stationInstitution.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <PostBtn
                    postFunction={saveInstitution}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default StationInstitutionBox;
