import { useEffect } from 'react';

export default function useInterval(fn, intervalPeriod) {
    useEffect(() => {
        fn();
        const interval = setInterval(fn, intervalPeriod);

        return () => clearInterval(interval);
    }, [fn, intervalPeriod]);
};
