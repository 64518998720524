import baseApi from '../../base';

export async function getAstronomicForecasts(harbor_id, datetimeStart, datetimeEnd) {
    const query = [
        `harbor_id=${harbor_id}`,
        `datetimeStart=${datetimeStart}`,
        `datetimeEnd=${datetimeEnd}`,
    ].join('&');
    const result = await baseApi.get(`/astronomic-forecasts?${query}`);
    return result.data;
};
