import React from 'react';

import EntityBox from '../auth/entity-box';
import EntityRolesHeader from '../auth/entity-roles-header';
import { VariableTypeUnitsInput } from '../../common/custom-inputs';

import { useVariableTypeBoxStates } from './states';

import './styles.css';

function VariableTypeBox({ variableType }) {
    const {
        creating,
        register,
        save,
        setUnits,
        shouldSave,
        title,
        units,
    } = useVariableTypeBoxStates(variableType);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="variable-type-box-form">
                <input
                    placeholder="*Nome"
                    {...register('name')}
                />
                <EntityRolesHeader
                    title="Unidades de medida"
                    shouldSave={shouldSave}
                    saveFunction={save}
                />
                <VariableTypeUnitsInput
                    defaultValue={units}
                    setValue={setUnits}
                />
            </div>
        </EntityBox>
    );
}

export default VariableTypeBox;
