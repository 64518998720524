const MAIN_DASHBOARD = 'MAIN_DASHBOARD';
const STATIONS_DASHBOARD = 'STATIONS_DASHBOARD';

export const pageSelectionOptions = [
    {
        value: MAIN_DASHBOARD,
        label: 'Dashboard principal',
    },
    {
        value: STATIONS_DASHBOARD,
        label: 'Dashboard de estações',
    },
].sort((a,b) => a.label.localeCompare(b.label));
