import React, { useState } from 'react';
import './styles.css';

export const Tooltip = ({ children, text }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => {
        setVisible(true);
    };

    const hideTooltip = () => {
        setVisible(false);
    };

    return (
        <div
            className="switch-tooltip-container"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {visible && <div className="switch-tooltip">{text}</div>}
        </div>
    );
};
