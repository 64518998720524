import baseApi from '../../base';

export async function getManageRealTimeAlertTemplates() {
    const response = await baseApi.get('/manage/real-time-alert-templates');
    return response.data;
};

export async function postManageRealTimeAlertTemplate(template) {
    const response = await baseApi.post('/manage/real-time-alert-templates', template);
    return response.data;
};

export async function putManageRealTimeAlertTemplate(template) {
    const response = await baseApi.put(`/manage/real-time-alert-templates/${template.id}`, template);
    return response.data;
};
