import baseApi from '../../base';

const baseResource = '/synoptic-texts'

export async function getSynopticTexts(forecast_region_id) {
    const result = await baseApi.get(`${baseResource}?forecast_region_id=${forecast_region_id}`);
    return result.data;
};

export async function postSynopticTexts(synopticText) {
    const result = await baseApi.post(baseResource, synopticText);
    return result.data;
};
