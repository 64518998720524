import { useEffect, useMemo, useState } from 'react';
import { useApiData } from '../../../../contexts/api-data';
import api from '../../../../services/api/base';

export function useMultipleCustomerAndRegionsSelectionInputState({
    selectRegion,
    isReset,
    setIsReset,
}) {
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [regionsByCustomer, setRegionsByCustomer] = useState([]);

    const {
        manageCustomers: {
            fetch: fetchManageCustomers,
            value: customers,
        },
        manageForecastRegions: {
            fetch: fetchManageForecastRegions,
            value: manageForecastRegions,
        },
    } = useApiData();

    useEffect(() => {
        fetchManageCustomers();
    }, [fetchManageCustomers]);

    useEffect(() => {
        fetchManageForecastRegions();
    }, [fetchManageForecastRegions]);

    useEffect(() => {
        if (isReset) {
            setSelectedCustomerIds([]);
            setRegionsByCustomer([]);
            setIsReset(false);
        }
        (async () => {
            if (!customers || !manageForecastRegions) {
                return;
            }
            const response = await api.get(
                `/manage/customer-settings/group-by-customer?key=FORECAST_REGIONS`
            );
            const regionsByCustomer = customers
                .filter(({ inactive }) => !inactive)
                .map(({ id, name }) => {
                    const forecastRegions = (response.data[id] || []).map(id => (
                        manageForecastRegions.find(region => region.id === Number(id))
                    )).filter(Boolean);
                    return {
                        id,
                        name,
                        forecastRegions,
                    };
                });

            setRegionsByCustomer(regionsByCustomer);
        })();
    }, [isReset, setIsReset, customers, manageForecastRegions]);

    const regionsOptions = useMemo(() => {
        const newSelectedCustomers = regionsByCustomer.filter((customer) =>
            selectedCustomerIds.includes(customer.id)
        );

        if (newSelectedCustomers?.length) {
            const allCustomersRegions = [...new Set(
                newSelectedCustomers.flatMap((customer) => customer.forecastRegions)
            )].sort((a, b) => a.name.localeCompare(b.name));

            return allCustomersRegions.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
        }
        return [];
    }, [regionsByCustomer, selectedCustomerIds]);

    const customerOptions = regionsByCustomer?.map(({ id, name }) => ({
        value: id,
        label: `${name}`,
    })) || [];

    const handleCustomerChange = (customerId) => {
        setSelectedCustomerIds(customerId);
    };

    const handleRegionChange = (regions) => {
        selectRegion(regions);
    };

    return {
        selectedCustomerIds,
        handleCustomerChange,
        handleRegionChange,
        regionsByCustomer,
        regionsOptions,
        customerOptions,
    };
};
