import React, { useCallback, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useMapDrawerInputter } from '../../contexts/map-drawer-inputter';
import { ConfirmModal } from '../modals';

import './styles.css';

function MapList({ multiSelect, rectangles, onSelect, onDelete }) {
    const { selectedDrawIds, setMultiSelect, allowToConfirm, setAllowToConfirm } = useMapDrawerInputter();

    const [isOpen, setIsOpen] = useState(false);
    const [deletingDraw, setDeletingDraw] = useState(null);

    const closeModal = () => setIsOpen(false);

    const openModal = (draw) => {
        setIsOpen(true);
        setDeletingDraw(draw);
    };

    const confirmAction = useCallback(() => {
        onDelete(deletingDraw.layer?._leaflet_id);
        setTimeout(() => {
            setAllowToConfirm(false);
        },50)
    }, [deletingDraw, onDelete, setAllowToConfirm]);

    useEffect(() => {
        setMultiSelect(multiSelect);
    }, [multiSelect, setMultiSelect]);

    return (
        <div className="map-list">
            <p>Modelos salvos</p>
            <ul className="list-group">
                {rectangles && rectangles.map((rectangle) => {
                    if (rectangle.layer){
                        const isSelected = selectedDrawIds.includes(rectangle.layer?._leaflet_id);
                        return (
                            <li
                                key={rectangle.layer?._leaflet_id}
                                className={`list-group-item ${
                                    isSelected
                                        ? "li-selected"
                                        : "list-group-item-secondary"
                                }`}
                                onClick={() => onSelect(rectangle.layer?._leaflet_id)}
                            >
                                <p className="map-list-title">
                                    {rectangle.model_name}
                                </p>
                                {!allowToConfirm && 
                                    <FaTrash
                                        className="map-list-delete-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openModal(rectangle);
                                        }}
                                    />
                                }
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </ul>
            <ConfirmModal 
                header={`Excluir o retângulo do modelo ${deletingDraw?.model_name}`}
                open={isOpen}
                onClose={closeModal}
                onDeny={closeModal}
                onConfirm={confirmAction}
                positiveString='excluir'
                negativeString='cancelar'
            />
        </div>
    )
}

export default MapList;
