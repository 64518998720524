import React, { createContext, useContext, useEffect, useState } from 'react';

import { neighborhoodShapesAPI } from '../services/shapes';

const ShapesContext = createContext({});

function ShapesProvider({ children }) {
    const [neighborhood, setNeighborhood] = useState(null);

    useEffect(() => {
        getNeighborhood();
    }, []);

    async function getNeighborhood() {
        const result = await neighborhoodShapesAPI.get();
        if (!result.data.features) {
            return;
        }
        result.data.features.forEach(({ properties }) => {
            const {
                codra,
                area_plane,
                nome,
            } = properties;
            const CODAPNUM = [8, 9].includes(codra) // Tijuca, Vila Isabel
                ? 3
                : Math.min(4, Number(area_plane)); // there are 5 area_plane's in API
            properties.CODAPNUM = CODAPNUM;
            properties.nome = nome.trim();
        });
        setNeighborhood(result.data);
    }

    return (
        <ShapesContext.Provider
            value={{
                neighborhood,
            }}
        >
            {children}
        </ShapesContext.Provider>
    );
}

export default ShapesProvider;

export function useShapes() {
    return useContext(ShapesContext);
};
