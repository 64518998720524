import React from 'react';

import NewBtn from '../../common/new-btn';
import ForecastRegionBox from '../region-box';
import { useForecastRegionsListPageState } from './states';
import PostBtn from '../../common/post-btn';
import ForecastRegionExportPopup from '../regions-export-popup';

function ForecastRegionsList() {
    const {
        addNewRegion,
        forecastRegions,
        exportForecastRegions,
        importFile,
        saveForecastRegions,
        shouldSave,
    } = useForecastRegionsListPageState();

    return (<>
        <div className="header">
            <h3>Regiões de Previsão</h3>
            <div className='buttons'>
                <NewBtn
                    label="Nova"
                    newFunction={addNewRegion}
                />
                <ForecastRegionExportPopup
                    forecastRegion={forecastRegions}
                    exports={exportForecastRegions}
                    showOnlyItemsAmount={true}
                />
                <NewBtn
                    label="Importar"
                    newFunction={importFile}
                />
                <PostBtn
                    label={'Salvar todas'}
                    shouldPost={shouldSave}
                    postFunction={saveForecastRegions}
                />
            </div>
        </div>
        {forecastRegions.map(forecastRegion => (
            <ForecastRegionBox
                key={forecastRegion.id}
                forecastRegion={forecastRegion}
            />
        ))}
    </>);
}

export default ForecastRegionsList;
