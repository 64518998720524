import { useEffect, useState, useMemo } from 'react';

import datetimeUtils from '../../../../utils/datetime';
import { useApiData } from '../../../../contexts/api-data';

export function useSearchFormStates({
    fetchMeasures,
    lastMeasureDatetime,
    measures,
    stations,
    initialStationId,
    telemetryVariables,
}) {
    const {
        systemSettings: {
            fetch: fetchSystemSettings,
            value: systemSettings,
        },
    } = useApiData();
    const maximumDatetimeValue = datetimeUtils.getInputFormatDatetimeFromDatetime(Date.now());
    const [minimumDatetimeValue, setMinimumDatetimeValue] = useState(null);
    const [datetimeStartValue, setDatetimeStartValue] = useState(null);
    const [datetimeEndValue, setDatetimeEndValue] = useState(null);
    const [stationId, setStationId] = useState(null);
    const [variables, setVariables] = useState(null);
    const telemetryVariablesOptions = useMemo(
        () => telemetryVariables
            ?.map(({ key, label }) => ({
                label,
                value: key,
            })),
        [telemetryVariables],
        );

    const stationsOptions = stations
        ?.filter(({ inactive }) => !inactive)
        ?.map(({ id, name }) => ({
            value: id,
            label: name,
        }));

    useEffect(() => {
        fetchSystemSettings();
    }, [fetchSystemSettings]);

    useEffect(() => {
        if (minimumDatetimeValue || !systemSettings?.STATION_MEASUREMENTS_MAXIMUM_STORAGE_PERIOD) {
            return;
        }
        const minimumDatetime = Date.now() - systemSettings.STATION_MEASUREMENTS_MAXIMUM_STORAGE_PERIOD;
        const newMinimumValue = datetimeUtils.getInputFormatDatetimeFromDatetime(minimumDatetime);
        setMinimumDatetimeValue(newMinimumValue);
    }, [systemSettings, minimumDatetimeValue]);

    useEffect(() => {
        if (datetimeEndValue || !lastMeasureDatetime) {
            return;
        }
        const datetimeEnd = Number(lastMeasureDatetime);
        const datetimeStart = datetimeUtils.addNDays(datetimeEnd, -1);
        setDatetimeEndValue(datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeEnd));
        setDatetimeStartValue(datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeStart));
    }, [datetimeStartValue, datetimeEndValue, lastMeasureDatetime]);

    useEffect(() => {
        if (stationId || !initialStationId) {
            return;
        }
        setStationId(initialStationId);
    }, [initialStationId, stationId]);

    function updateDatetimeStart(newDatetimeStartValue) {
        setDatetimeStartValue(newDatetimeStartValue);
        if (newDatetimeStartValue < datetimeEndValue) {
            return;
        }
        const datetimeStart = (new Date(newDatetimeStartValue)).getTime();
        const newDatetimeEndValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeStart, 1));
        setDatetimeEndValue(newDatetimeEndValue);
    }

    function updateDatetimeEnd(newDatetimeEndValue) {
        setDatetimeEndValue(newDatetimeEndValue);
        if (datetimeStartValue < newDatetimeEndValue) {
            return;
        }
        const datetimeEnd = (new Date(newDatetimeEndValue)).getTime();
        const newDatetimeStartValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeEnd, -1));
        setDatetimeStartValue(newDatetimeStartValue);
    }

    useEffect(() => {
        if (measures || !initialStationId || !datetimeStartValue ||
            !datetimeEndValue || !variables || !lastMeasureDatetime) {
            return;
        }
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchMeasures({
            stationId: initialStationId,
            datetimeStart,
            datetimeEnd,
            variables,
        });
    }, [fetchMeasures,
        lastMeasureDatetime,
        measures,
        initialStationId,
        datetimeStartValue,
        datetimeEndValue,
        variables]);

    useEffect(() => {
        if (!telemetryVariables || variables) {
            return;
        }
        setVariables(telemetryVariablesOptions?.map(({ value }) => value));
    }, [telemetryVariables, variables, telemetryVariablesOptions]);

    async function searchMeasures(event) {
        event.preventDefault();
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchMeasures({
            stationId,
            datetimeStart,
            datetimeEnd,
            variables,
        });
    }

    return {
        maximumDatetimeValue,
        minimumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        stationsOptions,
        stationId,
        setStationId,
        variables,
        setVariables,
        telemetryVariablesOptions,
    };
};
