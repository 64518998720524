import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../contexts/api-data';

export function useWeatherVariableBoxStates(variable) {
    const {
        manageVariableTypes: {
            fetch: fetchVariableTypes,
        },
        manageWeatherVariables: {
            save: saveWeatherVariable,
        },
    } = useApiData();

    useEffect(() => {
        fetchVariableTypes();
    }, [fetchVariableTypes]);

    const { register, watch } = useForm({
        defaultValues: {
            key: variable.key || '',
            label: variable.label || '',
            is_numeric: !!variable.is_numeric,
            type_id: variable.type_id || null,
        },
    });
    const key = watch('key');
    const label = watch('label');
    const is_numeric = watch('is_numeric');
    const [type_id, setTypeId] = useState(variable.type_id || null);
    const creating = !variable.key;
    const title = useMemo(() => label || key || '', [label, key]);
    const isNumericInputId = `is-numeric-checkbox-${variable.id}`;
    const variableTypeInputId = `variable-type-selection-${variable.id}`;

    function shouldSave() {
        const keyAndLabelAndTypeSet = key.length > 0 && label.length > 0 && type_id !== null;
        if (creating) {
            return keyAndLabelAndTypeSet;
        }
        const keyChanged = key !== variable.key;
        const labelChanged = label !== variable.label;
        const isNumericChanged = is_numeric !== variable.is_numeric;
        const typeIdChanged = type_id !== variable.type_id;
        return keyAndLabelAndTypeSet
            && (keyChanged || labelChanged || isNumericChanged || typeIdChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = variable;
            const variableToSend = {
                key,
                label,
                is_numeric,
                type_id,
            };
            if (!creating) {
                variableToSend.id = id;
            }
            saveWeatherVariable(id, variableToSend);
        }
    }

    return {
        creating,
        isNumericInputId,
        register,
        save,
        setTypeId,
        shouldSave,
        title,
        type_id,
        variableTypeInputId,
    };
};
