import { useEffect, useState } from 'react';
import { useApiData } from '../../../contexts/api-data';
import api from '../../../services/api';

export function useUsersListPageState() {
    const {
        manageCustomers: {
            fetch: fetchCustomers,
        },
        manageRoles: {
            value: roles,
            fetch: fetchRoles,
        },
    } = useApiData();

    const [filterName, setFilterName] = useState('');
    const [filterActive, setFilterActive] = useState(1);
    const [filterCustomerId, setFilterCustomerId] = useState(0);
    const [sortOptions, setSortOptions] = useState('name=');
    const [users, setUsers] = useState(null);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    useEffect(() => {
        (async function () {
            const response = await api.get('/manage/users');
            setUsers(response.data);
        })();
    }, []);

    const filterCustomerDefaultOption = {
        value: 0,
        label: 'Todos',
    };

    function addNewUser() {
        const id = Math.max(...users.map(user => user.id)) + 1;
        setUsers([
            { id, inactive: false },
            ...users,
        ]);
    }

    function changeSortOptions(newSortOptions) {
        setSortOptions(newSortOptions);
    }

    function sortUsers(a, b) {
        const [variable, desc] = sortOptions.split('=');
        const [x, y] = [a, b].map((u) => u[variable]);
        return x < y
            ? [-1, 1][Number(Boolean(desc))]
            : (x > y
                ? [1, -1][Number(Boolean(desc))]
                : 0);
    }

    return {
        addNewUser,
        changeSortOptions,
        filterActive,
        filterCustomerDefaultOption,
        filterCustomerId,
        filterName,
        roles,
        setFilterActive,
        setFilterCustomerId,
        setFilterName,
        sortOptions,
        sortUsers,
        users,
    };
};
