import { useCallback, useState } from 'react';

import { getManageHarbors } from '../../../../services/api';

export function useManageHarborsState() {
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function() {
        if (loading || value.length > 0) {
            return;
        }
        setLoading(true);
        const harbors = await getManageHarbors();
        setValue(harbors);
        setLoading(false);
    }, [loading, value]);

    return {
        fetch,
        loading,
        value,
    };
};
