import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';
import DeleteBtn from '../../../common/btn/delete-btn';

import { useBasinPrecipitationStageBoxStates } from './states';

import './styles.css';

function BasinPrecipitationStageBox({ stage }) {
    const {
        creating,
        deleteStage,
        register,
        save,
        shouldSave,
        title,
    } = useBasinPrecipitationStageBoxStates(stage);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="stage-box-form">
                <div className="form-group">
                    <label>Nível *</label>
                    <input
                        type="number"
                        {...register('level')}
                    />
                </div>
                <div className="form-group">
                    <label>Descrição *</label>
                    <input
                        {...register('description')}
                    />
                </div>
                <div className="form-group">
                    <label>Cor *</label>
                    <input
                        type="color"
                        {...register('color')}
                    />
                </div>
                
                <div className="actions">
                    <PostBtn
                        shouldPost={shouldSave}
                        postFunction={save}
                    />
                    {!creating && <DeleteBtn
                        onClick={deleteStage}
                        isAllowed={() => !creating}
                        label="Apagar"
                    />}
                </div>
            </div>
        </EntityBox>
    );
}

export default BasinPrecipitationStageBox;
