import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import StationProtocolBox from '../protocol-box';
import api from '../../../../services/api';

function StationProtocolsList() {
    const [stationProtocols, setStationProtocols] = useState([]);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/manage/station-protocols');
            setStationProtocols(response.data);
        })();
    }, []);

    function addNewProtocol() {
        const id = stationProtocols?.length ?
            Math.max(...stationProtocols.map(protocol => protocol.id)) + 1
            : 1;
        setStationProtocols([ ...stationProtocols, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Protocolos de estações do Alerta Rio</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewProtocol}
            />
        </div>
        {stationProtocols && stationProtocols.map(protocol => (
            <StationProtocolBox
                key={protocol.id}
                protocol={protocol}
            />
        ))}
    </>);
}

export default StationProtocolsList;
