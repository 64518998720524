import React from 'react';

import AllowedBtn from '../allowed-btn';

import './styles.css';

function DeleteBtn(properties, ref) {
    const {
        label,
        className,
        ...rest
    } = properties;

    return (
        <AllowedBtn
            {...rest}
            className={`${className} delete-btn`}
            label={label || 'Delete'}
            ref={ref}
        />
    );
}

export default React.forwardRef(DeleteBtn);
