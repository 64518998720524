import React, { useMemo, useEffect, useState, useRef } from 'react';
// import ReactDOMServer from 'react-dom/server';
import {
    ImArrowUp,
    ImArrowDown,
    ImMinus,
    ImBlocked,
    ImWarning,
    ImInfo,
} from 'react-icons/im';
import { FaNetworkWired } from 'react-icons/fa';

import { useApiData } from '../../../../contexts/api-data';

export const slipStages = [
    {
        level: 1,
        label: 'Baixo',
        color: '#008200',
    },
    {
        level: 2,
        label: 'Médio',
        color: '#E3D000',
    },
    {
        level: 3,
        label: 'Alto',
        color: '#FF0000',
    },
    {
        level: 4,
        label: 'Muito Alto',
        color: '#000000',
    },
];

function getPeriod(key) {
    if (key.includes('precipitationtx')) {
        return 12*60*24*30 + Number(key.replace('precipitationtx', '').replace('min', ''));
    }
    const timePeriodStr = key.replace('precipitation', '').replace('tx', '');
    if (timePeriodStr.includes('min')) {
        return Number(timePeriodStr.replace('min', ''));
    }
    if (timePeriodStr.includes('h')) {
        return Number(timePeriodStr.replace('h', ''))*60;
    }
    if (timePeriodStr.includes('m')) {
        return Number(timePeriodStr.replace('m', ''))*60*24*30;
    }
    return 0;
}

function getTendency(measures) {
    if (!measures) {
        return undefined;
    }
    const {
        precipitation5min,
        precipitation10min,
    } = measures;
    if ([
        precipitation5min,
        precipitation10min,
    ].some(value => [null, undefined].includes(value) || isNaN(value))) {
        return undefined;
    }
    return 2*Number(precipitation5min) - Number(precipitation10min);
}

function getTendencyIconAndColor(tendency) {
    if ([null, undefined].includes(tendency) || isNaN(tendency)) {
        return [ImBlocked, null];
    }
    if (tendency > 0) {
        return [ImArrowUp, 'hsl(240, 50%, 50%)'];
    }
    if (tendency < 0) {
        return [ImArrowDown, 'hsl(0, 50%, 50%)'];
    }
    return [ImMinus, null];
}

function getClassName(measures) {
    if (!measures) {
        return undefined;
    }
    const { precipitation1h } = measures;
    if (measures.is_delayed) {
        return 'row-prec-atrasada';
    }
    if (precipitation1h >= 0.2 && precipitation1h <= 5.0) {
        return 'row-prec-fraca';
    }
    if (precipitation1h > 5.0 && precipitation1h <= 25.0) {
        return 'row-prec-moderada';
    }
    if (precipitation1h > 25.0 && precipitation1h <= 50.0) {
        return 'row-prec-forte';
    }
    if (precipitation1h > 50.0) {
        return 'row-prec-muito-forte';
    }
}

function getIndividualTrigger(measures) {
    if (!measures) {
        return undefined;
    }
    const {
        precipitation15min,
        precipitation30min,
        precipitation1h,
        precipitation24h,
    } = measures;
    if (precipitation15min > 25
        || precipitation30min > 50
        || precipitation1h > 60
        || precipitation24h > 220
    ) {
        return 5;
    }
    if (precipitation15min > 15 || precipitation30min > 25) {
        return 4;
    }
    if (precipitation15min > 10 || precipitation1h > 25) {
        return 3;
    }
    if (precipitation1h > 5 && precipitation1h <= 20) {
        return 2;
    }
    if (precipitation1h > 0 && precipitation1h <= 5) {
        return 1;
    }
}

function shouldKeepTrigger(trigger, triggerMap) {
    if (trigger === 1 && (triggerMap[1]?.length || 0) < 3) {
        return false;
    }
    if (trigger === 2 && (triggerMap[2]?.length || 0) < 2) {
        return false;
    }
    const firstTriggerMeasure = triggerMap[trigger]?.[0];
    if (!firstTriggerMeasure) {
        return false;
    }
    const {
        precipitation30min,
        precipitation1h,
        precipitation24h,
    } = firstTriggerMeasure;
    if (trigger === 3 && triggerMap[3].length === 1 && precipitation1h <= 25) {
        return false;
    }
    if (trigger === 4 && triggerMap[4].length === 1 && precipitation30min <= 25) {
        return false;
    }
    if (trigger === 5
        && triggerMap[5].length === 1
        && precipitation30min <= 50
        && precipitation1h <= 60
        && precipitation24h <= 220
    ) {
        return false;
    }
    return true;
}

function setTriggers(measures, triggerMap) {
    measures.forEach(measure => {
        if (!measure) {
            return;
        }
        const { individualTrigger } = measure;
        measure.trigger = shouldKeepTrigger(individualTrigger, triggerMap)
            ? individualTrigger
            : 0;
    });
}

export function usePrecipitationTableStates(stations) {
    const {
        manageBasins: {
            value: basins,
            fetch: fetchBasins,
        },
        manageWeatherVariables: {
            value: weatherVariables,
        },
    } = useApiData();

    useEffect(() => {
        fetchBasins();
    }, [fetchBasins]);

    const [hiddenColumns, setHiddenColumns] = useState([]);
    const hiddingColumn = useRef(false);

    function hideColumn(index) {
        hiddingColumn.current = true;
        setHiddenColumns(old => [...old, index]);
    }

    function showAllColumns() {
        setHiddenColumns([]);
    }

    const tableData = useMemo(() => {
        if (!stations || !basins || !weatherVariables) {
            return null;
        }
        const variables = [];
        const slipStageVariables = [];
        const triggerMap = {};
        const measures = stations.reduce((result, {
            id: station_id,
            code,
            name,
            basin_id,
            measures,
            telemetryMeasures,
            inactive,
            is_delayed,
        }) => {
            if (!measures || inactive) {
                return result;
            }
            const { datetime } = measures;
            const precipitationMeasures = {};
            Object.keys(measures).forEach(key => {
                if (
                    !key.includes('precipitation')
                    && !key.includes('SLIP_STAGE')
                ) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const weatherVariable = weatherVariables.find(v => v.key === key);
                    if (weatherVariable) {
                        if (key.includes('precipitation')) {
                            variables.push(weatherVariable);
                        }
                        else if (key.includes('SLIP_STAGE')) {
                            slipStageVariables.push(weatherVariable);
                        }
                    }
                }
                precipitationMeasures[key] = measures[key];
            });
            const tendency = getTendency(measures);
            const { battery_voltage } = { ...telemetryMeasures };
            const individualTrigger = getIndividualTrigger(measures);
            if (!isNaN(individualTrigger) && individualTrigger > 0) {
                triggerMap[individualTrigger] = [
                    ...(triggerMap[individualTrigger] || []),
                    precipitationMeasures,
                ];
            }
            result.push({
                ...precipitationMeasures,
                tendency,
                datetime,
                station_id,
                code,
                name,
                basin_id,
                battery_voltage,
                individualTrigger,
                is_delayed,                
            });
            return result;
        }, []);

        setTriggers(measures, triggerMap);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => {
            return getPeriod(a.key) - getPeriod(b.key);
        });

        const tableHeader = ['N°', 'Estação', 'Bacia', 'Hora da Leitura', '', 'Tendência'].concat(
            variables.map(({ label }) => label?.replace('Chuva', '')?.trim()),
        ).concat([
            'Escorregamento',
            'Gatilho',
        ]);
        const tableContent = measures.map(measure => {
            const {
                datetime,
                name,
                code,
                basin_id,
                tendency,
                battery_voltage,
                trigger,
                SLIP_STAGE_LEVEL,
                AUTOMATIC_SLIP_STAGE,
                SLIP_STAGE_PERIOD,
            } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} - ${time}`;
            const basin = basins.find(({ id }) => id === basin_id);
            const basinName = basin ? basin.name : '-';
            const [TendencyIcon, tendencyColor] = getTendencyIconAndColor(tendency);
            const className = getClassName(measure);

            const batteryVoltageIcon = battery_voltage < 12
                ? <ImWarning color="#e00" title="Tensão da Bateria Abaixo de 12.0" />
                : (battery_voltage < 12.3
                    ? <ImInfo color="#FA0" title="Tensão da Bateria Abaixo de 12.3" />
                    : null);

            const slipStageLevel = Number(SLIP_STAGE_LEVEL);
            const autoSlipStageLevel = Number(AUTOMATIC_SLIP_STAGE);
            const slipStage = slipStages.find(({ level }) => level === autoSlipStageLevel);
            const slipStagePeriod = autoSlipStageLevel > 1 ? ` - ${SLIP_STAGE_PERIOD}` : '';
            const slipStageLabel = slipStage?.label ? `${slipStage?.label}${slipStagePeriod}` : 'ND';
            const shouldBlinkSlipStage = slipStage && slipStageLevel !== autoSlipStageLevel;

            return [
                Number(code),
                {
                    v: name,
                    f: (<>
                        {name} {batteryVoltageIcon}
                    </>)
                },
                basinName,
                {
                    v: datetime,
                    f:
                        <a
                            href={`/estacoes/pluviometricos?stationId=${measure.station_id}&lastMeasureDatetime=${measure.datetime}`}
                            className="access-link"
                        >
                            {datetimeStr}
                        </a>,
                },
                {
                    v: '',
                    f:
                        <a
                            href={`/estacoes/telemetricos?stationId=${measure.station_id}&lastMeasureDatetime=${measure.datetime}`}
                            className="access-link"
                        >
                            <FaNetworkWired title="Telemetria" />
                        </a>,
                },
                {
                    v: tendency,
                    f: <TendencyIcon color={tendencyColor} />,
                },
            ].concat(variables.map(({ key }) => {
                if (isNaN(measure[key])) {
                    return measure[key];
                }
                const v = Number(Number(measure[key]).toFixed(2));
                return {
                    v,
                    f: Number(v).toLocaleString('pt-BR'),
                };
            })).concat([
                {
                    v: autoSlipStageLevel || 0,
                    f: (
                        <span
                            className={`slip-stage ${shouldBlinkSlipStage ? 'animate' : ''}`}
                            style={{
                                '--color': slipStage?.color || 'black',
                            }}
                        >
                            {slipStageLabel}
                        </span>
                    )
                },
                trigger || ({
                    f: '',
                    v: 0,
                }),
                className,
            ]);
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [stations, basins, weatherVariables]);

    const colspans = useMemo(() => {
        const identification = hiddenColumns.reduce((result, index) => index < 5 ? result-1 : result, 5);
        const precipitation = hiddenColumns.reduce(
            (result, index) => index >=5 && index<tableData?.[0]?.length-2 ? result-1 : result,
            tableData?.[0]?.length - 7,
        );
        const alarms = 2;
        return {
            identification,
            precipitation,
            alarms,
        }
    }, [hiddenColumns, tableData]);

    return {
        colspans,
        hiddenColumns,
        hiddingColumn,
        hideColumn,
        showAllColumns,
        tableData,
    };
};
