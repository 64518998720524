import { useState, useRef, useMemo, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { FaUserCheck, } from 'react-icons/fa';
import {
    RiMailCheckFill,
    RiMailCloseFill
} from 'react-icons/ri';

import api from '../../../services/api';

import { useApiData } from '../../../contexts/api-data';

const PENDING_INVITATION_ICON = {
    icon: RiMailCloseFill,
    className: 'red',
    popup: 'Convite não enviado.',
};
const REGISTRATION_PENDING_ICON = {
    icon: RiMailCheckFill,
    className: 'orange',
    popup: 'Usuário não se registrou.',
};
const REGISTERED_USER_ICON = {
    icon: FaUserCheck,
    className: 'green',
    popup: 'Usuário cadastrado.',
};

export function useUserBoxState({ user }) {
    const {
        manageCustomers: {
            value: customersList,
        },
    } = useApiData();

    const [updatedUser, setUpdatedUser] = useState(user);
    const initialBotWhatsappUser = useRef({});
    const [botWhatsappUser, setBotWhatsappUser] = useState({});
    const initialUserRoles = useRef([]);
    const [userRoles, setUserRoles] = useState([]);

    const creating = !updatedUser.email;

    const [title, setTitle] = useState(updatedUser.name || '');
    const [name, setName] = useState(updatedUser.name || null);
    const [email, setEmail] = useState(updatedUser.email || '');
    const [inactive, setInactive] = useState(!!updatedUser.inactive);
    const [customer_id, setCustomerId] = useState(updatedUser.customer_id);
    const [inviteCode, setInviteCode] = useState(updatedUser.invite_code || '');

    const customerName = useMemo(() => {
        const customer = customersList?.find((c) => c.id === customer_id);
        return customer?.name || '';
    }, [customersList, customer_id]);

    const titleIcon = useMemo(() => {
        return updatedUser.password_set
        ? REGISTERED_USER_ICON
        : (inviteCode
            ? REGISTRATION_PENDING_ICON
            : PENDING_INVITATION_ICON
        );
    }, [updatedUser, inviteCode]);

    function shouldSaveUser() {
        const nameChanged = (name || '') !== (updatedUser.name || '');
        const emailChanged = (email || '') !== (updatedUser.email || '');
        const inactiveChanged = inactive !== updatedUser.inactive;
        const customerIdChanged = customer_id !== updatedUser.customer_id;

        return customer_id && (
            nameChanged ||
            emailChanged ||
            inactiveChanged ||
            customerIdChanged
        );
    }

    function shouldSaveBotWhatsappUser() {
        return (
            ['phone_number', 'inactive'].some((prop) => {
                return (
                    botWhatsappUser[prop] !==
                    initialBotWhatsappUser.current[prop] 
                );
            }) && botWhatsappUser.phone_number
        );
    }

    function shouldSaveUserRoles() {
        return initialUserRoles.current.join(',') !== userRoles.join(',');
    }

    function shouldSave() {
        return (
            shouldSaveUser() ||
            shouldSaveBotWhatsappUser() ||
            shouldSaveUserRoles()
        );
    }

    async function postManageUsers(user) {
        const result = await api.post(`/manage/users`, user);
        return result.data;
    }

    async function putManageUsers(userId, user) {
        const result = await api.put(`/manage/users/${userId}`, user);
        return result.data;
    }

    async function saveUser() {
        if (shouldSaveBotWhatsappUser()) {
            initialBotWhatsappUser.current = botWhatsappUser;
            api.post('/manage/bot/whatsapp-users', {
                ...botWhatsappUser,
                cronos_user_id: user.id,
            });
        }
        if (shouldSaveUserRoles()) {
            initialUserRoles.current = userRoles;
            api.put('/manage/roles/grant-only', {
                user_id: updatedUser.id,
                role_ids: userRoles,
            });
        }
        if (shouldSaveUser()) {
            const userToSend = {
                name,
                email,
                inactive,
                customer_id,
            };

            const userId = updatedUser.id;

            const response = await (updatedUser.email
                ? putManageUsers(userId, userToSend)
                : postManageUsers(userToSend));

            setUpdatedUser(response);
        }
    }

    async function getBotWhatsappUser() {
        const response = await api.get(
            `/manage/bot/whatsapp-users?cronos_user_id=${updatedUser.id}`
        );
        const result = response.data || {};
        initialBotWhatsappUser.current = result;
        setBotWhatsappUser(result);
    }

    async function getUserRoles() {
        const response = await api.get(
            `/manage/users/${updatedUser.id}/roles`
        );
        initialUserRoles.current = response.data;
        setUserRoles(response.data);
    }

    function getExtraUserInfo() {
        getBotWhatsappUser();
        getUserRoles();
    }

    function changeBotWhatsappUser(newWhatsappUser) {
        setBotWhatsappUser({
            ...botWhatsappUser,
            ...newWhatsappUser,
        });
    }

    async function inviteUser() {
        if (updatedUser.id) {
            await api.post(`/manage/users/${updatedUser.id}/invite`);
        }
    }

    useEffect(() => {
        const newTitle = (name && `${name} (${customerName})`)
            || (email && `${email} (${customerName})`)
            || 'Preencher novo usuário'
        setTitle(newTitle);
    }, [name, email, customerName]);

    useEffect(() => {
        if (inviteCode) {
            const { exp } = jwt_decode(inviteCode);
            if (Date.now() >= exp*1000) {
                setInviteCode(null)
            }
        }
    }, [inviteCode]);

    return {
        botWhatsappUser,
        changeBotWhatsappUser,
        creating,
        email,
        getExtraUserInfo,
        inactive,
        initialBotWhatsappUser,
        inviteCode,
        inviteUser,
        name,
        saveUser,
        setCustomerId,
        setEmail,
        setInactive,
        setName,
        setUserRoles,
        shouldSave,
        title,
        titleIcon,
        updatedUser,
        userRoles,
    };
};
