import { useState, useEffect, useCallback } from 'react';
import * as userUtils from '../../../utils/user';

export function useMapAlertState(apiData, settingsSet) {
    const {
        radars: { fetchAndSave, value: radars },
        selectedRadar: { selectRadar, selectedRadar },
        staticPositions,
        staticPositionTypes,
        selectStaticPositionTypes,
        selectedStaticPositionTypes
    } = apiData;

    const { RADAR_ALIASES: radarAliases = [], STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [] } = { ...settingsSet };

    const radarOption = (radarAliases.length > 0 && radars)
        ? radars.filter(({ alias, inactive }) => !inactive && radarAliases.includes(alias))
            .sort((a, b) => a.name.localeCompare(b.name)) : [];

    const [showLeftbar, setShowLeftbar] = useState(false);
    const [showNeighborhoodShapes, setShowNeighborhoodShapes] = useState(false);
    const [showLightnings, setShowLightnings] = useState(false);
    const [showAttentionPerimeters, setShowAttentionPerimeters] = useState(false);

    function removeObjectEntryByKey(obj, keyToRemove) {
        return Object.fromEntries(
            Object.entries(obj).filter(([key]) => key !== keyToRemove)
        );
    }

    function toggleStaticPositionType(alias) {
        const newSelectedStaticPositionTypes = Object.keys(selectedStaticPositionTypes).includes(alias)
            ? removeObjectEntryByKey(selectedStaticPositionTypes, alias)
            : { ...selectedStaticPositionTypes, [alias]: { showRanges: true } };
        selectStaticPositionTypes(newSelectedStaticPositionTypes);
    }

    useEffect(() => {
        const hasLightnings = Object.keys(staticPositionTypeAliases).includes('raios');
        if (!hasLightnings) {
            setShowLightnings(false);
        } else {
            setShowLightnings(userUtils.getUserSelectedShowLightnings());
        }
    }, [staticPositionTypeAliases]);

    function toggleShowLightnings() {
        const newShowLightnings = !showLightnings;
        setShowLightnings(newShowLightnings);
        userUtils.setUserSelectedShowLightnings(newShowLightnings);
    }

    useEffect(() => {
        if (staticPositionTypes && staticPositionTypes.length) {
            selectStaticPositionTypes();
        }
        fetchAndSave();
    }, [staticPositionTypes, fetchAndSave, selectStaticPositionTypes]);

    const handleSetRadiusOnOff = useCallback((alias) => {
        if (!selectedStaticPositionTypes[alias]) {
            return;
        }
        const newSelectedStaticPositionTypes = { ...selectedStaticPositionTypes };
        newSelectedStaticPositionTypes[alias].showRanges = !newSelectedStaticPositionTypes[alias]?.showRanges;
        selectStaticPositionTypes(newSelectedStaticPositionTypes);
    }, [selectedStaticPositionTypes, selectStaticPositionTypes]);

    const handleSwitchClick = (e, alias = null) => {
        e.stopPropagation();
        if (alias) {
            handleSetRadiusOnOff(alias);
        }
    };

    const shouldHideSwitchWithTypes = (alias) => {
        return ['raios', 'alaga', 'camera', 'limite'].some(prefix => alias.startsWith(prefix));
    };

    const shouldHideSwitchWithoutRadius = (alias) =>{
        const type = staticPositionTypes.find(type => type.alias === alias);
        const hasRadiusInType = type && Array.isArray(type.radius) && type.radius.length > 0;
        const hasRadiusInPositions = staticPositions.some(position => position.type_id === type.id && Array.isArray(position.radius) && position.radius.length > 0);

        return !(hasRadiusInType || hasRadiusInPositions);
    };
    return {
        radarOption,
        selectRadar, 
        selectedRadar,
        showLeftbar,
        setShowLeftbar,
        showNeighborhoodShapes,
        setShowNeighborhoodShapes,
        showLightnings,
        setShowLightnings,
        showAttentionPerimeters,
        setShowAttentionPerimeters,
        toggleStaticPositionType,
        toggleShowLightnings,
        staticPositionTypes,
        selectStaticPositionTypes,
        selectedStaticPositionTypes,
        staticPositionTypeAliases,
        handleSwitchClick,
        shouldHideSwitchWithTypes,
        shouldHideSwitchWithoutRadius
    };
}
