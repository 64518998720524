import { useState } from 'react';

export const units = [
    {
        text: 'minutos',
        period: 60000, // 60 seconds
    },
    {
        text: 'horas',
        period: 3600000, // 60*60 seconds
    },
    {
        text: 'dias',
        period: 86400000, // 24*60*60 seconds
    },
];

function getInitialState(defaultValue) {
    for (let { period } of units.slice().reverse()) {
        const amount = (Number(defaultValue) || 0) / period;
        if (Math.floor(amount) > 0) {
            return {
                amount,
                period,
            };
        }
    }
    return {
        amount: 0,
        period: units[0].period,
    };
}

export function useDatetimePeriodState(defaultValue, setValue) {
    const {
        amount: initialAmount,
        period: initialPeriod,
    } = getInitialState(defaultValue);

    const [amount, setNewAmount] = useState(initialAmount);
    const [period, setNewPeriod] = useState(initialPeriod);

    return {
        amount,
        period,
        setAmount: (newAmount) => {
            setNewAmount(newAmount);
            setValue(newAmount*period);
        },
        setPeriod: (newPeriod) => {
            setNewPeriod(newPeriod);
            setValue(newPeriod*amount);
        },
    }
};
