import { useEffect, useMemo, useState } from 'react';
import { useApiData } from '../../contexts/api-data';
import {
    getManageCustomersForecastRegions,
} from '../../services/api';
import { useHistory } from 'react-router-dom';

export function useSelectionRegionBoxState(hasManageCustomersPermission) {
    const {
        forecastRegions,
        initialRegionAlias,
        selectForecastRegion,
        selectedForecastRegion,
    } = useApiData();
    const history = useHistory();
    const [userForecastRegions, setUserForecastRegions] = useState();
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCustomerRegions, setSelectedCustomerRegions] = useState(null);

    useEffect(() => {
        setUserForecastRegions(hasManageCustomersPermission && forecastRegions);
    }, [forecastRegions, hasManageCustomersPermission]);

    const filteredRegions = useMemo(() => {
        if (!forecastRegions) return [];

        return forecastRegions.filter((region) => {
            const aliasCondition = !region.alias.startsWith('cor_');
            const customerCondition =
                !selectedCustomer ||
                (selectedCustomerRegions?.length > 0 &&
                    selectedCustomerRegions.includes(region.id));
            const stateCondition =
                !selectedState ||
                parseInt(region.state_id) === parseInt(selectedState);

            return aliasCondition && customerCondition && stateCondition;
        });
    }, [forecastRegions, selectedCustomerRegions, selectedCustomer, selectedState]);

    const regionOptions = useMemo(() => {
        if (!forecastRegions) return;

        return filteredRegions.map((region) => ({
            value: region.id,
            label: region.name,
        }));
    }, [filteredRegions, forecastRegions]);

    const stateOptions = useMemo(() => {
        if (!forecastRegions) return;

        if (selectedCustomer && selectedCustomerRegions) {
            const relevantRegions = forecastRegions.filter((region) =>
                selectedCustomerRegions.includes(region.id)
            );
            return [
                ...new Set(relevantRegions.map((region) => region.state_id)),
            ];
        } else {
            return [
                ...new Set(forecastRegions.map((region) => region.state_id)),
            ];
        }
    }, [forecastRegions, selectedCustomerRegions, selectedCustomer]);

    const removeQueryParameters = () => {
        if (history.location.search) {
            history.push(history.location.pathname);
        }
    };

    const selectRegion = (region) => {
        selectForecastRegion(region);
        removeQueryParameters();
    };

    const selectCustomer = (customer) => {
        setSelectedCustomer(customer);
        removeQueryParameters();
    }

    const selectState = (state) => {
        setSelectedState(state);
        removeQueryParameters();
    }

    useEffect(() => {
        const fetchCustomerRegions = async () => {
            if (selectedCustomer) {
                const response = await getManageCustomersForecastRegions(selectedCustomer);
                setSelectedCustomerRegions(response);
                setSelectedState(null);
            }
        };
        fetchCustomerRegions();
    }, [selectedCustomer]);

    useEffect(() => {
        if (
            !initialRegionAlias &&
            !selectedForecastRegion &&
            regionOptions?.length > 0
        ) {
            selectForecastRegion(regionOptions[0].value, userForecastRegions);
        }
    }, [
        hasManageCustomersPermission,
        initialRegionAlias,
        regionOptions,
        selectForecastRegion,
        userForecastRegions,
        selectedForecastRegion,
    ]);

    useEffect(() => {
        if (!filteredRegions) return;

        const regionForState = filteredRegions.find(
            (region) =>
                parseInt(region.state_id) === parseInt(selectedState)
        );

        selectForecastRegion(regionForState && regionForState.id);
    }, [selectedState, filteredRegions, selectForecastRegion]);

    useEffect(() => {
        if (filteredRegions.length <= 0 || !selectedCustomer) return;
        selectForecastRegion(filteredRegions[0]?.id);
    }, [selectedCustomer, filteredRegions, selectForecastRegion]);

    return {
        selectedCustomer,
        selectCustomer,
        selectState,
        setSelectedState,
        setSelectedCustomer,
        regionOptions,
        stateOptions,
        selectRegion,
    };
}
