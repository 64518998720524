import { useCallback, useState, useMemo } from 'react';

import {
    getManageAlertTemplates,
    postManageAlertTemplate,
    putManageAlertTemplate,
} from '../../../../services/api';

export function useManageAlertTemplatesState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.name) return -1;
            if (!b.name) return 1;
            return a.name.localeCompare(b.name);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const template = await getManageAlertTemplates();
            setValue(template);
            setLoading(false);
        },
        [value, loading],
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(templateId, template) {
        const index = value.findIndex(({ id }) => id === templateId);
        // creating
        if (!template.id) {
            template = await postManageAlertTemplate(template);
        }
        // updating
        else {
            await putManageAlertTemplate(template);
        }
        const newValue = [...value];
        newValue[index] = template;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        value: valueSorted,
    };
};
