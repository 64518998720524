import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import SatelliteProductTypeBox from '../satellite-product-type-box';
import {
    getSatelliteProductTypes
} from '../../../../services/api';

function SatelliteProductTypeList() {
    const [satelliteProductType, setSatelliteProductTypes] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await getSatelliteProductTypes();
            setSatelliteProductTypes(response);
        })();
    }, []);

    function addNewProductType() {
        const id = Math.max(...satelliteProductType.map(({ id }) => id)) + 1;
        setSatelliteProductTypes([ ...satelliteProductType, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Tipos de Produtos de Satélites</h3>
            <NewBtn
                label="Nova"
                newFunction={addNewProductType}
            />
        </div>
        {satelliteProductType && satelliteProductType.sort((a, b) => a.id - b.id).map(productType => (
            <SatelliteProductTypeBox
                key={productType.id}
                productType={productType}
            />
        ))}
    </>);
}

export default SatelliteProductTypeList;
