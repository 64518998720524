import { useEffect, useMemo, useRef, useState } from 'react';

import datetimeUtils from '../../../../utils/datetime';

import { useApiData } from '../../../../contexts/api-data';
import api from '../../../../services/api';
import { useStationExtraData } from '../station-extra-data-box/states';

export function useStationBoxStates(station, institution_id) {
    const {
        neighborhoodsByCity,
        retrieveNeighborhoodsByCity,
    } = useApiData();

    const {
        extraData,
        fetch: fetchExtraData,
        save: saveExtraData,
        shouldSave: shouldSaveExtraData,
        updateExtraData,
    } = useStationExtraData(station.id);

    const [updatedStation, setUpdatedStation] = useState(station);

    const creating = !updatedStation.name;
    const stationId = updatedStation.id || updatedStation.tempId;
    const [name, setName] = useState(updatedStation.name || '');
    const [code, setCode] = useState(updatedStation.code || '');
    const [latitude, setLatitude] = useState(updatedStation.latitude || null);
    const [longitude, setLongitude] = useState(updatedStation.longitude || null);
    const [time_frequency, setTimeFrequency] = useState(updatedStation.time_frequency || null);
    const [neighborhood_id, setNeighborhoodId] = useState(updatedStation.neighborhood_id || null);
    const [city, setCity] = useState(null);
    const [inactive, setInactive] = useState(Boolean(updatedStation.inactive));

    const [address, setAddress] = useState(updatedStation.address || '');
    const [alias, setAlias] = useState(updatedStation.alias || '');
    const [description, setDescription] = useState(updatedStation.description || '');
    const [is_weather, setIsWeather] = useState(Boolean(updatedStation.is_weather));
    const [calibration, setCalibration] = useState(Boolean(updatedStation.calibration));
    const [protocol_id, setProtocolId] = useState(updatedStation.station_protocol_id || null);
    const [configuration_id, setConfigurationId] = useState(updatedStation.station_configuration_id || null);
    const [basin_id, setBasinId] = useState(updatedStation.basin_id || null);

    const datetime_installed = updatedStation?.datetime_installed
        ? datetimeUtils.getInputFormatDateFromDatetime(updatedStation.datetime_installed, true)
        : '';
    const [formatDatetimeInstalled, setFormatDatetimeInstalled] = useState('');

    const [stationSensors, setStationSensors] = useState(null)
    const initialStationSensors = useRef([]);

    useEffect(() => {
        (async function() {
            if (stationSensors || stationId === updatedStation.tempId) {
                return
            }
            const response = await api.get(
                `/manage/station/${stationId}/sensors`
            );
            const sensorsIds = response.data
                .filter(({inactive}) => !inactive)
                .map(ss => ss.sensor_id)
                .sort((a, b) => a - b);

            initialStationSensors.current = sensorsIds;
            if (sensorsIds.length) {
                setStationSensors(sensorsIds);
            }
        })();
    }, [stationId, stationSensors, updatedStation]);

    useEffect(() => {
        if (datetime_installed) {
            setFormatDatetimeInstalled(datetime_installed);
        }
    }, [datetime_installed]);

    useEffect(() => {
        if (creating) {
            setCity({
                state_id: null,
                id: null,
            });
        }
    }, [creating]);

    function shouldSaveSensor() {
        const allMandatorySet = name?.length > 0
            && code?.length > 0
            && latitude?.length > 0
            && longitude?.length > 0
            && institution_id
            && time_frequency
            && city.id !== null && city.id !== undefined && city.id !== '' && !isNaN(Number(city.id));
        if (creating) {
            return allMandatorySet;
        }
        const nameChanged = name !== updatedStation.name;
        const codeChanged = code !== updatedStation.code;
        const latitudeChanged = Number(latitude) !== Number(updatedStation.latitude);
        const longitudeChanged = Number(longitude) !== Number(updatedStation.longitude);
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedStation.inactive);
        const institutionChanged = Number(institution_id) !== Number(updatedStation.institution_id);
        const neighborhoodChanged = Number(neighborhood_id) !== Number(updatedStation.neighborhood_id);
        const cityChanged = city && (Number(city.id) !== Number(updatedStation.city_id));
        const timeFrequencyChanged = Number(time_frequency) !== Number(updatedStation.time_frequency);
        const addressChanged = address !== (updatedStation.address || '');
        const aliasChanged = alias !== (updatedStation.alias || '');
        const descriptionChanged = description !== (updatedStation.description || '');
        const calibrationChanged = Boolean(calibration) !== Boolean(updatedStation.calibration);
        const isWeatherChanged = String(is_weather) !== String(updatedStation.is_weather);
        const formatDatetimeInstalledChanged = formatDatetimeInstalled !==  datetime_installed;
        const configurationIdChanged = Number(configuration_id) !== Number(updatedStation.station_configuration_id);
        const protocolIdChanged = Number(protocol_id) !== Number(updatedStation.station_protocol_id);
        const basinIdChanged = Number(basin_id) !== Number(updatedStation.basin_id);

        return allMandatorySet && (
            nameChanged
            || codeChanged
            || latitudeChanged
            || longitudeChanged
            || inactiveChanged
            || institutionChanged
            || neighborhoodChanged
            || cityChanged
            || timeFrequencyChanged
            || addressChanged
            || isWeatherChanged
            || aliasChanged
            || descriptionChanged
            || calibrationChanged
            || formatDatetimeInstalledChanged
            || configurationIdChanged
            || protocolIdChanged
            || basinIdChanged
        );
    }

    function shouldSaveStationSensors() {
        if (!stationSensors) {
            return
        }
        return initialStationSensors.current.join(',') !== stationSensors.sort((a, b) => a - b).join(',');
    }

    function shouldSave() {
        return shouldSaveSensor() || shouldSaveStationSensors() || shouldSaveExtraData();
    }

    async function saveStation() {
        if (shouldSaveStationSensors()) {
            initialStationSensors.current = stationSensors;
            api.put('/manage/station/sensors', {
                station_id: stationId,
                sensor_ids: stationSensors,
            });
        }

        if (shouldSaveExtraData()) {
            saveExtraData();
        }

        if (shouldSaveSensor()) {
            const stationToSend = {
                name,
                code,
                latitude,
                longitude,
                inactive,
                institution_id,
                neighborhood_id,
                city_id: city?.id || null,
                time_frequency,
                is_weather,
                address,
                description,
                calibration,
                alias,
                station_protocol_id: protocol_id,
                station_configuration_id: configuration_id,
                basin_id,
            };
            stationToSend.datetime_installed = datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formatDatetimeInstalled);

            if (creating) {
                const response = await api.post('/manage/station', stationToSend);
                stationToSend.id = response.data.id;
            }
            else {
                
                await api.put(`/manage/stations/${stationId}`, stationToSend);
                stationToSend.id = stationId
            }            
            setUpdatedStation(stationToSend);
        }
    }

    function openFunction() {
        if (city) {
            return;
        }
        const {
            state_id = null,
            id = null,
        } = { ...updatedStation.city };
        setCity({ state_id, id });

        fetchExtraData();
    }

    function selectCity([state_id, id]) {
        setCity({
            state_id: state_id || null,
            id: id || null,
        });
    }

    useEffect(() => {
        const { id: city_id } = { ...city };
        if (!city_id) {
            return;
        }
        retrieveNeighborhoodsByCity(city_id);
    }, [city, retrieveNeighborhoodsByCity]);

    const neighborhoods = useMemo(
        () => neighborhoodsByCity[city?.id] || [],
        [city, neighborhoodsByCity],
    );

    const title = useMemo(() => name && code ? `${name} (${code})` : '', [name, code]);
    const stationNameInputId = `station-name-${stationId}`;
    const stationCodeInputId = `station-code-${stationId}`;
    const stationAliasInputId = `station-alias-${stationId}`;
    const stationBasinInputId = `station-basin-${stationId}`;
    const stationLatitudeInputId = `station-latitude-${stationId}`;
    const stationLongitudeInputId = `station-longitude-${stationId}`;
    const stationSensorsInputId = `station-sensors-${stationId}`;
    const stationCityInputId = `station-city-id-${stationId}`;
    const stationNeighborhoodInputId = `station-neighborhood-id-${stationId}`;
    const stationAddressInputId = `station-address-${stationId}`;
    const stationTypeInputId = `station-type-${stationId}`;
    const stationDatetimeInstalledInputId = `station-datetime-installed`;
    const stationProtocolInputId = `station-protocol-id-${stationId}`;
    const stationConfigurationInputId = `station-configuration-id-${stationId}`;
    const stationTimeFrequencyInputId = `station-time-frequency-${stationId}`;
    const stationDescriptionInputId = `station-description-${stationId}`;
    const stationCalibrationInputId = `station-calibration-${stationId}`;
    const stationInactiveInputId = `station-inactive-${stationId}`;

    return {
        creating,
        address,
        alias,
        basin_id,
        calibration,
        city,
        code,
        configuration_id,
        description,
        extraData,
        formatDatetimeInstalled,
        inactive,
        is_weather,
        latitude,
        longitude,
        name,
        neighborhood_id,
        neighborhoods,
        stationSensors,
        stationNameInputId,
        stationCodeInputId,
        stationAliasInputId,
        stationBasinInputId,
        stationLatitudeInputId,
        stationLongitudeInputId,
        stationSensorsInputId,
        stationCityInputId,
        stationNeighborhoodInputId,
        stationAddressInputId,
        stationTypeInputId,
        stationDatetimeInstalledInputId,
        stationProtocolInputId,
        stationConfigurationInputId,
        stationTimeFrequencyInputId,
        stationDescriptionInputId,
        stationCalibrationInputId,
        stationInactiveInputId,
        protocol_id,
        time_frequency,
        title,
        openFunction,
        selectCity,
        setAddress,
        setAlias,
        setBasinId,
        setCalibration,
        setCode,
        setConfigurationId,
        setDescription,
        setFormatDatetimeInstalled,
        setInactive,
        setIsWeather,
        setLatitude,
        setLongitude,        
        setName,
        setNeighborhoodId,
        setProtocolId,
        setStationSensors,
        setTimeFrequency,
        saveStation,
        shouldSave,
        updateExtraData,
    };
};
