import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useMultipleWeatherVariablesSelectionInputState } from './states';

function MultipleWeatherVariablesSelectionInput({
    defaultValue,
    setValue,
    showOnlyItemsAmount,
}) {
    const {
        options,
        weatherVariables,
    } = useMultipleWeatherVariablesSelectionInputState();

    return weatherVariables ? (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            showOnlyItemsAmount={showOnlyItemsAmount}
        />
    ) : null;
}

export default MultipleWeatherVariablesSelectionInput;
