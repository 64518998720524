import React, { useState } from 'react';
import {
    FaEye,
    FaEyeSlash,
    FaLongArrowAltDown,
    FaLongArrowAltUp,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';
import PrecipitationColorLegend from './precipitation-color-legend';
import TendencyIconLegend from './tendency-icon-legend';
import TriggerLegend from './trigger-legend';

import { usePrecipitationTableStates } from './states';

import './styles.css';

function PrecipitationTable({ stations }) {
    const {
        colspans,
        hiddenColumns,
        hiddingColumn,
        hideColumn,
        showAllColumns,
        tableData,
    } = usePrecipitationTableStates(stations);

    const [sortOptions, setSortOptions] = useState({ index: 0, order: 'ASC' });

    function toggleSort(index) {
        if (hiddingColumn.current) {
            hiddingColumn.current = false;
            return;
        }
        if (sortOptions.index !== index) {
            setSortOptions({
                index,
                order: 'ASC',
            });
            return;
        }
        const order = sortOptions.order === 'ASC' ? 'DESC' : 'ASC';
        setSortOptions({
            index,
            order,
        });
    }

    return (
        <DashboardBox
            title="Estações Pluviométricas"
            className="stations-precipitation-table-box"
        >
            {tableData && <div className="stations-precipitation-table-container">
                <table
                    className="stations-precipitation-table"
                    style={{ width: '100%' }}
                >
                    <thead>
                        <tr className="first-header">
                            <th
                                colSpan={colspans.identification}
                                scope="colgroup"
                                className="identification"
                            >
                                <span>
                                    Identificação
                                    <button
                                        className="toggle-column-btn"
                                        onClick={showAllColumns}
                                    >
                                        <FaEye />
                                    </button>
                                </span>
                            </th>
                            <th
                                colSpan={colspans.precipitation}
                                scope="colgroup"
                                className="precipitation"
                            >
                                <span>
                                    Chuva
                                    <span className="precipitation-legend-trigger-container">
                                        <PrecipitationColorLegend />
                                    </span>
                                    <button
                                        className="toggle-column-btn"
                                        onClick={showAllColumns}
                                    >
                                        <FaEye />
                                    </button>
                                </span>
                            </th>
                            <th
                                colSpan={colspans.alarms}
                                scope="colgroup"
                                className="alarms"
                            >
                                <span>
                                    Alarmes
                                    <button
                                        className="toggle-column-btn"
                                        onClick={showAllColumns}
                                    >
                                        <FaEye />
                                    </button>
                                </span>
                            </th>
                        </tr>
                        <tr className="second-header">
                            {tableData[0].map((headerLabel, index) => hiddenColumns.includes(index)
                                ? null
                                : (
                                    <th
                                        key={`header-${index}`}
                                        className={index < 5
                                            ? 'identification'
                                            : ((index < tableData[0].length-colspans.alarms)
                                                ? 'precipitation'
                                                : 'alarms')
                                        }
                                        onClick={() => toggleSort(index)}
                                    >
                                        <span>
                                            <span className="header-label-container">
                                                {headerLabel}
                                                
                                                {sortOptions.index === index
                                                    ? (sortOptions.order === 'ASC'
                                                        ? <FaLongArrowAltUp />
                                                        : <FaLongArrowAltDown />
                                                    )
                                                    : null}
                                            </span>
                                            <span className="header-buttons">
                                                {index === 5
                                                    ? <TendencyIconLegend />
                                                    : null
                                                }
                                                {index === tableData[0].length-1
                                                    ? <TriggerLegend />
                                                    : null
                                                }
                                                <button
                                                    className="toggle-column-btn"
                                                    onClick={() => hideColumn(index)}
                                                >
                                                    <FaEyeSlash />
                                                </button>
                                            </span>
                                        </span>
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData
                            .slice(1)
                            .sort((a, b) => {
                                const [x, y] = [a, b].map(row => {
                                    const rowCell = row[sortOptions.index];
                                    if (typeof rowCell === 'object') {
                                        return rowCell.v;
                                    }
                                    return rowCell;
                                });
                                if ([null, undefined].includes(x)) {
                                    return sortOptions.order === 'DESC' ? 1 : -1;
                                }
                                if ([null, undefined].includes(y)) {
                                    return sortOptions.order === 'DESC' ? -1 : 1;
                                }
                                if ([x, y].some(isNaN)) {
                                    return sortOptions.order === 'DESC'
                                        ? y.localeCompare(x)
                                        : x.localeCompare(y);
                                }
                                return sortOptions.order === 'DESC'
                                    ? y - x
                                    : x - y;
                            })
                            .map((row, rowIndex) => (
                                <tr
                                    key={`row-${rowIndex}`}
                                    className={row[row.length-1]}
                                >
                                    {row
                                        .slice(0, -1)
                                        .map((cell, cellIndex) => hiddenColumns.includes(cellIndex)
                                            ? null
                                            : (
                                                <td
                                                    key={`cell-${rowIndex}-${cellIndex}`}
                                                    className={`${cellIndex >= row.length-colspans.alarms-1 ? 'alarm' : ''}`}
                                                >
                                                    {typeof cell === 'object'
                                                        ? cell.f
                                                        : cell}
                                                </td>
                                            )
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>}
        </DashboardBox>
    );
}

export default PrecipitationTable;
