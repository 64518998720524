import baseApi from '../../base';

export async function getManageRoles() {
    const result = await baseApi.get('/manage/roles');
    return result.data;
};

export async function postManageRoles(role) {
    const result = await baseApi.post('/manage/roles', role);
    return result.data;
};

export async function putManageRoles(role) {
    const result = await baseApi.put(`/manage/roles/${role.id}`, role);
    return result.data;
};

export async function postManageRolePermissions(roleId, permission_ids) {
    const result = await baseApi.post(
        `/manage/roles/${roleId}/permissions`,
        { permission_ids },
    );
    return result.data;
};
