import baseApi from '../../base';

const baseResource = '/weather-variables'
const baseManageResource = `/manage${baseResource}`;

export async function getWeatherVariables() {
    const result = await baseApi.get(baseResource);
    return result.data;
};

export async function postManageWeatherVariables(variable) {
    const result = await baseApi.post(baseManageResource, variable);
    return result.data;
};

export async function putManageWeatherVariables(variable) {
    const result = await baseApi.put(`${baseManageResource}/${variable.id}`, variable);
    return result.data;
};
