import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../contexts/api-data';

export function usePermissionBoxStates(permission) {
    const {
        managePermissions: {
            save: savePermission,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            key: permission.key || '',
            description: permission.description || '',
        },
    });
    const key = watch('key');
    const description = watch('description');
    const creating = !permission.key;
    const title = useMemo(() => description || key || '', [description, key]);

    function shouldSave() {
        const keyAndDescriptionSet = key.length > 0 && description.length > 0;
        if (creating) {
            return keyAndDescriptionSet;
        }
        const keyChanged = (key || '').toUpperCase() !== (permission.key || '');
        const descriptionChanged = (description || '') !== (permission.description || '');
        return keyAndDescriptionSet && (keyChanged || descriptionChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = permission;
            const permissionToSend = {
                key: key.toUpperCase(),
                description,
            };
            if (!creating) {
                permissionToSend.id = id;
            }
            savePermission(id, permissionToSend);
        }
    }

    return {
        creating,
        register,
        save,
        shouldSave,
        title,
    };
};
