import React, { useMemo } from 'react';

import CalendarMessagesBox from '../../components/calendar-messages-box';
import ContactPopup from '../../components/contact-popup';
import ForecastsChartBox from '../../components/forecasts-chart-box';
import SelectRegionBox from '../../components/select-region-box';
import { useApiData } from '../../contexts/api-data';

import './styles.css';

function Forecasts() {
    const {
        forecastRegions, 
    } = useApiData();

    const shouldShowRegionName = useMemo(() => {
        const regions = forecastRegions?.filter(({ alias }) => !alias.startsWith('cor_rj'));
        return regions?.length > 1 ? false : true;
    }, [forecastRegions]);

    const eightyFourHours = 84;
    const fifteenDays = 15 * 24;

    return (
        <div className={`forecasts-page-content ${shouldShowRegionName ? 'without-region-selection' : ''}`}>
            {!shouldShowRegionName && (
                <div className="region-selection-fixed-container">
                    <SelectRegionBox />
                </div>
            )}

            <div className="forecasts-page-data">
                <CalendarMessagesBox />

                <ForecastsChartBox
                    periodInHours={eightyFourHours}
                    wide={true}
                    showRegionName={shouldShowRegionName}
                />

                <ForecastsChartBox
                    periodInHours={fifteenDays}
                    wide={true}
                    showRegionName={shouldShowRegionName}
                />
            </div>
            <ContactPopup />
        </div>
    );
}

export default Forecasts;
