import React, { useState } from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import api from '../../../../services/api';

import './styles.css';

function BroadcastChannelBox({ broadcastChannel }) {
    const [updatedBroadcastChannel, setUpdatedBroadcastChannel] = useState(broadcastChannel);
    
    const creating = !updatedBroadcastChannel.name;
    const [alias, setAlias] = useState(updatedBroadcastChannel.alias || '');
    const [name, setName] = useState(updatedBroadcastChannel.name || '');
    const [inactive, setInactive] = useState(updatedBroadcastChannel.inactive || false);

    function shouldSave() {
        const nameAndAliasAreSet = name?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndAliasAreSet;
        }
        const nameChanged = name !== updatedBroadcastChannel.name;
        const aliasChanged = alias !== updatedBroadcastChannel.alias;
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedBroadcastChannel.inactive);   
        return nameAndAliasAreSet && (
            nameChanged
            || aliasChanged
            || inactiveChanged);
    }

    async function saveConfiguration() {
        if (shouldSave()) {
            const broadcastChannelToSend = {
                alias,
                name,
                inactive,
            };
            if (creating) {
                const response = await api.post('/manage/broadcast-channels', broadcastChannelToSend);
                broadcastChannelToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/broadcast-channels/${updatedBroadcastChannel.id}`, broadcastChannelToSend);
                broadcastChannelToSend.id = updatedBroadcastChannel.id;
            }
            setUpdatedBroadcastChannel(broadcastChannelToSend);
        }
    }    

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form broadcast-channel-box-form">
                <div className="form-group">
                    <label htmlFor={`broadcast-channel-alias-${updatedBroadcastChannel.id}`}>Identificador</label>
                    <input
                        id={`broadcast-channel-alias-${updatedBroadcastChannel.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`broadcast-channel-name-${updatedBroadcastChannel.id}`}>Nome</label>
                    <input
                        id={`broadcast-channel-name-${updatedBroadcastChannel.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`broadcast-channel-inactive-${updatedBroadcastChannel.id}`}>Desabilitar</label>
                    <input
                        id={`broadcast-channel-inactive-${updatedBroadcastChannel.id}`}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group">
                    <PostBtn
                        postFunction={saveConfiguration}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default BroadcastChannelBox;
