import React from 'react';

import SelectionInput from '../selection-input';

import {
    useVariableUnitInputStates,
} from './states';

import './styles.css';

function VariableUnitInput({
    defaultValue,
    setValue,
    isNullable = true,
    inputId,
}) {
    const {
        variable,
        unit,
        updateValue,
        weatherVariables,
        selectedVariable,
    } = useVariableUnitInputStates({ defaultValue, setValue });

    return weatherVariables
        ? (
            <div className="variable-unit-input">
                <SelectionInput
                    defaultValue={variable}
                    setValue={value => updateValue(value, unit)}
                    options={weatherVariables.map(({ id: value, label }) => ({ value, label }))}
                    isNullable={isNullable}
                    inputId={inputId}
                />
                {selectedVariable && (
                    <SelectionInput
                        defaultValue={unit}
                        setValue={value => updateValue(variable, value)}
                        options={selectedVariable.type.units?.map(({ id: value, label }) => ({ value, label })) || []}
                        isNullable={false}
                    />
                )}
            </div>
        )
        : null;
}

export default VariableUnitInput;
