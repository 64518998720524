import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function StationInstitutionSelectionInput({
    defaultOption,
    defaultValue,
    isNullable,
    setValue,
    inputId,
}) {
    const {
        manageStationInstitutions: {
            value: stationInstitutions,
        },
    } = useApiData();

    const stationInstitutionsOptions = stationInstitutions?.map((stationInstitution) => {
        return {
            value: stationInstitution.id,
            label: `${stationInstitution.name} (${stationInstitution.alias})`,
        }
    });

    return stationInstitutionsOptions?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            inputId={inputId}
            setValue={setValue}
            options={stationInstitutionsOptions}
            isNullable={isNullable}
        />)
        : (
        <select disabled id={inputId}>
            <option> Nenhuma instituição encontrada </option>
        </select>
        );
}

export default StationInstitutionSelectionInput;
