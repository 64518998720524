import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useRadioBoxStates } from './states';

import './styles.css';

function RadioBox({ radio }) {
    const {
        creating,
        register,
        save,
        shouldSave,
        title,
    } = useRadioBoxStates(radio);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="radio-box-form">
                <input
                    placeholder="Nome"
                    {...register('name')}
                />
                <input
                    placeholder="IP"
                    {...register('ip')}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default RadioBox;
