import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AuthError from '../../components/auth-error';
import AuthTemplate from '../../components/auth-template';
import { useAuth } from '../../contexts/auth';

import './styles.css';

function Login({ history }) {
    const { isAuthenticated, login } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
    });

    async function handleSubmit(event) {
        event.preventDefault();
        setLoggingIn(true);

        const result = await login(email, password);

        if (result.errors) {
            const errorsCodes = result.errors.map(({ code }) => code);
            [
                [
                    ['AUTHENTICATE_USER_NOT_FOUND','AUTHENTICATE_USER_INACTIVE'],
                    setEmailError,
                    ['Usuário não encontrado', 'Usuário desativado'],
                ],
                ['AUTHENTICATE_PASSWORD_INVALID', setPasswordError, 'Senha incorreta'],
            ].forEach(([errorCode, setStateFn, message]) => {
                if (typeof errorCode === 'string') {
                    const error = errorsCodes.includes(errorCode);
                    setStateFn(error ? message : '');
                    return;
                }
                if (errorCode.every((code, index) => {
                    if (errorsCodes.includes(code)) {
                        return setStateFn(message[index]);
                    }
                    return true;
                })) {
                    setStateFn('');
                }
            });
        }
        else {
            history.push('/');
            return;
        }

        setLoggingIn(false);
    }
    
    return (
        <AuthTemplate title="Login" loading={loggingIn}>          
            <form className="login-form" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">E-mail</label>
                    <input
                        className="form-control"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <AuthError error={emailError} />
                </div>
                <div>
                    <label htmlFor="password">Senha</label>
                    <input
                        className="form-control"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <AuthError error={passwordError} />
                </div>
                <Link
                    className="forgot-password-link"
                    to="/redefinir-senha"
                >
                    Esqueci minha senha
                </Link>
                <button type="submit" className="btn">Entrar</button>
            </form>
        </AuthTemplate>
    );
}

export default Login;
