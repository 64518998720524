import React, { useEffect } from 'react';

import TelemetryVariableBox from '../telemetry-variable-box';
import NewBtn from '../common/new-btn';
import { useApiData } from '../../../contexts/api-data';

import './styles.css';

function TelemetryVariablesList() {
    const {
        manageTelemetryVariables: {
            add: addNewTelemetryVariable,
            fetch: fetchTelemetryVariables,
            value: telemetryVariables,
        },
    } = useApiData();

    useEffect(() => {
        fetchTelemetryVariables();
    }, [fetchTelemetryVariables]);

    return (<>
        <div className="telemetry-variables-list-header">
            <h3>Variáveis climáticas</h3>
            <NewBtn
                newFunction={addNewTelemetryVariable}
                label="Nova"
            />
        </div>
        {telemetryVariables && telemetryVariables.map(variable => (
            <TelemetryVariableBox
                key={variable.id}
                variable={variable}
            />
        ))}
    </>);
}

export default TelemetryVariablesList;
