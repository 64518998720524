import React, { useEffect } from 'react';

import WeatherVariableBox from '../weather-variable-box';
import NewBtn from '../common/new-btn';
import { useApiData } from '../../../contexts/api-data';

import './styles.css';

function WeatherVariablesList() {
    const {
        manageWeatherVariables: {
            add: addNewWeatherVariable,
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    return (<>
        <div className="weather-variables-list-header">
            <h3>Variáveis climáticas</h3>
            <NewBtn
                newFunction={addNewWeatherVariable}
                label="Nova"
            />
        </div>
        {weatherVariables && weatherVariables.map(variable => (
            <WeatherVariableBox
                key={variable.id}
                variable={variable}
            />
        ))}
    </>);
}

export default WeatherVariablesList;
