import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import GetBtn from '../../../../common/btn/get-btn';
import BaseBtn from '../../../../common/btn/base-btn';
import MultipleStationsSelectionInput from '../../../../common/custom-inputs/multiple-station-selection-input';

import './styles.css';

function StationExportPopup({ stations, exports }) {
    const [selectStation, setSelectStation] = useState(null);
   
    function shouldExport(){
        return selectStation !== null
    };

    function downloadStation(){
        const newStations = stations.filter(
            station => selectStation.includes(station.id));

        exports(newStations);
    };
    
    return (
        <Popup
            trigger={
                <GetBtn
                    label="Exportar"
                    className="export-trigger"
                />
            }
            position="center center"
            className="export-modal"
            modal
            closeOnDocumentClick={false}
        >
            {(close) => (
                <>
                    <div className="export-popup-container">
                        <h5>Exportação de Estações</h5>
                        <div className="export-options">
                            <div>
                                <label>
                                    Selecione a Estação
                                </label>
                                < MultipleStationsSelectionInput
                                    stations={stations} 
                                    defaultValue={selectStation}
                                    setValue={setSelectStation}
                                    showOnlyItemsAmount={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="export-actions">
                        <GetBtn
                            onClick={downloadStation}
                            isAllowed={shouldExport}
                            label="Exportar"
                        />
                        <BaseBtn
                            className="cancel-btn"
                            onClick={close}
                            label="Cancelar"
                        />
                    </div>
                </>
            )}
        </Popup>
    );
}

export default StationExportPopup;
