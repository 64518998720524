import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function HarborSelectionInput({
    defaultValue,
    setValue,
    isNullable = true,
    inputId,
}) {
    const {
        manageHarbors: {
            value: harbors,
        },
    } = useApiData();

    const options = harbors.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return harbors.length > 0 ? (
        <SelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : null;
}

export default HarborSelectionInput;
