import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ApiDataProvider from './contexts/api-data';
import ShapesProvider from './contexts/shapes';

import PrivateRoute from './components/private-route';
import NavigationMenu from './components/navigation-menu';
import Header from './components/header';
import MessagesPopup from './components/messages-popup';

import Login from './pages/login';
import Register from './pages/register';
import ResetPassword from './pages/reset-password';
import Dashboard from './pages/dashboard';
import RainForecast from './pages/rain-forecast';
import MapAlert from './pages/map-alert';
import MapFull from './pages/map-full';
import Manage from './pages/manage';
import Operation from './pages/operation';
import Forecasts from './pages/forecasts';
import ClimateForecasts from './pages/climate-forecasts';
import Download from './pages/download';
import StationsDashboard from './pages/stations-dashboard';
import StationsPrecipitationMeasures from './pages/stations-precipitation-measures';
import StationsTelemetryMeasures from './pages/stations-telemetry-measures';
import StationsWeatherMeasures from './pages/stations-weather-measures';
import RadarDashboard from './pages/radar-dashboard';

import { useAuth } from './contexts/auth';

const initialPages = {
    MAIN_DASHBOARD: Dashboard,
    STATIONS_DASHBOARD: StationsDashboard,
};

function Routes() {
    const { settingsSet } = useAuth();

    const { INITIAL_PAGE } = { ...settingsSet };

    const initialDashboardKey = (settingsSet && !INITIAL_PAGE)
        ? 'MAIN_DASHBOARD'
        : INITIAL_PAGE;
    const initialDashboard = initialPages[initialDashboardKey];

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/redefinir-senha" component={ResetPassword} />
                <PrivateRoute path="/*" component={() => (
                    <ApiDataProvider>
                        <ShapesProvider>
                            <div className="flex-dashboard">
                                <Header>
                                    <Route exact path={['/mapa', '/mapa-full']} component={MessagesPopup} />
                                </Header>
                                <NavigationMenu />
                                <Switch>
                                    <Route exact path="/" component={initialDashboard} />
                                    <Route exact path="/estacoes/pluviometricos" component={StationsPrecipitationMeasures} />
                                    <Route exact path="/estacoes/meteorologicos" component={StationsWeatherMeasures} />
                                    <Route exact path="/estacoes/telemetricos" component={StationsTelemetryMeasures} />
                                    <Route path="/classificacao" component={RainForecast} />
                                    <Route exact path="/mapa" component={MapAlert} />
                                    <Route path="/mapa-full" component={MapFull} />
                                    <Route path="/manage" component={Manage} />
                                    <Route path="/operacao" component={Operation} />
                                    <Route path="/previsoes" component={Forecasts} />
                                    <Route path="/previsao-climatica" component={ClimateForecasts} />
                                    <Route path="/download" component={Download} />
                                    <Route path="/radar" component={RadarDashboard} />
                                </Switch>
                            </div>
                        </ShapesProvider>
                    </ApiDataProvider>
                )} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
