import React, { useMemo, useEffect } from 'react';
import { Link, Switch, Route, useLocation, useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/auth';

import { operationSections } from './subpages';

import './styles.css';

function Operation() {
    const location = useLocation();
    const history = useHistory();
    const {
        rolesSet,
        permissionsSet,
    } = useAuth();

    const pathnameResource = location.pathname.split('/').slice(-1)[0];

    const menuItems = useMemo(() => {
        if (!permissionsSet?.length && !rolesSet?.length) {
            return [];
        }
        return operationSections.reduce((result, current) => {
            const items = [current];
            const {
                permission,
                pages,
            } = current;
            pages.forEach(page => {
                if (
                    !page.permission
                    || (permissionsSet || []).includes(page.permission)
                    || (rolesSet || []).includes('MASTER')
                ) {
                    items.push(page);
                }
            });

            if (
                (items.length === 1 || (permission && !(permissionsSet || []).includes(permission)))
                && !(rolesSet || []).includes('MASTER')
            ) {
                return result;
            }
            return result.concat(items);
        }, []);
    }, [permissionsSet, rolesSet]);

    useEffect(() => {
        const firstSubpage = menuItems.find(({ section }) => !section);
        if (!firstSubpage) {
            return;
        }
        if (pathnameResource !== 'operacao') {
            return;
        }
        history.push(`/operacao/${firstSubpage.id}`);
    }, [menuItems, pathnameResource, history]);

    return (
        <div className="operation-page">
            <h2>Operação</h2>
            <div className="operation-container">
                <div className="operation-navbar">
                    {menuItems.map(page => !page.section
                        ? (
                            <Link
                                key={page.id}
                                to={`/operacao/${page.id}`}
                                className={`${pathnameResource === page.id ? 'selected' : ''}`}
                            >
                                {page.title}
                            </Link>
                        ) : <span key={page.id}>{page.section}</span>
                    )}
                </div>
                <div className="operation-content">
                    <Switch>
                        {menuItems.filter(page => !page.section).map(page => (
                            <Route
                                key={page.id}
                                path={`/operacao/${page.id}`}
                                component={page.component}
                            />
                        ))}
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Operation;
