import React from 'react';

import Loading from '../../components/loading';
import SearchForm from './components/search-form';
import MeasuresChart from './components/measures-chart';
import MeasuresTable from './components/measures-table';
import BasinsStages from '../../components/basins-stages';

import { useStationsPrecipitationMeasuresState } from './states';

import './styles.css';

function StationsPrecipitationMeasures() {
    const {
        fetchMeasures,
        lastMeasureDatetime,
        loadingMeasures,
        measures,
        stations,
        weatherVariables,
        stationId,
        stationName,
    } = useStationsPrecipitationMeasuresState();

    return (
        <div className="stations-weather-page">
            <h3>Últimos dados pluviométricos da estação</h3>
            <SearchForm
                fetchMeasures={fetchMeasures}
                lastMeasureDatetime={lastMeasureDatetime}
                measures={measures}
                stations={stations}
                weatherVariables={weatherVariables}
                initialStationId={stationId}
            />
            {loadingMeasures
                ? (<Loading size={32} color="#888" />)
                : (<>
                    <MeasuresChart
                        measures={measures}
                    />
                    <div className="table-and-basins">
                        <MeasuresTable
                            measures={measures}
                            stationName={stationName}
                            weatherVariables={weatherVariables}
                        />
                        <BasinsStages vertical={true} />
                    </div>
                </>)}
        </div>
    );
}

export default StationsPrecipitationMeasures;
