import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import PhoneOperatorBox from '../phone-operator-box';
import api from '../../../../services/api';

function PhoneOperatorsList() {
    const [phoneOperators, setPhoneOperators] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/phone-operators');
            setPhoneOperators(response.data);
        })();
    }, []);

    function addNewConfiguration() {
        const id = phoneOperators?.length ?
            Math.max(...phoneOperators.map(phoneOperator => phoneOperator.id)) + 1
            : 1;
        setPhoneOperators([ ...phoneOperators, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Operadoras telefônicas</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewConfiguration}
            />
        </div>
        {phoneOperators && phoneOperators.map(phoneOperator => (
            <PhoneOperatorBox
                key={phoneOperator.id}
                phoneOperator={phoneOperator}
            />
        ))}
    </>);
}

export default PhoneOperatorsList;
