import { useEffect } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useStationInstitutionsSelectionInputState() {
    const {
        manageStationInstitutions: {
            fetch: fetchStationInstitutions,
            value: stationInstitutions,
        }
    } = useApiData();

    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    const stationInstitutionOptions = stationInstitutions?.map(({ name, alias }) => {
        return {
            label: name,
            value: alias,
        }
    });

    return stationInstitutionOptions;
};
