import { useEffect, useMemo, useRef } from 'react';
import { useApiData } from '../../../api-data';
import { useAuth } from '../../../auth';

import {
    deleteUserSelectedItems,
    getUserSelectedItems,
    setUserSelectedItems
} from '../../../../utils/user';

export default function useRadarAndSatelliteSelection(radar) {
    const { settingsSet } = useAuth();
    const apiData = useApiData();
    const radarRef = useRef(radar)

    const {
        SATELLITE_PRODUCT_TYPES_ID: satellitesProductTypesIds = [],
        RADAR_ALIASES: radarAliases = [],
        SATELLITE_ALIASES: satelliteAliases = [],
    } = { ...settingsSet };

    const {
        satellitesProductTypes: { value: satellitesProductTypes },
        selectedRadar: { selectRadar },
    } = apiData;

    const selectRadarRef = useRef(selectRadar);
    useEffect(() => {
        if(radarRef.current){
            return;
        } 
        selectRadarRef.current = selectRadar;
    }, [selectRadar]);

    const userSelectedRadarOrSatellite = getUserSelectedItems('selected_radars_or_satellites');
    const userSatelliteProductTypesId = getUserSelectedItems('SATELLITE_PRODUCT_TYPES_ID');

    const allAliases = useMemo(() => [...radarAliases, ...satelliteAliases], [radarAliases, satelliteAliases]);

    useEffect(() => {
        if(radarRef.current){
            return;
        } 
        const selectedProductType = satellitesProductTypes.find(productType => productType.alias === userSatelliteProductTypesId);

        if (selectedProductType && !satellitesProductTypesIds.includes(String(selectedProductType.id))) {
            deleteUserSelectedItems('selected_satellites');
            deleteUserSelectedItems('SATELLITE_PRODUCT_TYPES_ID');
        }
    }, [userSatelliteProductTypesId, satellitesProductTypes, satellitesProductTypesIds]);

    useEffect(() => {
        if(radarRef.current){
            return;
        } 

        if (!allAliases.filter(item => item).length > 0) {
            deleteUserSelectedItems('selected_radars_or_satellites');
            deleteUserSelectedItems('selected_satellites');
            deleteUserSelectedItems('SATELLITE_PRODUCT_TYPES_ID');
        }

        if (!allAliases.includes(userSelectedRadarOrSatellite)) {
            deleteUserSelectedItems('selected_radars_or_satellites');

            const newSelectionAlias = allAliases.find(alias => alias) || null;

            if (newSelectionAlias) {
                if (radarAliases.includes(newSelectionAlias)) {
                    selectRadarRef.current(newSelectionAlias);
                    deleteUserSelectedItems('selected_satellites');
                } else if (satelliteAliases.includes(newSelectionAlias) || !radarAliases.length > 0) {
                    setUserSelectedItems('selected_radars_or_satellites', newSelectionAlias);
                    setUserSelectedItems('selected_satellites', newSelectionAlias);
                }
            }
        }
    }, [
        userSelectedRadarOrSatellite,
        allAliases,
        radarAliases,
        satelliteAliases
    ]);
}
