import { useState, useCallback } from 'react';

import api from '../../../../services/api';

export function useStaticPositionTypeState(type) {
    const [updatedType, setUpdatedType] = useState(type);

    const creating = !updatedType.name;
    const [name, setName] = useState(updatedType.name || '');
    const [alias, setAlias] = useState(updatedType.alias || '');
    const [radius, setRadius] = useState(updatedType.radius || []);
    const shouldSave = () => {
        const nameAndAliasAreSet = name?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndAliasAreSet;
        }
        const nameChanged = name !== updatedType.name;
        const aliasChanged = alias !== updatedType.alias;
   
        const currentRadius = radius || [];
        const initialRadius = updatedType.radius || [];

        let radiusChanged
        if(currentRadius.length !== initialRadius.length){
            radiusChanged = true
        }else{
            radiusChanged = currentRadius.some(() => {
                const updatedRadiusValues = currentRadius || [];
                const initialRadiusValues = initialRadius || [];
                if (updatedRadiusValues.length !== initialRadiusValues.length) {
                    return true;
                }
                return updatedRadiusValues.some((value, index) => value !== initialRadiusValues[index]);
            });
        }
    
        return nameAndAliasAreSet && (nameChanged || aliasChanged || radiusChanged);
    };
    async function saveType() {
        if (shouldSave()) {
            const typeToSend = {
                name,
                alias,
                radius
            };
            if (creating) {
                const response = await api.post('/manage/static-position-types', typeToSend);
                typeToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/static-position-types/${updatedType.id}`, typeToSend);
                typeToSend.id = updatedType.id;
            }
            setUpdatedType(typeToSend);
        }
    }

    const handleChangeRadius = useCallback((index, value) => {
        setRadius(prevRadius => (
            prevRadius.map((item, i) => i === index ? value : item)
        ));
    }, []);

    const addRadius = () => {
        setRadius([...radius, '']);
    };

    const removeRadius = (index) => {
        setRadius(radius.filter((_, i) => i !== index));
    };

    return {
        updatedType,
        setUpdatedType,
        creating,
        name,
        setName,
        alias,
        setAlias,
        radius,
        setRadius,
        shouldSave,
        handleChangeRadius,
        addRadius,
        removeRadius,
        saveType
    };
}
