import React from 'react';
import {
    FiArrowUpCircle,
    FiArrowDownCircle,
} from 'react-icons/fi';
import {
    FaChevronLeft,
    FaChevronRight,
    FaSearch,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';
import ActionIconBtn from '../../../../components/common/btn/action-icon-btn';
import { SelectionInput } from '../../../../components/common/custom-inputs';
import Loading from '../../../../components/loading';

import {
    useStationHighestPrecipitationsStates,
} from './states';

import './styles.css';

function StationHighestPrecipitations() {
    const {
        currentPage,
        download,
        frequency,
        highestPrecipitations,
        pages,
        precipitations,
        save,
        searchPrecipitations,
        searchingPrecipitations,
        setCurrentPage,
        setStationId,
        setVariableId,
        shouldSave,
        stationId,
        stationsOptions,
        togglePrecipitation,
        totalPages,
        variableId,
        variablesOptions,
    } = useStationHighestPrecipitationsStates();

    return (
        <div className="station-highest-precipitations-operation-page">
            <h3>Maiores Chuvas</h3>

            <form
                className="station-precipitations-search-form"
            >
                <div className="form-inputs">
                    <div className="form-group">
                        <label htmlFor="select-station">Estação:</label>
                        {stationsOptions && <SelectionInput
                            inputId="select-station"
                            disabled={searchingPrecipitations}
                            defaultValue={stationId}
                            isNullable={false}
                            options={stationsOptions}
                            setValue={setStationId}
                        />}
                    </div>

                    <div className="form-group">
                        <label htmlFor="select-variable">Frequência:</label>
                        {(variablesOptions && variableId) && <SelectionInput
                            inputId="select-variable"
                            disabled={searchingPrecipitations}
                            defaultValue={variableId}
                            isNullable={false}
                            options={variablesOptions}
                            setValue={value => setVariableId(Number(value))}
                        />}
                    </div>
                </div>

                <div className="form-actions">
                    <ActionIconBtn
                        action={searchPrecipitations}
                        disabled={searchingPrecipitations}
                        label="Pesquisar"
                        icon={FaSearch}
                        className="main-btn"
                    />

                    <ActionIconBtn
                        action={save}
                        disabled={!shouldSave()}
                        label="Publicar"
                        icon={FiArrowUpCircle}
                        className="main-btn"
                    />
                </div>
            </form>

            <div className="download-action-container">
                <ActionIconBtn
                    action={download}
                    disabled={searchingPrecipitations}
                    label="Baixar CSV"
                    icon={FiArrowDownCircle}
                    className="download-btn"
                />
            </div>

            <DashboardBox
                title="Maiores Chuvas - Todas as estações"
                className="station-highest-precipitations-table-box"
            >
                {searchingPrecipitations && (<div className="loading-container">
                    <Loading size={32} color="#888" />
                </div>)}
                {!searchingPrecipitations && precipitations && (
                    <div className="station-highest-precipitations-table-container">
                        <table
                            className="station-highest-precipitations-table"
                        >
                            <thead>
                                <tr className="first-header">
                                    <th className="identification">
                                        Estação
                                    </th>
                                    <th className="identification">
                                        Hora da Leitura
                                    </th>
                                    <th className="precipitation">
                                        Acumulado em {frequency}
                                    </th>
                                    <th className="precipitation">
                                        Selecionar
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {precipitations
                                    .map(precipitation => {
                                        const {
                                            stationName,
                                            datetimeStr,
                                            datetime_id,
                                            variable_id,
                                            value,
                                        } = precipitation;
                                        const checked = !!(highestPrecipitations[variable_id] || []).find(p => p.datetime_id === datetime_id);
                                        return (
                                            <tr key={`row-${datetime_id}`}>
                                                <td>
                                                    {stationName}
                                                </td>
                                                <td>
                                                    {datetimeStr}
                                                </td>
                                                <td className="precipitation-value">
                                                    {value}
                                                </td>
                                                <td className="precipitation-select">
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={() => togglePrecipitation(precipitation)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        {pages && <div className="station-highest-precipitations-pagination">
                            Página {pages.map(pageIndex => (
                                <span
                                    key={`page-${pageIndex}`}
                                    className={`${pageIndex<1 ? 'separator' : ''} ${pageIndex === currentPage ? 'current' : ''}`}
                                    onClick={pageIndex<1 ? () => {} : () => setCurrentPage(pageIndex)}
                                >
                                    {pageIndex<1 ? '...' : pageIndex}
                                </span>
                            ))}
                            <FaChevronLeft
                                className="navigate-page"
                                onClick={() => currentPage>1 && setCurrentPage(currentPage-1)}
                            />
                            <FaChevronRight
                                className="navigate-page"
                                onClick={() => currentPage<totalPages && setCurrentPage(currentPage+1)}
                            />
                        </div>}
                    </div>
                )}
            </DashboardBox>
        </div>
    );
}

export default StationHighestPrecipitations;
