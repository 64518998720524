import { useMemo, useState, useEffect } from 'react';
import api from '../../../../services/api';

function viewDatetime(datetime) {
    const dateObject = new Date(Number(datetime));
    const date = dateObject.toLocaleDateString('pt-BR');
    const time = dateObject.toLocaleTimeString('pt-BR');
    return `${date} - ${time}`;
}

export function useMeasuresTableStates(measures, telemetryVariables) {
    const [phoneOperators, setPhoneOperators] = useState(null);
    const [broadcastChannels, setBroadcastChannels] = useState(null);

    useEffect(() => {
        if (phoneOperators && broadcastChannels) {
            return;
        }
        (async function() {
            const operators = (await api.get('/phone-operators')).data;
            const channels = (await api.get('/broadcast-channels')).data;
            setPhoneOperators(operators);
            setBroadcastChannels(channels);
        })();
    }, [phoneOperators, broadcastChannels]);

    const tableData = useMemo(() => {
        if (!measures || !telemetryVariables || !phoneOperators || !broadcastChannels) {
            return null;
        }
        
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime, sensorDatetimes } = measure;
            const telemetryMeasures = {};
            Object.keys(measure).forEach(key => {
                if (['datetime', 'sensorDatetimes'].includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const telemetryVariable = telemetryVariables.find(v => v.key === key);
                    variables.push(telemetryVariable);
                }
                telemetryMeasures[key] = measure[key];
            });
            result.push({
                ...telemetryMeasures,
                datetime,
                sensorDatetimes,
            });
            return result;
        }, []);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const tableHeader = [
            'Hora da Leitura',
            'Hora da Recepção',
            'Hora de Envio',
            'Canal',
            'Operadora',
        ].concat(
            variables.map(({ label }) => label),
        );
        const tableContent = content.map(measure => {
            const { datetime, sensorDatetimes } = measure;
            const {
                datetime_sent,
                datetime_received,
                broadcast_channel_id,
                phone_operator_id,
            } = { ...sensorDatetimes };
            const datetimes = [datetime, datetime_received, datetime_sent];
            const operator = phoneOperators.find(({ id }) => id === phone_operator_id);
            const channel = broadcastChannels.find(({ id }) => id === broadcast_channel_id);
            return datetimes
                .map(d => d
                    ? {
                        v: d,
                        f: viewDatetime(d),
                    }
                    : {
                        v: 0,
                        f: '-',
                    })
                // .map(d => d ? viewDatetime(d) : '-')
                .concat([
                    channel?.name,
                    operator?.name,
                ])
                .concat(variables.map(({ key }) => {
                    if (isNaN(measure[key])) {
                        return measure[key];
                    }
                    const v = Number(Number(measure[key]).toFixed(2));
                    return {
                        v,
                        f: Number(v).toLocaleString('pt-BR'),
                    };
                }));
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [measures, telemetryVariables, phoneOperators, broadcastChannels]);

    return {
        tableData,
    };
};
