import React from 'react';

import Map from '../map';

function DashboardMapBox() {
    return (
        <div className="dashboard-box5">
            <Map />
        </div>
    );
}

export default DashboardMapBox;
