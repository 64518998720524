import { useEffect, useState, useCallback, useMemo } from 'react';

import { useApiData } from '../../../../contexts/api-data';

import {
    filterStationWeatherMeasures,
    filterStationTelemetryMeasures,
} from '../../../../services/api';

import api from '../../../../services/api';

export function useMeasuresWithSensorListStates() {
    const {
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
        manageTelemetryVariables: {
            fetch: fetchTelemetryVariables,
            value: telemetryVariables,
        },
        manageStations: {
            fetchByInstitution,
            value: originalStations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
    } = useApiData();

    const [stations, setStations] = useState(null);
    const [loadingMeasures, setLoadingMeasures] = useState(false);
    const [stationMeasures, setStationMeasures] = useState(null);
    const [selectedStation, setSelectedStation] = useState(null);
    const [phoneOperators, setPhoneOperators] = useState(null);
    const [broadcastChannels, setBroadcastChannels] = useState(null);

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    useEffect(() => {
        if (stations || !institutionId) return;
        fetchByInstitution(institutionId, false);
    }, [fetchByInstitution, institutionId, stations]);

    useEffect(() => {
        setStations(originalStations);
    }, [originalStations]);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        fetchTelemetryVariables();
    }, [fetchTelemetryVariables]);

    useEffect(() => {
        if (phoneOperators && broadcastChannels) {
            return;
        }
        (async function() {
            const operators = (await api.get('/phone-operators')).data;
            const channels = (await api.get('/broadcast-channels')).data;
            setPhoneOperators(operators);
            setBroadcastChannels(channels);
        })();
    }, [phoneOperators, broadcastChannels]);

    const fetchMeasures = useCallback(({ stationId, datetimeStart, datetimeEnd }) => {
        if (loadingMeasures || !datetimeStart || !datetimeEnd || !weatherVariables || !telemetryVariables) {
            return;
        }
        (async function () {
            setLoadingMeasures(true);
            const parameters = {
                stationId,
                datetimeStart,
                datetimeEnd,
            }
            setSelectedStation(stations.find(({id}) => id === Number(stationId)));
            const newMeasures = await filterStationWeatherMeasures({
                ...parameters,
                variables: weatherVariables.map(wv => wv.key),
            });
            const newTelemetryMeasures = await filterStationTelemetryMeasures({
                ...parameters,
                variables: telemetryVariables.map(tv => tv.key),
            });
            const newStationMeasures = newTelemetryMeasures
                .reduce((result, newTelemetryMeasure) => {
                    const newMeasure = newMeasures.find(nm => newTelemetryMeasure.datetime === nm.datetime);
                    if (!newMeasure) {
                        return newTelemetryMeasure;
                    }
                    result.push({
                        ...newTelemetryMeasure,
                        ...newMeasure
                    })
                    return result;
            }, [])

            setStationMeasures(newStationMeasures.sort((a, b) => b.datetime - a.datetime));
            setLoadingMeasures(false);
        })();
    }, [loadingMeasures, stations, weatherVariables, telemetryVariables]);

    return {
        broadcastChannels,
        fetchMeasures,
        loadingMeasures,
        phoneOperators,
        selectedStation,
        stationMeasures,
        stations,
        institutionId,
    };
};
