import React, {useEffect, useState} from 'react';

import { useAuth } from '../../../../contexts/auth';
import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function SatelliteSelection(){
    const { settingsSet } = useAuth();
    const apiData = useApiData();

    const {
        SATELLITE_ALIASES: satelliteAliases = [],
        SATELLITE_PRODUCT_TYPES_ID: satellitesProductTypesIds = [],
    } = { ...settingsSet };

    const {
        satellites : {
            fetchAndSave,
            value: satellites
        },
        selectSatellites :{
            selectedSatellite,
            selectSatelliteAndProduct,
            selectedSatelliteProductType
        },
        satellitesProductTypes : {
            fetchAndSave: fetchAndSaveProductType,
            value: satellitesProductTypes
        },
    } = apiData;

    const satellitesOptions = (satelliteAliases.length > 0 && satellites) ? satellites
        .filter(({alias, inactive}) => !inactive && satelliteAliases.includes(alias))
        .sort((a, b) => a.name.localeCompare(b.name)) : [];

    const satelliteProductTypesOptions = (satellitesProductTypesIds.length > 0 && satellitesProductTypes) ? satellitesProductTypes
        .filter(({id}) => satellitesProductTypesIds.includes(id.toString()))
        .sort((a, b) => a.name.localeCompare(b.name)) : [];

    function Dropdown({satellite, satelliteProducts}){
        const [openMenuSatellite, setOpenMenuSatellite] = useState(false);

        useEffect(() => {
            if(selectedSatellite  && selectedSatellite?.alias === satellite?.alias){
                setOpenMenuSatellite(true)
            }
        }, [satellite.alias]);
        return (
            <>
                    <li
                        key={`satellite-${satellite?.alias}`}
                        className={`list-group-item list-group-item-secondary ${(selectedSatellite  && selectedSatellite?.alias === satellite?.alias) ? 'li-selected' : ''}`}
                        onClick={() => setOpenMenuSatellite(!openMenuSatellite)}
                    >
                        {satellite?.name}
                    </li>
                    {openMenuSatellite && 
                        (<ul className="dropdown-list list-group">
                            {satelliteProducts.map(satelliteProduct => (
                                <li
                                    key={`satellite-${satelliteProduct.alias}`}
                                    className={`dropdown-list-item ${(selectedSatelliteProductType && selectedSatelliteProductType.alias === satelliteProduct.alias && selectedSatellite?.alias === satellite?.alias) ? 'dropdown-li-selected' : ''}`}
                                    onClick={() => selectSatelliteAndProduct(satellite.alias, satelliteProduct.alias)}
                                >
                                    {satelliteProduct.name}
                                </li>
                            ))}
                        </ul>)
                    }
            </>
        );
    }

    useEffect(() => {
        fetchAndSave();
        fetchAndSaveProductType();
    }, [fetchAndSave, fetchAndSaveProductType]);

    return satellitesOptions.length > 0 && (<>
       <div className="leftbar-title">Satélites Meteorológicos</div>
            <ul className='list-group'>
                {satellitesOptions.map(satellite =>(
                    <Dropdown key={satellite.alias} satellite={satellite} satelliteProducts={satelliteProductTypesOptions}/>
                ))} 
            </ul>                           
    </>);
}

export default SatelliteSelection;