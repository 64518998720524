import React from 'react';
import { Popup } from 'reactjs-popup';
import { FaInfoCircle } from 'react-icons/fa';
import {
    ImArrowUp,
    ImArrowDown,
    ImMinus,
    ImBlocked,
} from 'react-icons/im';

function TendencyIconLegend() {
    return (
        <Popup
            closeOnDocumentClick={true}
            closeOnEscape={true}
            className="precipitation-legend"
            position="bottom left"
            trigger={
                <span className="precipitation-legend-trigger">
                    <FaInfoCircle />
                </span>
            }
        >
            <div className="precipitation-legend-container">
                <div>
                    Tendência é calculada<br />
                    com base na chuva de 5 min
                </div>
                <div>
                    <ImBlocked />
                    <span>Dados com erro</span>
                </div>
                <div>
                    <ImMinus />
                    <span>Chuva estável</span>
                </div>
                <div>
                    <ImArrowUp color="hsl(240, 50%, 50%)" />
                    <span>Chuva elevou</span>
                </div>
                <div>
                    <ImArrowDown color="hsl(0, 50%, 50%)" />
                    <span>Chuva declinou</span>
                </div>
            </div>
        </Popup>
    );
}

export default TendencyIconLegend;
