import React from 'react';

import EntityBox from '../auth/entity-box';
import PostBtn from '../common/post-btn';

import { useTelemetryVariableBoxStates } from './states';

import './styles.css';

function TelemetryVariableBox({ variable }) {
    const {
        creating,
        isNumericInputId,
        register,
        save,
        shouldSave,
        title,
    } = useTelemetryVariableBoxStates(variable);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="telemetry-variable-box-form">
                <input
                    placeholder="Propriedade"
                    {...register('key')}
                />
                <input
                    placeholder="Nome de exibição"
                    {...register('label')}
                />
                <label htmlFor={isNumericInputId}>
                    Numérica
                </label>
                <input
                    id={isNumericInputId}
                    type="checkbox"
                    {...register('is_numeric')}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default TelemetryVariableBox;
