import React from 'react';

import PostBtn from '../../../common/post-btn';

import EntityBox from '../../../auth/entity-box';

import { useStationMonitorBoxStates } from './states';

import './styles.css';

function StationMonitorBox({ station }) {
    const {
        formattedDatetime,
        formattedDelay,
        inactive,
        openFunction,
        saveStation,
        setInactive,
        shouldSave,
        stationDelayInputId,
        stationMeasureDatetimeInputId,
        stationInactiveInputId,
        title,
    } = useStationMonitorBoxStates(station);

    return (
        <EntityBox
            title={title}
            onOpen={openFunction}
            alwaysOpen={true}
        >
            <div id="station-monitor-box-form" className="entity-box-form station-monitor-box-form">
                <div className="form-group">
                    <label htmlFor={stationMeasureDatetimeInputId}>Última Leitura:</label>
                    <span>{formattedDatetime}</span>
                </div>
                <div className="form-group">
                    <label htmlFor={stationDelayInputId}>Tempo de atraso:</label>
                    <span>{formattedDelay}</span>
                </div>                

                <div className="form-group checkbox-input">
                    <label htmlFor={stationInactiveInputId}>Desabilitar</label>
                    <input
                        id={stationInactiveInputId}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group right">
                    <PostBtn
                        postFunction={saveStation}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default StationMonitorBox;
