import React, { useEffect } from 'react';

import NewBtn from '../../common/new-btn';
import StationInstitutionBox from '../institution-box';

import { useApiData } from '../../../../contexts/api-data';

function StationInstitutionsList() {
    const {
        manageStationInstitutions:{
            fetch: fetchStationInstitutions,
            value: stationInstitutions,
            add: addNewInstitution,
        },
    } = useApiData();
    
    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    return (<>
        <div className="header">
            <h3>Instituições de Estações Meteorológicas</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewInstitution}
            />
        </div>
        {stationInstitutions && stationInstitutions.map(stationInstitution => (
            <StationInstitutionBox
                key={stationInstitution.id}
                stationInstitution={stationInstitution}
            />
        ))}
    </>);
}

export default StationInstitutionsList;
