import React from 'react';

import EntityCard from '../../../common/entity-card';
import StationInstitutionDownloadPopup from '../institution-download-popup';

function StationInstitutionCard({ institution }) {
    return (
        <EntityCard title={institution.name}>
            <StationInstitutionDownloadPopup institution={institution} />
        </EntityCard>
    );
}

export default StationInstitutionCard;
