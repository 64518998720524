import baseApi from '../../base';

const baseResource = '/extended-forecasts'

export async function getExtendedForecasts(forecast_region_id) {
    const result = await baseApi.get(`${baseResource}?forecast_region_id=${forecast_region_id}`);
    return result.data;
};

export async function postExtendedForecasts(extendedForecasts) {
    const result = await baseApi.post(baseResource, extendedForecasts);
    return result.data;
};
