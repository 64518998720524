import { useState } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export const variableSelectionOptions = [
    {
        value: 'precipitation',
        label: 'Precipitação',
    },
    {
        value: 'precipitation_accuracy',
        label: 'Acurácia de Precipitação',
    },
    {
        value: 'wind_speed',
        label: 'Velocidade do Vento',
    },
    {
        value: 'wind_direction',
        label: 'Direção do Vento',
    },
    {
        value: 'temperature',
        label: 'Temperatura',
    },
    {
        value: 'relative_humidity',
        label: 'Umidade Relativa',
    },
    {
        value: 'wind_gust',
        label: 'Rajada de Vento',
    },
    {
        value: 'pressure',
        label: 'Pressão (PNMM)',
    },
    {
        value: 'radiation',
        label: 'Radiação Solar',
    },
    {
        value: 'dew_point',
        label: 'Ponto de Orvalho',
    },
    {
        value: 'fog_fsi',
        label: 'Nevoeiro (FSI)',
    },
    {
        value: 'fog_vis',
        label: 'Nevoeiro (Vis)',
    },
    {
        value: 'significant_wave_height',
        label: 'Altura de Onda Significativa',
    },
    {
        value: 'mean_wave_period',
        label: 'Período Médio de Onda',
    },
    {
        value: 'mean_wave_direction',
        label: 'Direção Média de Onda',
    },
    {
        value: 'astronomic',
        label: 'Altura da Maré e Fase da Lua',
    },
];

export function useVariableByModelInputStates({ defaultValue, setValue }) {
    const {
        forecastModels: {
            value: forecastModels,
        },
    } = useApiData();

    const modelOptions = Object.values(forecastModels).reduce((result, {id, name, alias, chart_frequency}) => {
        const option = {
            value: `${id}`,
            label: `${name} (${alias})`,
        }
        result.push(option);
        return result;
    }, [{
        value: 'ast',
        label: 'Astronômica',
    }]);

    const defaultValueSplitted = defaultValue?.split(',');
    const [variableModel, setVariableModel] = useState(defaultValueSplitted?.[0]);
    const [hourlyModel, setHourlyModel] = useState(defaultValueSplitted?.[1]);
    const [dailyModel, setDailyModel] = useState(defaultValueSplitted?.[2]);

    function updateValue(newVariableModel, newHourlyModel, newDailyModel) {
        if (newVariableModel !== variableModel) {
            setVariableModel(newVariableModel);
        }
        if (newHourlyModel !== hourlyModel) {
            setHourlyModel(newHourlyModel);
        }
        if (newDailyModel !== dailyModel) {
            setDailyModel(newDailyModel);
        }
        if (!newVariableModel || !newHourlyModel || !newDailyModel) {
            return;
        }
        setValue(`${newVariableModel},${newHourlyModel},${newDailyModel}`);
    }

    return {
        dailyModel,
        hourlyModel,
        modelOptions,
        updateValue,
        variableModel,
    };
};
