import React, { createContext, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';

import api, { connectSocket } from '../services/api';
import { getLoggedUser, removeLoggedUser, setLoggedUser } from '../utils/user';

const AuthContext = createContext({});

export default AuthContext;

export function AuthProvider({ children }) {
    const loggedUserIsSet = getLoggedUser() !== null;
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [rolesSet, setRolesSet] = useState(null);
    const [permissionsSet, setPermissionsSet] = useState(null);
    const [settingsSet, setSettingsSet] = useState(null);
    const [socket, setSocket] = useState(null);

    const isMaster = rolesSet?.includes('MASTER');
    const isManager = isMaster || permissionsSet?.some(key => {
        return key.startsWith('MANAGE_')
            || key === 'VIEW_SYSTEM_MONITOR'
            || key === 'VIEW_DATA_CALENDAR_MESSAGES';
    });

    useEffect(() => {
        if (isAuthenticated) {
            const loggedUser = getLoggedUser();

            const promises = [];
            promises.push((async function() {
                const response = await api.get('/auth/roles-keys');
                loggedUser.roles = response.data;
                setRolesSet(response.data);
            })());

            promises.push((async function() {
                const response = await api.get('/auth/permissions-keys');
                loggedUser.permissions = response.data;
                setPermissionsSet(response.data);
            })());
            
            promises.push((async function() {
                const response = await api.get('/auth/customer-settings');
                loggedUser.customer_settings = response.data;
                setSettingsSet(response.data);
            })());

            (async function() {
                await Promise.all(promises);
                setLoggedUser(loggedUser);
            })();

            const {
                authorization: { access_token },
            } = loggedUser;
            const socket = connectSocket(access_token);

            socket.on('customer-settings', data => {
                const loggedUser = getLoggedUser();
                loggedUser.customer_settings = data;
                setLoggedUser(loggedUser);
                setSettingsSet(data);
            });

            setSocket(socket);
        }
    }, [isAuthenticated]);

    if (loggedUserIsSet) {
        startResettingAccessToken();
    }

    async function startResettingAccessToken() {
        const loggedUser = getLoggedUser();
        if (loggedUser) {
            const { access_token, refresh_token } = loggedUser.authorization;
            const tokenDecoded = jwt_decode(access_token);
            const twoMinutesFromHere = Date.now() + 120000;
            const expirationTime = tokenDecoded.exp*1000;
            if (twoMinutesFromHere >= expirationTime) {
                const response = await api.post('/auth/token', { refresh_token });
                loggedUser.authorization.access_token = response.data.access_token;
                setLoggedUser(loggedUser);
            }
            if (!isAuthenticated) {
                setIsAuthenticated(true);
            }

            setTimeout(startResettingAccessToken, 60000);
        }
    }

    async function login(email, password) {
        try {
            const response = await api.post('/auth/login', { email, password });

            setLoggedUser(response.data);
            setIsAuthenticated(true);

            return response.data;
        }
        catch (error) {
            return error.response.data;
        }
    }

    async function logout() {
        const { authorization: { refresh_token } } = getLoggedUser();
        await api.post('/auth/logout', { refresh_token });
        removeLoggedUser();
        setIsAuthenticated(false);
    }

    async function register(inviteCode, id, email, name, password) {
        try {
            const response = await api.post(`/auth/users/${id}/register`, {
                email,
                name,
                password,
            }, {
                headers: {
                    Authorization: inviteCode,
                },
            });

            return response.data;
        }
        catch (error) {
            return error.response.data;
        }
    }

    async function resetPassword(code, password) {
        try {
            const response = await api.put('/auth/users/password', {
                password,
            }, {
                headers: {
                    Authorization: code,
                },
            });

            return response.data;
        }
        catch (error) {
            return error.response.data;
        }
    }

    async function sendResetPasswordEmail(email) {
        try {
            const response = await api.post('/auth/users/reset-password', { email });

            return response.data;
        }
        catch (error) {
            return error.response.data;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isManager,
                isMaster,
                login,
                logout,
                permissionsSet,
                register,
                resetPassword,
                rolesSet,
                sendResetPasswordEmail,
                settingsSet,
                socket,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
