import React from 'react';
import {
    MapContainer,
    TileLayer,
    ZoomControl,
} from 'react-leaflet';

import { MapProvider, useMap } from '../../contexts/map-context';

import { useAuth } from '../../contexts/auth';

import AttentionPerimeters from '../attention-perimeters';
import LightningsBoundaries from '../lightnings-boundaries';
import LightningsMarkers from '../lightnings-markers';
import MapShapes from '../map-shapes';
import MapTimeControl from '../map-time-control';
import RadarCircle from '../radar-circle';
import RadarImage from '../radar-image';
import RadarLegend from '../radar-legend';
import SatelliteLegend from '../satellite-legend';
import StaticPositionsMarkers from '../static-positions-markers';
import StationsLegend from '../stations-legend';
import StationsMarkers from '../stations-markers';

import './styles.css';

function MapComponent({ showNeighborhoodShapes, showLightnings, showAttentionPerimeters }) {

    const {
        mapPosition: { initialLatitude, initialLongitude, initialMapZoom },
        selectedRadarOrSatellite,
        shapes
    } = useMap();

    return (initialLatitude && initialLongitude && initialMapZoom) ? (
        <MapContainer
            id="map"
            className="map"
            center={[initialLatitude, initialLongitude]}
            zoom={initialMapZoom}
            zoomControl={false}
            style={{ width: '100%', height: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={17}
            />

            <ZoomControl position='topright' />

            {showNeighborhoodShapes && shapes.neighborhood && (
                <MapShapes
                    jsonData={shapes.neighborhood}
                    nameAttribute="nome"
                />
            )}

            <MapTimeControl />

            {showLightnings && (<>
                <LightningsMarkers />

                <LightningsBoundaries />
            </>)}

            {showAttentionPerimeters && (<>
                <AttentionPerimeters />
            </>)}

            <StationsMarkers />

            <StationsLegend />

            {selectedRadarOrSatellite && (<>
                <RadarImage  />
            </>)}

            <RadarLegend />

            <SatelliteLegend />

            {selectedRadarOrSatellite?.radius && (
                <RadarCircle
                    selectedRadarOrSatellite={selectedRadarOrSatellite}
                />
            )}

            <StaticPositionsMarkers />

        </MapContainer>
    ) : null;
}

const Map = (props) => {
    const { settingsSet } = useAuth();

    return settingsSet ? (
        <MapProvider radar={props.radar} >
            <MapComponent { ...props } />
        </MapProvider>
    ) : <div>Carregando configurações...</div>
};

export default Map;
