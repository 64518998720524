import { useEffect, useMemo, useState } from 'react';

import datetimeUtils from '../../../../../utils/datetime';

import { useApiData } from '../../../../../contexts/api-data';
import api from '../../../../../services/api';

export function useStationMonitorBoxStates(station) {
    const {
        retrieveNeighborhoodsByCity,
    } = useApiData();

    const [updatedStation, setUpdatedStation] = useState(station);
    const [city, setCity] = useState(null);
    const [inactive, setInactive] = useState(Boolean(updatedStation.inactive));

    const {
        name,
        code,
        delay,
        measures,
    } = updatedStation;
    const stationId = updatedStation.id || updatedStation.tempId;

    const delayInMinutes = datetimeUtils.msToMinutes(delay);
    const delayInHours = datetimeUtils.msToHours(delay);
    const formattedDelay = delayInHours !== undefined
        ? delayInHours > 2 ? `${delayInHours} horas` : `${delayInMinutes} minutos`
        : 'Tempo indefinido';
    const formattedDatetime = measures
        ? datetimeUtils.getFormatDatetimeFromDatetime(measures.datetime, true)
        : '-';
    const title = useMemo(() => (
        name && code && formattedDelay ? `${name} (${code})` : ''
    ), [name, code, formattedDelay]);

    const stationMeasureDatetimeInputId = `station-datetime-${stationId}`;
    const stationDelayInputId = `station-delay-${stationId}`;
    const stationInactiveInputId = `station-inactive-${stationId}`;

    useEffect(() => {
        const { id: city_id } = { ...city };
        if (!city_id) {
            return;
        }
        retrieveNeighborhoodsByCity(city_id);
    }, [city, retrieveNeighborhoodsByCity]);

    function shouldSave() {
        return Boolean(inactive) !== Boolean(updatedStation.inactive);
    }

    async function saveStation() {
        if (shouldSave()) {
            const stationToSend = { ...updatedStation }
            stationToSend.inactive = inactive;
            await api.put(`/manage/stations/${stationId}`, stationToSend);
            setUpdatedStation(stationToSend);
        }
    }

    function openFunction() {
        if (city) {
            return;
        }
        const {
            state_id = null,
            id = null,
        } = { ...updatedStation.city };
        setCity({ state_id, id });
    }

    return {
        formattedDatetime,
        formattedDelay,
        inactive,
        openFunction,
        saveStation,
        setInactive,
        shouldSave,
        stationDelayInputId,
        stationMeasureDatetimeInputId,
        stationInactiveInputId,
        title,
    }
};
