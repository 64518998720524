import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { useMeasuresChartStates } from './states';

import './styles.css';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Title,
    Legend,
    Tooltip,
);

function MeasuresChart({ measures, telemetryVariables }) {
    const {
        chartData,
    } = useMeasuresChartStates(measures, telemetryVariables);

    return (
        <div className="stations-telemetry-measures-chart-container">
            {chartData && (
                <Chart
                    type="line"
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                            point:{
                                radius: 0,
                            },
                        },
                        plugins: {
                            legend: {
                                position: 'bottom',
                                onClick(e, legendItem) {
                                    const index = legendItem.datasetIndex;
                                    const ci = this.chart;
                                    
                                    ci.data.datasets.forEach(function(e, i) {
                                        const meta = ci.getDatasetMeta(i);

                                        if (i === index) {
                                            meta.hidden = false;
                                        } else {
                                            meta.hidden = true;
                                        }
                                    });

                                    ci.update();
                                },
                                onHover(event) {
                                    event.native.target.style.cursor = 'pointer';
                                },
                                onLeave(event) {
                                    event.native.target.style.cursor = 'default';
                                },
                            },
                            tooltip: {
                                displayColors: false,
                                titleFontSize: 16,
                                bodyFontSize: 14,
                                xPadding: 10,
                                yPadding: 10,
                                // callbacks: {
                                //     label: (tooltipItem) => {
                                //         const annotation = tooltipItem.dataset.annotations[tooltipItem.dataIndex];
                                //         return [
                                //             `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${annotation ? `(${annotation})` : ''}`,
                                //         ];
                                //     },
                                // },
                            },
                        },
                        scales: {
                            xAxis: {
                                ticks: {
                                    autoSkip: true,
                                    align: 'center',
                                    maxRotation: 0,
                                },
                                grid: {
                                    display: false,
                                },
                            },
                            // yAxis: {
                            //     max,
                            //     min: 0,
                            //     ticks: {
                            //         stepSize: daysFrequency ? 10 : 1,
                            //         maxTicksLimit: daysFrequency ? 6 : 11,
                            //     },
                            // },
                        },
                    }}
                    height={'250px'}
                    className="stations-telemetry-measures-chart"
                />
            )}
        </div>
    );
}

export default MeasuresChart;
