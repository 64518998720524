import React from 'react';
import {
    FiArrowUpCircle,
} from 'react-icons/fi';
import {
    LuCopyPlus,
} from 'react-icons/lu';
import {
    FaTrash,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';
import ActionIconBtn from '../../../../components/common/btn/action-icon-btn';
import { SelectionInput } from '../../../../components/common/custom-inputs';
import * as icons from '../../../../media/bulletin';
import { ConfirmModal } from '../../../../components/modals';

import {
    useExtendedForecastsStates,
} from './states';

import './styles.css';

function ExtendedForecasts() {
    const {
        NULL_VALUE,
        addForecast,
        changeForecast,
        extendedForecasts,
        forecastsOptions,
        lastUpdateDatetimeString,
        regionsOptions,
        removeForecast,
        save,
        setForecastRegionId,
        shouldSave,
    } = useExtendedForecastsStates();

    return (
        <div className="extended-forecasts-operation-page">
            <div className="extended-forecasts-header">
                <h3>Previsão Estendida</h3>
                {regionsOptions && regionsOptions.length > 0 && (
                    <ActionIconBtn
                        action={addForecast}
                        label="Novo Período"
                        icon={LuCopyPlus}
                        className="main-btn"
                    />
                )}
            </div>

            <DashboardBox
                className="extended-forecasts-info-box"
            >
                <div className="extended-forecasts-info-box-container">
                    <div className="last-update-datetime">
                        <span>Última atualização: </span>
                        <span>
                            {lastUpdateDatetimeString || '-'}
                        </span>
                    </div>
                    {(regionsOptions?.length > 1) && (
                        <div className="select-region-box-container">
                            <label htmlFor="select-region">Região:</label>
                            {regionsOptions?.length ?
                                <SelectionInput
                                    setValue={value => setForecastRegionId(Number(value))}
                                    defaultValue={regionsOptions[0].value}
                                    options={regionsOptions}
                                    inputId="select-region"
                                />
                                :
                                <select disabled id="select-region">
                                    <option>Nenhuma região encontrada</option>
                                </select>
                            }
                        </div>
                    )}
                </div>
            </DashboardBox>

            {extendedForecasts?.map(({
                id,
                date,
                dayOfTheWeekString,
                period,
                sky,
                precipitation,
                icon,
                iconPath,
                windSpeed,
                windDirection,
                maxTemperature,
                minTemperature,
            }) => (
                <DashboardBox
                    key={`forecast-period-${id}`}
                    title=" "
                    className="forecast-period-box"
                >
                    <div className="forecast-period-container">
                        <div className="form-group">
                            <label htmlFor={`date-${id}`}>Data {dayOfTheWeekString}</label>
                            <input
                                type="date"
                                id={`date-${id}`}
                                value={date}
                                onChange={event => changeForecast(id, { date: event.target.value })}
                            />
                        </div>
                        <div className="variable-box-form-group">
                            <h4>Condição</h4>
                            <div className="form-group">
                                <label htmlFor={`sky-${id}`}>Céu</label>
                                <select
                                    id={`sky-${id}`}
                                    value={sky}
                                    onChange={event => changeForecast(id, { sky: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.sky?.map(({ label }, index) => (
                                        <option
                                            key={`sky-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`precipitation-${id}`}>Chuva</label>
                                <select
                                    id={`precipitation-${id}`}
                                    value={precipitation}
                                    onChange={event => changeForecast(id, { precipitation: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.precipitation?.map(({ label }, index) => (
                                        <option
                                            key={`precipitation-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`period-${id}`}>Período</label>
                                <select
                                    id={`period-${id}`}
                                    value={period}
                                    onChange={event => changeForecast(id, { period: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.extendedPeriod?.map(({ label }, index) => (
                                        <option
                                            key={`period-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`icon-${id}`}>Ícone</label>
                                <select
                                    id={`icon-${id}`}
                                    value={icon}
                                    onChange={event => changeForecast(id, { icon: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.conditionIcon?.map(({ label, path }) => (
                                        <option
                                            key={`icon-${id}-${path}`}
                                            value={path}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                                <img
                                    className="condition-icon"
                                    src={icons[iconPath]}
                                    alt={icon}
                                />
                            </div>
                        </div>
                        <div className="variable-box-form-group">
                            <h4>Vento</h4>
                            <div className="form-group">
                                <label htmlFor={`windSpeed-${id}`}>Velocidade</label>
                                <select
                                    id={`windSpeed-${id}`}
                                    value={windSpeed}
                                    onChange={event => changeForecast(id, { windSpeed: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.windSpeed?.map(({ label }, index) => (
                                        <option
                                            key={`wind-speed-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`windDirection-${id}`}>Direção</label>
                                <select
                                    id={`windDirection-${id}`}
                                    value={windDirection}
                                    onChange={event => changeForecast(id, { windDirection: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.windDirection?.map(({ label }, index) => (
                                        <option
                                            key={`wind-direction-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        <div className="variable-box-form-group temperature-box">
                            <h4>Temperatura</h4>
                            <div className="form-group">
                                <label htmlFor={`max-temperature-${id}`}>Máxima</label>
                                <input
                                    id={`max-temperature-${id}`}
                                    type="number"
                                    value={maxTemperature}
                                    onChange={event => changeForecast(id, { maxTemperature: event.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`min-temperature-${id}`}>Mínima</label>
                                <input
                                    id={`min-temperature-${id}`}
                                    type="number"
                                    value={minTemperature}
                                    onChange={event => changeForecast(id, { minTemperature: event.target.value })}
                                />
                            </div>
                        </div>
                        <div className="forecast-period-actions">
                            <ConfirmModal
                                header="Remover período de previsão?"
                                trigger={(
                                    <button
                                        className="base-btn allowed-btn action-icon-btn delete-btn"
                                    >
                                        <FaTrash />
                                        Remover
                                    </button>
                                )}
                                onConfirm={() => removeForecast(id)}
                                positiveString="Remover"
                                negativeString="Cancelar"
                            />
                        </div>
                    </div>
                </DashboardBox>
            ))}

            <div className="extended-forecasts-actions">
                <ActionIconBtn
                    disabled={!shouldSave()}
                    action={save}
                    label="Publicar"
                    icon={FiArrowUpCircle}
                    className="main-btn"
                />
            </div>
        </div>
    );
}

export default ExtendedForecasts;
