import { useMemo } from 'react';

export function useMeasuresChartStates(measures, telemetryVariables) {
    const chartData = useMemo(() => {
        if (!measures || !telemetryVariables) {
            return null;
        }
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const telemetryMeasures = {};
            Object.keys(measure).forEach(key => {
                if (['datetime', 'sensorDatetimes'].includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const telemetryVariable = telemetryVariables.find(v => v.key === key);
                    if (telemetryVariable.is_numeric && !isNaN(measure[key])) {
                        variables.push(telemetryVariable);
                    }
                }
                telemetryMeasures[key] = measure[key];
            });
            result.push({
                ...telemetryMeasures,
                datetime,
            });
            return result;
        }, []).sort((a, b) => a.datetime - b.datetime);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const chartLabels = [
            { type: 'string', label: 'Dia-Hora' },
            ...(variables.map(({ label }) => ({
                type: 'number',
                label,
            }))),
        ];
        const chartValues = content.map(measure => {
            const { datetime } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} ${time}`;
            return [datetimeStr].concat(variables.map(({ label, key }) => {
                const value = key in measure ? `${measure[key]}` : '-';
                const annotation = `${label} ${value}`;
                return {
                    value,
                    annotation,
                };
            }));
        });

        const datasets = chartLabels?.slice(1).map(({ label }, index) => {
            const data = chartValues.map(item => item[index+1]?.value);
            const annotations = chartValues.map(item => item[index+1]?.annotation);
            const color = `hsla(${index*20}, 50%, 50%, 1)`;
            return {
                label,
                backgroundColor: color,
                data,
                annotations,
                borderColor: color,
                borderWidth: 1.5,
                hidden: true,
            };
        }) || [];

        const firstDataset = datasets[0];
        if (firstDataset) {
            firstDataset.hidden = false;
        }

        return {
            labels: chartValues?.map(item => item[0]) || [],
            datasets,
        };
    }, [measures, telemetryVariables]);

    return {
        chartData,
    };
};
