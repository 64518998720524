import { useMemo } from 'react';

import { useApiData } from '../../../contexts/api-data';

export function useForecastsChartBoxStates({ periodInHours, showRegionName }) {
    const {
        selectedForecastRegion,
    } = useApiData();
    
    const periodInMs = useMemo(() => (periodInHours * 60 * 60 * 1000) - 1, [periodInHours]);
    const days = useMemo(() => periodInHours / 24.0, [periodInHours]);
    const daysFrequency = useMemo(() => days > 4, [days]);
    const titlePeriod = daysFrequency
        ? `os próximos ${days} dias`
        : `as próximas ${periodInHours} horas`;

    const regionNameOnTitle = (showRegionName && selectedForecastRegion) ? `- ${selectedForecastRegion.name}` : '';
    const title = `Previsões para ${titlePeriod} ${regionNameOnTitle}`;

    return {
        periodInMs,
        daysFrequency,
        title,
    };
};
