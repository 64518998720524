import { useEffect } from 'react';
import { useAuth } from '../../../auth';
import {
    getLoggedUserId,
    getSelectedItems,
    setSelectedItems,
} from '../../../../utils/user';

export default function useShowLightnings() {
    const loggedUserId = getLoggedUserId();
    const { settingsSet } = useAuth();
    const {
        STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [],
    } = { ...settingsSet };

    useEffect(() => {
        const canShowLightnings = staticPositionTypeAliases.includes('raios');

        const selectedShowLightnings = getSelectedItems('show_lightnings') || {};
        if (selectedShowLightnings[loggedUserId] && !canShowLightnings) {
            selectedShowLightnings[loggedUserId] = canShowLightnings;
            setSelectedItems('show_lightnings', selectedShowLightnings);
        }

    }, [staticPositionTypeAliases, loggedUserId]);
}