import React from 'react';

import './styles.css';

function AuthError({ error }) {
    return error && (
        <span className="auth-error">{error}</span>
    );
}

export default AuthError;
