import React from 'react';

import DashboardBox from '../../../../components/dashboard-box';
import SearchForm from './../energy-events-search-form';
import EnergyEventsTable from './../energy-events-table';

import { useEnergyEventsBoxState } from './states';

function EnergyEventsBox() {
    const {
        fetchEvents,
        events,
        radarId,
    } = useEnergyEventsBoxState();

    return (
        <DashboardBox title="Histórico do Controle de Energia">
            <SearchForm
                fetchEvents={fetchEvents}
                events={events}
                radarId={radarId}
            />
            <EnergyEventsTable
                events={events}
            />
        </DashboardBox>
    );
}

export default EnergyEventsBox;
