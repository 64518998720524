import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

function EntityRolesHeader({ title, shouldSave, saveFunction, feedback, creating=true }) {
    return (
        <div className="entity-roles-header-container">
            <h5>{title}</h5>
            <Link
                to="#"
                className={`save-btn ${shouldSave() ? '' : 'disabled'}`}
                onClick={saveFunction}
            >
                Salvar
            </Link>
            {creating && (
                <span className="observation strong">
                    {feedback}
                </span>
            )}
        </div>
    );
}

export default EntityRolesHeader;
