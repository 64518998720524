import { useEffect } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useMultipleWeatherVariablesSelectionInputState() {
    const {
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    const options = (weatherVariables || [])
        .filter(({ key }) => !key.includes('SLIP_STAGE'))
        .map(({ key, label }) => ({
            label,
            value: key,
        }));

    return {
        options,
        weatherVariables,
    };
};
