import { useCallback, useState } from 'react';

import { getManageConfigurations } from '../../../../services/api';

export function useManageStationConfigurationsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const stationConfigurations = await getManageConfigurations();
            setValue(stationConfigurations);
            setLoading(false);
        },
        [value, loading],
    );

    return {
        fetch,
        loading,
        value,
    };
};
