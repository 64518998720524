import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useAuth } from '../../contexts/auth';
import { subpages } from './states';

import ContactPopup from '../../components/contact-popup';

import './styles.css';

function Download() {
    const { settingsSet } = useAuth();

    const { PAGES = [] } = {
        ...settingsSet,
    };
    const downloadPagesSettings = PAGES.filter(page => page.endsWith('_DOWNLOAD'));
    const subpagesSorted = downloadPagesSettings.map(page => subpages.find(({ setting }) => page === setting));

    return (
        <div className="download-page">
            <h1>Download</h1>
            <div className="download-container">
                <div className="download-content">
                    <Switch>
                        {subpagesSorted.map(page => (
                            <Route
                                key={page.id}
                                path={`/download/${page.id}`}
                                component={page.component}
                            />
                        ))}
                    </Switch>
                </div>
            </div>
            <ContactPopup />
        </div>
    );
}

export default Download;
