import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function StationSelectionInput({
    defaultOption,
    defaultValue,
    isNullable,
    inputId,
    setValue,
    stationsInstitutionId,
}) {
    const {
        manageStations,
    } = useApiData();
    const stations = stationsInstitutionId ? manageStations.value : manageStations.allStations;
    const options = stations?.map((station) => {
        const sufix = station.inactive
            ? ' [DESATIVADA]'
            : station.is_delayed
                ? ' [ATRASADA]'
                : '';
        return {
            value: station.id,
            label: `${station.name} (${station.code})${sufix}`,
        }
    });

    return stations?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : (
        <select disabled id="select-station">
            <option> Nenhuma estação encontrada </option>
        </select>
    );
}

export default StationSelectionInput;
