import baseApi from '../../base';

const baseResource = '/bulletin-forecasts-options'

export async function getBulletinForecastsOptions(forecast_region_id) {
    const result = await baseApi.get(`${baseResource}?forecast_region_id=${forecast_region_id}`);
    return result.data;
};

export async function postBulletinForecastsOptions(forecastsOptions) {
    const result = await baseApi.post(baseResource, forecastsOptions);
    return result.data;
};
