import baseApi from '../../base';

const baseResource = '/telemetry-variables'
const baseManageResource = `/manage${baseResource}`;

export async function getTelemetryVariables() {
    const result = await baseApi.get(baseResource);
    return result.data;
};

export async function postManageTelemetryVariables(variable) {
    const result = await baseApi.post(baseManageResource, variable);
    return result.data;
};

export async function putManageTelemetryVariables(variable) {
    const result = await baseApi.put(`${baseManageResource}/${variable.id}`, variable);
    return result.data;
};
