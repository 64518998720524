import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import StaticPositionTypeBox from '../type-box';
import api from '../../../../services/api';

function StaticPositionTypesList() {
    const [staticPositionTypes, setStaticPositionTypes] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/static-position-types');
            setStaticPositionTypes(response.data);
        })();
    }, []);

    function addNewType() {
        const id = Math.max(...staticPositionTypes.map(region => region.id)) + 1;
        setStaticPositionTypes([ ...staticPositionTypes, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Tipos de Posições Estáticas</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewType}
            />
        </div>
        {staticPositionTypes && staticPositionTypes.map(type => (
            <StaticPositionTypeBox
                key={type.id}
                type={type}
            />
        ))}
    </>);
}

export default StaticPositionTypesList;
