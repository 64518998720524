import React, { useState } from 'react';
import {
    FaLongArrowAltDown,
    FaLongArrowAltUp,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';

import { useImpactWaysTableStates } from './states';

import './styles.css';

function ImpactWaysTable({ institutionId, stations }) {
    const {
        tableData,
    } = useImpactWaysTableStates(institutionId, stations);

    const [sortOptions, setSortOptions] = useState({ index: 0, order: 'ASC' });

    function toggleSort(index) {
        if (sortOptions.index !== index) {
            setSortOptions({
                index,
                order: 'ASC',
            });
            return;
        }
        const order = sortOptions.order === 'ASC' ? 'DESC' : 'ASC';
        setSortOptions({
            index,
            order,
        });
    }

    return (
        <DashboardBox
            title="Vias Impactadas"
            className="stations-impact-ways-table-box"
        >
            {tableData && <div className="stations-impact-ways-table-container">
                <table
                    className="stations-impact-ways-table"
                    style={{ width: '100%' }}
                >
                    <thead>
                        <tr>
                            {tableData[0].map((headerLabel, index) => (
                                <th
                                    key={`header-${index}`}
                                    className={index < 2 ? 'identification' : 'impact-way'}
                                    onClick={() => toggleSort(index)}
                                >
                                    <span>
                                        <span className="header-label-container">
                                            {headerLabel}
                                            
                                            {sortOptions.index === index
                                                ? (sortOptions.order === 'ASC'
                                                    ? <FaLongArrowAltUp />
                                                    : <FaLongArrowAltDown />
                                                )
                                                : null}
                                        </span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData
                            .slice(1)
                            .sort((a, b) => {
                                const [x, y] = [a, b].map(row => {
                                    const rowCell = row[sortOptions.index];
                                    if (typeof rowCell === 'object') {
                                        return rowCell.v;
                                    }
                                    return rowCell;
                                });
                                if ([null, undefined].includes(x)) {
                                    return sortOptions.order === 'DESC' ? 1 : -1;
                                }
                                if ([null, undefined].includes(y)) {
                                    return sortOptions.order === 'DESC' ? -1 : 1;
                                }
                                if ([x, y].some(isNaN)) {
                                    return sortOptions.order === 'DESC'
                                        ? y.localeCompare(x)
                                        : x.localeCompare(y);
                                }
                                return sortOptions.order === 'DESC'
                                    ? y - x
                                    : x - y;
                            })
                            .map((row, rowIndex) => (
                                <tr key={`row-${rowIndex}`}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={`cell-${rowIndex}-${cellIndex}`}>
                                            {typeof cell === 'object'
                                                ? cell.f
                                                : cell}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>}
        </DashboardBox>
    );
}

export default ImpactWaysTable;
