import { useEffect } from 'react';
import { useAuth } from '../contexts/auth';

export function useSocket(event, callback) {
    const { socket } = useAuth();

    useEffect(() => {
        if (!socket) {
            return;
        }
        socket.emit('subscribe', event);
        socket.on(event, callback);

        return () => {
            socket.emit('unsubscribe', event);
            socket.off(event);
        };
    }, [socket, event, callback]);
};
