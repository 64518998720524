import React, { useState, useEffect, useMemo } from 'react';

import CalendarMessageForm from '../calendar-message-form';

import { useApiData } from '../../../../contexts/api-data';
import api from '../../../../services/api';
import datetimeUtils from '../../../../utils/datetime';
import CalendarDates from '../../../calendar-dates';
import DeleteBtn from '../../../common/btn/delete-btn';
import PostBtn from '../../common/post-btn';
import {
    postWhatsappCalendarMessages,
} from '../../../../services/api';
import { MultipleCustomersAndRegionsSelectionInput } from '../../../common/custom-inputs';

import './styles.css';

const todayInputFormatDate = datetimeUtils.getInputFormatDateFromDatetime(Date.now());
const today = datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(todayInputFormatDate);

function CalendarMessagesList() {

    const [calendarMessages, setCalendarMessages] = useState({});
    const [datetime, setDatetime] = useState('');
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMessagesIds, setSelectedMessagesIds] = useState([]);
    
    const {
        manageForecastRegions: {
            fetch: fetchForecastRegions,
            value: forecastRegions,
        },
    } = useApiData();

    useEffect(() => {
        fetchForecastRegions();
    }, [fetchForecastRegions]);

    const messagesFilteredByRegion = useMemo(() => {
        if (!selectedRegions || !selectedRegions.length) {
            return calendarMessages;
        }

        const filteredMessages = {};

        Object.keys(calendarMessages).forEach((datetime) => {
            const filteredDatetimeMessages = calendarMessages[datetime].filter(
                (calendarMessage) => selectedRegions.includes(calendarMessage.forecast_region_id)
            );

            if (filteredDatetimeMessages.length > 0) {
                filteredMessages[datetime] = filteredDatetimeMessages.sort(
                    (a, b) => b.id - a.id
                );
            }
        });

        return filteredMessages;
    }, [calendarMessages, selectedRegions]);

    useEffect(() => { 
        setDatetime(today);
        const lastDay = datetimeUtils.addNDays(today, 14);
        const query = `datetimeStart=${today}&datetimeEnd=${lastDay}`;
        (async function() {
            const response = await api.get(`/calendar-messages?${query}`);
            setCalendarMessages(response.data);
        })();
    }, []);

    function updateCalendarMessagesState(fn) {
        const newCalendarMessages = {
            ...calendarMessages,
        };
        fn(newCalendarMessages);
        setCalendarMessages(newCalendarMessages);
    }

    async function createCalendarMessage(
        datetime,
        regionsByCustomer,
        text,
        id,
        template,
        template_args,
        sendWhatsappMessage,
    ) {
        const whatsappMessages = [];
        const calendarMessages = regionsByCustomer.map((regionId) => {
            const region = forecastRegions.find(({ id }) => id === Number(regionId));
            const messageContent = template
                ? template_args.reduce((result, current, index) => (
                    result.replace(template.inputs[index].replaceText, current)
                ), template.format)
                : text;
            
            const forecast_region_id = Number(regionId);
            
            const whatsappText = messageContent.replace(/\n/g, '\\n');
            whatsappMessages.push({
                forecast_region_id,
                text: region ? `(${region.name}) ${whatsappText}` : whatsappText,
            });

            return {
                forecast_region_id,
                code: region?.alias,
                datetime,
                text: messageContent,
                run_datetime: today,
                region,
            };
        });

        const response = await api.post('/manage/calendar-messages', calendarMessages);

        response.data.forEach(({ calendar_message_id, forecast_region_id }) => {
            const calendarMessage = calendarMessages.find(cm => cm.forecast_region_id === forecast_region_id);
            if (calendarMessage) {
                calendarMessage.id = calendar_message_id;
            }
        });

        if (sendWhatsappMessage) {
            await postWhatsappCalendarMessages(whatsappMessages);
        }

        updateCalendarMessagesState((newCalendarMessages) => {
            newCalendarMessages[datetime] = [
                ...(newCalendarMessages[datetime] || []),
                ...calendarMessages,
            ];
        });
    }

    async function updateCalendarMessage(
        datetime,
        forecast_region_id,
        text,
        id,
    ) {
        const calendarMessage = {
            id,
            forecast_region_id,
            datetime,
            text,
            run_datetime: today,
        };
        await api.put(`/manage/calendar-messages/${id}`, calendarMessage);

        updateCalendarMessagesState((newCalendarMessages) => {
            const messages = newCalendarMessages[datetime];
            const index = messages.indexOf(messages.find(m => m.id === calendarMessage.id));
            messages[index] = calendarMessage;
        });
    }
    
    async function removeCalendarMessages() {
        const toDelete = selectedMessagesIds.map(id => ({
            calendar_message_id: id,
            run_datetime: today,
        }));

        await api.delete('/manage/calendar-messages', {
            data: {
                data: toDelete,
            },
        });
        updateCalendarMessagesState((newCalendarMessages) => {
            newCalendarMessages[datetime] = newCalendarMessages[datetime]
                .filter(({ id }) => !selectedMessagesIds.includes(id));
        });
        setSelectedMessagesIds([]);
    }

    function toggleSelectedMessage(messageId) {
        const newSelectedMessagesIds = selectedMessagesIds.includes(messageId)
            ? selectedMessagesIds.filter(id => id !== messageId)
            : [...selectedMessagesIds, messageId];
        setSelectedMessagesIds(newSelectedMessagesIds);
    }

    function changeDatetime(newDatetime) {
        if (newDatetime !== datetime) {
            setDatetime(newDatetime);
            setSelectedMessagesIds([]);
        }
    }

    function selectRegions(regions) {
        const newRegion = regions;
        if(newRegion[1]?.length || newRegion.length === 0){
            setSelectedRegions(newRegion[1] || []);
        }else{
            setSelectedRegions('1');
        }
    }

    async function sendCalendarMessageWhatsapp() {
        const toSend = selectedMessagesIds.map(id => {
            const {
                text,
                forecast_region_id,
            } = messagesFilteredByRegion[datetime].find(m => m.id === id);
            const region = forecastRegions.find(({ id }) => id === Number(forecast_region_id));
            const whatsappText = text.replace(/\n/g, '\\n');
            return {
                text: region ? `(${region.name}) ${whatsappText}` : whatsappText,
                forecast_region_id,
            };
        });
        await postWhatsappCalendarMessages(toSend);
        setSelectedMessagesIds([]);
    }

    return (<>
            <h3>Previsões em Agenda</h3>
            <div className="calendar-search-container">
                <CalendarDates
                    days={15}
                    datetime={datetime}
                    setDatetime={changeDatetime}
                    messages={messagesFilteredByRegion}
                />
                <CalendarMessageForm
                    postFunction={createCalendarMessage}
                    runDatetime={today}
                    datetime={datetime}
                    forecastRegions={forecastRegions}
                />
            </div>
            <div className="calendar-messages-filter">
                <div className="calendar-messages-title">
                    <h4>Filtro</h4>
                    <div className="calendar-messages-btn">
                        <DeleteBtn
                            onClick={removeCalendarMessages}
                            isAllowed={() => selectedMessagesIds.length > 0}
                            label="Remover"
                        />
                        <PostBtn
                            postFunction={sendCalendarMessageWhatsapp}
                            shouldPost={() => selectedMessagesIds.length > 0}
                            label={'Enviar por WhatsApp'}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <MultipleCustomersAndRegionsSelectionInput
                        inputId={'selected-code'}
                        defaultCustomerOption={{
                            value: '0',
                            label: 'Todas',
                        }}
                        selectRegion={selectRegions}
                    />
                </div>
            </div>

            {messagesFilteredByRegion[datetime]?.map(message => (
                <CalendarMessageForm
                    key={`${message.id}-${message.run_datetime}`}
                    message={message}
                    postFunction={updateCalendarMessage}
                    selected={selectedMessagesIds.includes(message.id)}
                    toggleSelectedMessage={toggleSelectedMessage}
                    disabled={true}
                    forecastRegions={forecastRegions}
                />
            ))}
        </>);
}

export default CalendarMessagesList;
