import { useMemo } from 'react';

function viewDatetime(datetime) {
    const dateObject = new Date(Number(datetime));
    const date = dateObject.toLocaleDateString('pt-BR');
    const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
    return `${date} - ${time}`;
}

const variablesUnits = {
    battery_voltage: 'V',
};

export function useMeasuresTableStates(measures, telemetryVariables) {
    const tableData = useMemo(() => {
        if (!measures || !telemetryVariables) {
            return null;
        }
        
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const telemetryMeasures = {};
            Object.keys(measure).forEach(key => {
                if (['datetime'].includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const telemetryVariable = telemetryVariables.find(v => v.key === key);
                    variables.push(telemetryVariable);
                }
                telemetryMeasures[key] = measure[key];
            });
            result.push({
                ...telemetryMeasures,
                datetime,
            });
            return result;
        }, []);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const tableHeader = [
            'Hora da Leitura',
        ].concat(
            variables.map(({ key, label }) => `${label}${variablesUnits[key] ? ` (${variablesUnits[key]})` : ''}`),
        );
        const tableContent = content.map(measure => {
            const { datetime } = measure;
            const datetimes = [datetime];
            return datetimes
                .map(d => d
                    ? {
                        v: d,
                        f: viewDatetime(d),
                    }
                    : {
                        v: 0,
                        f: '-',
                    }
                )
                .concat(variables.map(({ key }) => {
                    if (isNaN(measure[key])) {
                        return measure[key];
                    }
                    const v = Number(Number(measure[key]).toFixed(1));
                    return {
                        v,
                        f: Number(v).toLocaleString('pt-BR'),
                    };
                }));
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [measures, telemetryVariables]);

    return {
        tableData,
    };
};
