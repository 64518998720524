import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function ForecastsRunDatetimeSelectionInput({
    defaultValue,
    setValue,
    isNullable = true,
    inputId,
    modelId,
}) {
    const {
        forecastsRunDatetimesByModel: {
            value: valueByModel,
        },
    } = useApiData();

    const runDatetimes = valueByModel[modelId] || [];

    const options = runDatetimes
        .sort((a, b) => b-a)
        .map(datetime => ({
            value: datetime,
            label: (new Date(datetime)).toLocaleString(),
        }));

    return (
        <SelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    );
}

export default ForecastsRunDatetimeSelectionInput;
