import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function ForecastRegionSelectionInput({
    regions,
    defaultValue,
    setValue,
    isNullable = true,
    inputId,
}) {
    const {
        manageForecastRegions,
    } = useApiData();

    const forecastRegions = regions || manageForecastRegions.value;

    const options = forecastRegions.map(({ id, name, alias }) => ({
        value: id,
        label: `${name} (${alias})`,
    }));

    return forecastRegions.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : null;
}

export default ForecastRegionSelectionInput;
