import { useCallback, useMemo, useState } from 'react';

import {
    getManageRoles,
    postManageRoles,
    putManageRoles,
    postManageRolePermissions,
} from '../../../../services/api';

export function useManageRolesState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.key) return -1;
            if (!b.key) return 1;
            if (a.key === 'MASTER') return -1;
            if (b.key === 'MASTER') return 1;
            return a.description.localeCompare(b.description);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const permissions = await getManageRoles();
            setValue(permissions);
            setLoading(false);
        },
        [value, loading],
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(roleId, role) {
        const index = value.findIndex(({ id }) => id === roleId);
        // creating
        if (!role.id) {
            role = await postManageRoles(role);
        }
        // updating
        else {
            await putManageRoles(role);
        }
        const newValue = [...value];
        newValue[index] = role;
        setValue(newValue);
    }

    async function savePermissions(role_id, permissionIds) {
        await postManageRolePermissions(role_id, permissionIds);
        const index = value.findIndex(({ id }) => id === role_id);
        const newValue = [...value];
        newValue[index].permissions = permissionIds.map(permission_id => ({
            role_id,
            permission_id,
        }));
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        savePermissions,
        value: valueSorted,
    };
};
