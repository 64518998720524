import React, { useCallback, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useMapDrawerInputter } from '../../contexts/map-drawer-inputter';
import './style.css';
import MapList from '../map-list';

function MapInputter() {
    const INPUT_STEPPER = 0.01;

    const {
        selectedDraw,
        draws,
        addDrawFromInput,
        selectDraw,
        removeDraw,
        updateDraw,
        selectModel,
        selectedModel,
        filteredModelsOptions,
        allowChangeModel,
        allowToConfirm,
        updateDrawsToSave,
        drawsToSave,
        cancelUpdate,
        setAllowToConfirm,
        addDrawFromDuplicate
    } = useMapDrawerInputter();

    const [coordinates, setCoordinates] = useState({
        topLatitude: '',
        rightLongitude: '',
        bottomLatitude: '',
        leftLongitude: '',
    });
    const [addingOrUpdating, setAddingOrUpdating] = useState('adding');
    const [allowToDuplicate, setAllowToDuplicate] = useState(false);
    const setAllowToConfirmRef = useRef(setAllowToConfirm);

    const resetCoordinates = () => {
        setCoordinates({
            topLatitude: '',
            rightLongitude: '',
            bottomLatitude: '',
            leftLongitude: '',
        });
    }

    useEffect(() => {
        if(draws?.length !== drawsToSave?.length) {
            setAddingOrUpdating('added');
        } else if (draws?.length < drawsToSave?.length) {
            setAddingOrUpdating('removed')
        } else if (draws !== drawsToSave && selectedDraw?.data) {
            setAddingOrUpdating('updating');
        } else {
            setAddingOrUpdating('startAdding')
            if(        
                coordinates.topLatitude !== '' &&
                coordinates.rightLongitude !== '' &&
                coordinates.bottomLatitude !== '' &&
                coordinates.leftLongitude !== '' &&
                !selectedDraw?.layer?._leaflet_id &&
                selectedModel
            ) {
                setAddingOrUpdating('readyToAdd');
                setAllowToConfirmRef.current(true);
            }
        }
    }, [draws, drawsToSave, selectedDraw, coordinates, selectedModel]);

    useEffect(() => {
        if (!selectedDraw || !selectedDraw.id) {
            resetCoordinates();
        }
        if (selectedDraw?.type === 'rectangle' && selectedDraw?.data[0]?._northEast.lat) {
            setCoordinates({
                topLatitude: selectedDraw.data[0]._northEast.lat,
                rightLongitude: selectedDraw.data[0]._northEast.lng,
                bottomLatitude: selectedDraw.data[0]._southWest.lat,
                leftLongitude: selectedDraw.data[0]._southWest.lng,
            });
        }
    }, [selectedDraw]);

    useEffect(() => {
        if(allowToConfirm || !selectedDraw?.layer?._leaflet_id || !selectedModel?.id) {
            setAllowToDuplicate(false);
        } else {
            setAllowToDuplicate(true);
        }
    }, [allowToConfirm, selectedDraw, selectedModel])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const hasNegativeSign = value.startsWith('-');
        const parsedValueString = value
        .replace(/[^\d.,-]/g, '')       // Allows numbers, periods, commas and negative signs
        .replace(/,/g, '.')             // Replaces all commas with periods
        .replace(/(\.\d{4})\d+/g, '$1') // Limits to 4 digits after the decimal point
        .replace(/\.(?=.*\.)/g, '')     // Remove extra points, keep only the first
        .replace(/-/g, '')              // Removes all negative signs
        .replace(/^(.*)$/, (number) => hasNegativeSign ? `-${number}` : number); // Add the negative sign back

        const endsWithSeparator = parsedValueString.charAt(parsedValueString.length - 1) === '.';

        const coordinateValue = parseFloat(parsedValueString);
        if ((name.includes('Latitude') && (coordinateValue < -90 || coordinateValue > 90)) ||
            (name.includes('Longitude') && (coordinateValue < -180 || coordinateValue > 180))) {
            toast('Erro! Coordenadas inválidas.', {
                type: 'error',
            });
            setCoordinates((prevCoordinates) => ({
                ...prevCoordinates,
                [name]: '',
            }));
            return;
        }

        setCoordinates((prevCoordinates) => ({
            ...prevCoordinates,
            [name]: parsedValueString,
        }));

        if (
            !isNaN(parsedValueString) &&
            !endsWithSeparator
        ) {
            const updatedData = {
                ...coordinates,
                [name]: parsedValueString,
            };

            const newData = {
                _northEast: {
                    lat: updatedData.topLatitude,
                    lng: updatedData.rightLongitude,
                },
                _southWest: {
                    lat: updatedData.bottomLatitude,
                    lng: updatedData.leftLongitude,
                },
            };

            if (
                selectedDraw &&
                selectedDraw.type === 'rectangle'
            ) {
                updateDraw({ ...selectedDraw, data: newData }, selectedDraw.layer?._leaflet_id);
            }
        }
    };

    const handleSelectMapList = (item) => {
        selectDraw(item);
    };

    const handleClear = () => {
        selectDraw();
        setTimeout(() => {
            setAllowToConfirm(false);
            resetCoordinates();
            setAddingOrUpdating('startAdding');
        },50)
    };

    const handleCancel = () => {
        cancelUpdate();
        setAddingOrUpdating('startAdding');
    }

    const handleDuplicate = useCallback(() => {
        if(!selectedDraw || !selectedDraw?.layer._leaflet_id) return;

        const drawModel = {
            model_id: selectedModel.id,
            model_name: selectedModel.name,
        };

        addDrawFromDuplicate(selectedDraw?.data[0], drawModel);
    }, [addDrawFromDuplicate, selectedDraw, selectedModel])

    const handleConfirm = useCallback(() => {
        if (coordinates.topLatitude === coordinates.bottomLatitude ||
            coordinates.rightLongitude === coordinates.leftLongitude) {
            toast('Erro! Os valores de latitude ou longitude iniciais e finais não podem ser iguais.', {
                type: 'error',
            });
            return;
        }

        if(selectedDraw?.data || addingOrUpdating === 'removed' || addingOrUpdating === 'updating') {
            updateDrawsToSave();
        } else {
            const newCoordinates = {
                topLatitude: parseFloat(coordinates.topLatitude),
                rightLongitude: parseFloat(coordinates.rightLongitude),
                bottomLatitude: parseFloat(coordinates.bottomLatitude),
                leftLongitude: parseFloat(coordinates.leftLongitude),
            };
            addDrawFromInput(newCoordinates);
        }
    }, [addDrawFromInput, addingOrUpdating, coordinates, selectedDraw, updateDrawsToSave]);

    const handleDeleteMapList = (id) => {
        removeDraw(id);
        resetCoordinates();
        setAddingOrUpdating('startAdding');
    };

    return (
        <div className="map-inputter-container">
            <div className="select-group">
                <label htmlFor="select-model">Selecione o modelo:</label>
                <br />
                <select
                    id="select-model"
                    key={filteredModelsOptions}
                    disabled={!allowChangeModel || !filteredModelsOptions?.length > 0}
                    value={selectedModel?.id || ''}
                    onChange={(event) => selectModel(event.target.value)}
                >
                    <option disabled value="">
                        {filteredModelsOptions.length > 0
                            ? 'Selecione'
                            : 'Lista vazia'}
                    </option>
                    {filteredModelsOptions?.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="map-inputter-inputs">
                <div className="inputs--topLatitude">
                    <span>Latitude</span>
                    <input
                        name="topLatitude"
                        type="text"
                        step={INPUT_STEPPER}
                        value={coordinates.topLatitude}
                        onChange={handleInputChange}
                        placeholder="Superior"
                    />
                </div>

                <div className="inputs--rightLongitude">
                    <span>Longitude</span>
                    <input
                        name="rightLongitude"
                        type="text"
                        step={INPUT_STEPPER}
                        value={coordinates.rightLongitude}
                        onChange={handleInputChange}
                        placeholder="Direita"
                    />
                </div>

                <div className="inputs--bottomLatitude">
                    <span>Latitude</span>
                    <input
                        name="bottomLatitude"
                        type="text"
                        step={INPUT_STEPPER}
                        value={coordinates.bottomLatitude}
                        onChange={handleInputChange}
                        placeholder="Inferior"
                    />
                </div>

                <div className="inputs--leftLongitude">
                    <span>Longitude</span>
                    <input
                        name="leftLongitude"
                        type="text"
                        step={INPUT_STEPPER}
                        value={coordinates.leftLongitude}
                        onChange={handleInputChange}
                        placeholder="Esquerda"
                    />
                </div>
            </div>

            <div className="map-inputter-button-group">
                <div className="confirm-add-btn">
                    {allowToDuplicate ? (
                        <button
                            className={`base-btn ${
                                allowToDuplicate
                                    ? 'confirm-btn'
                                    : 'disabled disabled-btn'
                            }`}
                            disabled={!allowToDuplicate}
                            onClick={handleDuplicate}
                        >
                            Duplicar
                        </button>
                    ) : addingOrUpdating === 'updating' ||
                        addingOrUpdating === 'added' ||
                        addingOrUpdating === 'removed' ? (
                        <button
                            className={`base-btn ${
                                allowToConfirm ? 'confirm-btn' : 'disabled-btn'
                            }`}
                            disabled={!allowToConfirm}
                            onClick={handleConfirm}
                        >
                            Confirmar
                        </button>
                    ) : (
                        <button
                            className={`base-btn ${
                                allowToConfirm ? 'confirm-btn' : 'disabled-btn'
                            }`}
                            disabled={!allowToConfirm}
                            onClick={handleConfirm}
                        >
                            Adicionar
                        </button>
                    )}
                </div>

                <div className="cancel-clear-btn">
                    {allowToConfirm ? (
                        <button
                            className="base-btn cancel-btn"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                    ) : (
                        <button
                            className={`base-btn ${
                                selectedDraw?.layer?._leaflet_id
                                    ? 'delete-btn'
                                    : 'disabled-btn'
                            }`}
                            disabled={!selectedDraw?.layer?._leaflet_id}
                            onClick={handleClear}
                        >
                            Limpar
                        </button>
                    )}
                </div>
            </div>

            <MapList
                multiSelect={false}
                rectangles={draws}
                onSelect={handleSelectMapList}
                onDelete={handleDeleteMapList}
            />
        </div>
    );
}

export default MapInputter;
