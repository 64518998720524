import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import RadarBox from '../radar-box';
import api from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import { useTerritoriesState } from './states/territories';

import './styles.css';

const variablesTypes = {
    name: String,
    alias: String,
};

function RadarsList() {
    const apiData = useApiData();
    useTerritoriesState(apiData);

    const [radarInstitutions, setRadarInstitutions] = useState([]);
    const [radars, setRadars] = useState([]);
    const [filterActive, setFilterActive] = useState(1);
    const [sortOptions, setSortOptions] = useState('name=asc');

    useEffect(() => {
        (async function() {
            const response = await api.get('/manage/radar-institutions');
            setRadarInstitutions(response.data.sort((a, b) => a.id - b.id));
        })();

        (async function() {
            const response = await api.get('/radars');
            setRadars(response.data.sort((a, b) => a.id - b.id));
        })();
    }, []);

    function addNewRadar() {
        const newRadar = {
            tempId: Date.now(),
            inactive: false,
        };
        setRadars([ newRadar, ...radars ]);
    }

    function updateRadar(oldId, newObject) {
        const index = radars.findIndex(radar => radar.id === oldId);
        if (index === -1) {
            return;
        }
        const newRadars = [ ...radars ];
        newRadars[index] = newObject;
        setRadars(newRadars);
    }

    function sortRadars() {
        const [variable, asc] = sortOptions.split('=');
        const newRadars = [ ...radars ];
        const varType = variablesTypes[variable];
        newRadars.sort((a, b) => {
            if (varType === String) {
                const [x, y] = [a, b].map(c => (c[variable] || '').toLowerCase());
                return (x < y)
                    ? [1, -1][Number(Boolean(asc))]
                    : ((x > y)
                        ? [-1, 1][Number(Boolean(asc))]
                        : 0);
            }
            return asc
                ? a[variable]-b[variable]
                : b[variable]-a[variable];
        });
        return newRadars;
    }

    return (<>
        <div className="header">
            <h3>Radares</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewRadar}
            />
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="filter-active">Habilitados: </label>
                <select
                    id="filter-active"
                    value={filterActive}
                    onChange={(event) => setFilterActive(Number(event.target.value))}
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                    <option value="-1">Todos</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="sort-options">Ordenar: </label>
                <select
                    id="sort-options"
                    value={sortOptions}
                    onChange={(event) => setSortOptions(event.target.value)}
                >
                    <option value="name=asc">Nome (crescente)</option>
                    <option value="name=">Nome (decrescente)</option>
                    <option value="alias=asc">Identificador (crescente)</option>
                    <option value="alias=">Identificador (decrescente)</option>
                </select>
            </div>
        </div>
        {sortRadars().filter(({ inactive }) => {
            if (filterActive !== -1
                && inactive !== [true, false][filterActive]
            ) {
                return false;
            }
            return true;
        })
        .map(radar => (
            <RadarBox
                key={radar.id || radar.tempId}
                radar={radar}
                radarInstitutions={radarInstitutions}
                updateRadar={updateRadar}
            />
        ))}
    </>);
}

export default RadarsList;
