import { useState } from 'react';

import api from '../../../../services/api';

export function useSensorBoxState({ sensor }) {
    const [updatedSensor, setUpdatedSensor] = useState(sensor);
    const creating = !updatedSensor.name;
    const [alias, setAlias] = useState(updatedSensor.alias);
    const [name, setName] = useState(updatedSensor.name);
    const [formula, setFormula] = useState(updatedSensor.formula);
    const [minimum_value, setMinimumValue] = useState(updatedSensor.minimum_value);
    const [maximum_value, setMaximumValue] = useState(updatedSensor.maximum_value);
    const [data_position, setDataPosition] = useState(updatedSensor.data_position);
    const [data_length, setDataLength] = useState(updatedSensor.data_length);
    const [is_data, setIsData] = useState(!!updatedSensor.is_data);
    const [is_control, setIsControl] = useState(!!updatedSensor.is_control);
    const [inactive, setInactive] = useState(!!updatedSensor.inactive);
    const [calc_func, setCalcFunc] = useState(!!updatedSensor.calc_func);
    const [measure_sensor, setMeasureSensor] = useState(!!updatedSensor.measure_sensor);

    function shouldSave() {
        const allMandatorySet = name?.length > 0
            && alias?.length > 0
            && formula?.length > 0
            && minimum_value?.length > 0
            && maximum_value?.length > 0
            && (typeof data_position !== 'undefined')
            && (typeof data_length !== 'undefined');

        const nameChanged = name !== updatedSensor.name;
        const aliasChanged = alias !== updatedSensor.alias;
        const formulaChanged = formula !== updatedSensor.formula;
        const minimumValueChanged = minimum_value !== updatedSensor.minimum_value;
        const maximumValueChanged = maximum_value !== updatedSensor.maximum_value;
        const dataPositionChanged = data_position !== updatedSensor.data_position;
        const dataLengthChanged = data_length !== updatedSensor.data_length;
        const isDataChanged = String(is_data) !== String(updatedSensor.is_data);
        const isControlChanged = String(is_control) !== String(updatedSensor.is_control);
        const inactiveChanged = inactive !== updatedSensor.inactive;
        const calcFuncChanged = calc_func !== updatedSensor.calc_func;
        const measureSensorChanged = measure_sensor !== updatedSensor.measure_sensor;

        return allMandatorySet && (
            nameChanged
            || aliasChanged
            || minimumValueChanged
            || maximumValueChanged
            || formulaChanged
            || dataPositionChanged
            || dataLengthChanged
            || inactiveChanged
            || isDataChanged
            || isControlChanged
            || calcFuncChanged
            || measureSensorChanged
        );
    }

    async function saveConfiguration() {
        if (shouldSave()) {
            const sensorToSend = {
                alias,
                name,
                minimum_value,
                maximum_value,
                formula,
                data_position,
                data_length,
                is_data,
                is_control,
                inactive,
                calc_func,
                measure_sensor,
            };

            if (creating) {
                const response = await api.post('/manage/sensors', sensorToSend);
                sensorToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/sensors/${updatedSensor.id}`, sensorToSend);
                sensorToSend.id = updatedSensor.id;
            }
            setUpdatedSensor(sensorToSend);
        }
    }

    return {
        alias,
        calc_func,
        creating,
        data_position,
        data_length,
        formula,
        inactive,
        is_data,
        is_control,
        maximum_value,
        measure_sensor,
        minimum_value,
        name,
        saveConfiguration,
        setAlias,
        setCalcFunc,
        setDataPosition,
        setDataLength,
        setFormula,
        setInactive,
        setIsData,
        setIsControl,
        setMaximumValue,
        setMeasureSensor,
        setMinimumValue,
        setName,
        setUpdatedSensor,
        shouldSave,
        updatedSensor,
    };
}
