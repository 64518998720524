import React, { useEffect } from 'react';

import { useApiData } from '../../contexts/api-data';
import { useQuery } from '../../utils/hooks/query';

import { setSelectedRadarsOrSatellite } from '../../utils/user';

import Map from '../../components/map';

import './styles.css';

function MapFull() {
    const apiData = useApiData();
    const queryParams = useQuery();

    const {
        radars : {
            fetchAndSave: fetchAndSaveRadars
        },
        getAllStaticPositions,
        staticPositionTypes,
        manageStationInstitutions: {
            fetch: fetchStationInstitutions,
            value: stationInstitutions,
        },
        selectStationInstitution,
    } = apiData;

    const { estacao, radar, estatico } = queryParams;

    const layers = (queryParams.camadas || '').split(',').map(layer => layer.toUpperCase());
    const showNeighborhoodShapes = layers.includes('LIMITES_BAIRROS_RJ');
    const showLightnings = layers.includes('RAIOS');
    const showAttentionPerimeters = layers.includes('PERIMETROS');

    useEffect(() => {
        fetchAndSaveRadars()
    }, [fetchAndSaveRadars]);

    useEffect(() => {
        fetchStationInstitutions()
    }, [fetchStationInstitutions]);

    useEffect(() => {
        if (stationInstitutions) {
            selectStationInstitution(estacao);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estacao, stationInstitutions]);

    useEffect(() => {
        if (staticPositionTypes) {
            const staticTypes = (estatico || '').toLowerCase().split(',');
            getAllStaticPositions(staticTypes);
        }
    }, [staticPositionTypes, getAllStaticPositions, estatico]);

    setSelectedRadarsOrSatellite(radar);

    return (
        <div className="map-full-res">
            <Map
                showNeighborhoodShapes={showNeighborhoodShapes}
                showLightnings={showLightnings}
                showAttentionPerimeters={showAttentionPerimeters}
                radar={radar}
            />
        </div>
    );
}

export default MapFull;
