import React, { useEffect } from 'react';

import ForecastModelBox from '../model-box';
import { useApiData } from '../../../../contexts/api-data';

function ForecastModelsList() {
    const {
        forecastModels: {
            fetchAndSave: fetchForecastModels,
            value: forecastModels,
        },
        manageForecastRegions: {
            fetch: fetchManageForecastRegions,
        }
    } = useApiData();

    useEffect(() => {
        fetchManageForecastRegions();
    }, [fetchManageForecastRegions]);

    useEffect(() => {
        fetchForecastModels();
    }, [fetchForecastModels]);

    return (<>
        <h3>Modelos de Previsão</h3>
        {forecastModels && forecastModels.map(forecastModel => (
            <ForecastModelBox
                key={forecastModel.id}
                forecastModel={forecastModel}
            />
        ))}
    </>);
}

export default ForecastModelsList;
