import React, { useCallback, useEffect, useState } from 'react';
import { LayerGroup, Circle, Popup as MapPopup } from 'react-leaflet';

import './styles.css';
import { useApiData } from '../../contexts/api-data';

function AttentionPerimeters() {
    const {getAllStaticPositionPerimeters, selectedStaticPositionTypes, staticPositionPerimeters} = useApiData();
    const [updateKey, setUpdateKey] = useState(0);

    const getPerimeterPositions = useCallback(async () => {
        if (!selectedStaticPositionTypes) {
            return;
        }
        await getAllStaticPositionPerimeters(Object.keys(selectedStaticPositionTypes));
        setUpdateKey(prevKey => prevKey + 1);
    },[getAllStaticPositionPerimeters, selectedStaticPositionTypes])

    useEffect(() => {
        getPerimeterPositions();
      }, [getPerimeterPositions]);

    return (
        <>
        {staticPositionPerimeters && (
            <LayerGroup key={updateKey}>
                {staticPositionPerimeters.map((perimeter, i) => {
                    return (
                        <Circle
                            key={`circle-${i}`}
                            center={perimeter.coords}
                            radius={selectedStaticPositionTypes[perimeter.alias]?.showRanges
                                  ?(perimeter.showRanges ? perimeter.radius * 1000 : 0):0}
                            pathOptions={{
                                className: 'attention-perimeter',
                                color: '#333',
                                fillOpacity: 0.02,
                                weight: 0.8,
                                fillcolor: '#333'
                            }}
                            >
                            <MapPopup>
                                <b>Raio do perímetro:</b> {perimeter.radius} km
                            </MapPopup>
                        </Circle>    
                    );
                })}
            </LayerGroup>
        )}
        </>
    )
}

export default AttentionPerimeters;
