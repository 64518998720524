import baseApi from '../../base';

export async function getBasinSlipStages() {
    const result = await baseApi.get('/basin-slip-stages');
    return result.data;
};

export async function postManageBasinSlipStages(stage) {
    const result = await baseApi.post('/manage/basin-slip-stages', stage);
    return result.data;
};

export async function putManageBasinSlipStages(stage) {
    const result = await baseApi.put(`/manage/basin-slip-stages/${stage.id}`, stage);
    return result.data;
};

export async function deleteManageBasinSlipStages(stageId) {
    const result = await baseApi.delete(`/manage/basin-slip-stages/${stageId}`);
    return result.data;
};
