import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import {
    postSatelliteProductTypes,
    putSatelliteProductTypes
} from '../../../../services/api';

import './styles.css';

function SatelliteProductTypeBox({ productType }) {
    const [updatedProductType, setUpdatedProductType] = useState(productType);
    const { register, watch } = useForm({
        defaultValues: {
            name: updatedProductType.name || '',
            description: updatedProductType.description || '',
            alias: updatedProductType.alias || '',
        },
    });

    const name = watch('name');
    const description = watch('description');
    const alias = watch('alias');
    
    const creating = !updatedProductType.name;

    function shouldSave() {
        const nameAndDescriptionAndAliasAreSet = name?.length > 0 && description?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndDescriptionAndAliasAreSet;
        }
        const nameChanged = name !== updatedProductType.name;
        const descriptionChanged = description !== updatedProductType.description;
        const aliasChanged = alias !== updatedProductType.alias;
        return nameAndDescriptionAndAliasAreSet && (nameChanged || descriptionChanged || aliasChanged);
    }

    async function saveProductType() {
        if (shouldSave()) {
            const productTypeToSend = {
                name,
                description,
                alias
            };
            if (creating) {
                const response = await postSatelliteProductTypes(productTypeToSend);
                productTypeToSend.id = response.id;
            }
            else {
                await putSatelliteProductTypes(updatedProductType, productTypeToSend);
                productTypeToSend.id = updatedProductType.id;
            }
            setUpdatedProductType(productTypeToSend);
        }
    }
    
    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form productType-box-form">
                <div className="form-group">
                    <label htmlFor={`productType-name-${updatedProductType.id}`}>Nome</label>
                    <input
                        id={`productType-name-${updatedProductType.id}`}
                        placeholder="Nome"
                        maxLength={30}
                        {...register('name')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`productType-description-${updatedProductType.id}`}>Descrição</label>
                    <input
                        id={`productType-description-${updatedProductType.id}`}
                        placeholder="Descrição"
                        maxLength={30}
                        {...register('description')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`productType-alias-${updatedProductType.id}`}>Identificador</label>
                    <input
                        id={`productType-alias-${updatedProductType.id}`}
                        placeholder="Identificador"
                        maxLength={30}
                        {...register('alias')}
                    />
                </div>

                <PostBtn
                    postFunction={saveProductType}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default SatelliteProductTypeBox;
