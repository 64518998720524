import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { useApiData } from '../../contexts/api-data';

import './styles.css';

function MapTimeControl() {
    const {
        selectedRadarOrSatelliteProduct
    } = useApiData();
    const map = useMap();

    useEffect(() => {
        const times = (selectedRadarOrSatelliteProduct || []).map(({ datetime }) => Number(datetime));

        const timeDimension = new L.TimeDimension({
            times,
        });
        
        times.sort((a, b) => a-b);

        const timeDimensionControlOptions = {
            autoPlay: true,
            playerOptions: {
                buffer: 10,
                transitionTime: 1000,
                loop: true,
            },
            timeDimension,
            timeZones: ['local', 'utc'],
            position: 'bottomleft',
        };

        const timeControl = new L.Control.TimeDimension(timeDimensionControlOptions);

        map.addControl(timeControl);
        map.timeDimension = timeDimension;

        return () => map.removeControl(timeControl);
    }, [ selectedRadarOrSatelliteProduct, map]);

    return null;
}

export default MapTimeControl;
