import React, { useEffect, useState, useMemo } from 'react';
import { Rectangle } from 'react-leaflet';
import {
    FaChevronDown,
    FaChevronUp,
} from 'react-icons/fa';
import { Chart } from 'react-google-charts';

import ClimateForecastsLegend from '../climate-forecasts-legend';
import { useAuth } from '../../contexts/auth';
import { useApiData } from '../../contexts/api-data';
import datetimeUtils from '../../utils/datetime';

import './styles.css';

function getColor(dailyPrecipitation) {
    // gray - Sem medição
    if (dailyPrecipitation <= 0.2) {
        return 'green';
    }
    if (dailyPrecipitation <= 5) {
        return 'blue';
    }
    if (dailyPrecipitation <= 25) {
        return 'yellow';
    }
    if (dailyPrecipitation <= 50) {
        return 'orange';
    }
    return 'red';
}

function ClimateForecastsControl() {
    const { settingsSet } = useAuth();
    const {
        retrieveClimateForecasts,
    } = useApiData();

    const {
        CLIMATE_FORECAST_REGIONS,
        CLIMATE_FORECAST_WEEKS,
    } = { ...settingsSet };

    const [chartVisible, setChartVisible] = useState(true);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(0);
    const [forecasts, setForecasts] = useState(null);
    const todayDatetime = datetimeUtils.getTodayZeroHourDatetime();
    const weekDay = (new Date(todayDatetime)).getDay();
    const datetimeStart = datetimeUtils.addNDays(todayDatetime, -((weekDay + 6) % 7));
    const totalWeeks = Number(CLIMATE_FORECAST_WEEKS || 6);
    const datetimeEnd = datetimeUtils.addNDays(datetimeStart, totalWeeks*7 + 1);

    useEffect(() => {
        if (forecasts || !retrieveClimateForecasts ||
            !CLIMATE_FORECAST_REGIONS || !datetimeStart || !datetimeEnd
        ) {
            return;
        }
        const frequency = 24*60*60*1000; // one day
        (async function() {
            let newForecasts = {};
            for await (let regionId of CLIMATE_FORECAST_REGIONS) {
                const result = await retrieveClimateForecasts(
                    regionId,
                    frequency,
                    datetimeStart,
                    datetimeEnd,
                );
                newForecasts = {
                    ...newForecasts,
                    ...result,
                };
                setForecasts(newForecasts);
            }
        })();
    }, [
        CLIMATE_FORECAST_REGIONS,
        retrieveClimateForecasts,
        forecasts,
        datetimeStart,
        datetimeEnd,
    ]);

    function selectWeek(weekIndex) {
        setSelectedWeek(weekIndex);
        setChartVisible(true);
    }

    function toggleChartVisible() {
        setChartVisible(!chartVisible);
    }

    function selectPosition(position) {
        setSelectedPosition(position);
    }

    const rectangles = useMemo(() => {
        if (!forecasts) {
            return {};
        }
        return Object.keys(forecasts).reduce((result, position) => {
            const [latitude, longitude] = position.split(',').map(Number);
            const [
                top,
                left,
                bottom,
                right,
            ] = [
                latitude+0.25,
                longitude-0.25,
                latitude-0.25,
                longitude+0.25,
            ];
            const bounds = [[top, left], [bottom, right]];
            const colorFactor = Math.max(
                ...forecasts[position].slice(selectedWeek*7, (selectedWeek+1)*7)
                    .map(({ precipitation }) => precipitation.sum),
            );
            const color = getColor(colorFactor);
            result.push({ color, bounds, position });
            return result;
        }, []);
    }, [forecasts, selectedWeek]);

    const data = useMemo(() => (forecasts && selectedPosition && [
        [
            { type: 'string', label: 'Dia' },
            { type: 'number', label: 'Precipitação (mm)' },
            { type: 'string', role: 'style' },
        ],
        ...forecasts[selectedPosition].slice(selectedWeek*7, (selectedWeek+1)*7).map(forecast => {
            const datetimeObject = new Date(forecast.datetime);
            const date = datetimeObject.toLocaleDateString().substring(0, 5);
            return [
                date,
                forecast.precipitation.sum,
                'color:blue; opacity:0.75',
            ];
        }),
    ]), [forecasts, selectedPosition, selectedWeek]);

    const maxPrecipitation = data && Math.max(30, ...data.slice(1).map(([, prec]) => prec));
    
    const [latitude, longitude] = (selectedPosition || '').split(',');

    return forecasts ? (<>
        <ClimateForecastsLegend />
        {rectangles.map(({ bounds, color, position }, index) => (
            <Rectangle
                bubblingMouseEvents={false}
                key={`${index}${color}`}
                color={color}
                weight={1}
                bounds={bounds}
                fillColor={color}
                fillOpacity={.5}
                eventHandlers={{
                    click: () => selectPosition(position),
                }}
            />
        ))}
        <div className="climate-forecasts-control-container">
            <div className="climate-forecasts-weeks-buttons-container">
                {[...Array(totalWeeks).keys()].map(weekIndex => {
                    const firstDatetime = datetimeUtils.addNDays(datetimeStart, weekIndex*7);
                    const lastDatetime = datetimeUtils.addNDays(firstDatetime, 6);
                    const label = [firstDatetime, lastDatetime].map(datetime => {
                        const dateObject = new Date(datetime);
                        const day = `${dateObject.getDate()}`.padStart(2, '0');
                        const month = `${dateObject.getMonth()+1}`.padStart(2, '0');
                        return `${day}/${month}`;
                    }).join(' - ');
                    return (
                        <span
                            onClick={() => selectWeek(weekIndex)}
                            key={weekIndex}
                            className={`week-button ${selectedWeek === weekIndex ? 'selected' : ''}`}
                        >
                            {label}
                        </span>
                    );
                })}
                {selectedPosition && (
                    <span
                        onClick={toggleChartVisible}
                        className="week-button"
                    >
                        {chartVisible ? <FaChevronDown /> : <FaChevronUp />}
                    </span>
                )}
            </div>
            {data && (
                <div className={`climate-forecasts-chart-container ${!chartVisible ? 'hidden' : ''}`}>
                    <Chart
                        chartType="ColumnChart"
                        data={data}
                        options={{
                            title: `Precipitação acumulada diária - lat: ${latitude} lon: ${longitude}`,
                            titleTextStyle: {
                                color: 'red',
                                bold: true,
                            },
                            colors: ['#104E8B'],
                            legend: { position: 'none' },
                            width: '100%',
                            height: '95%',
                            hAxis: {
                                title: 'Dia',
                            },
                            vAxis: {
                                ticks: maxPrecipitation === 30
                                    ? [0,5,10,20,30]
                                    : [...Array(Math.ceil(maxPrecipitation/10)+1).keys()].map(i => i*10),
                                viewWindow: {
                                    max: maxPrecipitation === 30
                                        ? maxPrecipitation
                                        : Math.ceil(maxPrecipitation/10)*10,
                                },
                            },
                        }}
                    />
                </div>
            )}
        </div>
    </>) : null;
}

export default ClimateForecastsControl;
