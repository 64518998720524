import { useEffect, useState } from 'react';

import datetimeUtils from '../../../../utils/datetime';

export function useSearchFormStates({
    fetchEvents,
    events,
    radarId,
}) {
    const now = Date.now();
    const defaultDatetimeStart = datetimeUtils.addNDays(now, -60);
    const maximumDatetimeValue = datetimeUtils.getInputFormatDatetimeFromDatetime(now);
    const [datetimeStartValue, setDatetimeStartValue] = useState(
        datetimeUtils.getInputFormatDatetimeFromDatetime(defaultDatetimeStart)
    );
    const [datetimeEndValue, setDatetimeEndValue] = useState(datetimeUtils.getInputFormatDatetimeFromDatetime(now));
    
    useEffect(() => {
        if (events || !datetimeStartValue || !datetimeEndValue) {
            return;
        }
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchEvents({
            radarId,
            datetimeStart,
            datetimeEnd,
        });
    }, [fetchEvents, events, radarId, datetimeStartValue, datetimeEndValue]);

    async function searchEvents(event) {
        event.preventDefault();
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchEvents({
            radarId,
            datetimeStart,
            datetimeEnd,
        });
    }

    function updateDatetimeStart(newDatetimeStartValue) {
        setDatetimeStartValue(newDatetimeStartValue);
        if (newDatetimeStartValue < datetimeEndValue) {
            return;
        }
        const datetimeStart = (new Date(newDatetimeStartValue)).getTime();
        const newDatetimeEndValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeStart, 1));
        setDatetimeEndValue(newDatetimeEndValue);
    }

    function updateDatetimeEnd(newDatetimeEndValue) {
        setDatetimeEndValue(newDatetimeEndValue);
        if (datetimeStartValue < newDatetimeEndValue) {
            return;
        }
        const datetimeEnd = (new Date(newDatetimeEndValue)).getTime();
        const newDatetimeStartValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeEnd, -1));
        setDatetimeStartValue(newDatetimeStartValue);
    }

    return {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchEvents,
    };
};
