import baseApi from '../../base';

const baseResource = '/synoptic-text-types';
const baseManageResource = `/manage${baseResource}`;

export async function getSynopticTextTypes() {
    const result = await baseApi.get(baseResource);
    return result.data;
};

export async function postManageSynopticTextTypes(textType) {
    const result = await baseApi.post(baseManageResource, textType);
    return result.data;
};

export async function putManageSynopticTextTypes(textType) {
    const result = await baseApi.put(`${baseManageResource}/${textType.id}`, textType);
    return result.data;
};
