import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useApiData } from '../../../../contexts/api-data';

function MultipleForecastModelSelectionInput({ defaultValue, setValue }) {
    const {
        forecastModels: {
            value: forecastModels,
        },
    } = useApiData();

    const options = forecastModels.map(({ id, name, alias }) => ({
        value: id.toString(),
        label: `${name} (${alias})`,
    }));

    return forecastModels.length ? (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
        />
    ) : null;
}

export default MultipleForecastModelSelectionInput;
