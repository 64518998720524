import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import RadarInstitutionBox from '../institution-box';
import api from '../../../../services/api';

function RadarInstitutionsList() {
    const [radarInstitutions, setRadarInstitutions] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/manage/radar-institutions');
            setRadarInstitutions(response.data);
        })();
    }, []);

    function addNewInstitution() {
        const id = Math.max(...radarInstitutions.map(({ id }) => id)) + 1;
        setRadarInstitutions([ ...radarInstitutions, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Instituições de Radares</h3>
            <NewBtn
                label="Nova"
                newFunction={addNewInstitution}
            />
        </div>
        {radarInstitutions && radarInstitutions.map(institution => (
            <RadarInstitutionBox
                key={institution.id}
                institution={institution}
            />
        ))}
    </>);
}

export default RadarInstitutionsList;
