import { useEffect, useState, useCallback } from 'react';

import {
    filterRadarTelemetryMeasures,
    getRadios,
} from '../../../../services/api';
import datetimeUtils from '../../../../utils/datetime';
import { useSocket } from '../../../../hooks/use-socket';

const variables = 'battery_voltage';

export function useRadarLastTelemetryMeasuresStates({ radarId }) {
    const [radios, setRadios] = useState(null);
    const [loadingRadios, setLoadingRadios] = useState(false);
    const [radiosWithMeasures, setRadiosWithMeasures] = useState(null);

    useSocket(
        'radar-telemetry-measures',
        useCallback((data) => {
            setRadiosWithMeasures(
                lastMeasures => lastMeasures.map(({ radio, measure }) => ({
                    radio,
                    measure: radio.id in data ? data[radio.id] : measure,
                }))
            );
        }, []),
    );

    useEffect(() => {
        if (radios || loadingRadios) {
            return;
        }
        (async function () {
            setLoadingRadios(true);
            const newRadios = await getRadios();
            setRadios(newRadios);
            setLoadingRadios(false);
        })();
    }, [radios, loadingRadios]);

    useEffect(() => {
        if (!radarId || !radios) {
            return;
        }
        const datetimeEnd = Date.now();
        const datetimeStart = datetimeUtils.addNDays(Date.now(), -1);
        (async function() {
            const newRadiosWithMeasures = await Promise.all(
                radios.map(async (radio) => {
                    const { id: radio_id } = radio;
                    const measures = await filterRadarTelemetryMeasures({
                        radar_id: radarId,
                        radio_id,
                        datetimeStart,
                        datetimeEnd,
                        variables,
                    });
                    measures.sort((a, b) => Number(b.datetime) - Number(a.datetime));
                    return {
                        radio,
                        measure: measures[0],
                    };
                })
            );
            setRadiosWithMeasures(newRadiosWithMeasures);
        })();
    }, [radarId, radios]);

    return {
        radiosWithMeasures,
    };
};
