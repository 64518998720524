import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

function PostBtn({ shouldPost, postFunction, label }) {
    return (
        <Link
            to="#"
            className={`post-btn ${shouldPost() ? '' : 'disabled'}`}
            onClick={postFunction}
        >
            {label || 'Salvar'}
        </Link>
    );
}

export default PostBtn;
