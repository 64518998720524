import { useState, useCallback } from 'react';

import {
    getTelemetryVariables,
    postManageStationTelemetryMeasures,
    filterStationTelemetryMeasures,
} from '../../../../services/api';

export function useManageStationTelemetryMeasuresState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    async function update(institutionId, measures) {
        measures.sensor_datetimes = measures.sensorDatetimes;
        const stationTelemetryMeasures = {data: [measures]}
        await postManageStationTelemetryMeasures(institutionId, stationTelemetryMeasures);
    }

    const fetch = useCallback(({
        stationId,
        datetimeStart,
        datetimeEnd,
    }) => {
        if (value || loading || !datetimeStart || !datetimeEnd) {
            return;
        }
        (async function () {
            setLoading(true);
            const variables = await getTelemetryVariables();
            const newMeasures = await filterStationTelemetryMeasures({
                stationId,
                datetimeStart,
                datetimeEnd,
                variables,
            });
            newMeasures.sort((a, b) => b.datetime - a.datetime);
            setValue(newMeasures);
            setLoading(false);
        })();
    }, [value, loading]);

    return {
        fetch,
        loading,
        update,
        value,
    };
};
