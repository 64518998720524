import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';
import SelectionInput from '../selection-input';

import { useCitySelectionInputState } from './states';

import './styles.css';

function CitySelectionInput({
    defaultCityOption,
    defaultStateOption,
    defaultValue,
    isStateNullable,
    selectCity,
    inputId,
    multiple,
}) {
    const {
        citiesByState,
        citiesOptions,
        loadingCities,
        selectedState,
        selectState,
        statesOptions,
    } = useCitySelectionInputState({ defaultValue, selectCity });

    return (
        <div className="city-selection-input">
            {statesOptions.length > 0 && <SelectionInput
                defaultOption={defaultStateOption}
                defaultValue={defaultValue?.[0] || 0}
                setValue={selectState}
                options={statesOptions}
                isNullable={isStateNullable}
                disabled={loadingCities}
            />}
            {citiesByState[selectedState] && (multiple
                ? (<MultipleSelectionInput
                    defaultValue={(defaultValue || []).slice(1)}
                    setValue={citiesIdsStrings => selectCity([selectedState, ...citiesIdsStrings])}
                    options={citiesOptions}
                    inputId={inputId}
                    disabled={loadingCities}
                />)
                : (<SelectionInput
                    defaultOption={defaultCityOption}
                    defaultValue={defaultValue?.[1] || 0}
                    setValue={cityIdString => selectCity([selectedState, Number(cityIdString)])}
                    options={citiesOptions}
                    isNullable={true}
                    inputId={inputId}
                    disabled={loadingCities}
                />)
            )}
        </div>
    );
}

export default CitySelectionInput;
