import React from 'react';
import {
    FiArrowUpCircle,
    FiCheck,
} from 'react-icons/fi';

import DashboardBox from '../../../../components/dashboard-box';
import ActionIconBtn from '../../../../components/common/btn/action-icon-btn';

import {
    slipStages,
    useStationsSlipStagesStates,
} from './states';

import './styles.css';

function StationsSlipStages() {
    const {
        acceptChanges,
        stations,
        stationsStages,
        changeStage,
        saveStages,
        shouldAcceptChanges,
        shouldSaveStages,
    } = useStationsSlipStagesStates();

    return (
        <div className="stations-stages-operation-page">
            <h3>Mudança de estágio das Zonas Pluviométricas</h3>

            <DashboardBox title="Probabilidade de Escorregamento">
                <table className="stages-table">
                    <thead>
                        <tr>
                            <th>Estação</th>
                            <th>Escorregamento</th>
                            <th colSpan={slipStages?.length}>Estágio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stations?.map(({
                            code,
                            name,
                            measures,
                        }) => {
                            const oldStageLevel = Number(measures?.SLIP_STAGE_LEVEL || 0);
                            const autoStageLevel = Number(measures?.AUTOMATIC_SLIP_STAGE || 0);
                            const slipStagePeriod = measures?.SLIP_STAGE_PERIOD;
                            const slipStagePeriodLabel = autoStageLevel > 1 ? ` - ${slipStagePeriod}` : '';
                            const autoStage = slipStages.find(({ level }) => level === autoStageLevel);
                            const autoStageLabel = autoStage?.label || 'ND';
                            const autoStageColor = autoStage?.color || 'black';
                            const newStageLevel = stationsStages?.find(({ station_code }) => code === station_code)?.slip_stage_level;
                            return (
                                <tr key={`slip-station-${code}-row`}>
                                    <td>{name}</td>
                                    <td style={{ color: autoStageColor }}>
                                        {autoStageLabel}{slipStagePeriodLabel}
                                    </td>
                                    {slipStages?.map(({
                                        level,
                                        label,
                                        color,
                                    }) => {
                                        const selected = level === oldStageLevel;
                                        const opacity = selected ? '' : (level === newStageLevel ? 'B5' : '60');
                                        const backgroundColor = color ? `${color}${opacity}` : null;
                                        return (
                                            <td
                                                key={`station-${code}-stage-${level}`}
                                                className="stage-cell"
                                                style={{ width: `${55/slipStages.length}%` }}
                                                onClick={() => changeStage(code, level)}
                                            >
                                                <span
                                                    className={`stage ${selected ? 'selected' : ''}`}
                                                    style={{
                                                        backgroundColor,
                                                    }}
                                                >
                                                    {label}
                                                </span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </DashboardBox>

            <div className="station-slip-stages-actions">
                <ActionIconBtn
                    disabled={!shouldAcceptChanges()}
                    action={acceptChanges}
                    label="Aceitar"
                    icon={FiCheck}
                    className="success-btn"
                />

                <ActionIconBtn
                    disabled={!shouldSaveStages()}
                    action={saveStages}
                    label="Publicar"
                    icon={FiArrowUpCircle}
                    className="main-btn"
                />
            </div>
        </div>
    );
}

export default StationsSlipStages;
