import { useCallback, useEffect, useState } from 'react';

import { useApiData } from '../../../../contexts/api-data';
import {
    downloadStation,
} from '../../../../services/api';

import './styles.css';

export function useStationInstitutionDownloadPopupState({ institution }) {
    const {
        manageStations: {
            fetchByInstitution,
            value: stations,
        },
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
        systemSettings: {
            fetch: fetchSystemSettings,
            value: systemSettings,
        },
    } = useApiData();

    const [stationId, setStationId] = useState(null);
    const [datetimeStart, setDatetimeStart] = useState(null);
    const maximumDatetime = Date.now();
    const [datetimeEnd, setDatetimeEnd] = useState(maximumDatetime);
    const [minimumDatetime, setMinimumDatetime] = useState(null);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        fetchSystemSettings();
    }, [fetchSystemSettings]);

    const [variables, setVariables] = useState(null);

    useEffect(() => {
        if (variables || !weatherVariables) {
            return;
        }
        setVariables(
            weatherVariables
                .filter(({ key }) => !key.includes('SLIP_STAGE'))
                .map(({ key }) => key)
        );
    }, [weatherVariables, variables]);

    useEffect(() => {
        if (minimumDatetime || !systemSettings) {
            return;
        }
        const {
            STATION_MEASUREMENTS_MAXIMUM_STORAGE_PERIOD: maxPeriod = 0,
        } = systemSettings;
        const minDatetime = Date.now() - maxPeriod;
        setMinimumDatetime(minDatetime);
        setDatetimeStart(minDatetime);
    }, [systemSettings, minimumDatetime]);

    const selectStations = useCallback(
        async function () {
            await fetchByInstitution(institution.id);
        },
        [fetchByInstitution, institution]
    );

    function shouldDownload() {
        return stationId !== null
            && datetimeStart !== null
            && datetimeEnd !== null;
    }

    async function executeDownload() {
        const station = stations.find(s => s.id === stationId);
        await downloadStation({
            station,
            datetimeStart,
            datetimeEnd,
            variables,
        });
    }

    return {
        datetimeEnd,
        datetimeStart,
        executeDownload,
        maximumDatetime,
        minimumDatetime,
        selectStations,
        setDatetimeEnd,
        setDatetimeStart,
        setStationId,
        setVariables,
        shouldDownload,
        stationId,
        stations,
        variables,
    }
}
