import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useApiData } from '../../../../contexts/api-data';

function MultipleForecastRegionSelectionInput({
    regions,
    defaultValue,
    setValue,
    showOnlyItemsAmount,
}) {
    const { manageForecastRegions } = useApiData();

    const forecastRegions = regions || manageForecastRegions.value;

    const options = forecastRegions.map(({ id, name, alias }) => ({
        value: id.toString(),
        label: `${name} (${alias})`,
    }));

    return forecastRegions.length ? (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            showOnlyItemsAmount={showOnlyItemsAmount}
        />
    ) : null;
}

export default MultipleForecastRegionSelectionInput;
