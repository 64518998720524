import React, { useEffect, useState, useMemo } from 'react';

import NewBtn from '../../common/new-btn';
import StationBox from '../station-box';
import { useApiData } from '../../../../contexts/api-data';
import { useTerritoriesState } from './states/territories';

import './styles.css';

const variablesTypes = {
    name: String,
    code: Number,
};

function StationsList() {
    const apiData = useApiData();
    const {
        manageStationConfigurations: {
            fetch: fetchStationConfigurations,
        },
        manageStationProtocols: {
            fetch: fetchStationProtocols,
        },
        sensors: {
            fetch: fetchSensors,
        },
        manageStations: {
            fetchByInstitution,
            value: originalStations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
    } = apiData;

    const [stations, setStations] = useState([]);
    const [sortOptions, setSortOptions] = useState('name=asc');

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    useEffect(() => {
        if (stations?.length > 0 || !institutionId) return;
        fetchByInstitution(institutionId, false);
    }, [fetchByInstitution, institutionId, stations]);

    useEffect(() => {
        setStations(originalStations);
    }, [originalStations]);

    useEffect(() => {
        fetchStationProtocols();
    }, [fetchStationProtocols]);

    useEffect(() => {
        fetchStationConfigurations();
    }, [fetchStationConfigurations]);

    useEffect(() => {
        fetchSensors();
    }, [fetchSensors]);

    useTerritoriesState(apiData);

    function addNewStation() {
        const newStation = {
            tempId: Date.now(),
            inactive: false,
        };
        setStations([ newStation, ...stations ]);
    }

    function changeSortOptions(newSortOptions) {
        setSortOptions(newSortOptions);
        sortStations(newSortOptions);
    }

    function sortStations(sortOptions, currentStations=null) {
        const [variable, asc] = sortOptions.split('=');
        const newStations = [ ...(currentStations || stations) ];
        const varType = variablesTypes[variable];
        newStations.sort((a, b) => {
            if (varType === String) {
                const [x, y] = [a, b].map(c => c[variable].toLowerCase());
                return (x < y)
                    ? [1, -1][Number(Boolean(asc))]
                    : ((x > y)
                        ? [-1, 1][Number(Boolean(asc))]
                        : 0);
            }
            return asc
                ? a[variable]-b[variable]
                : b[variable]-a[variable];
        });
        setStations(newStations);
    }

    return (<>
        <div className="header">
            <h3>Estações Meteorológicas</h3>
            {<NewBtn
                label="Nova"
                newFunction={addNewStation}
            />}
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="sort-options">Ordenar: </label>
                <select
                    id="sort-options"
                    value={sortOptions}
                    onChange={(event) => changeSortOptions(event.target.value)}
                >
                    <option value="name=asc">Nome (crescente)</option>
                    <option value="name=">Nome (decrescente)</option>
                    <option value="code=asc">Código (crescente)</option>
                    <option value="code=">Código (decrescente)</option>
                </select>
            </div>
        </div>
        {institutionId && stations && stations.map(station => (
            <StationBox
                key={station.id || station.tempId}
                station={station}
                institution_id={institutionId}                
            />
        ))}
    </>);
}

export default StationsList;
