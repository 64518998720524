import React, { useEffect } from 'react';

import ForecastModelCard from '../model-card';

import { useApiData } from '../../../../contexts/api-data';
import { useAuth } from '../../../../contexts/auth';

import './styles.css';

function DownloadForecasts() {
    const {
        forecastModels: {
            fetchAndSave: fetchForecastModels,
            value: forecastModels,
        },
        retrieveForecastRegions,
    } = useApiData();

    useEffect(() => {
        fetchForecastModels();
    }, [fetchForecastModels]);

    useEffect(() => {
        retrieveForecastRegions();
    }, [retrieveForecastRegions]);

    const { settingsSet } = useAuth();
    const {
        DOWNLOAD_FORECAST_MODELS = [],
    } = { ...settingsSet };

    const downloadForecastModelsSetting = DOWNLOAD_FORECAST_MODELS.map(Number);
    const modelsToShow = forecastModels.filter(({ id }) => downloadForecastModelsSetting.includes(id));

    return (<>
        <h3>Modelos de Previsão</h3>
        <div className="download-forecast-models-grid">
            {modelsToShow.map(forecastModel => (
                <ForecastModelCard
                    key={forecastModel.id}
                    forecastModel={forecastModel}
                />
            ))}
        </div>
    </>);
}

export default DownloadForecasts;
