import React, { useState, useEffect, useCallback, useMemo } from 'react';

import DashboardBox from '../dashboard-box';

import datetimeUtils from '../../utils/datetime';
import { useApiData } from '../../contexts/api-data';
import useInterval from '../../hooks/use-interval';

import './styles.css';
import CalendarDates from '../calendar-dates';

function CalendarMessagesBox() {
    const {
        retrieveCalendarMessages,
        selectedForecastRegion,
    } = useApiData();

    const [today, setToday] = useState(0);
    const [datetime, setDatetime] = useState('');
    const [messages, setMessages] = useState({});

    useEffect(() => {
        const dateValue = datetimeUtils.getInputFormatDateFromDatetime(Date.now());
        const todayMs = datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(dateValue);
        setDatetime(todayMs);
        setToday(todayMs)
    }, []);

    const retrieveMessages = useCallback(async function() {
        if (today) {
            const lastDay = datetimeUtils.addNDays(today, 14);
            const response = await retrieveCalendarMessages(today, lastDay);
            setMessages(response);
        }
    }, [today, retrieveCalendarMessages]);

    useInterval(retrieveMessages, 60000);

    const messagesFilteredByRegion = useMemo(() => {
        const result = {};
        if (messages && selectedForecastRegion) {
            Object.keys(messages).forEach(datetime => {
                result[datetime] = messages[datetime]
                    .filter(message => (message.code === selectedForecastRegion.alias)
                        || (message.forecast_region_id === selectedForecastRegion.id));
            });
        }
        return result;
    }, [messages, selectedForecastRegion]);
    
    return (
        <DashboardBox title="Previsão de impactos nos próximos 15 dias">
            <div className="calendar-messages-container">
                <CalendarDates
                    days={15}
                    datetime={datetime}
                    setDatetime={setDatetime}
                    messages={messagesFilteredByRegion}
                />
                {messagesFilteredByRegion[datetime]?.length
                    ? messagesFilteredByRegion[datetime]
                        .sort((a, b) => a.id - b.id).map(message => (
                            <pre
                                key={message.id}
                                className="calendar-message-text"
                            >
                                {message.text}
                            </pre>
                        ))
                    : (
                        <div className="no-calendar-message">Sem previsões de impacto na obra para o dia selecionado</div>
                    )}
            </div>
        </DashboardBox>
    );
}

export default CalendarMessagesBox;
