import React from 'react';

import DashboardBox from '../../../../components/dashboard-box';
import RadarLastTelemetryMeasures from '../radar-last-telemetry-measures';
import RadarLastEnergyEvent from '../radar-last-energy-event';

import { useRadarBoxState } from './states';

import './styles.css';

function RadarBox() {
    const {
        radarId,
    } = useRadarBoxState();

    return (
        <DashboardBox title="Informações do Radar do Sumaré">
            <RadarLastTelemetryMeasures
                radarId={radarId}
            />
            <div className="radar-box-separator"></div>
            <RadarLastEnergyEvent
                radarId={radarId}
            />
        </DashboardBox>
    );
}

export default RadarBox;
