import {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

import {
    getBulletinForecastsOptions,
    getDetailedForecasts,
    postDetailedForecasts,
    getSynopticTextTypes,
    getSynopticTexts,
    postSynopticTexts,
    getTemperatureZones,
    patchTemperatureZones,
} from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import datetimeUtils from '../../../../utils/datetime';
import { useSocket } from '../../../../hooks/use-socket';

export function useDetailedForecastsStates() {
    const {
        forecastRegions,
        retrieveForecastRegions,
    } = useApiData();
    const initialDetailedForecasts = useRef();
    const initialSynopticText = useRef();
    const initialTemperaturesDatetime = useRef();
    const initialTemperatureZones = useRef();
    const [bulletinForecastsOptions, setBulletinForecastsOptions] = useState(null);
    const [detailedForecasts, setDetailedForecasts] = useState(null);
    const [synopticTextTypes, setSynopticTextTypes] = useState(null);
    const [synopticText, setSynopticText] = useState(null);
    const [temperaturesDatetime, setTemperaturesDatetime] = useState(null);
    const temperatureDate = useMemo(() => {
        return datetimeUtils.getInputFormatDateFromDatetime(temperaturesDatetime);
    }, [temperaturesDatetime]);
    const [temperatureZones, setTemperatureZones] = useState(null);
    const [forecastRegionId, setForecastRegionId] = useState(null);

    const NULL_VALUE = 'NULL_VALUE';

    const regionsOptions = useMemo(() => {
        const options = forecastRegions
            ?.filter(({ alias }) => alias.startsWith('boletim_'))
            ?.map(({ id, name }) => ({
                value: id,
                label: name,
            }));
        if (options?.length) {
            setForecastRegionId(old => old || options[0].value);
        }
        return options;
    }, [forecastRegions]);

    useEffect(() => {
        retrieveForecastRegions();
    }, [retrieveForecastRegions]);

    useEffect(() => {
        (async function() {
            const data = await getSynopticTextTypes();
            setSynopticTextTypes(data);
        })();
    }, []);

    function copyDetailedForecasts({
        forecast_region_id,
        forecasts,
    }) {
        return {
            forecast_region_id,
            forecasts: forecasts?.map(f => ({ ...f })) || [],
        };
    }

    useEffect(() => {
        if (!forecastRegionId || !synopticTextTypes) {
            return;
        }
        (async function() {
            const data = await getBulletinForecastsOptions(forecastRegionId);
            Object.keys(data?.options || {}).forEach(key => {
                if (key === 'period') {
                    return;
                }
                data.options[key] = data.options[key].sort((a, b) => a.label.localeCompare(b.label));
            });
            setBulletinForecastsOptions(data);
        })();
        (async function() {
            const data = (await getDetailedForecasts(forecastRegionId)) || ({
                forecast_region_id: forecastRegionId,
                forecasts: [],
            });
            initialDetailedForecasts.current = copyDetailedForecasts(data);
            setDetailedForecasts(data);
        })();
        (async function() {
            const firstType = synopticTextTypes?.[0];
            const data = (await getSynopticTexts(forecastRegionId)) || ({
                forecast_region_id: forecastRegionId,
                text: '',
                type_id: firstType?.id,
            });
            initialSynopticText.current = data;
            setSynopticText(data);
        })();
        (async function() {
            const data = await getTemperatureZones(forecastRegionId);
            initialTemperatureZones.current = data;
            const datetime = data[0]?.datetime;
            initialTemperaturesDatetime.current = datetime;
            setTemperaturesDatetime(datetime);
            setTemperatureZones(data);
        })();
    }, [forecastRegionId, synopticTextTypes]);

    useEffect(() => {
        if (!synopticText || synopticText.lastUpdateDatetimeString || !synopticText.last_bulletin_update) {
            return;
        }
        synopticText.lastUpdateDatetimeString = datetimeUtils.getFormatDatetimeFromDatetime(synopticText.last_bulletin_update);
        initialSynopticText.current = synopticText;
        setSynopticText({ ...synopticText });
    }, [synopticText])

    function setTemperature(data) {
        const { id } = data;
        setTemperatureZones(old => old.map(zone => {
            return zone.id === id
                ? { ...zone, ...data }
                : zone;
        }));
    }

    function setTemperatureDate(date) {
        const datetime = new Date(`${date}T00:00`).getTime();
        setTemperaturesDatetime(datetime);
    }

    function addForecast() {
        if (!detailedForecasts?.forecasts) {
            detailedForecasts.forecasts = [];
        }
        detailedForecasts.forecasts.push({
            id: Date.now(),
        });
        setDetailedForecasts({ ...detailedForecasts });
    }

    function changeForecast(id, data) {
        const oldForecastIndex = detailedForecasts.forecasts.findIndex(f => f.id === id);
        if (oldForecastIndex === -1) {
            return;
        }
        const oldForecast = detailedForecasts.forecasts[oldForecastIndex];
        detailedForecasts.forecasts[oldForecastIndex] = {
            ...oldForecast,
            ...data,
        };
        setDetailedForecasts({ ...detailedForecasts });
    }

    useEffect(() => {
        if (!detailedForecasts?.forecasts || !bulletinForecastsOptions) {
            return;
        }
        let changedAnyIcon = false;
        detailedForecasts.forecasts.forEach(forecast => {
            const periodOption = bulletinForecastsOptions.options.period.find(({ inicio }) => inicio === forecast.period);
            if (!periodOption) {
                return;
            }
            const newIconPath = `${periodOption.pathIcon}${forecast.icon}`.replace('.gif', '');
            if (newIconPath !== forecast.iconPath) {
                forecast.iconPath = newIconPath;
                changedAnyIcon = true;
            }
        })
        
        if (changedAnyIcon) {
            setDetailedForecasts({ ...detailedForecasts });
        }
    }, [detailedForecasts, bulletinForecastsOptions]);

    function changeSynopticText(data) {
        setSynopticText(old => ({
            ...old,
            ...data,
        }));
    }

    function isDetailedForecastsValid() {
        return !detailedForecasts?.forecasts || detailedForecasts?.forecasts?.slice(-4)?.every(f => (
            [
                'date',
                'period',
                'sky',
                'precipitation',
                'icon',
                'windSpeed',
                'windDirection',
                'windRisk',
            ].every(key => ![NULL_VALUE, null, undefined].includes(f[key]))
        ));
    }

    function detailedForecastsChanged() {
        return JSON.stringify(detailedForecasts || '')
            !== JSON.stringify(initialDetailedForecasts.current || '');
    }

    function shouldSaveDetailedForecasts() {
        return isDetailedForecastsValid() && detailedForecastsChanged();
    }

    function synopticTextChanged() {
        return JSON.stringify(synopticText || '') !== JSON.stringify(initialSynopticText.current || '');
    }

    function isTemperaturesValid() {
        const temperaturesSet = !temperatureZones || temperatureZones.every(({ max_temperature, min_temperature}) => (
            max_temperature && min_temperature
        ));
        return temperaturesDatetime && temperaturesSet;
    }

    function temperaturesChanged() {
        const temperaturesDatetimeChanged = temperaturesDatetime !== initialTemperaturesDatetime.current;
        const temperaturesChanged = JSON.stringify(temperatureZones || []) !== JSON.stringify(initialTemperatureZones.current || []);
        return temperaturesDatetimeChanged || temperaturesChanged;
    }

    function shouldSaveTemperatureZones() {
        return isTemperaturesValid() && temperaturesChanged();
    }

    function shouldSave() {
        if (!isDetailedForecastsValid() || !isTemperaturesValid()) {
            return false;
        }
        return detailedForecastsChanged() || synopticTextChanged() || temperaturesChanged();
    }

    async function save() {
        if (!shouldSave()) {
            return;
        }
        if (shouldSaveDetailedForecasts()) {
            detailedForecasts.forecasts = detailedForecasts?.forecasts?.slice(-4);
            await postDetailedForecasts(detailedForecasts);
            initialDetailedForecasts.current = copyDetailedForecasts(detailedForecasts);
            setDetailedForecasts({ ...detailedForecasts });
        }
        if (shouldSaveTemperatureZones()) {
            await patchTemperatureZones({
                data: temperatureZones,
                datetime: temperaturesDatetime,
            });
            initialTemperatureZones.current = temperatureZones;
            initialTemperaturesDatetime.current = temperaturesDatetime;
        }
        await postSynopticTexts(synopticText);
        if (synopticTextChanged()) {
            initialSynopticText.current = synopticText;
        }
    }

    useSocket(
        'detailed-forecasts',
        useCallback((data) => {
            if (data.forecast_region_id !== forecastRegionId) {
                return;
            }
            initialDetailedForecasts.current = copyDetailedForecasts(data);
            setDetailedForecasts(data);
        }, [forecastRegionId]),
    );

    useSocket(
        'temperature-zones',
        useCallback((data) => {
            if (data.forecast_region_id !== forecastRegionId) {
                return;
            }
            initialTemperatureZones.current = data;
            setTemperatureZones(data);
        }, [forecastRegionId]),
    );

    useSocket(
        'synoptic-texts',
        useCallback((data) => {
            if (data.forecast_region_id !== forecastRegionId) {
                return;
            }
            initialSynopticText.current = data;
            setSynopticText(data);
        }, [forecastRegionId]),
    );

    function removeForecast(id) {
        detailedForecasts.forecasts = detailedForecasts.forecasts.filter(f => f.id !== id);
        setDetailedForecasts({ ...detailedForecasts });
    }

    return {
        NULL_VALUE,
        addForecast,
        changeForecast,
        changeSynopticText,
        detailedForecasts: detailedForecasts?.forecasts?.slice(-4),
        forecastsOptions: bulletinForecastsOptions?.options,
        regionsOptions,
        removeForecast,
        save,
        setForecastRegionId,
        setTemperature,
        setTemperatureDate,
        shouldSave,
        synopticText,
        synopticTextTypes,
        temperatureDate,
        temperatureZones,
    };
};
