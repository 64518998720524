import { useMemo } from 'react';

import { useAuth } from '../../../../contexts/auth';

const availableVariables = [
    'dew_point',
    'temperature',
    'relative_humidity',
    'wind_speed',
    'wind_direction',
    'thermal_sensation',
    'atmospheric_pressure',
];

export function useMeasuresChartStates(measures, weatherVariables) {
    const { settingsSet } = useAuth();
    const { VARIABLES_UNITS } = { ...settingsSet };

    const chartData = useMemo(() => {
        if (!measures || !weatherVariables) {
            return null;
        }
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const weatherMeasures = {};
            Object.keys(measure).forEach(key => {
                if (!availableVariables.includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const weatherVariable = weatherVariables.find(v => v.key === key);
                    variables.push(weatherVariable);
                }
                weatherMeasures[key] = measure[key];
            });
            result.push({
                ...weatherMeasures,
                datetime,
            });
            return result;
        }, []).sort((a, b) => a.datetime - b.datetime);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const variablesUnits = VARIABLES_UNITS?.map(value => value.split(',').map(Number)) || [];

        const chartLegends = [
            { type: 'string', label: 'Dia-Hora' },
            ...(variables.map(({ id, label, key }) => {
                const variableUnit = variablesUnits.find(vu => vu[0] === id);
                const units = weatherVariables.find(wv => wv.id === id).type.units;
                const unit = units.find(u => (
                    !variableUnit ? u.formula === 'x' : u.id === variableUnit[1]
                )) || units.find(u => u.formula === 'x');
                return {
                    type: 'number',
                    label: `${label} (${unit.label})`,
                    variableKey: key,
                };
            })),
        ];
        const chartValues = content.map(measure => {
            const { datetime } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} ${time}`;
            return [datetimeStr].concat(variables.map(({ label, key }) => {
                const value = key in measure ? `${measure[key]}` : '-';
                const annotation = `${label} ${value}`;
                return {
                    value,
                    annotation,
                };
            }));
        });

        const datasets = chartLegends?.slice(1).map(({ label: originalLabel, variableKey }, index) => {
            const data = chartValues.map(item => item[index+1]?.value);
            const annotations = chartValues.map(item => item[index+1]?.annotation);
            const color = `hsla(${index*20}, 50%, 50%, 1)`;
            const label = variableKey === 'wind_speed'
                ? originalLabel.replace('Velocidade', 'Velocidade e Direção')
                : originalLabel;
            return {
                label,
                backgroundColor: color,
                data,
                annotations,
                borderColor: color,
                borderWidth: 1.5,
                variableKey,
                hidden: true,
            };
        }) || [];
        const windSpeed = datasets.find(({ variableKey }) => variableKey === 'wind_speed');
        if (windSpeed) {
            const windDirectionIndex = datasets.findIndex(({ variableKey }) => variableKey === 'wind_direction');
            if (windDirectionIndex !== -1) {
                windSpeed.directions = datasets.splice(windDirectionIndex, 1)[0];
            }
            else {
                windSpeed.label = windSpeed.label.replace('Velocidade e Direção', 'Velocidade');
            }
        }

        const firstDataset = datasets[0];
        if (firstDataset) {
            firstDataset.hidden = false;
        }

        return {
            labels: chartValues?.map(item => item[0]) || [],
            datasets,
        };
    }, [measures, weatherVariables, VARIABLES_UNITS]);

    return {
        chartData,
    };
};
