import { useCallback, useState } from 'react';

import { getAstronomicForecasts } from '../../../../services/api';

export function useAstronomicForecastsState() {
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function(harbor_id, datetimeStart, datetimeEnd) {
        if (loading || value.length > 0) {
            return;
        }
        setLoading(true);
        const forecasts = await getAstronomicForecasts(harbor_id, datetimeStart, datetimeEnd);
        setValue(forecasts);
        setLoading(false);
    }, [loading, value]);

    return {
        fetch,
        loading,
        value,
    };
};
