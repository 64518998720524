import React from 'react';

import {
    CitySelectionInput,
    SelectionInput,
} from '../../../../common/custom-inputs';

import StationMonitorBox from '../station-monitor-box';

import { useStationMonitorListStates } from './states';

import './styles.css';


function StationsMonitorList() {   
    const {
        changeSortOptions,
        filterActive,
        filterStations,
        selectStationInstitution,
        selectedStationInstitution,
        setFilterActive,
        setFilterCity,
        sortOptions,
        stations,
        stationInstitutionOptions,
    } = useStationMonitorListStates();

    return (
        <>
        <div className="header">
            <h3>Estações Atrasadas</h3>
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="selected-station-institution">Instituição: </label>
                {stationInstitutionOptions?.length && selectedStationInstitution && <SelectionInput
                    defaultValue={selectedStationInstitution?.id}
                    setValue={selectStationInstitution}
                    options={stationInstitutionOptions}
                />}
            </div>

            <div className="form-group">
                <label htmlFor="filter-active">Habilitadas: </label>
                <select
                    id="filter-active"
                    value={filterActive}
                    onChange={(event) => setFilterActive(Number(event.target.value))}
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                    <option value="-1">Todas</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="filter-city-id">Cidade: </label>
                <CitySelectionInput
                    defaultStateOption={{ value: 0, label: 'Todos' }}
                    isStateNullable={true}
                    defaultCityOption={{ value: 0, label: 'Todas' }}
                    selectCity={([state_id, city_id]) => setFilterCity({ state_id, city_id })}
                    inputId="filter-city-id"
                />
            </div>

            <div className="form-group">
                <label htmlFor="sort-options">Ordenar: </label>
                <select
                    id="sort-options"
                    value={sortOptions}
                    onChange={(event) => changeSortOptions(event.target.value)}
                >
                    <option value="name=asc">Nome (crescente)</option>
                    <option value="name=">Nome (decrescente)</option>
                    <option value="code=asc">Código (crescente)</option>
                    <option value="code=">Código (decrescente)</option>
                    <option value="delay=asc">Tempo de atraso (crescente)</option>
                    <option value="delay=">Tempo de atraso (decrescente)</option>
                </select>
            </div>
        </div>
        <div className="delayed-station-amount">
            Quantidade de estações atrasadas:
            <span>{stations && stations.filter(filterStations).length}</span>
        </div>
        {stations && stations
            .filter(filterStations)
            .map(station => (
                <StationMonitorBox
                    key={station.id || station.tempId}
                    station={station}
                />
            ))}
    </>);
}

export default StationsMonitorList;
