import { useMemo } from 'react';

import { useAuth } from '../../../../contexts/auth';

const availableVariables = [
    'dew_point',
    'temperature',
    'relative_humidity',
    'wind_speed',
    'wind_direction',
    'thermal_sensation',
    'atmospheric_pressure',
];

export function useMeasuresTableStates(measures, weatherVariables) {
    const { settingsSet } = useAuth();
    const { VARIABLES_UNITS } = { ...settingsSet };

    const tableData = useMemo(() => {
        if (!measures || !weatherVariables) {
            return null;
        }
        
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const weatherMeasures = {};
            Object.keys(measure).forEach(key => {
                if (!availableVariables.includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const weatherVariable = weatherVariables.find(v => v.key === key);
                    variables.push(weatherVariable);
                }
                weatherMeasures[key] = measure[key];
            });
            result.push({
                ...weatherMeasures,
                datetime,
            });
            return result;
        }, []);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const variablesUnits = VARIABLES_UNITS?.map(value => value.split(',').map(Number)) || [];

        const tableHeader = ['Hora da Leitura'].concat(
            variables.map(({ id, label }) => {
                const variableUnit = variablesUnits.find(vu => vu[0] === id);
                const units = weatherVariables.find(wv => wv.id === id).type.units;
                const unit = units.find(u => (
                    !variableUnit ? u.formula === 'x' : u.id === variableUnit[1]
                )) || units.find(u => u.formula === 'x');
                return `${label} (${unit.label})`;
            }),
        );
        const tableContent = content.map(measure => {
            const { datetime } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} - ${time}`;
            // return [datetimeStr]
            return [{
                v: datetime,
                f: datetimeStr,
            }].concat(variables.map(({ key }) => {
                if (isNaN(measure[key])) {
                    return measure[key];
                }
                const v = Number(Number(measure[key]).toFixed(2));
                return {
                    v,
                    f: Number(v).toLocaleString('pt-BR'),
                };
            }));
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [measures, weatherVariables, VARIABLES_UNITS]);

    return {
        tableData,
    };
};
