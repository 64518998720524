import React from 'react';

import SelectionInput from '../selection-input';

function FakeSelectionInput({
    optionLabels,
    inputId,
    booleanVar,
    setValue,
}) {
    const options = optionLabels.map((optionLabel, id) => ({
        value: String(!id),
        label: optionLabel,
    }));

    return options.length && (<>
        <SelectionInput
            defaultValue={String(booleanVar)}
            options={options}
            inputId={inputId}
            setValue={setValue}
        />
    </>);
}

export default FakeSelectionInput;
