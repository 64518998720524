import { useMemo } from 'react';

function getPeriod(key) {
    if (key.includes('precipitationtx')) {
        return 12*60*24*30 + Number(key.replace('precipitationtx', '').replace('min', ''));
    }
    const timePeriodStr = key.replace('precipitation', '').replace('tx', '');
    if (timePeriodStr.includes('min')) {
        return Number(timePeriodStr.replace('min', ''));
    }
    if (timePeriodStr.includes('h')) {
        return Number(timePeriodStr.replace('h', ''))*60;
    }
    if (timePeriodStr.includes('m')) {
        return Number(timePeriodStr.replace('m', ''))*60*24*30;
    }
    return 0;
}

export function useMeasuresTableStates(measures, weatherVariables) {
    const tableData = useMemo(() => {
        if (!measures || !weatherVariables) {
            return null;
        }
        
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const precipitationMeasures = {};
            Object.keys(measure).forEach(key => {
                if (!key.includes('precipitation')) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const weatherVariable = weatherVariables.find(v => v.key === key);
                    variables.push(weatherVariable);
                }
                precipitationMeasures[key] = measure[key];
            });
            result.push({
                ...precipitationMeasures,
                datetime,
            });
            return result;
        }, []);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => {
            return getPeriod(a.key) - getPeriod(b.key);
        });

        const tableHeader = ['Hora da Leitura'].concat(
            variables.map(({ label }) => label?.replace('Chuva', '')?.trim()),
        );
        const tableContent = content.map(measure => {
            const { datetime } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} - ${time}`;
            // return [datetimeStr]
            return [{
                v: datetime,
                f: datetimeStr,
            }].concat(variables.map(({ key }) => {
                if (isNaN(measure[key])) {
                    return measure[key];
                }
                const v = Number(Number(measure[key]).toFixed(2));
                return {
                    v,
                    f: Number(v).toLocaleString('pt-BR'),
                };
            }));
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [measures, weatherVariables]);

    return {
        tableData,
    };
};
