import React from 'react';

import BasinBox from '../basin-box';
import NewBtn from '../../common/new-btn';
import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function BasinsList() {
    const {
        manageBasins: {
            add: addNewBasin,
            useFetchAndSocket,
            value: basins,
        },
    } = useApiData();

    useFetchAndSocket();

    return (<>
        <div className="basins-list-header">
            <h3>Bacias</h3>
            <NewBtn
                newFunction={addNewBasin}
                label="Nova"
            />
        </div>
        {basins && basins.map(basin => (
            <BasinBox
                key={basin.id}
                basin={basin}
            />
        ))}
    </>);
}

export default BasinsList;
