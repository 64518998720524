import React, { useEffect } from 'react';

import RadioBox from '../radio-box';
import NewBtn from '../../common/new-btn';
import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function RadiosList() {
    const {
        manageRadios: {
            add: addNewRadio,
            fetch: fetchRadios,
            value: radios,
        },
    } = useApiData();

    useEffect(() => {
        fetchRadios();
    }, [fetchRadios]);

    return (<>
        <div className="radios-list-header">
            <h3>Rádios</h3>
            <NewBtn
                newFunction={addNewRadio}
                label="Novo"
            />
        </div>
        {radios && radios.map(radio => (
            <RadioBox
                key={radio.id}
                radio={radio}
            />
        ))}
    </>);
}

export default RadiosList;
