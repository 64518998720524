import { useCallback, useState } from 'react';

import { getManageProtocols } from '../../../../services/api';

export function useManageStationProtocolsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const stationProtocols = await getManageProtocols();
            setValue(stationProtocols);
            setLoading(false);
        },
        [value, loading],
    );

    return {
        fetch,
        loading,
        value,
    };
};
