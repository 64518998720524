import React, { useEffect, useState } from 'react';

import './styles.css';

function DateComponent() {
    const [extendedDate, setExtendedDate] = useState(null);

    useEffect(updateExtendedDate, []);

    function updateExtendedDate() {
        const now = new Date();
        const weekDayNames = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const weekDay = weekDayNames[now.getDay()];
         // dd/mm/yyyy
        const date = now.toLocaleDateString('pt-BR');
         // hh:mm
        const time = now.toLocaleTimeString('pt-BR').substr(0, 5);

        const newExtendedDate = `${weekDay}, ${date} - ${time}`;
        
        if (newExtendedDate !== extendedDate) {
            setExtendedDate(newExtendedDate);
        }
        
        setTimeout(updateExtendedDate, 5000);
    }
    
    return extendedDate && (
        <span
            className="data"
            id="data"
        >
            <b>{extendedDate}</b>
        </span>
    );
}

export default DateComponent;
