import { useEffect, useMemo, useState } from 'react';

import { useApiData } from '../../../contexts/api-data';
import { useAuth } from '../../../contexts/auth';

function getHoursFromDatetime(datetime) {
    const datetimeObject = new Date(Number(datetime));
    return `${datetimeObject.getHours()}h`;
}

export function getVariableLabel(selectedVariable, index) {
    const { label, unitLabel } = selectedVariable;
    if (!unitLabel) {
        return label(index);
    }
    return `${label(index)} (${unitLabel})`;
}

export const allForecastsVariables = {
    precipitation: {
        label: () => 'Precipitação',
        unitLabel: 'mm',
        chartType: 'bar',
        daysFrequencyVars: ['precipitation'],
        daysFrequencyObservation: 'Acumulado de precipitação em 24 horas',
        query: 'sum',
        getDaysFrequencyVars: (forecast) => [forecast.precipitation?.sum],
    },
    precipitation_accuracy: {
        label: () => 'Acurácia de Precipitação',
        unitLabel: '%',
        chartType: 'line',
        daysFrequencyVars: ['precipitation_accuracy'],
        daysFrequencyObservation: 'Acurácia de precipitação predominante em 24 horas',
        query: 'max',
        getDaysFrequencyVars: (forecast) => [forecast.precipitation_accuracy?.max],
    },
    wind_speed: {
        label: () => 'Vento',
        unitLabel: 'm/s',
        chartType: 'line',
        daysFrequencyVars: ['wind_speed'],
        daysFrequencyObservation: 'Velocidade máxima do vento',
        daysFrequencyAnnotation: (forecast) => getHoursFromDatetime(forecast.wind_speed.max_datetime),
        query: 'max',
        getDaysFrequencyVars: (forecast) => [forecast.wind_speed?.max],
    },
    wind_direction: {
        label: () => 'Direção do Vento',
        unitLabel: '°',
        chartType: 'line',
        daysFrequencyVars: ['wind_direction'],
        daysFrequencyObservation: 'Direção predominante do vento',
        query: 'mode',
        getDaysFrequencyVars: (forecast) => [forecast.wind_direction?.mode],
    },
    wind_gust: {
        label: () => 'Rajada de Vento',
        unitLabel: 'm/s',
        chartType: 'line',
        daysFrequencyVars: ['wind_gust'],
        daysFrequencyObservation: 'Rajada máxima de vento diária',
        query: 'max',
        getDaysFrequencyVars: (forecast) => [forecast.wind_gust?.max],
    },
    temperature: {
        label: (i) => {
            const replacement = i===0 ? ' Mínima' : ((i===1) ? ' Máxima' : '');
            return `Temperatura${replacement}`;
        },
        unitLabel: '°C',
        chartType: 'line',
        daysFrequencyVars: ['minimum_temperature', 'maximum_temperature'],
        daysFrequencyObservation: 'Temperaturas máxima e mínima diárias',
        query: 'min,max',
        getDaysFrequencyVars: (forecast) => [
            forecast.temperature?.min,
            forecast.temperature?.max,
        ],
    },
    relative_humidity: {
        label: () => 'Umidade Relativa',
        unitLabel: '%',
        chartType: 'line',
        daysFrequencyVars: ['relative_humidity'],
        daysFrequencyObservation: 'Umidade relativa média diária',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.relative_humidity?.avg],
    },
    pressure: {
        label: () => 'Pressão (PNMM)',
        unitLabel: 'hPa',
        chartType: 'line',
        daysFrequencyVars: ['pressure'],
        daysFrequencyObservation: 'Pressão (PNMM) média',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.pressure?.avg],
    },
    radiation: {
        label: () => 'Radiação Solar',
        unitLabel: 'W/m²',
        chartType: 'line',
        daysFrequencyVars: ['radiation'],
        daysFrequencyObservation: 'Radiação solar máxima',
        query: 'max',
        getDaysFrequencyVars: (forecast) => [forecast.radiation?.max],
    },
    dew_point: {
        label: () => 'Ponto de Orvalho',
        unitLabel: '°C',
        chartType: 'line',
        daysFrequencyVars: ['dew_point'],
        daysFrequencyObservation: 'Média do ponto de orvalho',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.dew_point?.avg],
    },
    fog_fsi: {
        label: () => 'Nevoeiro (FSI)',
        chartType: 'line',
        daysFrequencyVars: ['fog_fsi'],
        daysFrequencyObservation: 'FSI < 31 - Alta, 31 < FSI < 55 - Moderada, FSI > 55 - Baixa',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.fog_fsi?.avg],
    },
    fog_vis: {
        label: () => 'Nevoeiro (Vis)',
        unitLabel: 'Km',
        chartType: 'line',
        daysFrequencyVars: ['fog_vis'],
        daysFrequencyObservation: 'Visibilidade < 1 km - Ocorrência de nevoeiro',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.fog_vis?.avg],
    },
    significant_wave_height: {
        label: () => 'Altura de Onda Significativa',
        unitLabel: 'm',
        chartType: 'line',
        daysFrequencyVars: ['significant_wave_height'],
        daysFrequencyObservation: 'Altura de onda significativa máxima diária',
        query: 'max',
        getDaysFrequencyVars: (forecast) => [forecast.significant_wave_height?.max],
    },
    mean_wave_period: {
        label: () => 'Período Médio de Onda',
        unitLabel: 's',
        chartType: 'line',
        daysFrequencyVars: ['mean_wave_period'],
        daysFrequencyObservation: 'Período médio de onda diário',
        query: 'avg',
        getDaysFrequencyVars: (forecast) => [forecast.mean_wave_period?.avg],
    },
    mean_wave_direction: {
        label: () => 'Direção Média de Onda',
        unitLabel: '°',
        chartType: 'line',
        daysFrequencyVars: ['mean_wave_direction'],
        daysFrequencyObservation: 'Direção média de onda diária',
        query: 'mode',
        getDaysFrequencyVars: (forecast) => [forecast.mean_wave_direction?.mode],
    },
    astronomic: {
        label: () => 'Maré e Lua',
        chartType: 'Table',
    },
};

export function useForecastsVariablesStates() {
    const {
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();
    const {
        settingsSet,
    } = useAuth();
    const {
        VARIABLES_BY_FORECAST_MODELS,
        VARIABLES_UNITS,
    } = { ...settingsSet };

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    const [selectedVariableKey, setSelectedVariableKey] = useState(null);
    const selectedVariable = useMemo(() => allForecastsVariables[selectedVariableKey], [selectedVariableKey]);

    useEffect(() => {
        if (!VARIABLES_BY_FORECAST_MODELS) {
            return;
        }
        const variablesKeys = VARIABLES_BY_FORECAST_MODELS.map(value => value.split(',')[0]);
        if (variablesKeys.includes(selectedVariableKey)) {
            return;
        }
        const firstVariable = Object.keys(allForecastsVariables)
            .find(key => variablesKeys.includes(key));
        setSelectedVariableKey(firstVariable);
    }, [VARIABLES_BY_FORECAST_MODELS, selectedVariableKey]);

    const forecastsVariables = useMemo(() => {
        if (VARIABLES_UNITS?.length > 0 && weatherVariables) {
            VARIABLES_UNITS.forEach(value => {
                const [variableId, unitId] = value.split(',').map(Number);
                const variable = weatherVariables.find(({ id }) => id === variableId);
                const unit = variable.type.units.find(({ id }) => id === unitId);
                const forecastVariable = allForecastsVariables[variable.key];
                if (unit && forecastVariable) {
                    forecastVariable.unitLabel = unit.label;
                }
            });
        }
        if (!VARIABLES_BY_FORECAST_MODELS?.length) {
            return {};
        }
        const variablesKeys = VARIABLES_BY_FORECAST_MODELS.map(value => value.split(',')[0]);
        return Object.keys(allForecastsVariables)
            .filter(key => variablesKeys.includes(key))
            .reduce((result, key) => {
                result[key] = { ...allForecastsVariables[key] };
                return result;
            }, {});
    }, [VARIABLES_BY_FORECAST_MODELS, VARIABLES_UNITS, weatherVariables]);

    const forecastsVariablesOptions = useMemo(() => {
        return Object.keys(forecastsVariables).map(value => ({
            value,
            label: getVariableLabel(forecastsVariables[value]),
        }));
    }, [forecastsVariables]);

    return {
        forecastsVariables,
        forecastsVariablesOptions,
        selectedVariable,
        selectedVariableKey,
        setSelectedVariableKey,
    };
};
