import { useCallback, useState, useRef } from 'react';

import { getManageCitiesByState } from '../../../../services/api';

export function useManageCitiesState() {
    const valueRef = useRef({});
    const [value, setValue] = useState({});
    const loadingRef = useRef(false);
    const [loading, setLoading] = useState(false);

    const fetchByState = useCallback(async function(stateId) {
        if (loadingRef.current || value[stateId]) {
            return;
        }
        loadingRef.current = true;
        setLoading(true);
        const cities = await getManageCitiesByState(stateId);
        loadingRef.current = false;
        setLoading(false);
        valueRef.current[stateId] = cities;
        setValue({
            ...valueRef.current,
        });
    }, [value]);

    return {
        fetchByState,
        loading,
        value,
    };
};
