import { useState, useMemo, useEffect, useCallback } from 'react';

export function useMultipleSelectionInputState({
    defaultValue,
    defaultLabel = 'Selecione',
    setValue,
    options,
    showOnlyItemsAmount,
}) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [defaultValueSet, setDefaultValueSet] = useState(false);

    function selectItems(newSelectedItems) {
        const newSelectedValues = newSelectedItems.map(({ value }) => value);
        setSelectedItems(newSelectedItems);
        setSelectedValues(newSelectedValues);
        return newSelectedValues;
    }

    useEffect(() => {
        if (
            !defaultValue?.length
            || !options?.length
            || selectedItems.length
            || defaultValueSet
        ) {
            return;
        }
        selectItems(options.filter(({ value }) => defaultValue.includes(value)));
        setDefaultValueSet(true);
    }, [defaultValue, options, selectedItems, defaultValueSet]);

    const selectItemsAndSetValue = useCallback((newSelectedItems) => {
        const newSelectedValues = selectItems(newSelectedItems);
        setValue(newSelectedValues);
    }, [setValue]);

    function selectAll() {
        const newSelectedItems = selectedItems.length === options.length
            ? []
            : [ ...options ];
        selectItemsAndSetValue(newSelectedItems);
    }

    function selectOption(option) {
        const newSelectedItems = selectedValues.includes(option.value)
            ? selectedItems.filter(({ value }) => value !== option.value)
            : [ ...selectedItems, option ];
        selectItemsAndSetValue(newSelectedItems);
    }

    const nullOptionLabel = useMemo(() => {
        const getSelectedItemsLabel = (length) => {
            if (length === options.length) {
                return 'tudo';
            }
            if (showOnlyItemsAmount || length > 2) {
                return `${length} itens`;
            }
            return selectedItems.map(({ label }) => label).join(', ');
        };
        const { length } = selectedItems;
        if (length === 0) {
            return defaultLabel;
        }
        return `Selecionado: ${getSelectedItemsLabel(length)}`;
    }, [options.length, selectedItems, showOnlyItemsAmount, defaultLabel]);

    useEffect(() => {
        if (selectedValues?.some(v => !options.find(({ value }) => value === v))) {
            selectItemsAndSetValue(options.filter(({ value }) => selectedValues.includes(value)));
        }
    }, [options, selectedValues, selectItemsAndSetValue]);

    return {
        nullOptionLabel,
        options,
        selectAll,
        selectedItems,
        selectedValues,
        selectOption,
    };
};
