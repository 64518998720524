import { useCallback, useState } from 'react';

import {
    getStationInstitutions,
    postManageStationInstitutions,
    putManageStationInstitutions,
} from '../../../../services/api';

export function useManageStationInstitutionsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function() {
        if (value || loading) {
            return;
        }
        setLoading(true);
        const institutions = await getStationInstitutions();
        setValue(institutions);
        setLoading(false);
    }, [value, loading]);

    const getIdByAlias = useCallback(function(institutionAlias) {
        const stationInstitution = value?.find(({ alias }) => alias === institutionAlias);
        return stationInstitution?.id;
    }, [value]);

    async function add() {
        const id = Math.max(...value.map(region => region.id)) + 1;
        setValue([ ...value, { id } ]);
    }

    async function save(institutionId, institution) {
        const index = value.findIndex(({ id }) => id === institutionId);
        if (institution.id) {
            await putManageStationInstitutions(institutionId, institution);
        }
        else {
            institution = await postManageStationInstitutions(institution);
        }
        const newValue = [...value];
        newValue[index] = institution;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        getIdByAlias,
        loading,
        save,
        setValue,
        value,        
    };
};
