import React, { useMemo } from 'react';
// import ReactDOMServer from 'react-dom/server';
import {
    FaCheckCircle,
    FaTimesCircle,
} from 'react-icons/fa';

const TrueIcon = () => <FaCheckCircle color="hsl(120, 50%, 50%)" fontSize="1.5em" />;
const FalseIcon = () => <FaTimesCircle color="hsl(0, 50%, 50%)" fontSize="1.5em" />;

function viewDatetime(datetime) {
    const dateObject = new Date(Number(datetime));
    const date = dateObject.toLocaleDateString('pt-BR');
    const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
    return `${date} - ${time}`;
}

export function useEnergyEventsTableStates(events) {
    const tableData = useMemo(() => {
        if (!events?.length) {
            return null;
        }
        
        const tableHeader = [
            'Hora da Leitura',
            'Rede Elétrica',
            'Gerador',
        ];
        const tableContent = events.map(({
            datetime,
            electrical_network,
            generator,
        }) => ([
            {
                v: datetime,
                f: viewDatetime(datetime),
            },
            // ReactDOMServer.renderToString(electrical_network ? <TrueIcon /> : <FalseIcon />),
            // ReactDOMServer.renderToString(generator ? <TrueIcon /> : <FalseIcon />),
            {
                v: `e_${electrical_network ? 1 : 2}`,
                f: electrical_network ? TrueIcon() : FalseIcon(),
            },
            {
                v: `g_${generator ? 1 : 2}`,
                f: generator ? TrueIcon() : FalseIcon(),
            },
        ]));

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [events]);

    return {
        tableData,
    };
};
