import downloadFile from '../../../download-file';
import baseApi from '../../base';

import datetimeUtils from '../../../../utils/datetime';

function getDownloadFilename({datetimeStart, datetimeEnd}) {
    const start = datetimeUtils.getInputFormatDateFromDatetime(datetimeStart, true)
    const end = datetimeUtils.getInputFormatDateFromDatetime(datetimeEnd, true)
    return `i${start}_f${end}.csv`;
}

export async function getCalendarMessages(queryObject) {
    const queryString = Object.keys(queryObject)
        .filter(key => Boolean(queryObject[key]))
        .map(key => `${key}=${queryObject[key]}`)
        .join('&');
    const requestComplement = `/manage/calendar-messages?${queryString}`;
    if (queryObject.csv) {
        const response = await baseApi.get(
            requestComplement,
            { responseType: 'blob' },
        );
        const filename = getDownloadFilename(queryObject);
        downloadFile(response.data, filename);
        return response.data;
    }
    else {
        const response = await baseApi.get(requestComplement);
        return response.data;  
    }
};
