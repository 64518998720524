import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { MultipleForecastRegionSelectionInput } from '../../../common/custom-inputs';
import BaseBtn from '../../../common/btn/base-btn';
import GetBtn from '../../../common/btn/get-btn';

import './styles.css';


function ForecastRegionExportPopup({ forecastRegion, exports,showOnlyItemsAmount }) {
    const [selectedForecastRegions, setSelectedForecastRegions] = useState(null);

    function shouldExport(){
        return selectedForecastRegions !== null
    };

    function downloadModel(){
        const newForecastRegions = [];

        selectedForecastRegions.map((id) => {
            const regions = forecastRegion.find(
                (region) => region.id === Number(id)
            );
            return newForecastRegions.push(regions);
        });

        exports(newForecastRegions);
    };
    
    return (
        <Popup
            trigger={
                <GetBtn
                    label="Exportar"
                    className="forecast-model-export-trigger"
                />
            }
            position="center center"
            className="forecast-model-export-modal"
            modal
            closeOnDocumentClick={false}
        >
            {(close) => (
                <>
                    <div className="forecast-model-export-popup-container">
                        <h5>Exportação de Região de Previsão</h5>
                        <div className="forecast-model-export-options">
                            <div>
                                <label>
                                    Selecione a Região
                                </label>
                                <MultipleForecastRegionSelectionInput
                                    defaultValue={selectedForecastRegions}
                                    setValue={setSelectedForecastRegions}
                                    showOnlyItemsAmount
                                />
                            </div>
                        </div>
                    </div>
                    <div className="forecast-model-export-actions">
                        <GetBtn
                            onClick={downloadModel}
                            isAllowed={shouldExport}
                            label="Exportar"
                        />
                        <BaseBtn
                            className="cancel-btn"
                            onClick={close}
                            label="Cancelar"
                        />
                    </div>
                </>
            )}
        </Popup>
    );
}

export default ForecastRegionExportPopup;
