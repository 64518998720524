import React from 'react';

import {
    FaMapMarkerAlt
} from "react-icons/fa";

import {
    MdWatchLater
} from "react-icons/md";


import './styles.css';

function PinIcon({ className, size, delayed }) {
    return (
        <div className={`overlapped-icons ${className}`}>
            <FaMapMarkerAlt className="secondary-icon" size={size} />
            <FaMapMarkerAlt className="primary-icon" size={size} />
            {delayed && <MdWatchLater className="delayed-icon" />}
                   
        </div>
    );
};

export default PinIcon;
