import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Chart as GoogleChart } from 'react-google-charts';

import DashboardBox from '../dashboard-box';
import { SelectionInput } from '../common/custom-inputs';

import { useAstronomicForecastsRetrieval } from './states/astronomic-forecasts-retrieval';
import { useChartDataStates } from './states/chart-data';
import { useForecastsChartBoxStates } from './states/forecasts-chart-box';
import { useForecastsVariablesStates } from './states/forecasts-variables';
import { useModelForecastsRetrieval } from './states/model-forecasts-retrieval';

import './styles.css';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Title,
    Legend,
    Tooltip,
);

function ForecastsChartBox({ periodInHours, wide, showRegionName = true }) {
    const {
        periodInMs,
        daysFrequency,
        title,
    } = useForecastsChartBoxStates({ periodInHours, showRegionName });

    const {
        forecastsVariables,
        forecastsVariablesOptions,
        selectedVariable,
        selectedVariableKey,
        setSelectedVariableKey,
    } = useForecastsVariablesStates();

    const {
        forecasts: modelForecasts,
    } = useModelForecastsRetrieval({
        periodInMs,
        daysFrequency,
        forecastsVariables,
    });

    const {
        forecasts: astronomicForecasts,
    } = useAstronomicForecastsRetrieval({ periodInMs, forecastsVariables });

    const {
        data,
        chartTitle,
    } = useChartDataStates({
        modelForecasts,
        astronomicForecasts,
        daysFrequency,
        selectedVariable,
        selectedVariableKey,
    });

    const header = data?.[0];
    const items = data?.slice(1);
    const maxValue = items ? Math.max(...items.map(row => daysFrequency ? row[1].value : row[1])) : 0;
    const max = daysFrequency ? 50 : 10;
    const yAxis = (!items || selectedVariableKey !== 'precipitation' || max < maxValue)
        ? {}
        : {
            max,
            min: 0,
            ticks: {
                stepSize: daysFrequency ? 10 : 1,
                maxTicksLimit: daysFrequency ? 6 : 11,
            },
        };

    const configChart = {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'none',
                },
                title: {
                    display: Boolean(daysFrequency),
                    text: chartTitle,
                    color: 'red',
                },
                tooltip: {
                    displayColors: false,
                    titleFontSize: 16,
                    bodyFontSize: 14,
                    xPadding: 10,
                    yPadding: 10,
                    callbacks: {
                        label: (tooltipItem) => {
                            const annotation = tooltipItem.dataset.annotations[tooltipItem.dataIndex];
                            return [
                                `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${annotation ? `(${annotation})` : ''}`,
                            ];
                        },
                    },
                },
            },
            scales: {
                xAxis: {
                    ticks: {
                        callback: function(value, index) {
                            return (daysFrequency || index%24 === 0)
                                ? this.getLabelForValue(value)
                                : '';
                        },
                        autoSkip: false,
                        maxRotation: daysFrequency ? 90 : 0,
                        align: 'center',
                    },
                    grid: {
                        display: false,
                    },
                },
                yAxis,
            },
        },
        data: {
            labels: items?.map(item => item[0]) || [],
            datasets: header?.slice(1).map((caption, index) => {
                const data = items.map(item => {
                    const value = item[index+1]?.value;
                    if (isNaN(value)) {
                        return item[index+1];
                    }
                    return value;
                });
                const annotations = items.map(item => item[index+1]?.annotation);
                return {
                    label: caption?.label,
                    backgroundColor: '#104E8B',
                    data,
                    annotations,
                    borderColor: '#104E8B',
                    borderWidth: 1,
                };
            }) || [],
        }
    };

    return (
        <DashboardBox title={title}>
            {data && <div className="forecasts-chart-box-container">
                <div>
                    {selectedVariableKey !== 'astronomic'
                        ? (<Chart
                            type={selectedVariable?.chartType}
                            data={configChart.data}
                            options={configChart.options}
                        />)
                        : (<GoogleChart
                            chartType={selectedVariable?.chartType}
                            data={data}
                            options={{
                                width: '100%',
                                height: '10em',
                                allowHtml: true,
                            }}
                            className="forecasts-chart-content"
                        />)}
                </div>
                <SelectionInput
                    defaultValue={selectedVariableKey}
                    options={forecastsVariablesOptions}
                    setValue={setSelectedVariableKey}
                />
            </div>}
        </DashboardBox>
    );
}

export default ForecastsChartBox;
