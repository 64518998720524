import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaCircle } from 'react-icons/fa';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { useApiData } from '../../contexts/api-data';

import './styles.css';

const radarNames = {
    sumare: 'Sumaré',
    redemet: 'Redemet',
    inea: 'Inea',
};

function LegendTable({ items, radarAlias }) {
    return (
        <label className="tabLegenda">
            <input type="checkbox" /> 
            <div className="legenda-titulo"> Radar ({radarNames[radarAlias]}) </div>
            {items.map(item => (
                <div key={item.value} className="legenda-item">
                    <div className="legenda-simb">
                        <FaCircle className={`${radarAlias}_dbz${item.value}`} />
                    </div>
                    <div className="legenda-desc">
                        {item.greater && '>'}{item.value} dBz - {item.description}
                    </div>
                </div>
            ))}
        </label>
    );
}

function createLegendDiv(items, radarAlias) {
    const div = L.DomUtil.create('div', 'info legend');
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <LegendTable
            items={items}
            radarAlias={radarAlias}
        />
    );
    return div;
}

const sumareItems = [
    { value: 20, description: 'Muito Fraca' },
    { value: 25, description: 'Fraca' },
    { value: 30, description: 'Fraca/Moderada' },
    { value: 35, description: 'Moderada' },
    { value: 40, description: 'Moderada/Forte' },
    { value: 45, description: 'Forte' },
    { value: 50, description: 'Muito Forte' },
];

const sumareLegend = L.control({ position: 'topright' });
sumareLegend.onAdd = () => createLegendDiv(sumareItems, 'sumare');

const redemetItems = [
    { value: 10, description: 'Muito Fraca' },
    { value: 20, description: 'Fraca' },
    { value: 25, description: 'Fraca' },
    { value: 30, description: 'Fraca/Moderada' },
    { value: 40, description: 'Moderada' },
    { value: 45, description: 'Forte' },
    { value: 55, description: 'Forte/Muito Forte' },
    { value: 63, description: 'Muito Forte', greater: true },
];

const redemetLegend = L.control({ position: 'topright' });
redemetLegend.onAdd = () => createLegendDiv(redemetItems, 'redemet');

const ineaItems = [
    { value: 0, description: 'Sem chuva' },
    { value: 5, description: 'Sem chuva' },
    { value: 10, description: 'Sem chuva' },
    { value: 15, description: 'Chuva Fraca' },
    { value: 20, description: 'Chuva Fraca' },
    { value: 25, description: 'Chuva Fraca' },
    { value: 30, description: 'Fraca' },
    { value: 35, description: 'Moderada' },
    { value: 40, description: 'Moderada' },
    { value: 45, description: 'Moderada a Forte' },
    { value: 50, description: 'Forte' },
    { value: 55, description: 'Forte' },
    { value: 60, description: 'Muito Forte' },
    { value: 65, description: 'Muito Forte' },
    { value: 70, description: 'Muito Forte', greater: true },
];

const ineaLegend = L.control({ position: 'topright' });
ineaLegend.onAdd = () => createLegendDiv(ineaItems, 'inea');

const legends = {
    al: redemetLegend,
    be: redemetLegend,
    bv: redemetLegend,
    cn: redemetLegend,
    cz: redemetLegend,
    ga: redemetLegend,
    jr: redemetLegend,
    mq: redemetLegend,
    mo: redemetLegend,
    mn: redemetLegend,
    mi: redemetLegend,
    nt: redemetLegend,
    pl: redemetLegend,
    pc: redemetLegend,
    pv: redemetLegend,
    sv: redemetLegend,
    sn: redemetLegend,
    st: redemetLegend,
    sg: redemetLegend,
    sf: redemetLegend,
    ua: redemetLegend,
    sl: redemetLegend,
    sr: redemetLegend,
    tt: redemetLegend,
    tf: redemetLegend,
    tm: redemetLegend,
    sumare: sumareLegend,
    gua: ineaLegend,
    mac: ineaLegend,
};

function RadarLegend() {
    const apiData = useApiData();
    const map = useMap();
    const {
        selectedRadar
    } = apiData

    useEffect(() => {
        map.removeControl(sumareLegend);
        map.removeControl(redemetLegend);
        map.removeControl(ineaLegend);

        if (selectedRadar) {
            const legend = legends[selectedRadar.selectedRadar?.alias];
            if (legend) {
                legend.addTo(map);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRadar]);

    return null;
}

export default RadarLegend;
