import baseApi from '../../base';

export async function getForecasts({
    modelId,
    region_id,
    datetimeStart,
    datetimeEnd,
    frequency,
    ...variables
}) {
    const queryObject = {
        region_id,
        datetimeStart,
        datetimeEnd,
        frequency,
        ...variables,
    };
    const query = Object.keys(queryObject)
        .map(key => `${key}=${queryObject[key]}`)
        .join('&');
    const result = await baseApi.get(`/forecast-models/${modelId}/forecasts?${query}`);
    return result.data;
};

export async function getForecastsRunDatetimes(modelId) {
    const result = await baseApi.get(`/forecasts/run-datetimes?model_id=${modelId}`);
    return result.data;
};
