import { useEffect, useState, useCallback } from 'react';

import {
    getRadarLastEnergyEvent,
} from '../../../../services/api';
import { useSocket } from '../../../../hooks/use-socket';

export function useRadarLastEnergyEventStates({ radarId }) {
    const [lastEnergyEvent, setLastEnergyEvent] = useState(null);

    useSocket(
        'radar-energy-event',
        useCallback((data) => {
            setLastEnergyEvent(lastEnergyEvent => {
                if (lastEnergyEvent?.datetime > data.datetime) {
                    return lastEnergyEvent;
                }
                return data;
            });
        }, []),
    );
    
    useEffect(() => {
        if (lastEnergyEvent) {
            return;
        }
        (async function() {
            const lastEvent = await getRadarLastEnergyEvent(radarId);
            setLastEnergyEvent(lastEvent);
        })();
    }, [lastEnergyEvent, radarId]);

    return {
        lastEnergyEvent,
    };
};
