import React from 'react';

import {
    ArraySetting,
    SettingTemplate,
    StringSetting,
} from '../../settings';

import EntityBox from '../../auth/entity-box';

import './styles.css';

function getDefaultSettingType(value) {
    return (typeof value === 'object')
        ? ArraySetting(StringSetting)
        : StringSetting;
}

function CustomerSettingsBox({ label, alwaysOpen, settings, customerSettings, setSetting }) {
    return (<>
        <EntityBox
            title={label}
            alwaysOpen={alwaysOpen}
        >
            {Object.keys(settings).map(key => {
                const {
                    label: settingLabel,
                    SettingType = getDefaultSettingType(customerSettings[key]),
                    placeholder,
                    inlineDisplay,
                    isOptional,
                } = settings[key];

                return (
                    <div
                        key={`customer-settings-${key}`}
                        className={`customer-settings-item ${inlineDisplay ? 'inline' : 'block'}`}
                    >
                        <SettingTemplate
                            key={key}
                            label={`${isOptional ? '' : '*'} ${settingLabel}`}
                            defaultValue={customerSettings[key]}
                            setValue={(newValue) => setSetting(key, newValue)}
                            placeholder={placeholder}
                            SettingType={SettingType}
                        />
                    </div>
                );
            })}
        </EntityBox>
    </>);
}

export default CustomerSettingsBox;