import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';

import { useApiData } from '../../../../contexts/api-data';
import { useAuth } from '../../../../contexts/auth';
import { downloadModel } from '../../../../services/api';
import datetimeUtils from '../../../../utils/datetime';
import BaseBtn from '../../../common/btn/base-btn';
import GetBtn from '../../../common/btn/get-btn';
import ToggleBtn from '../../../common/btn/toggle-btn';
import {
    DatetimeLocalInput,
    ForecastRegionSelectionInput,
    ForecastsRunDatetimeSelectionInput,
} from '../../../common/custom-inputs';

const functions = {
    min: {
        label: 'Mínimo',
    },
    max: {
        label: 'Máximo',
    },
    sum: {
        label: 'Acumulado',
    },
    avg: {
        label: 'Média',
    },
    mode: {
        label: 'Moda',
    },
    abs: {
        label: 'Absoluto',
    },
};
const variables = {
    precipitation: {
        label: 'Precipitação',
        defaultFunctions: ['sum', 'abs'],
    },
    precipitation_accuracy: {
        label: 'Acurácia de precipitação',
        defaultFunctions: ['max', 'abs'],
    },
    wind_speed: {
        label: 'Velocidade do vento',
        defaultFunctions: ['max', 'abs'],
    },
    wind_direction: {
        label: 'Direção do vento',
        defaultFunctions: ['mode', 'abs'],
    },
    wind_gust: {
        label: 'Rajada de vento',
        defaultFunctions: ['max', 'abs'],
    },
    temperature: {
        label: 'Temperatura',
        defaultFunctions: ['min', 'max', 'abs'],
    },
    relative_humidity: {
        label: 'Umidade relativa',
        defaultFunctions: ['avg', 'abs'],
    },
    pressure: {
        label: 'Pressão (PNMM)',
        defaultFunctions: ['avg', 'abs'],
    },
    radiation: {
        label: 'Radiação Solar',
        defaultFunctions: ['avg', 'abs'],
    },
    dew_point: {
        label: 'Ponto de Orvalho',
        defaultFunctions: ['avg', 'abs'],
    },
    fog_fsi: {
        label: 'Nevoeiro (FSI)',
        defaultFunctions: ['avg', 'abs'],
    },
    fog_vis: {
        label: 'Nevoeiro (Vis)',
        defaultFunctions: ['avg', 'abs'],
    },
    significant_wave_height: {
        label: 'Altura de onda significativa',
        defaultFunctions: ['max', 'abs'],
    },
    mean_wave_period: {
        label: 'Período médio de onda',
        defaultFunctions: ['avg', 'abs'],
    },
    mean_wave_direction: {
        label: 'Direção média de onda',
        defaultFunctions: ['mode', 'abs'],
    },
};
const defaultModelVariables = Object.keys(variables).reduce((result, variable) => {
    result[variable] = [ ...variables[variable].defaultFunctions ];
    return result;
}, {});
const oneHour = 60*60*1000;

function ForecastModelDownloadPopup({ model }) {
    const {
        forecastRegions,
    } = useApiData();
    const {
        settingsSet,
    } = useAuth();

    const {
        VARIABLES_BY_FORECAST_MODELS: variablesByForecastModelsSetting,
    } = {
        ...settingsSet,
    };

    const modelVariablesRef = useRef({});

    const minimumFrequency = model.data_frequency / oneHour;
    const maximumFrequency = 24; // model.max_data_period / oneHour;
    const [frequencyHour, setFrequencyHour] = useState(null);
    
    const [regionId, setRegionId] = useState(null);
    const [runDatetime, setRunDatetime] = useState(null);
    const [datetimeStart, setDatetimeStart] = useState(null);
    const [datetimeEnd, setDatetimeEnd] = useState(null);

    useEffect(() => {
        if (frequencyHour !== null || !model) {
            return;
        }
        setFrequencyHour(model.chart_frequency / oneHour);
    }, [frequencyHour, model]);

    useEffect(() => {
        if (!variablesByForecastModelsSetting?.length) {
            return ;
        }
        modelVariablesRef.current = variablesByForecastModelsSetting
            .map(variablesByForecastModel => variablesByForecastModel.split(',')[0])
            .reduce((result, variableKey) => {
                if (variableKey in defaultModelVariables) {
                    result[variableKey] = defaultModelVariables[variableKey];
                }
                return result;
        }, {});
    }, [variablesByForecastModelsSetting]);

    useEffect(() => {
        if (!runDatetime || !model?.max_data_period) {
            return;
        }
        const now = Date.now();
        const today = datetimeUtils.getInputFormatDateFromDatetime(now);
        const datetimeStart = datetimeUtils.getZeroHourDatetimeFromInputFormatDate(today);
        setDatetimeStart(datetimeStart);
        setDatetimeEnd(datetimeStart + Number(model.max_data_period));
    }, [runDatetime, model.max_data_period]);

    function toggleVariableFunction(variable, fn, event) {
        const vars = modelVariablesRef.current;
        const varFns = vars[variable];
        const { checked } = event.target;
        vars[variable] = checked
            ? [ ...varFns, fn ]
            : varFns.filter(v => v !== fn);
    }

    function shouldDownload() {
        return regionId !== null
            && runDatetime !== null
            && datetimeStart !== null
            && datetimeEnd !== null;
    }

    async function executeDownload() {
        const frequency = frequencyHour * oneHour;
        const modelVariables = Object.keys(modelVariablesRef.current)
            .reduce((result, variable) => {
                const value = modelVariablesRef.current[variable];
                const isAbsolute = frequencyHour <= minimumFrequency;
                if (isAbsolute && value.includes('abs')) {
                    result[variable] = 'abs';
                    return result;
                }
                const valueWithoutAbs = value.filter(v => v !== 'abs');
                if (!isAbsolute && valueWithoutAbs.length > 0) {
                    result[variable] = valueWithoutAbs.join(',');
                }
                return result;
            }, {});
        
        const region = forecastRegions.find(r => r.id === regionId);
        await downloadModel({
            model_id: model.id,
            region,
            run_datetime: runDatetime,
            frequency,
            datetimeStart,
            datetimeEnd,
            modelVariables,
        });
    }

    return (
        <Popup
            trigger={<GetBtn label="Download" className="forecast-model-download-trigger" />}
            position="center center"
            className="forecast-model-download-modal"
            modal
        >
            {close => (
                <div className="forecast-model-download-popup-container">
                    <h5>Download do Modelo: {model.name}</h5>
                    <div className="forecast-model-variables">
                        {Object.keys(variables)
                            ?.filter(variable => variablesByForecastModelsSetting
                                ?.map(variablesByForecastModel => variablesByForecastModel.split(',')[0])
                                ?.includes(variable)
                            )
                            ?.map(variable => (
                                <div
                                    key={variable}
                                    className="forecast-variable-option"
                                >
                                    <span className="variable-label">{variables[variable].label}</span>
                                    <div className="variable-fns">
                                        {Object.keys(functions).map(fn => {
                                            const isAbsolute = fn === 'abs';
                                            const disabled = (isAbsolute && frequencyHour > minimumFrequency)
                                                || (!isAbsolute && frequencyHour <= minimumFrequency);
                                            const variableFnId = `${fn}_${variable}`;
                                            const isDefault = variables[variable].defaultFunctions.includes(fn);
                                            const value = isDefault || isAbsolute;
                                            return <ToggleBtn
                                                key={variableFnId}
                                                id={variableFnId}
                                                label={functions[fn].label}
                                                value={value}
                                                onChange={event => toggleVariableFunction(variable, fn, event)}
                                                disabled={disabled}
                                            />;
                                        })}
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="forecast-model-download-options">
                        <div className="form-group">
                            <label htmlFor="region">Região</label>
                            {forecastRegions && (<ForecastRegionSelectionInput
                                regions={forecastRegions.filter(({ alias }) => alias !== 'cor_rj')}
                                defaultValue={regionId}
                                setValue={value => setRegionId(Number(value))}
                                isNullable={false}
                                inputId="region"
                            />)}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="runDatetime">Rodada</label>
                            <ForecastsRunDatetimeSelectionInput
                                defaultValue={runDatetime}
                                setValue={value => setRunDatetime(Number(value))}
                                isNullable={false}
                                inputId="runDatetime"
                                modelId={model.id}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="frequency">Frequência (em horas)</label>
                            <input
                                type="number"
                                id="frequency"
                                defaultValue={frequencyHour}
                                min={minimumFrequency}
                                max={maximumFrequency}
                                step={minimumFrequency}
                                onChange={event => setFrequencyHour(event.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="datetimeStart">Início</label>
                            <DatetimeLocalInput
                                inputId="datetimeStart"
                                value={datetimeStart}
                                setValue={setDatetimeStart}
                                disabled={!runDatetime}
                                min={runDatetime || Date.now()}
                                max={datetimeEnd}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="datetimeEnd">Fim</label>
                            <DatetimeLocalInput
                                inputId="datetimeEnd"
                                value={datetimeEnd}
                                setValue={setDatetimeEnd}
                                disabled={!runDatetime}
                                min={datetimeStart}
                                max={(runDatetime || Date.now()) + Number(model.max_data_period || 0)}
                            />
                        </div>

                        <div className="forecast-model-download-actions">
                            <GetBtn
                                onClick={executeDownload}
                                isAllowed={shouldDownload}
                                label="Baixar"
                            />
                            <BaseBtn
                                className="cancel-btn"
                                onClick={close}
                                label="Cancelar"
                            />
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default ForecastModelDownloadPopup;
