import React from 'react';

import { useRadarLastEnergyEventStates } from './states';

import './styles.css';

function RadarLastEnergyEvent({ radarId }) {
    const {
        lastEnergyEvent,
    } = useRadarLastEnergyEventStates({ radarId });

    const {
        generator,
        electrical_network,
    } = { ...lastEnergyEvent };

    return (
        <div className="radar-info-box">
            <h4>Status de Energia</h4>
            <div className="last-energy-event">
                <span className={electrical_network ? 'selected' : ''}>Rede Elétrica</span>
                <span className={generator ? 'selected' : ''}>Gerador</span>
            </div>
        </div>
    );
}

export default RadarLastEnergyEvent;
