import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react';
import { useApiData } from '../api-data';

import useMapPositionState from '../map-context/states/map-position';

function regionModelsToDraws(regionModels) {
    return regionModels
        .map((regionModel) => {
            const hasData =
                parseFloat(regionModel.initial_latitude) !== 0 ||
                parseFloat(regionModel.initial_longitude) !== 0 ||
                parseFloat(regionModel.final_latitude) !== 0 ||
                parseFloat(regionModel.final_longitude) !== 0;

            if (hasData) {
                return {
                    model_id: regionModel.model_id,
                    model_name: regionModel.model_name,
                    data: [
                        {
                            _southWest: {
                                lat: parseFloat(regionModel.final_latitude),
                                lng: parseFloat(regionModel.initial_longitude),
                            },
                            _northEast: {
                                lat: parseFloat(regionModel.initial_latitude),
                                lng: parseFloat(regionModel.final_longitude),
                            },
                        },
                    ],
                    type: 'rectangle',
                };
            }

            return null;
        })
        .filter((item) => item !== null);
}

const MapDrawerInputterContext = createContext();
export const useMapDrawerInputter = () => useContext(MapDrawerInputterContext);

export const MapDrawerProvider = ({
    children,
    onDrawsChange,
    initialRegionModels,
    stationId
}) => {
    const {
        forecastModels: { value: forecastModels },
        manageStations: { getById: getManageStationsById },
    } = useApiData();
    const defaultMapPosition = useMapPositionState();

    const [draws, setDraws] = useState([]);
    const [filteredModelsOptions, setFilteredModelOptions] = useState([]);
    const [multiSelect, setMultiSelect] = useState(true);
    const [selectedDraw, setSelectedDraw] = useState({});
    const [selectedDrawIds, setSelectedDrawIds] = useState([]);
    const [selectedModel, setSelectedModel] = useState(forecastModels[0]);
    const [drawsToSave, setDrawsToSave] = useState(null);
    const [allowToConfirm, setAllowToConfirm] = useState(false);
    const [allowChangeModel, setAllowChangeModel] = useState(true);
    const [initialPosition, setInitialPosition] = useState(null);

    const drawsRef = useRef([]);
    const filteredModelsOptionsRef = useRef([]);
    const selectedModelRef = useRef(null);
    const initialDrawsRef = useRef(null);

    useEffect(() => {
        if(stationId) {
            const manageStation = getManageStationsById(stationId);
            setInitialPosition({
                center: [manageStation?.latitude, manageStation?.longitude],
                zoom: 8,
            });
        } else if (defaultMapPosition?.initialLatitude && defaultMapPosition?.initialLongitude) {
            setInitialPosition({
                center: [defaultMapPosition.initialLatitude, defaultMapPosition.initialLongitude],
                zoom: defaultMapPosition.initialMapZoom,
            });
        } else {
            setInitialPosition({
                center: [-14.873462, -47.3202926],
                zoom: 5,
            });
        }
    }, [getManageStationsById, stationId, defaultMapPosition]);

    useEffect(() => {
        onDrawsChange(drawsToSave);
    }, [drawsToSave, onDrawsChange]);

    useEffect(() => {
        const translatedData = regionModelsToDraws(initialRegionModels);
        initialDrawsRef.current = translatedData;
    }, [initialRegionModels]);

    useEffect(() => {
        if (forecastModels?.length > 0) {
            const usedModelIds = new Set(draws.map((draw) => draw.model_id));
            const filteredOptions = forecastModels?.filter(
                (model) => !usedModelIds.has(model.id)
            );

            setSelectedModel(filteredOptions[0]);
            setFilteredModelOptions(filteredOptions);
        }
    }, [draws, forecastModels]);

    useEffect(() => {
        selectedModelRef.current = selectedModel;
    }, [selectedModel]);

    useEffect(() => {
        if (drawsRef.current !== draws) {
            drawsRef.current = draws;
        }
    }, [draws]);

    useEffect(() => {
        filteredModelsOptionsRef.current = filteredModelsOptions;
    }, [filteredModelsOptions]);

    useEffect(() => {
        setTimeout(() => {
            setDrawsToSave(initialDrawsRef.current);
            setDraws(initialDrawsRef.current);
            setSelectedDraw();
            setSelectedDrawIds([]);
            setAllowToConfirm(false);
            setAllowChangeModel(true);
        }, 200);
    }, []);

    const formatDrawData = useCallback((data, type, layer, drawModel) => {
        const dataArray = Array.isArray(data) ? data[0] : [data];
        return {
            type: type.toLowerCase(),
            data: dataArray,
            layer: layer,
            model_name: !drawModel
                ? selectedModel.name
                : drawModel.model_name,
            model_id: !drawModel
                ? selectedModel.id
                : drawModel.model_id,
        };
    }, [selectedModel]);

    const updateDrawsToSave = () => {
        setDrawsToSave(drawsRef.current);
        setAllowToConfirm(false);
        setAllowChangeModel(true);
    };

    const cancelUpdate = useCallback(() => {
        setDraws(drawsToSave);
        setAllowChangeModel(true);
        setAllowToConfirm(false);
        setSelectedDraw();
        setSelectedDrawIds([]);
    }, [drawsToSave]);

    const addDraw = useCallback((data, drawType, layer, drawModel) => {
        return new Promise((resolve) => {
            if (filteredModelsOptionsRef.current.length > 0 && drawModel) {
                setAllowToConfirm(true);
                setAllowChangeModel(false);
                const existingIndex = draws.findIndex((d) => d.model_id === drawModel.model_id);
                if (existingIndex !== -1) {
                    const updatedDraws = [...draws];
                    updatedDraws[existingIndex] = {
                        ...updatedDraws[existingIndex],
                        data,
                        layer,
                    };
                    setDraws(updatedDraws);
                    setSelectedDraw(updatedDraws[existingIndex]);
                    setSelectedDrawIds([updatedDraws[existingIndex].layer?._leaflet_id]);
                    resolve();
                } else {
                    const formattedDrawData = formatDrawData(data, drawType, layer, drawModel);
                    setDraws((prevData) => [...prevData, formattedDrawData]);
                    setTimeout(() => {
                        setSelectedDraw(formattedDrawData);
                        setSelectedDrawIds([formattedDrawData.layer?._leaflet_id]);
                        resolve();
                    }, 100);
                }
            }
        });
    }, [draws, formatDrawData]);

    const addDrawFromInput = useCallback(
        (coordinates) => {
            setAllowToConfirm(true);
            setAllowChangeModel(false);
            const data = {
                _northEast: {
                    lat: coordinates.topLatitude,
                    lng: coordinates.rightLongitude,
                },
                _southWest: {
                    lat: coordinates.bottomLatitude,
                    lng: coordinates.leftLongitude,
                },
            };

            const drawModel = {
                model_id: selectedModel.id,
                model_name: selectedModel.name,
            };

            addDraw(data, 'rectangle', null, drawModel);
        },
        [addDraw, selectedModel]
    );

    const addDrawFromDuplicate = (data, drawModel) => {
        addDraw(data, 'rectangle', null, drawModel).then(() => {
            updateDrawsToSave();
        });
    }

    const updateDraw = (newData, drawId) => {
        setAllowToConfirm(true);
        setAllowChangeModel(false);
        setDraws((prevDraws) =>
            prevDraws.map((draw) => {
                if (draw.layer?._leaflet_id === drawId) {
                    return {
                        ...draw,
                        data: [newData.data],
                    };
                }
                return draw;
            })
        );

        setSelectedDraw((prevSelectedDraw) => ({
            ...prevSelectedDraw,
            data: [newData.data],
        }));
    };

    const removeDraw = (drawId) => {
        const newSelectedDrawIds = selectedDrawIds.filter(
            (draw) => draw !== drawId
        );
        setSelectedDrawIds(newSelectedDrawIds);

        if (selectedDraw?.layer?._leaflet_id === drawId) {
            if (newSelectedDrawIds.length > 0) {
                selectDraw(newSelectedDrawIds[0], true);
            } else {
                selectDraw();
            }
        }

        const drawToRemove = drawsRef.current.find(
            (draw) => draw.layer?._leaflet_id === drawId
        );
        if (drawToRemove) {
            drawToRemove.layer.remove();
        }

        if (draws.length === 1) {
            setDraws([]);
            setDrawsToSave([]);
        } else {
            setDraws((prevDraws) =>
                prevDraws.filter((draw) => draw.layer?._leaflet_id !== drawId)
            );
            setDrawsToSave((prevDraws) =>
                prevDraws.filter((draw) => draw.model_id !== drawToRemove.model_id)
            );
        }

        setAllowToConfirm(false);
        setAllowChangeModel(true);
    };

    const selectModel = (selectedId) => {
        const selectedObj = forecastModels?.find(
            (option) => parseInt(option.id) === parseInt(selectedId)
        );
        setSelectedModel(selectedObj);
    };

    const selectDraw = (drawId, alreadySelected = false) => {
        if (!drawId) {
            setSelectedDrawIds([]);
            setSelectedDraw({});
            return;
        }
        if (multiSelect) {
            setSelectedDrawIds((prevIds) => {
                const isBeingSelected = !prevIds.includes(drawId);
                const newSelectedDrawIds = isBeingSelected
                    ? [...prevIds, drawId]
                    : prevIds.filter((id) => id !== drawId);

                if (isBeingSelected || alreadySelected) {
                    const newDraw = drawsRef.current.find((draw) => {
                        return draw.id === drawId;
                    });
                    if (newDraw) {
                        setSelectedDraw(newDraw);
                    }
                }

                return alreadySelected ? prevIds : newSelectedDrawIds;
            });
        } else {
            setSelectedDrawIds((prevIds) => {
                const isBeingSelected = !prevIds.includes(drawId);

                if (isBeingSelected || alreadySelected) {
                    const newDraw = drawsRef.current.find((draw) => {
                        return draw.layer?._leaflet_id === drawId;
                    });
                    if (newDraw) {
                        setSelectedDraw(newDraw);
                    }
                }

                return alreadySelected ? prevIds : [drawId];
            });
        }
    };

    return (
        <MapDrawerInputterContext.Provider
            value={{
                initialPosition,
                draws,
                selectedDrawIds,
                selectedDraw,

                selectModel,
                selectedModel,
                filteredModelsOptions,

                setMultiSelect,
                addDraw,
                addDrawFromDuplicate,
                addDrawFromInput,
                cancelUpdate,
                updateDraw,
                removeDraw,
                selectDraw,

                drawsToSave,
                setDrawsToSave,
                updateDrawsToSave,
                allowToConfirm,
                setAllowToConfirm,
                allowChangeModel,
                setAllowChangeModel,
                initialDrawsRef,
            }}
        >
            {children}
        </MapDrawerInputterContext.Provider>
    );
};
