import { useEffect, useState, useMemo } from 'react';

import datetimeUtils from '../../../../utils/datetime';

export function useSearchFormStates({
    fetchMeasures,
    measures,
    radios,
    radarId,
}) {
    const now = Date.now();
    const defaultDatetimeStart = datetimeUtils.addNDays(now, -1);
    const maximumDatetimeValue = datetimeUtils.getInputFormatDatetimeFromDatetime(now);
    const [datetimeStartValue, setDatetimeStartValue] = useState(
        datetimeUtils.getInputFormatDatetimeFromDatetime(defaultDatetimeStart)
    );
    const [datetimeEndValue, setDatetimeEndValue] = useState(datetimeUtils.getInputFormatDatetimeFromDatetime(now));

    const [radioId, setRadioId] = useState(null);
    const variables = useMemo(() => 'battery_voltage', []);
    const radiosOptions = radios
        ?.map(({ id, name }) => ({
            value: id,
            label: name,
        }));

    useEffect(() => {
        if (!radios?.length || radioId) {
            return;
        }
        setRadioId(radios[0].id);
    }, [radios, radioId]);

    useEffect(() => {
        if (measures || !radarId || !datetimeStartValue || !datetimeEndValue || !variables || !radioId) {
            return;
        }
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchMeasures({
            radarId,
            radioId,
            datetimeStart,
            datetimeEnd,
            variables,
        });
    }, [fetchMeasures, measures, radarId, datetimeStartValue, datetimeEndValue, variables, radioId]);

    async function searchMeasures(event) {
        event.preventDefault();
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchMeasures({
            radarId,
            radioId,
            datetimeStart,
            datetimeEnd,
            variables,
        });
    }

    function updateDatetimeStart(newDatetimeStartValue) {
        setDatetimeStartValue(newDatetimeStartValue);
        if (newDatetimeStartValue < datetimeEndValue) {
            return;
        }
        const datetimeStart = (new Date(newDatetimeStartValue)).getTime();
        const newDatetimeEndValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeStart, 1));
        setDatetimeEndValue(newDatetimeEndValue);
    }

    function updateDatetimeEnd(newDatetimeEndValue) {
        setDatetimeEndValue(newDatetimeEndValue);
        if (datetimeStartValue < newDatetimeEndValue) {
            return;
        }
        const datetimeEnd = (new Date(newDatetimeEndValue)).getTime();
        const newDatetimeStartValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeEnd, -1));
        setDatetimeStartValue(newDatetimeStartValue);
    }

    return {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        radiosOptions,
        radioId,
        setRadioId,
        variables,
    };
};
