import baseApi from '../../base';

export async function postManageStationTelemetryMeasures(institutionId, stationTelemetryMeasures) {
    await baseApi.post(
        `/station-institutions/${institutionId}/telemetry-measures?should_send_socket_event=true`,
        stationTelemetryMeasures,
    );
};

export async function getStationsLastTelemetryMeasures(institutionId) {
    const response = await baseApi.get(
        `/station-institutions/${institutionId}/stations/last-telemetry-measures`,
    );
    return response.data;
};
