import { useEffect } from 'react';

import { useApiData } from '../../../../contexts/api-data';
import api, { postManageForecastRegions } from '../../../../services/api';
import downloadFile from '../../../../services/download-file';

export function useForecastRegionsListPageState() {
    const {
        forecastModels: {
            fetchAndSave: fetchForecastModels,
            value: forecastModels,
        },
        manageForecastRegions: {
            fetch: fetchManageForecastRegions,
            new: addNewRegion,
            value: forecastRegions,
            set: setValue,
        },
        manageStations: {
            fetch: fetchStations,
        },
        manageStates: {
            fetch: fetchManageStates,
        },
    } = useApiData();

    useEffect(() => {
        fetchManageForecastRegions();
    }, [fetchManageForecastRegions]);

    useEffect(() => {
        fetchForecastModels();
    }, [fetchForecastModels]);

    useEffect(() => {
        fetchStations();
    }, [fetchStations]);

    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);

    async function exportForecastRegions(forecastRegions) {
        const forecastRegionIds = forecastRegions.map(
            (forecastRegion) => forecastRegion.id
        );
        const requests = forecastRegionIds.map((id) =>
            api.get(`/manage/forecast-regions/${id}/models`)
        );
        const responses = await Promise.all(requests);
        const forecastRegionModels = responses.map((response) => response.data);

        const regionInfoMap = {};
        const modelInfoMap = {};

        forecastRegions.forEach((info) => {
            regionInfoMap[info.id] = {
                id: info.id,
                name: info.name,
                alias: info.alias,
            };
        });

        forecastModels.forEach((info) => {
            modelInfoMap[info.id] = {
                alias: info.alias,
                name: info.name,
            };
        });

        const newForecastModels = {};

        forecastRegionModels.forEach((forecastRegionModel, i) => {
            const forecastRegionModelId = forecastRegionIds[i];

            forecastRegionModel.forEach((forecastModel) => {
                if (forecastRegionIds.includes(forecastModel.region_id)) {
                    if (!newForecastModels[forecastRegionModelId]) {
                        newForecastModels[forecastRegionModelId] = [];
                    }

                    const modelInfo = modelInfoMap[forecastModel.model_id];

                    forecastModel.model_name = modelInfo.name;
                    forecastModel.model_alias = modelInfo.alias;
                    newForecastModels[forecastRegionModelId].push(
                        forecastModel
                    );
                }
            });
        });

        const newForecastModelRegions = forecastRegionIds.map((regionId) => {
            const info = regionInfoMap[regionId];
            return {
                id: info.id,
                name: info.name,
                alias: info.alias,
                regionModels: newForecastModels[regionId] || [],
            };
        });

        const fileName = `forecast_regions.json`;
        const fileContent = JSON.stringify(newForecastModelRegions, null, 4);
        const file = new Blob([fileContent], {
            type: 'data:text/json;charset=utf-8',
        });

        downloadFile(file, fileName);
    }

    function importFile() {
        const input = document.createElement('input');
        input.type = 'file';

        input.addEventListener('change', (event) => {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const fileContent = JSON.parse(e.target.result);
                    const forecastRegionModelReplaceIds = fileContent.map((region) => {
                        const regionModels = region.regionModels.map((rm) => {
                            const matchingModels = forecastModels.find((fm) => fm.alias === rm.model_alias);
                            if(matchingModels){
                                return {...rm, model_id: matchingModels.id}
                            }
                            return rm;
                        })
                        return { ...region, regionModels };
                    });
                    const forecastRegionModels = forecastRegions.concat(
                        forecastRegionModelReplaceIds
                    );
                    const newForecastRegionModels = forecastRegionModels.reduce(
                        (forecastRegion, current) => {
                            const existingRegion = forecastRegion.find(
                                (obj) => obj.alias === current.alias
                            );

                            if (!existingRegion) {
                                forecastRegion.push(current);
                            }

                            return forecastRegion;
                        },
                        []
                    );
                    setValue(newForecastRegionModels);
                };

                reader.readAsText(file);
            }
        });

        input.click();
    };

    function shouldSave() {
        let objetosSemId = 0;

        forecastRegions.forEach((item) => {
            if (typeof item.regionModels !== 'undefined') {
                objetosSemId++;
            }
        });

        return objetosSemId;
    };

    async function saveForecastRegions() {
        if(shouldSave()){
            const forecastRegionWithModels = [];
            const newForecastRegions = [];
            const forecastModels = [];

            forecastRegions.forEach((region) => {
                if (typeof region.regionModels !== 'undefined') {
                    forecastRegionWithModels.push(region);
                }
            });
            forecastRegionWithModels.map((fr) => {
                const newForecastRegion = {
                    name: fr.name,
                    alias: fr.alias,
                };
                newForecastRegions.push(newForecastRegion);
                return newForecastRegions;
            });
            forecastRegionWithModels.map((frs) => {
                return forecastModels.push(frs.regionModels);
            });

            const requests = newForecastRegions.map((fr) =>
                postManageForecastRegions(fr)
            );
            const response = await Promise.all(requests);

            if (response.length === forecastModels.length ){
                for (let i = 0; i < response.length; i++) {
                    const forecastRegionId = response[i].id;
                    const forecastRegionModel = forecastModels[i];
                    api.post(
                        `/manage/forecast-regions/${forecastRegionId}/models`,
                        {
                            data: forecastRegionModel,
                        }
                    );
                }
            }
        }
    }

    return {
        forecastModels,
        addNewRegion,
        forecastRegions,
        exportForecastRegions,
        importFile,
        saveForecastRegions,
        shouldSave,
    };
};
