import React from 'react';

import SelectionInput from '../selection-input';
import { useApiData } from '../../../../contexts/api-data';

function StationConfigurationSelectionInput({
    defaultValue,
    defaultOption,
    setValue,
    isNullable = true,
    inputId,
    nullLabel,
}) {
    const {
        manageStationConfigurations: {
            value: stationConfigurations,
        },
    } = useApiData();

    const options = stationConfigurations?.map(({ id: value, name: label }) => ({
        value,
        label,
    }));

    return stationConfigurations?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
            nullLabel={nullLabel}
        />
    ) : null;
}

export default StationConfigurationSelectionInput;
