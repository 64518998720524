import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

function ActionIconBtn(properties, ref) {
    const {
        label,
        className,
        icon,
        action,
        disabled,
        ...rest
    } = properties;

    function onClick() {
        if (!disabled && action) {
            action();
        }
    }

    return (
        <Link
            to="#"
            {...rest}
            className={`${className} base-btn allowed-btn action-icon-btn ${disabled ? 'disabled' : ''}`}
            onClick={onClick}
            ref={ref}
        >
            {icon && icon()}
            {label}
        </Link>
    );
}

export default React.forwardRef(ActionIconBtn);
