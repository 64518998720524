import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { useAuth } from '../auth';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils/hooks/query';

import api, {
    getForecasts,
    getV2ManageStationByInstitutions,
} from '../../services/api';

import * as userUtils from '../../utils/user';

import { useAstronomicForecastsState } from './states/astronomic/astronomic-forecasts';
import { useForecastModelsState } from './states/forecasts/forecast-models';
import { useForecastsRunDatetimesByModel } from './states/forecasts/forecasts-run-datetimes';
import { useManageBasinsState } from './states/basins/manage-basins';
import { useManageBasinPrecipitationStagesState } from './states/basins/manage-basin-precipitation-stages';
import { useManageBasinSlipStagesState } from './states/basins/manage-basin-slip-stages';
import { useManageAlertTemplatesState } from './states/alerts/manage-alert-templates';
import { useManageRealTimeAlertTemplatesState } from './states/alerts/manage-real-time-alert-templates';
import { useManageCitiesState } from './states/territories/manage-cities';
import { useManageForecastRegionsState } from './states/forecasts/manage-forecast-regions';
import { useManageHarborsState } from './states/astronomic/manage-harbors';
import { useManageStatesState } from './states/territories/manage-states';
import { useManageCustomersState } from './states/auth/manage-customers';

import { useSatelliteState } from './states/satellites/satellites';
import { useSatelliteInstitutionsState } from './states/satellites/satellite-institutions';
import { useSelectedSatellites } from './states/satellites/selectedSatellites';
import { useSatelliteProductTypesState } from './states/satellites/satellites-product-types';
import { useRadarsState } from './states/radars/radar';
import { useRadarProductsStates } from './states/radars/radar-products';
import { useSelectedRadar } from './states/radars/select-radar';

import { useManagePermissionsState } from './states/auth/manage-permissions';
import { useManageRadiosState } from './states/radars/manage-radios';
import { useManageRolesState } from './states/auth/manage-roles';
import { useSensorsState } from './states/alertario/sensors';
import { useManageStationConfigurationsState } from './states/stations/manage-station-configurations';
import { useManageStationInstitutionsState } from './states/stations/manage-station-institutions';
import { useManageStationMeasuresState } from './states/stations/manage-station-measures';
import { useManageStationTelemetryMeasuresState } from './states/stations/manage-station-telemetry-measures';
import { useManageStationProtocolsState } from './states/stations/manage-station-protocols';
import { useManageStationsState } from './states/stations/manage-stations';
import { useManageTelemetryVariablesState } from './states/variables/manage-telemetry-variables';
import { useManageVariableTypesState } from './states/variables/manage-variable-types';
import { useManageWeatherVariablesState } from './states/variables/manage-weather-variables';
import { useStationSensorsState } from './states/alertario/station-sensors';
import { useSystemSettingsState } from './states/system-settings';
import { useSystemMonitorState } from './states/system-monitor';


const ApiDataContext = createContext({});

const oneHour = 60 * 60 * 1000;
const twelveHours = 12 * oneHour;

function ApiDataProvider({ children }) {
    const auth = useAuth();
    const { settingsSet } = auth;
    const history = useHistory();
    const {regiao: initialRegionAlias } = useQuery();

    const [forecasts, setForecasts] = useState(null);
    const [forecastRegions, setForecastRegions] = useState(null);
    const [selectedForecastRegion, setSelectedForecastRegion] = useState(null);
    const [selectedForecast, setSelectedForecast] = useState(null);
    const [maxForecastPrecipitationClasses, setMaxForecastPrecipitationClasses] = useState(null);

    const [selectedStationInstitution, setSelectedStationInstitution] = useState(null);
    const [stations, setStations] = useState(null);

    const [lightnings, setLightnings] = useState(null);

    const [staticPositionTypes, setStaticPositionTypes] = useState(null);
    const [selectedStaticPositionTypes, setSelectedStaticPositionTypes] = useState(null);
    const [staticPositions, setStaticPositions] = useState([]);
    const [staticPositionPerimeters, setStaticPositionPerimeters] = useState([]);

    const [realTimeMessages, setRealTimeMessages] = useState([]);

    const [cities, setCities] = useState([]);
    const [neighborhoodsByCity, setNeighborhoodsByCity] = useState({});

    const astronomicForecasts = useAstronomicForecastsState();
    const forecastModels = useForecastModelsState();
    const {
        value: forecastModelsList,
        fetchAndSave: fetchForecastModelsAndSave,
    } = forecastModels;
    const forecastsRunDatetimesByModel = useForecastsRunDatetimesByModel();
    const manageBasins = useManageBasinsState();
    const manageBasinPrecipitationStages = useManageBasinPrecipitationStagesState();
    const manageBasinSlipStages = useManageBasinSlipStagesState();
    const manageAlertTemplates = useManageAlertTemplatesState();
    const manageRealTimeAlertTemplates = useManageRealTimeAlertTemplatesState();
    const manageCitiesByState = useManageCitiesState();
    const manageCustomers = useManageCustomersState();
    const manageForecastRegions = useManageForecastRegionsState();
    const manageHarbors = useManageHarborsState();
    const managePermissions = useManagePermissionsState();
    const manageRadios = useManageRadiosState();
    const manageRoles = useManageRolesState();
    const sensors = useSensorsState();
    const manageStates = useManageStatesState();
    const manageTelemetryVariables = useManageTelemetryVariablesState();
    const manageVariableTypes = useManageVariableTypesState();
    const manageStationConfigurations = useManageStationConfigurationsState();
    const manageStationInstitutions = useManageStationInstitutionsState();
    const {
        fetch: fetchStationInstitutions,
        value: stationInstitutions,
    } = manageStationInstitutions;
    const manageStationMeasures = useManageStationMeasuresState();
    const manageStationProtocols = useManageStationProtocolsState();
    const manageStationTelemetryMeasures = useManageStationTelemetryMeasuresState();
    const manageWeatherVariables = useManageWeatherVariablesState();
    const stationSensors = useStationSensorsState();
    const systemSettings = useSystemSettingsState();
    const systemMonitor = useSystemMonitorState();
    const satellites = useSatelliteState();
    const satelliteInstitutions = useSatelliteInstitutionsState();
    const selectSatellites = useSelectedSatellites();
    const satellitesProductTypes = useSatelliteProductTypesState();
    const radars = useRadarsState();
    const radarProducts = useRadarProductsStates();
    const selectedRadar = useSelectedRadar();
    const manageStations = useManageStationsState(systemSettings);
    const { identifiesDelayedStations } = manageStations;

    const stationsTimeoutIndex = useRef();

    const [selectedRadarOrSatellite, setSelectedRadarOrSatellite] = useState(null);
    const [selectedRadarOrSatelliteProduct, setSelectedRadarOrSatelliteProduct] = useState(null);

    useEffect(() => {
        while (!auth.isAuthenticated) {}
        getStaticPositionTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setViewSatelliteOrRadar = useCallback(() => {
        const selectedAlias = userUtils.getSelectedRadarsOrSatellite();
        const radar = radars.value.find(({ alias }) => alias === selectedAlias);
        if(radar){
            setSelectedRadarOrSatellite(selectedRadar.selectedRadar);
            setSelectedRadarOrSatelliteProduct(selectedRadar.radarProducts);
            selectSatellites.resetSatellite();
        }
        else{
            setSelectedRadarOrSatellite(selectSatellites.selectedSatellite);
            setSelectedRadarOrSatelliteProduct(selectSatellites.satelliteProducts);
            selectedRadar.resetRadar()
        }
    },[radars, selectSatellites, selectedRadar]);

    useEffect(() => {
        setViewSatelliteOrRadar()
    },[setViewSatelliteOrRadar])

    const selectForecastRegion = useCallback((regionId, customRegions) => {
        if (!regionId) {
            return;
        }

        const regions = customRegions ? customRegions : forecastRegions;
        const forecastRegion = regions.find(({ id }) => `${id}` === `${regionId}`);

        if (!forecastRegion) {
            return;
        }
        setSelectedForecastRegion(forecastRegion);
        if (maxForecastPrecipitationClasses) {
            setSelectedForecast(maxForecastPrecipitationClasses[forecastRegion.alias]);
        }
    }, [maxForecastPrecipitationClasses, forecastRegions]);

    // select initial forecast region
    useEffect(() => {
        const { PAGES } = {
            ...auth.settingsSet,
        };

        if (PAGES && forecastRegions && !selectedForecastRegion) {
            if (initialRegionAlias) {
                const initialRegion = forecastRegions.find(
                    ({ alias }) => alias === initialRegionAlias)
                if (!initialRegion) {
                    toast('Erro! Região não encontrada.', {
                        type: 'error',
                    });
                    history.push('/previsoes');
                    return ;
                }
                selectForecastRegion(initialRegion.id);
            }
            else {
                const newForecastsPage = PAGES.includes('FORECASTS');
                const regionId = (newForecastsPage)
                    ? forecastRegions.find(({ alias }) => !alias.startsWith('cor_rj'))?.id
                    : 'COR_RJ_ID';
                selectForecastRegion(regionId);
            }
        }
    }, [
        auth.settingsSet,
        forecastRegions,
        history,
        initialRegionAlias,
        selectedForecastRegion,
        selectForecastRegion,
    ]);



    function selectForecastTime(index) {
        setSelectedForecast(forecasts[selectedForecastRegion.alias][index]);
    }

    // TODO: Chamar esse useEffect apenas nos locais onde stationInstitutions são requisitados
    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    const getStations = useCallback(async (institution) => {
        if (!institution) {
            return;
        }
        const newStations = await getV2ManageStationByInstitutions(institution.id, true);        
        const delayedStations = await identifiesDelayedStations(newStations);
        setStations(delayedStations);

        stationsTimeoutIndex.current = setTimeout(() => getStations(institution), 60000); // 1 minute
    }, [identifiesDelayedStations]);

    async function selectStationInstitution(alias) {
        if (!alias || stationsTimeoutIndex.current) {
            clearTimeout(stationsTimeoutIndex.current);
        }
        if (!alias) {
            setSelectedStationInstitution(null);
            return;
        }
        const institution = stationInstitutions?.find(institution => institution.alias === alias);
        if (!institution) {
            return;
        }

        await getStations(institution);

        setSelectedStationInstitution(institution);
    }

    const stationCityIds = useRef(null);

    useEffect(() => {
        const { STATION_CITY_IDS = [] } = { ...settingsSet };
        if(STATION_CITY_IDS.toString() === (stationCityIds.current || '').toString()) {
            return;
        }
        if (stationsTimeoutIndex.current) {
            clearTimeout(stationsTimeoutIndex.current);
        }
        stationCityIds.current = STATION_CITY_IDS;
        getStations(selectedStationInstitution);
    }, [settingsSet, selectedStationInstitution, getStations]);

    async function getStaticPositionTypes() {
        const response = await api.get('/static-position-types');
        setStaticPositionTypes(response.data);
    }

    const retrieveStaticPositions = useCallback(async function(alias) {
        const type = (staticPositionTypes || []).find(type => type.alias === alias);

        if (!type) {
            return [];
        }

        const response = await api.get(`/static-position-types/${type.id}/static-positions`);
        return response.data;
    }, [staticPositionTypes]);

    const retrieveStaticPositionToPerimeters = useCallback(async function(alias) {
        const type = (staticPositionTypes || []).find(type => type.alias === alias);

        if (!type) {
            return [];
        }
        let combinedRadius = null
        const staticPosition = staticPositions.filter(position => position.type_id === type.id).map(position => {
            combinedRadius = position.radius ? position.radius : type.radius;
            return {
                coords: 
                    [position.latitude,
                     position.longitude
                    ],
                radius: combinedRadius,
                showIcon: position.show_icon,
                showRanges: position.show_ranges,
                id: position.id,
                alias: type.alias
            };
        });

        const positions = staticPosition?.filter(Boolean).flatMap(position => {
            const {coords, radius, showIcon, showRanges} = position;

            const internRadius = radius?.map(internRadius => {
                return {
                    coords,
                    radius:internRadius,
                    showIcon,
                    showRanges,
                    id: position.id,
                    alias: position.alias
                }
            }) || [];

            return internRadius
        })
    
        return positions;

    }, [staticPositionTypes, staticPositions]);

    const getAllStaticPositionPerimeters = useCallback(async function(aliases) {
        
        const { STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [] } = { ...settingsSet };

        const filteredAliases = aliases.filter(alias => staticPositionTypeAliases.includes(alias));

        const staticPositionPerimetersLayer = (await Promise.all(filteredAliases.map(retrieveStaticPositionToPerimeters)))
            .reduce((result, current) => result.concat(current), []);
    
        const newStaticPosition =  staticPositionPerimetersLayer.sort((a, b) => (a.radius < b.radius) ? 1 : -1);

        setStaticPositionPerimeters(newStaticPosition);
        
    }, [retrieveStaticPositionToPerimeters, settingsSet]);

    const getAllStaticPositions = useCallback(async function(aliases) {
        const { STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [] } = { ...settingsSet };

        const filteredAliases = Object.keys(aliases).filter(alias => staticPositionTypeAliases.includes(alias));
    
        const staticPositions = (await Promise.all(filteredAliases.map(retrieveStaticPositions)))
            .reduce((result, current) => result.concat(current), []);   
          
        setSelectedStaticPositionTypes(aliases);
        setStaticPositions(staticPositions)   
        
    }, [retrieveStaticPositions, settingsSet]);
    

    const selectStaticPositionTypes = useCallback(async function(aliases) {
        if (aliases) {
            userUtils.setSelectedStaticPositionTypes(aliases);
        }
        else {
            aliases = userUtils.getSelectedStaticPositionTypes();
            if (!aliases) {
                return;
            }
        }

        await getAllStaticPositions(aliases);
    }, [getAllStaticPositions]);

    const retrieveRealTimeMessages = useCallback(async function () {
        const result = await api.get('/real-time-messages');
        // DESC by datetime
        result.data.sort((a, b) => b.datetime - a.datetime);
        setRealTimeMessages(result.data);
    }, []);

    const retrieveForecastRegions = useCallback(async function () {
        if (!forecastRegions) {
            const response = await api.get('/forecast-regions');
            setForecastRegions([
                {
                    id: 'COR_RJ_ID',
                    name: 'Rio de Janeiro',
                    alias: 'cor_rj',
                },
                ...response.data,
            ]);
            return true;
        }
        return false;
    }, [forecastRegions]);

    const forecastRegionsSetting = useRef(null);

    useEffect(() => {
        const { FORECAST_REGIONS = [] } = { ...settingsSet };
        if(FORECAST_REGIONS.toString() === (forecastRegionsSetting.current || '').toString()) {
            return;
        }
        forecastRegionsSetting.current = FORECAST_REGIONS;
        setForecastRegions(null);
        setForecasts(null);
        setSelectedForecastRegion(null);
        setSelectedForecast(null);
    }, [settingsSet]);

    const retrieveForecastsForCor = useCallback(async function() {
        const [updatedForecastRegions] = await Promise.all([
            retrieveForecastRegions(),
            fetchForecastModelsAndSave(),
        ]);
        if (updatedForecastRegions || forecastModelsList.length === 0) {
            return;
        }
        const modelId = forecastModelsList.find(({ alias }) => alias === 'wrf')?.id;

        const datetimeStart = Date.now();
        const datetimeEnd = datetimeStart + twelveHours;
        const result = {
            cor_rj: [],
        };
        const maxClasses = {};

        await Promise.all(forecastRegions
            .filter(({ id, alias }) => alias.startsWith('cor_rj') && id !== 'COR_RJ_ID')
            .map(region => (async function() {
                const forecasts = await getForecasts({
                    modelId,
                    region_id: region.id,
                    datetimeStart,
                    datetimeEnd,
                    frequency: oneHour,
                    precipitation: 'abs',
                    precipitation_accuracy: 'abs',
                });
                forecasts.forEach((forecast, i) => {
                    const { precipitation, precipitation_accuracy } = forecast;
                    const prec = Number(precipitation);
                    forecast.precipitationClass = prec < 0.2
                        ? 0
                        : (prec <= 5
                            ? 1
                            : (prec <= 25
                                ? 2
                                : 3));
                    if (precipitation > (maxClasses[region.alias]?.precipitation || -1)) {
                        maxClasses[region.alias] = forecast;
                    }
                    if (precipitation > (maxClasses.cor_rj?.precipitation || -1)) {
                        maxClasses.cor_rj = forecast;
                    }
                    const corRJForecast = result.cor_rj[i];
                    if (corRJForecast) {
                        if (precipitation > corRJForecast.precipitation) {
                            corRJForecast.precipitation = precipitation;
                            corRJForecast.precipitationClass = forecast.precipitationClass;
                        }
                        if (precipitation_accuracy > corRJForecast.precipitation_accuracy) {
                            corRJForecast.precipitation_accuracy = precipitation_accuracy;
                        }
                    }
                    else {
                        result.cor_rj.push(forecast);
                    }
                });
                result[region.alias] = forecasts;
            })())
        );

        setForecasts(result);
        setMaxForecastPrecipitationClasses(maxClasses);

        if (!selectedForecast) {
            setSelectedForecast(maxClasses['cor_rj']);
        }

    }, [
        retrieveForecastRegions,
        forecastRegions,
        selectedForecast,
        forecastModelsList,
        fetchForecastModelsAndSave,
    ]);

    const retrieveForecasts = useCallback(async function({
        modelId,
        datetimeStart,
        datetimeEnd,
        frequency,
        ...variablesQuery
    }) {
        if (await retrieveForecastRegions()) {
            return;
        }

        if (!selectedForecastRegion) {
            return [];
        }

        const forecasts = await getForecasts({
            modelId,
            region_id: selectedForecastRegion.id,
            datetimeStart,
            datetimeEnd,
            frequency,
            ...variablesQuery,
        });
        forecasts.forEach(forecast => {
            if (typeof forecast.temperature === 'object') {
                forecast.minimum_temperature_datetime = forecast.temperature.min_datetime;
                forecast.maximum_temperature_datetime = forecast.temperature.max_datetime;
            }
        });

        return forecasts;
    }, [retrieveForecastRegions, selectedForecastRegion]);

    const retrieveCalendarMessages = useCallback(async function(datetimeStart, datetimeEnd) {
        const query = `datetimeStart=${datetimeStart}&datetimeEnd=${datetimeEnd}`;
        const response = await api.get(`/calendar-messages?${query}`);
        return response.data;
    }, []);

    const retrieveLightnings = useCallback(async () => {
        const response = await api.get('/lightnings');
        setLightnings(response.data);
    }, []);

    const retrieveCustomerSettingsByKeys = useCallback(async (...keys) => {
        const query = `keys=${keys.join(',')}`;
        const response = await api.get(`/manage/customer-settings?${query}`);
        return response.data;
    }, []);

    const retrieveClimateForecasts = useCallback(async (regionId, frequency, datetimeStart, datetimeEnd) => {
        const query = [
            `region_id=${regionId}`,
            `frequency=${frequency}`,
            `datetimeStart=${datetimeStart}`,
            `datetimeEnd=${datetimeEnd}`,
            'precipitation=sum',
        ].join('&');
        const response = await api.get(`/climate-forecasts?${query}`);
        return response.data;
    }, []);

    const retrieveCities = useCallback(async () => {
        if (cities) {
            return;
        }
        const response = await api.get('/manage/cities');
        response.data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase())
            ? -1
            : 1);
        setCities(response.data);
    }, [cities]);

    const retrieveNeighborhoodsByCity = useCallback(async (cityId) => {
        if (neighborhoodsByCity[cityId]) {
            return;
        }
        const response = await api.get(`/manage/cities/${cityId}/neighborhoods`);
        response.data.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase())
            ? -1
            : 1);
        setNeighborhoodsByCity({
            ...neighborhoodsByCity,
            [cityId]: response.data,
        });
    }, [neighborhoodsByCity]);

    return (
        <ApiDataContext.Provider
            value={{
                astronomicForecasts,
                cities,
                forecastModels,
                forecastRegions,
                forecasts,
                forecastsRunDatetimesByModel,
                getAllStaticPositions,
                getAllStaticPositionPerimeters,
                initialRegionAlias,
                lightnings,
                manageBasins,
                manageBasinPrecipitationStages,
                manageBasinSlipStages,
                manageAlertTemplates,
                manageRealTimeAlertTemplates,
                manageCitiesByState,
                manageCustomers,
                manageForecastRegions,
                manageHarbors,
                managePermissions,
                manageRadios,
                manageRoles,
                manageStates,
                manageStationConfigurations,
                manageStationInstitutions,
                manageStationMeasures,
                manageStationProtocols,
                manageStations,
                manageStationTelemetryMeasures,
                manageTelemetryVariables,
                manageVariableTypes,
                manageWeatherVariables,
                maxForecastPrecipitationClasses,
                neighborhoodsByCity,
                radarProducts,
                radars,
                realTimeMessages,
                retrieveCalendarMessages,
                retrieveCities,
                retrieveClimateForecasts,
                retrieveCustomerSettingsByKeys,
                retrieveForecastRegions,
                retrieveForecasts,
                retrieveForecastsForCor,
                retrieveLightnings,
                retrieveNeighborhoodsByCity,
                retrieveRealTimeMessages,
                retrieveStaticPositions,
                retrieveStaticPositionToPerimeters,
                selectedForecast,
                selectedForecastRegion,
                selectedStationInstitution,
                selectedStaticPositionTypes,
                staticPositions,
                selectStaticPositionTypes,
                selectStationInstitution,
                selectForecastTime,
                selectForecastRegion,
                sensors,
                staticPositionTypes,
                staticPositionPerimeters,
                stationSensors,
                stations,
                systemSettings,
                systemMonitor,
                satellites,
                satelliteInstitutions,
                selectSatellites,
                satellitesProductTypes,
                selectedRadarOrSatellite,
                selectedRadarOrSatelliteProduct,
                selectedRadar
            }}
        >
            {children}
        </ApiDataContext.Provider>
    );
}

export default ApiDataProvider;

export function useApiData() {
    return useContext(ApiDataContext);
};
