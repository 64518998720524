import React from 'react';
import MapDrawer from '../map-drawer';
import MapInputter from '../map-inputter';
import { MapDrawerProvider } from '../../contexts/map-drawer-inputter';

import './style.css';

function MapDrawerInputter({ onDrawsChange, initialRegionModels, stationId }) {
    return (
        <MapDrawerProvider
            className="mapDrawInputter"
            onDrawsChange={onDrawsChange}
            initialRegionModels={initialRegionModels}
            stationId={stationId}
        >
            <div className="map-draw-inputter-drawer">
                <MapDrawer key={initialRegionModels} />
            </div>
            <div className="map-draw-inputter">
                <MapInputter />
            </div>
        </MapDrawerProvider>
    );
}

export default MapDrawerInputter;
