import { useEffect, useState, useMemo, useCallback } from 'react';

import { useApiData } from '../../../contexts/api-data';
import {
    getStationsLastTelemetryMeasures,
} from '../../../services/api';
import { useSocket } from '../../../hooks/use-socket';

function roundNumericValues(measures, variables) {
    if (!measures) {
        return;
    }
    Object.keys(measures).forEach(key => {
        if (key === 'datetime') {
            return;
        }
        const variable = variables.find(v => v.key === key);
        if (!variable?.is_numeric || !measures[key]) {
            return;
        }
        measures[key] = Number(Number(measures[key]).toFixed(2));
    });
    return measures;
}

export function useStationsDashboardState() {
    const {
        manageStations: {
            fetchByInstitution,
            identifiesDelayedStations,
            value: rawStations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();
    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);
    const [stations, setStations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [telemetryMeasuresFetched, setTelemetryMeasuresFetched] = useState(false);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        if (stations || !institutionId) return;
        fetchByInstitution(institutionId);
    }, [fetchByInstitution, institutionId, stations]);

    useEffect(() => {
        if (!rawStations || !weatherVariables) return;
        rawStations.forEach(({ measures }) => {
            roundNumericValues(measures, weatherVariables);
        });
        setStations(rawStations);
    }, [rawStations, weatherVariables]);

    useEffect(() => {
        if (!stations || loading || !institutionId || telemetryMeasuresFetched) {
            return;
        }
        (async function() {
            setLoading(true);
            const stationsTelemetryMeasures = await getStationsLastTelemetryMeasures(institutionId);
            setStations(
                stations => stations.map(station => {
                    const { id, telemetryMeasures } = station;
                    const newMeasure = stationsTelemetryMeasures.find(({ station_id }) => station_id === id);
                    if (newMeasure && (!telemetryMeasures || newMeasure.datetime >= telemetryMeasures.datetime)) {
                        station.telemetryMeasures = newMeasure;
                    }
                    return station;
                })
            );
            setTelemetryMeasuresFetched(true);
            setLoading(false);
        })();
    }, [stations, loading, telemetryMeasuresFetched, institutionId]);

    useSocket(
        'stations-weather-measures',
        useCallback((data) => {
            setStations(
                stations => {
                    if (!stations) {
                        return null;
                    }
                    if (!weatherVariables) {
                        return stations;
                    }
                    return stations.map(station => {
                        const { id, measures } = station;
                        const newMeasure = data.find(({ station_id }) => station_id === id);
                        if (newMeasure && (!measures || newMeasure.datetime >= measures.datetime)) {
                            const {
                                datetime,
                                variables,
                            } = newMeasure;
                            station.measures = roundNumericValues({
                                datetime,
                                ...variables,
                            }, weatherVariables);
                        }
                        return station;
                    })
                }
            );
        }, [weatherVariables]),
    );

    useSocket(
        'last-station-telemetry-measure',
        useCallback((data) => {
            setStations(
                stations => {
                    if (!stations) {
                        return null;
                    }
                    return stations.map(station => {
                        const { id, telemetryMeasures } = station;
                        const newMeasure = data.find(({ station_id }) => station_id === id);
                        if (newMeasure && (!telemetryMeasures || newMeasure.datetime >= telemetryMeasures.datetime)) {
                            const {
                                datetime,
                                measures,
                            } = newMeasure;
                            station.telemetryMeasures = {
                                datetime,
                                ...measures,
                            };
                        }
                        return station;
                    })
                }
            );
        }, []),
    );

    useSocket(
        'stations-slip-stages',
        useCallback((data) => {
            setStations(
                stations => {
                    if (!stations) {
                        return null;
                    }
                    return stations.map(station => {
                        const newMeasure = data.find(({ station_code }) => station_code === station.code);
                        if (newMeasure) {
                            const {
                                slip_stage_level,
                            } = newMeasure;
                            station.measures.SLIP_STAGE_LEVEL = slip_stage_level;
                        }
                        return station;
                    })
                }
            );
        }, []),
    );

    // TODO: passar para dentro do useSocket
    identifiesDelayedStations(stations);

    return {
        institutionId,
        stations,
    };
};
