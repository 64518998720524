import React, { useEffect } from 'react';

import SelectionInput from '../selection-input';
import { useApiData } from '../../../../contexts/api-data';

function BasinSelectionInput({
    defaultValue,
    defaultOption,
    setValue,
    isNullable = true,
    inputId,
}) {
    const {
        manageBasins: {
            value: basins,
            fetch: fetchBasins,
        },
    } = useApiData();

    useEffect(() => {
        fetchBasins();
    }, [fetchBasins]);

    const options = basins?.map(({ id, name }) => ({
        value: id,
        label: `${name}`
    }));

    return basins?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : null;
}

export default BasinSelectionInput;
