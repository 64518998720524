import React, { useRef } from 'react';
import { FaCopy } from 'react-icons/fa';

import './styles.css';

function CopyButton({ value }) {
    const hiddenText = useRef(null);

    function copy() {
        if (hiddenText) {
            hiddenText.current.value = value;
            hiddenText.current.select();
            document.execCommand('copy');
        }
    }

    return (
        <>
            <span className="i-corpo2" onClick={copy}>
                <FaCopy />
            </span>
            <textarea
                className="copytextarea"
                ref={hiddenText}
            >
            </textarea>
        </>
    );
}

export default CopyButton;
