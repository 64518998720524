import baseApi, { connectSocket } from './base';

export default baseApi;

export { connectSocket };

export {
    getAstronomicForecasts,
} from './endpoints/astronomic/astronomic-forecasts';

export {
    getManageHarbors,
} from './endpoints/astronomic/harbors';

export {
    getSystemSettings,
    postSystemSettings,
} from './endpoints/system/system-settings';

export {
    getImagesAmount
} from './endpoints/system/sytem-monitor';

export {
    getForecastModels,
    downloadModel,
    manageDownloadModel,
} from './endpoints/forecasts/forecast-models';

export {
    getManageForecastRegions,
    postManageForecastRegions,
    putManageForecastRegions,
} from './endpoints/forecasts/forecast-regions';

export {
    getForecasts,
    getForecastsRunDatetimes,
} from './endpoints/forecasts/forecasts';

export {
    getManageCustomers,
    postManageCustomers,
    putManageCustomers,
    getManageCustomersForecastRegions
} from './endpoints/auth/customers';

export {
    deleteRealTimeMessages,
} from './endpoints/alerts/real-time-messages';

export {
    getCalendarMessages
} from './endpoints/alerts/calendar-messages';

export {
    downloadStation,
    filterStationWeatherMeasures,
    filterStationTelemetryMeasures,
    getManageStations,
    getManageStationByInstitutions,
    getV2ManageStationByInstitutions,
    postManageStations,
} from './endpoints/stations/stations';

export {
    getStationInstitutions,
    postManageStationInstitutions,
    putManageStationInstitutions,
} from './endpoints/stations/station-institutions';

export {
    getManageStates,
} from './endpoints/territories/states';

export {
    getManageCitiesByState,
} from './endpoints/territories/cities';

export {
    getSatelliteInstitutions,
    postSatelliteInstitutions,
    putSatelliteInstitutions,
} from './endpoints/satellites/satellite-institutions';

export {
    getSatellites,
    postSatellites,
    putSatellites,
} from './endpoints/satellites/satellite';

export {
    getSatelliteProductTypes,
    postSatelliteProductTypes,
    putSatelliteProductTypes
} from './endpoints/satellites/satellite-product-types';

export {
    getSatelliteProducts,
} from './endpoints/satellites/satellite-products';

export {
    getRadars,
} from './endpoints/radars/radar';

export {
    getRadarProducts,
} from './endpoints/radars/radar-products';

export {
    getManagePermissions,
    postManagePermissions,
    putManagePermissions,
} from './endpoints/auth/permissions';

export {
    getManageRoles,
    postManageRoles,
    putManageRoles,
    postManageRolePermissions,
} from './endpoints/auth/roles';

export {
    getManageVariableTypes,
    postManageVariableTypes,
    putManageVariableTypes,
} from './endpoints/variables/variable-types';

export {
    getWeatherVariables,
    postManageWeatherVariables,
    putManageWeatherVariables,
} from './endpoints/variables/weather-variables';

export {
    postWhatsappCalendarMessages,
} from './endpoints/bot/whatsapp/whatsapp-calendar-message';

export {
    getTelemetryVariables,
    postManageTelemetryVariables,
    putManageTelemetryVariables,
} from './endpoints/variables/telemetry-variables';

export {
    getManageProtocols,
} from './endpoints/stations/protocols';

export {
    getManageConfigurations,
} from './endpoints/stations/configurations';

export {
    getSensors,
} from './endpoints/alertario/sensors';

export {
    getManageStationSensors,
} from './endpoints/alertario/station-sensors';

export {
    postManageStationMeasures,
    putStationsSlipStages,
    getHistoricStationMeasuresByVariable,
} from './endpoints/stations/station-measures';

export {
    getBasins,
    postManageBasins,
    putManageBasins,
    patchBasinStages,
} from './endpoints/basins/basins';

export {
    getBasinSlipStages,
    postManageBasinSlipStages,
    putManageBasinSlipStages,
    deleteManageBasinSlipStages,
} from './endpoints/basins/basin-slip-stages';

export {
    getBasinPrecipitationStages,
    postManageBasinPrecipitationStages,
    putManageBasinPrecipitationStages,
    deleteManageBasinPrecipitationStages,
} from './endpoints/basins/basin-precipitation-stages';

export {
    getStationsLastTelemetryMeasures,
    postManageStationTelemetryMeasures,
} from './endpoints/stations/station-telemetry-measures';

export {
    getRadios,
    postManageRadios,
    putManageRadios,
} from './endpoints/radars/radios';

export {
    filterRadarTelemetryMeasures,
} from './endpoints/radars/radar-telemetry-measures';

export {
    filterRadarEnergyEvents,
    getRadarLastEnergyEvent,
} from './endpoints/radars/radar-energy-events';

export {
    getManageAlertTemplates,
    postManageAlertTemplate,
    putManageAlertTemplate,
} from './endpoints/alerts/alert-templates';

export {
    getManageRealTimeAlertTemplates,
    postManageRealTimeAlertTemplate,
    putManageRealTimeAlertTemplate,
} from './endpoints/alerts/real-time-alert-templates';

export {
    getStationExtraDataByInstitution,
    getStationExtraDataByStation,
    postStationExtraData,
} from './endpoints/stations/station-extra-data';

export {
    getBulletinForecastsOptions,
    postBulletinForecastsOptions,
} from './endpoints/bulletin/bulletin-forecasts-options';

export {
    getDetailedForecasts,
    postDetailedForecasts,
} from './endpoints/bulletin/detailed-forecasts';

export {
    getExtendedForecasts,
    postExtendedForecasts,
} from './endpoints/bulletin/extended-forecasts';

export {
    getSynopticTextTypes,
    postManageSynopticTextTypes,
    putManageSynopticTextTypes,
} from './endpoints/bulletin/synoptic-text-types';

export {
    getSynopticTexts,
    postSynopticTexts,
} from './endpoints/bulletin/synoptic-texts';

export {
    getManageTemperatureZones,
    getTemperatureZones,
    patchTemperatureZones,
    postManageTemperatureZones,
    putManageTemperatureZones,
} from './endpoints/bulletin/temperature-zones';

export {
    getStationHighestPrecipitations,
    postStationHighestPrecipitations,
} from './endpoints/stations/station-highest-precipitations';
