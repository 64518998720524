import React, { useState } from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import api from '../../../../services/api';

import './styles.css';

function StationProtocolBox({ protocol }) {
    const [updatedProtocol, setUpdatedProtocol] = useState(protocol);
    
    const creating = !updatedProtocol.name;
    const [name, setName] = useState(updatedProtocol.name || '');
    const [inactive, setInactive] = useState(updatedProtocol.inactive || false);

    function shouldSave() {
        const nameIsSet = name?.length;
        if (creating) {
            return nameIsSet;
        }
        const nameChanged = name !== updatedProtocol.name;
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedProtocol.inactive);        
        return nameIsSet && (nameChanged || inactiveChanged);
    }

    async function saveConfiguration() {
        if (shouldSave()) {
            const protocolToSend = {
                name,
                inactive,
            };
            if (creating) {
                const response = await api.post('/manage/station-protocols', protocolToSend);
                protocolToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/station-protocols/${updatedProtocol.id}`, protocolToSend);
                protocolToSend.id = updatedProtocol.id;
            }
            setUpdatedProtocol(protocolToSend);
        }
    }    

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form protocol-box-form">
                <div className="form-group flexible-div">
                    <label htmlFor={`protocol-name-${updatedProtocol.id}`}>Nome</label>
                    <input
                        id={`protocol-name-${updatedProtocol.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={50}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`protocol-inactive-${protocol.id}`}>Desabilitar</label>
                    <input
                        id={`protocol-inactive-${protocol.id}`}
                        type="checkbox"
                        checked={inactive}
                        defaultChecked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group">
                    <PostBtn
                        postFunction={saveConfiguration}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default StationProtocolBox;
