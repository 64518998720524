import baseApi from '../../base';

const baseManageResource = '/manage/radios';
 
export async function getRadios() {
    const result = await baseApi.get('/radios');
    return result.data;
};

export async function postManageRadios(radio) {
    const result = await baseApi.post(baseManageResource, radio);
    return result.data;
};

export async function putManageRadios(radio) {
    const result = await baseApi.put(`${baseManageResource}/${radio.id}`, radio);
    return result.data;
};
