export function getLoggedUser() {
    return JSON.parse(localStorage.getItem('logged_user'));
};

export function setLoggedUser(userObject) {
    localStorage.setItem('logged_user', JSON.stringify(userObject));
};

export function removeLoggedUser() {
    localStorage.removeItem('logged_user');
};

export function getLoggedUserId() {
    const loggedUser = getLoggedUser();
    return loggedUser?.user?.id;
}

export function getSelectedItems(key) {
    return JSON.parse(localStorage.getItem(key));
}

export function setSelectedItems(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getUserSelectedItems(key) {
    const loggedUserId = getLoggedUserId();
    if (!loggedUserId) {
        return null;
    }
    const selectedItems = getSelectedItems(key);
    return selectedItems?.[loggedUserId];
}

export function setUserSelectedItems(key, value) {
    const loggedUserId = getLoggedUserId();
    if (!loggedUserId) {
        return null;
    }
    const selectedItems = getSelectedItems(key) || {};
    selectedItems[loggedUserId] = value;
    localStorage.setItem(key, JSON.stringify(selectedItems));
}

export function deleteUserSelectedItems(key) {
    const loggedUserId = getLoggedUserId();
    if(!loggedUserId) {
        return null;
    }
    const selectedItems = getSelectedItems(key) || {};
    delete selectedItems[loggedUserId];
    setSelectedItems(key, selectedItems);
};

const showLightningsKey = 'show_lightnings';
export function getUserSelectedShowLightnings() {
    return getUserSelectedItems(showLightningsKey);
};
export function setUserSelectedShowLightnings(value) {
    setUserSelectedItems(showLightningsKey, value);
};

const showAttentionPerimetersKey = 'show_attention_perimeters';
export function getUserSelectedShowAttentionPerimeters() {
    return getUserSelectedItems(showAttentionPerimetersKey);
};
export function setUserSelectedShowAttentionPerimeters(value) {
    setUserSelectedItems(showAttentionPerimetersKey, value);
};

function getSelectedRadars() {
    return JSON.parse(localStorage.getItem('selected_radars'));
}

export function getSelectedRadar() {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return null;
    }
    const selectedRadars = getSelectedRadars();
    return selectedRadars && selectedRadars[loggedUser.user.id];
};

export function setSelectedRadar(radarAlias) {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return ;
    }
    const selectedRadars = getSelectedRadars() || {};
    selectedRadars[loggedUser.user.id] = radarAlias;
    localStorage.setItem('selected_radars', JSON.stringify(selectedRadars));
};

function getSelectedSatelliteProductTypes() {
    return JSON.parse(localStorage.getItem('SATELLITE_PRODUCT_TYPES_ID'));
}

export function getSelectedSatelliteProductType() {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return null;
    }
    const selectedSatellitesProductType = getSelectedSatelliteProductTypes();
    return selectedSatellitesProductType && selectedSatellitesProductType[loggedUser.user.id];
};

export function setSelectedSatelliteProductType(satelliteProductTypeId) {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return ;
    }
    const selectedSatellitesProductType = getSelectedSatelliteProductTypes() || {};
    selectedSatellitesProductType[loggedUser.user.id] = satelliteProductTypeId;
    localStorage.setItem('SATELLITE_PRODUCT_TYPES_ID', JSON.stringify(selectedSatellitesProductType));
};
function getSelectedRadarsOrSatellites() {
    return JSON.parse(localStorage.getItem('selected_radars_or_satellites'));
}

export function getSelectedRadarsOrSatellite() {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return null;
    }
    const selectedRadarOrSatellites = getSelectedRadarsOrSatellites();
    return selectedRadarOrSatellites && selectedRadarOrSatellites[loggedUser.user.id];
};

export function setSelectedRadarsOrSatellite(radarOrSatelliteAlias) {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return ;
    }
    const selectedRadarOrSatellites = getSelectedRadarsOrSatellites() || {};
    selectedRadarOrSatellites[loggedUser.user.id] = radarOrSatelliteAlias;
    localStorage.setItem('selected_radars_or_satellites', JSON.stringify(selectedRadarOrSatellites));
};

function getSelectedSatellites() {
    return JSON.parse(localStorage.getItem('selected_satellites'));
}

export function getSelectedSatellite() {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return null;
    }
    const selectedSatellites = getSelectedSatellites();
    return selectedSatellites && selectedSatellites[loggedUser.user.id];
};

export function setSelectedSatellite(satelliteAlias) {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return ;
    }
    const selectedSatellites = getSelectedSatellites() || {};
    selectedSatellites[loggedUser.user.id] = satelliteAlias;
    localStorage.setItem('selected_satellites', JSON.stringify(selectedSatellites));
};

function getSavedStaticTypes() {
    return JSON.parse(localStorage.getItem('selected_static_position_types'));
}

export function setSelectedStaticPositionTypes(aliases) {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user || !aliases) {
        return ;
    }

    const selectedStaticTypes = getSavedStaticTypes() || {};

    selectedStaticTypes[loggedUser.user.id] = aliases;

    localStorage.setItem(
        'selected_static_position_types', 
        JSON.stringify(selectedStaticTypes),
    );
};

export function getSelectedStaticPositionTypes() {
    const loggedUser = getLoggedUser();
    if (!loggedUser || !loggedUser.user) {
        return null;
    }
    const selectedStaticTypes = getSavedStaticTypes();
    return (selectedStaticTypes && selectedStaticTypes[loggedUser.user.id]) || [];
};
