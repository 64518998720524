import React from 'react';

import './style.css';

function SettingTemplate({ label, defaultValue, setValue, placeholder, SettingType }) {
    return (<>
        <div className='input-label'>
            <span>{label}</span>
        </div>
        <SettingType
            defaultValue={defaultValue}
            setValue={setValue}
            placeholder={placeholder}
        />
    </>);
}

export default SettingTemplate;
