import React from 'react';

import EntityBox from '../auth/entity-box';
import EntityRolesHeader from '../auth/entity-roles-header';
import EntityRoles from '../auth/entity-roles';

import { useRoleBoxStates } from './states';

function RoleBox({ role }) {
    const {
        creating,
        isMaster,
        managePermissions,
        permissions,
        register,
        save,
        shouldSave,
        title,
        updatePermissions,
    } = useRoleBoxStates(role);
    
    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            {isMaster
                ? (<>
                    <span>{role.key}</span>
                    <span>{role.description}</span>
                </>)
                : (<>
                    <input
                        placeholder="Nome"
                        {...register('key')}
                    />
                    <input
                        placeholder="Descrição"
                        {...register('description')}
                    />
                </>)}
            {!isMaster && (<>
                <EntityRolesHeader
                    title="Permissões"
                    shouldSave={shouldSave}
                    saveFunction={save}
                />
                
                {creating
                    ? (
                        <span>Permissões só podem ser associadas após salvar o papel</span>
                    )
                    : (
                        <EntityRoles
                            entityRoles={permissions || []}
                            setEntityRoles={updatePermissions}
                            rolesList={managePermissions || []}
                        />
                    )
                }
            </>)}
        </EntityBox>
    );
}

export default RoleBox;
