import React from 'react';
import { Chart } from 'react-google-charts';

import { useApiData } from '../../contexts/api-data';

import './styles.css';

function StatisticsBox() {
    const {
        selectedForecast,
        selectedForecastRegion,
    } = useApiData();

    const { precipitation_accuracy } = {
        ...selectedForecast,
    };

    return (
        <div className="dashboard-box4">
            <div id="descricao-tit-4" className="db-titulo" style={{ height: 'auto' }}>
                Estatísticas - {selectedForecastRegion?.name || ''}
            </div>
            <div id="container3">
                {precipitation_accuracy && <Chart
                    chartType="PieChart"
                    data={[
                        ['Task', 'Acurária'],
                        ['Acerto', precipitation_accuracy],
                        ['Erro', 100 - precipitation_accuracy],
                    ]}
                    options={{
                        slices: { 0: { offset: 0.1 } },
                        pieSliceText: 'none',
                        pieStartAngle: 180,
                        width: '99%',
                        height: '99%',
                        legend: { position: 'none' },
                        colors: ["#104E8B", "#ff6347"],
                        chartArea: {
                            left: 0, right: 0, bottom: 1, top: 10,
                            width: '100%', height: '80%',
                        }
                    }}
                />}
            </div>
            <div className="desc-pizza">
                <div className="valores-pizza">
                    <div id="v-acerto">{precipitation_accuracy && `${precipitation_accuracy}%`}</div>
                    <div id="l-acerto">ACERTO</div>
                </div>
                <div className="valores-pizza">
                    <div id="v-erro">{precipitation_accuracy && `${100 - precipitation_accuracy}%`}</div>
                    <div id="l-erro">ERRO</div>
                </div>
            </div>
        </div>
    );
}

export default StatisticsBox;
