import { useCallback, useEffect, useState } from 'react';

import {
    getManageStations,
    getV2ManageStationByInstitutions,
} from '../../../../services/api';

const DEFAULT_PERIOD = 60 * 60000; // 60 min

export function useManageStationsState(systemSettingsState) {
    const {
        value: systemSettings,
        fetch: fetchSystemSettings,
    } = systemSettingsState;

    const [value, setValue] = useState(null);
    const [allStations, setAllStations] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSystemSettings();
    }, [fetchSystemSettings]);

    const identifiesDelayedStations = useCallback(async function(stations) {
        const now = Date.now();
        return stations?.map(station => {
            const institutionDelayPeriod = systemSettings?.STATION_DELAY_PERIODS?.find(
                delayPeriod => delayPeriod[0] === station.institution_id);
            const period = institutionDelayPeriod?.[1] || DEFAULT_PERIOD;
            station.delay = station.measures && now - station.measures?.datetime;
            station.is_delayed = station.measures ? station.delay > period : true;
            return station;
        });
    }, [systemSettings]);

    const fetch = useCallback(async function() {
        if (allStations || loading) {
            return;
        }
        setLoading(true);
        const stations = await getManageStations();
        await identifiesDelayedStations(stations);
        setAllStations(stations);
        setLoading(false);
    }, [allStations, identifiesDelayedStations, loading]);

    const fetchByInstitution = useCallback(
        async function (institutionId, filterByCity=true) {
            if (loading) {
                return;
            }
            setLoading(true);
            const stations = await getV2ManageStationByInstitutions(institutionId, filterByCity);
            await identifiesDelayedStations(stations);
            setValue(stations);
            setLoading(false);
        },
        [identifiesDelayedStations, loading],
    );

    const getById = useCallback((stationId) => {
        if (!allStations) {
            return;
        }
        return allStations.find(station => parseInt(station.id) === parseInt(stationId)) || null;
    }, [allStations]);

    return {
        allStations,
        identifiesDelayedStations,
        fetchByInstitution,
        fetch,
        getById,
        loading,
        value,
        setValue,
    };
};
