import { useState,useCallback, useRef } from 'react';
import { getRadarProducts } from '../../../../services/api';

export function useRadarProductsStates(){
    const [value, setValue] = useState([]);
    const loading = useRef(false);

    const fetch = useCallback(async function() {
        return await getRadarProducts();
    }, []);

    const fetchAndSave = useCallback(async function() {
        if (loading.current || value.length > 0) {
            return;
        }
        loading.current = true;
        setValue(await getRadarProducts());
        loading.current = false;
    }, [value]);

    return {
        fetch,
        fetchAndSave,
        value,
    };
}