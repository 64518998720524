import React from 'react';
import {
    FaExclamationCircle,
    FaTimes,
} from 'react-icons/fa';

import CopyButton from '../copy-button';
import { useApiData } from '../../contexts/api-data';
import useInterval from '../../hooks/use-interval';

import './styles.css';

function MessagesList({ shouldRetrieveMessages = true, close }) {
    const {
        realTimeMessages,
        retrieveRealTimeMessages,
    } = useApiData();

    useInterval(
        shouldRetrieveMessages ? retrieveRealTimeMessages : () => {},
        60000,
    );

    function getExtendedTime(datetime) {
        const date = new Date(datetime);
        return `${date.toLocaleTimeString().substring(0, 5)}`;
    }

    function messageWarning(expiry_datetime) {
        return `\n(Aviso válido até ${getExtendedTime(expiry_datetime)})`;
    }

    function formattedMessage(text, expiry_datetime) {
        return text + messageWarning(expiry_datetime);
    }

    return (
        <>
            <div className="db-titulo messages-list-title">
                <span></span>
                Mensagens
                <span>
                    {close && (
                        <span className="messages-list-close">
                            <FaTimes onClick={close} />
                        </span>
                    )}
                </span>
            </div>
            <ul
                className="list-group real-time-messages-list"
                style={{
                    overflowY: 'auto',
                    // height: '100%',
                    margin: '2px !important',
                }}
            >
                {realTimeMessages && realTimeMessages.map(({
                    id: messageId,
                    text: messageText,
                    datetime,
                    expiry_datetime,
                }) => (
                    <li
                        key={messageId}
                        className="list-group-item li-notif"
                        style={{ padding: '4%' }}
                    >
                        <FaExclamationCircle className="exc" />
                        <b>{getExtendedTime(datetime)}</b>
                        <br />
                        <pre
                            id={`msg-txt-${messageId}`}
                            className="message-content"
                        >
                            {formattedMessage(messageText, expiry_datetime)}
                        </pre>
                        &nbsp;
                        <CopyButton value={messageText} />
                    </li>
                ))}
            </ul>
        </>
    );
}

export default MessagesList;
