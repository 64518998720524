import { useEffect } from 'react';

export function useTerritoriesState({
    manageStates: {
        fetch: fetchManageStates,
    },
}) {
    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);
};
