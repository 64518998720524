import React from 'react';

import BasinPrecipitationStageBox from '../precipitation-stage-box';
import NewBtn from '../../common/new-btn';
import { useApiData } from '../../../../contexts/api-data';

function BasinPrecipitationStagesList() {
    const {
        manageBasinPrecipitationStages: {
            add,
            useFetchAndSocket,
            value: precipitationStages,
        },
    } = useApiData();

    useFetchAndSocket();

    return (<>
        <div className="basins-list-header">
            <h3>Estágios de chuva para Bacias</h3>
            <NewBtn
                newFunction={add}
                label="Novo"
            />
        </div>
        {precipitationStages && precipitationStages.map(stage => (
            <BasinPrecipitationStageBox
                key={stage.id}
                stage={stage}
            />
        ))}
    </>);
}

export default BasinPrecipitationStagesList;
