import React, { useRef } from 'react';
import { Popup } from 'reactjs-popup';

import { useMultipleSelectionInputState } from './states';

import './styles.css';

function MultipleSelectionInput(props) {
    const {
        inputId,
        disabled,
    } = props;
    const {
        nullOptionLabel,
        options,
        selectAll,
        selectedItems,
        selectedValues,
        selectOption,
    } = useMultipleSelectionInputState(props);

    // const [checkboxesStyle, setCheckboxesStyle] = useState({});
    const checkboxesStyle = {};

    const multipleSelectionInput = useRef(null);

    // useEffect(() => {
    //     if (!multipleSelectionInput.current) {
    //         return ;
    //     }
    //     setCheckboxesStyle({
    //         width: `${multipleSelectionInput.current.offsetWidth}px`
    //     });
    // }, [multipleSelectionInput]);

    return (
        <div ref={multipleSelectionInput} className="multiple-selection-input">
            <Popup
                closeOnDocumentClick={true}
                closeOnEscape={true}
                disabled={disabled}
                className="multiple-selection-checkboxes"
                position="bottom left"
                // TODO: testar essa propriedade com uma caixa não fixa que fique abaixo do select
                // keepTooltipInside=".multiple-selection-input"
                arrow={false}
                trigger={
                    <div className="select-box">
                        <select
                            id={inputId}
                            disabled={disabled}
                        >
                            <option>{nullOptionLabel}</option>
                        </select>
                        <div className="over-select"></div>
                    </div>
                }
            >
                <div className="checkboxes" style={checkboxesStyle}>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectedItems.length === options.length}
                            onChange={selectAll}
                        />
                        Selecionar tudo
                    </label>
                    {options?.length > 0 && options.map(option => {
                        const { label, value } = option;
                        const key = `${inputId || ''}-multi-selection-input-option-${label}-${value}`;
                        return (
                            <label key={key}>
                                <input
                                    type="checkbox"
                                    checked={selectedValues.includes(value)}
                                    onChange={() => selectOption(option)}
                                />
                                {label}
                            </label>
                        );
                    })}
                </div>
            </Popup>
        </div>
    );
}

export default MultipleSelectionInput;
