import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useBasinBoxStates } from './states';

import './styles.css';

function BasinBox({ basin }) {
    const {
        creating,
        register,
        save,
        shouldSave,
        title,
    } = useBasinBoxStates(basin);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="basin-box-form">
                <div className="form-group">
                    <label>Nome</label>
                    <input
                        {...register('name')}
                    />
                </div>
                <div className="form-group">
                    <label>Identificador</label>
                    <input
                        {...register('alias')}
                    />
                </div>
                <label className="checkbox-group">
                    <input
                        type="checkbox"
                        {...register('inactive')}
                    />
                    Desabilitar
                </label>
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default BasinBox;
