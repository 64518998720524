import React from 'react';
import { AiFillCopy } from 'react-icons/ai';

import { Link } from 'react-router-dom';

import EntityBox from '../auth/entity-box';
import EntityRolesHeader from '../auth/entity-roles-header';
import EntityRoles from '../auth/entity-roles';

import { CustomerSelectionInput } from '../../common/custom-inputs';

import { useUserBoxState } from './states';

import './styles.css';

function UserBox({ user, rolesList }) {

    const {
        botWhatsappUser,
        changeBotWhatsappUser,
        creating,
        email,
        getExtraUserInfo,
        inactive,
        initialBotWhatsappUser,
        inviteCode,
        inviteUser,
        name,
        saveUser,
        setCustomerId,
        setEmail,
        setInactive,
        setName,
        setUserRoles,
        shouldSave,
        title,
        titleIcon,
        updatedUser,
        userRoles,
    } = useUserBoxState({ user });

    function copyInviteCode() {
        const inviteUrl = `${window.location.origin}/register?code=${inviteCode}`;
        navigator.clipboard.writeText(inviteUrl)
    }

    const formatPhoneNumber = () => {
        const rawPhoneNumber = botWhatsappUser.phone_number;
        let number = '';
        if(rawPhoneNumber){
            if (rawPhoneNumber.length <= 2) {
                number = `+${rawPhoneNumber}`;
            } else if (rawPhoneNumber.length <= 4) {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2)}`;
            } else if (rawPhoneNumber.length <= 9) {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2, 4)}) ${rawPhoneNumber.slice(4)}`;
            } else {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2, 4)}) ${rawPhoneNumber.slice(4, 9)}-${rawPhoneNumber.slice(9)}`;
            }
        }
        return number;
    };

    const postBackendPhoneNumber = (event) => {
        const number = event.target.value
        // Remover caracteres não numéricos do input
        const rawPhoneNumber = number.replace(/\D/g, '');
        // Remover o caractere '+' do início, se existir
        return rawPhoneNumber.startsWith('+') ? rawPhoneNumber.slice(1) : rawPhoneNumber;
    };

    return (
        <EntityBox
            creating={creating}
            title={title}
            titleIcon={titleIcon}
            onOpen={getExtraUserInfo}
        >
            <div className="user-box-form">
                <div className="form-group">
                    <label htmlFor={`user-name-${updatedUser.id}`}>
                        Nome e Sobrenome
                    </label>
                    <input
                        id={`user-name-${user.id}`}
                        value={(name || '')}
                        placeholder="Nome (opcional)"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={40}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`user-email-${updatedUser.id}`}>
                        E-mail
                    </label>
                    <input
                        id={`user-email-${user.id}`}
                        value={email}
                        placeholder="E-mail"
                        onChange={(event) => setEmail(event.target.value)}
                        maxLength={70}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`user-inactive-${user.id}`}>Ativo</label>
                    <input
                        id={`user-inactive-${user.id}`}
                        type="checkbox"
                        defaultChecked={!inactive}
                        onChange={(event) => setInactive(!event.target.checked)}
                    />
                </div>
            </div>

            <h5>Cliente</h5>
            <CustomerSelectionInput
                defaultValue={updatedUser.customer_id}
                setValue={(value) => setCustomerId(Number(value))}
                isNullable={false}
            />

            <h5>Notificações</h5>

            <div className="telegram-user-container">
                <div className="form-group">
                    <label htmlFor={`bot-whtasapp-user-phoneNumer-${user.id}`}>
                        Número de telefone no Whatsapp
                    </label>
                    <input
                        type="text"
                        id={`bot-whtasapp-user-phoneNumer-${user.id}`}
                        defaultValue={formatPhoneNumber()}
                        onChange={(event) => 
                            changeBotWhatsappUser({
                                phone_number: postBackendPhoneNumber(event)
                            })
                        }
                        maxLength={19}
                        placeholder="+00 (00) 00000-0000"
                    />
                </div>

                {initialBotWhatsappUser.current.phone_number?.length >= 0 && (
                    <div className="form-group checkbox-input">
                        <label htmlFor={`bot-whatsapp-user-active-${user.id}`}>
                            Ativo
                        </label>
                        <input
                            type="checkbox"
                            id={`bot-whatsapp-user-active-${user.id}`}
                            defaultChecked={!botWhatsappUser.inactive}
                            onChange={(event) =>
                                changeBotWhatsappUser({
                                    inactive: !event.target.checked,
                                })
                            }
                        />
                    </div>
                )}
            </div>

            <EntityRolesHeader
                title="Papéis *"
                shouldSave={shouldSave}
                saveFunction={saveUser}
                feedback={'* Papéis associados somente ao usuário'}
            />

            <EntityRoles
                entityRoles={userRoles}
                setEntityRoles={setUserRoles}
                rolesList={rolesList}
            />

            {!updatedUser.password_set && updatedUser.email && (
                <Link
                    to="#"
                    className={`invite-user-btn`}
                    onClick={inviteUser}
                >
                    Convidar
                </Link>
            )}

            {!updatedUser.password_set && inviteCode && (
                <Link
                    to="#"
                    className={`link-copy-btn`}
                    onClick={copyInviteCode}
                >
                    <AiFillCopy /> Link de Registro
                </Link>
            )}

        </EntityBox>
    );
}

export default UserBox;
