import baseApi from '../../base';

const baseResource = '/detailed-forecasts'

export async function getDetailedForecasts(forecast_region_id) {
    const result = await baseApi.get(`${baseResource}?forecast_region_id=${forecast_region_id}`);
    return result.data;
};

export async function postDetailedForecasts(detailedForecasts) {
    const result = await baseApi.post(baseResource, detailedForecasts);
    return result.data;
};
