import React from 'react';

import SearchForm from './components/search-form';
import StationMeasureWithSensorBox from '../station-measure-with-sensor-box';
import Loading from '../../../loading';

import { useMeasuresWithSensorListStates } from './states';

import './styles.css';

function StationMeasuresWithSensorList() {
    const {
        broadcastChannels,
        fetchMeasures,
        loadingMeasures,
        phoneOperators,
        selectedStation,
        stationMeasures,
        stations,
        institutionId,
    } = useMeasuresWithSensorListStates();

    return (<>
        <div className="header">
            <h3> Leituras dos sensores</h3>
        </div>
        <div className="list-filter">
            <SearchForm
                fetchMeasures={fetchMeasures}
                loadingMeasures={loadingMeasures}
                stations={stations}
            />

            {loadingMeasures && (
                <Loading size={32} color="#888" />
            )}
        </div>

        {stationMeasures && stationMeasures.map(stationMeasure => {
            if (stationMeasure?.sensorDatetimes?.datetime_received) {
                return (
                    <StationMeasureWithSensorBox                    
                        key={`station-measure-${stationMeasure.sensorDatetimes.id}`}
                        selectedStation={selectedStation}
                        stationMeasure={stationMeasure}
                        phoneOperators={phoneOperators}
                        broadcastChannels={broadcastChannels}
                        institutionId={institutionId}
                    />
                );
            }
            return null;
        })}
    </>);
}

export default StationMeasuresWithSensorList;
