import baseApi from '../../base';

export async function getSatellites() {
    const result = await baseApi.get('/satellites');
    return result.data;
};

export async function postSatellites(satellite) {
    const result = await baseApi.post('/manage/satellites', satellite);
    return result.data;
};

export async function putSatellites(updatedSatellite, satellite) {
    const result = await baseApi.put(`/manage/satellites/${updatedSatellite.id}`, satellite);
    return result.data;
};