import React, { useEffect } from 'react';

import EntityCard from '../../../common/entity-card';
import ForecastModelDownloadPopup from '../model-download-popup';

import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function getPeriodObject(period) {
    const oneHour = 60 * 60 * 1000;
    const oneDay = 24 * oneHour;
    const hours = period / oneHour;
    const days = period / oneDay;
    return {
        period,
        hours,
        days,
    };
}

function ForecastModelCard({ forecastModel }) {
    const {
        forecastsRunDatetimesByModel: {
            fetch: fetchForecastsRunDatetimes,
        },
    } = useApiData();

    useEffect(() => {
        if (!forecastModel?.id) {
            return;
        }
        fetchForecastsRunDatetimes(forecastModel.id);
    }, [fetchForecastsRunDatetimes, forecastModel]);

    const periodLabels = [
        ['max_data_period', 'Maior quantidade de dados'],
        ['data_frequency', 'Frequência dos dados'],
    ].map(([key, initialLabel]) => {
        const {
            hours,
            days,
        } = getPeriodObject(Number(forecastModel[key]));
        const description = `${initialLabel}:`;
        const daysLabel = days > 1 ? ` (${days} dias)` : ''
        const value = `${hours} horas${daysLabel}`;
        return {
            key,
            description,
            value,
        };
    });
    
    const last_run = Number(forecastModel.last_run || 0);
    const lastRun = last_run > 0 ? new Date(last_run) : '-';

    return (
        <EntityCard title={forecastModel.name}>
            {periodLabels.map(({ key, description, value }) => (
                <div
                    key={key}
                    className="forecast-model-property"
                >
                    <span className="description">{description}</span>
                    <span>{value}</span>
                </div>
            ))}
            <div className="forecast-model-property">
                <span className="description">Última rodada:</span>
                <span>{lastRun.toLocaleString()}</span>
            </div>
            <ForecastModelDownloadPopup model={forecastModel} />
        </EntityCard>
    );
}

export default ForecastModelCard;
