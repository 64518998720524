import React from 'react';

import {
    FakeSelectionInput,
    CitySelectionInput,
    StationConfigurationSelectionInput,
    StationProtocolSelectionInput,
    MultipleSensorsSelectionInput,
    BasinSelectionInput,
} from '../../../common/custom-inputs';
import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useStationBoxStates } from './states';

import './styles.css';
import StationExtraDataBox from '../../stations/station-extra-data-box';

function StationBox({ station, institution_id }) {
    const {
        creating,
        address,
        alias,
        basin_id,
        calibration,
        city,
        code,
        configuration_id,
        description,
        extraData,
        formatDatetimeInstalled,
        inactive,
        is_weather,
        latitude,
        longitude,
        name,
        neighborhood_id,
        neighborhoods,
        stationSensors,
        stationNameInputId,
        stationCodeInputId,
        stationAliasInputId,
        stationBasinInputId,
        stationLatitudeInputId,
        stationLongitudeInputId,
        stationSensorsInputId,
        stationCityInputId,
        stationNeighborhoodInputId,
        stationAddressInputId,
        stationTypeInputId,
        stationDatetimeInstalledInputId,
        stationProtocolInputId,
        stationConfigurationInputId,
        stationTimeFrequencyInputId,
        stationDescriptionInputId,
        stationCalibrationInputId,
        stationInactiveInputId,
        protocol_id,
        time_frequency,
        title,
        openFunction,
        selectCity,
        setAddress,
        setAlias,
        setBasinId,
        setCalibration,
        setCode,
        setConfigurationId,
        setDescription,
        setFormatDatetimeInstalled,
        setInactive,
        setIsWeather,
        setLatitude,
        setLongitude,        
        setName,
        setNeighborhoodId,
        setProtocolId,
        setStationSensors,
        setTimeFrequency,
        saveStation,
        shouldSave,
        updateExtraData,
    } = useStationBoxStates(station, institution_id);

    return (
        <EntityBox
            creating={creating}
            title={title}
            onOpen={openFunction}
        >
            <div className="entity-box-form station-box-form">
                <div className="form-group">
                    <label htmlFor={stationNameInputId}>*Nome</label>
                    <input
                        id={stationNameInputId}
                        value={name}
                        type="text"
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={60}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationCodeInputId}>*Código</label>
                    <input
                        id={stationCodeInputId}
                        value={code}
                        type="text"
                        placeholder="Código"
                        onChange={(event) => setCode(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationAliasInputId}>Identificador</label>
                    <input
                        id={stationAliasInputId}
                        value={alias}
                        type="text"
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationLatitudeInputId}>*Latitude</label>
                    <input
                        id={stationLatitudeInputId}
                        type="number"
                        value={latitude || ''}
                        onChange={(event) => setLatitude(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationLongitudeInputId}>*Longitude</label>
                    <input
                        id={stationLongitudeInputId}
                        type="number"
                        value={longitude || ''}
                        onChange={(event) => setLongitude(event.target.value)}
                    />
                </div>


                <div className="form-group">
                    <span className={stationSensorsInputId}>Sensores</span>
                    <MultipleSensorsSelectionInput
                        defaultValue={stationSensors}
                        setValue={setStationSensors}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationCityInputId}>*Cidade</label>
                    {city && <CitySelectionInput
                        defaultCityOption={{ value: '0', label: 'Nulo' }}
                        defaultStateOption={{ value: '0', label: 'Estado' }}
                        defaultValue={[city?.state_id, city?.id]}
                        isStateNullable={true}
                        selectCity={selectCity}
                        inputId={stationCityInputId}
                    />}
                </div>

                <div className="form-group">
                    <label htmlFor={stationNeighborhoodInputId}>Bairro</label>
                    <select
                        id={stationNeighborhoodInputId}
                        value={neighborhood_id || 0}
                        onChange={(event) => {
                            const newNeighborhoodId = Number(event.target.value);
                            setNeighborhoodId(newNeighborhoodId || null);
                        }}
                    >
                        <option value="0">Nulo</option>
                        {neighborhoods.map(({ id, name }) => (
                            <option
                                key={id}
                                value={id}
                            >
                                {name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor={stationAddressInputId}>Localização</label>
                    <input
                        id={stationAddressInputId}
                        value={address}
                        type="text"
                        onChange={(event) => setAddress(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationTypeInputId}>Tipo</label>
                    <FakeSelectionInput
                        optionLabels={["Meteorológica", "Pluviométrica"]}
                        inputId={stationTypeInputId}
                        booleanVar={is_weather}
                        setValue={setIsWeather}
                    />
                </div>

                <div className="form-group">
                   <label htmlFor={stationDatetimeInstalledInputId}>
                        Data de Instalação
                    </label>
                   <input
                       id={stationDatetimeInstalledInputId}
                       type="date"
                       value={formatDatetimeInstalled}
                       onChange={(event) => setFormatDatetimeInstalled(event.target.value)}                       
                   />
               </div>

               <div className="form-group">
                    <label htmlFor={stationProtocolInputId}>Protocolo</label>
                    <StationProtocolSelectionInput
                        defaultValue={protocol_id}
                        setValue={setProtocolId}
                        inputId={stationProtocolInputId}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationConfigurationInputId}>Configuração</label>
                    <StationConfigurationSelectionInput
                        defaultValue={configuration_id}
                        setValue={setConfigurationId}
                        inputId={stationConfigurationInputId}
                    />
                </div>
                
                <div className="form-group">
                    <label htmlFor={stationTimeFrequencyInputId}>*Frequência dos dados (min)</label>
                    <input
                        id={stationTimeFrequencyInputId}
                        type="number"
                        value={time_frequency || ''}
                        onChange={(event) => setTimeFrequency(Number(event.target.value))}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={stationBasinInputId}>Bacia</label>
                    <BasinSelectionInput
                        defaultValue={basin_id}
                        setValue={setBasinId}
                        inputId={stationBasinInputId}
                        isNullable={true}
                    />
                </div>

                <div className="form-group desc-input">
                    <label htmlFor={stationDescriptionInputId}>Descrição</label>
                    <textarea
                        className="textarea"
                        rows={2}
                        id={stationDescriptionInputId}
                        value={description}
                        placeholder="Digite uma breve descrição da estação"
                        onChange={event => setDescription(event.target.value)}
                    ></textarea>
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={stationCalibrationInputId}>Aferição</label>
                    <input
                        id={stationCalibrationInputId}
                        type="checkbox"
                        checked={calibration}
                        onChange={(event) => setCalibration(event.target.checked)}
                    />

                    <label htmlFor={stationInactiveInputId}>Desabilitar</label>
                    <input
                        id={stationInactiveInputId}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <StationExtraDataBox
                    stationId={station.id}
                    extraData={extraData}
                    updateExtraData={updateExtraData}
                />

                <PostBtn
                    postFunction={saveStation}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default StationBox;
