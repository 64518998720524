import { useState, useEffect } from 'react';
import { useAuth } from '../../../auth';

export default function useMapPositionState() {
    const { settingsSet } = useAuth();

    const {
        INITIAL_MAP_POSITION,
        INITIAL_MAP_ZOOM,
    } = { ...settingsSet };

    const [mapPosition, setMapPosition] = useState({
        initialLatitude: null,
        initialLongitude: null,
        initialMapZoom: 9,
    });

    useEffect(() => {
        const initialMapZoom = Number(INITIAL_MAP_ZOOM);
        const [initialLatitude, initialLongitude] = INITIAL_MAP_POSITION
            ? INITIAL_MAP_POSITION.split(',').map(Number)
            : [null, null];

        setMapPosition({ initialLatitude, initialLongitude, initialMapZoom });
    }, [INITIAL_MAP_POSITION, INITIAL_MAP_ZOOM]);

    return (mapPosition);
}
