import React, { useEffect } from 'react';

import EntityBox from '../../auth/entity-box';
import ForecastModelDownloadPopup from '../model-download-popup';
import { useApiData } from '../../../../contexts/api-data';

function getPeriodObject(period) {
    const oneHour = 60 * 60 * 1000;
    const oneDay = 24 * oneHour;
    const hours = period / oneHour;
    const days = period / oneDay;
    return {
        period,
        hours,
        days,
    };
}

function ForecastModelBox({ forecastModel }) {
    const {
        forecastsRunDatetimesByModel: {
            fetch: fetchForecastsRunDatetimes,
        },
    } = useApiData();

    useEffect(() => {
        if (!forecastModel?.id) {
            return;
        }
        fetchForecastsRunDatetimes(forecastModel.id);
    }, [fetchForecastsRunDatetimes, forecastModel]);

    const periodLabels = [
        ['max_data_period', 'Maior quantidade de dados'],
        ['data_frequency', 'Frequência dos dados'],
        ['chart_frequency', 'Intervalo do gráfico'],
    ].map(([key, initialLabel]) => {
        const {
            hours,
            days,
        } = getPeriodObject(Number(forecastModel[key]));
        const daysLabel = days > 1 ? ` (${days} dias)` : ''
        const label = `${initialLabel}: ${hours} horas${daysLabel}`;
        return {
            key,
            label,
        };
    });
    
    const last_run = Number(forecastModel.last_run);
    const lastRun = last_run > 0 ? new Date(last_run) : '-';

    return (
        <EntityBox
            creating={false}
            title={forecastModel.name}
            alwaysOpen={true}
        >
            <span>Id: {forecastModel.id}</span>
            <span>Nome: {forecastModel.name}</span>
            <span>Identificador: {forecastModel.alias}</span>
            <span>Tamanho do grid: {forecastModel.grid_size}</span>
            {periodLabels.map(({ key, label }) => (
                <span key={key}>{label}</span>
            ))}
            <span>Última rodada: {lastRun.toLocaleString()}</span>
            <ForecastModelDownloadPopup
                model={forecastModel}
            />
        </EntityBox>
    );
}

export default ForecastModelBox;
