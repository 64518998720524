import React from 'react';
import {
    FiArrowUpCircle,
} from 'react-icons/fi';

import DashboardBox from '../../../../components/dashboard-box';
import ActionIconBtn from '../../../../components/common/btn/action-icon-btn';

import { useBasinsStagesStates } from './states';

import './styles.css';

function BasinsStages() {
    const {
        basins,
        basinsStages,
        precipitationStages,
        slipStages,
        changeStage,
        saveStages,
        shouldSaveStages,
    } = useBasinsStagesStates();

    return (
        <div className="basins-stages-operation-page">
            <h3>Mudança de estágios das Bacias</h3>

            <DashboardBox title="Condições de Chuva">
                <table className="stages-table">
                    <thead>
                        <tr>
                            <th>Bacia</th>
                            <th colSpan={precipitationStages?.length}>Estágio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {basins?.map(({
                            id: basinId,
                            name,
                            precipitation_stage_id: oldStageId,
                        }) => {
                            const newStageId = basinsStages?.find(({ id }) => basinId === id)?.precipitation_stage_id;
                            return (
                                <tr key={`prec-basin-${basinId}-row`}>
                                    <td>{name}</td>
                                    {precipitationStages?.map(({
                                        id,
                                        description,
                                        color,
                                    }) => {
                                        const selected = id === oldStageId;
                                        const opacity = selected ? '' : (id === newStageId ? 'B5' : '60');
                                        const backgroundColor = color ? `${color}${opacity}` : null;
                                        return (
                                            <td
                                                key={`prec-basin-${basinId}-stage-${id}`}
                                                className="stage-cell"
                                                style={{ width: `${80/precipitationStages.length}%` }}
                                                onClick={() => changeStage(basinId, { precipitation_stage_id: id })}
                                            >
                                                <span
                                                    className={`stage ${selected ? 'selected' : ''}`}
                                                    style={{
                                                        backgroundColor,
                                                    }}
                                                >
                                                    {description}
                                                </span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </DashboardBox>

            <DashboardBox title="Probabilidade de Escorregamento">
                <table className="stages-table">
                    <thead>
                        <tr>
                            <th>Bacia</th>
                            <th colSpan={slipStages?.length}>Estágio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {basins?.map(({
                            id: basinId,
                            name,
                            slip_stage_id: oldStageId,
                        }) => {
                            const newStageId = basinsStages?.find(({ id }) => basinId === id)?.slip_stage_id;
                            return (
                                <tr key={`slip-basin-${basinId}-row`}>
                                    <td>{name}</td>
                                    {slipStages?.map(({
                                        id,
                                        description,
                                        color,
                                    }) => {
                                        const selected = id === oldStageId;
                                        const opacity = selected ? '' : (id === newStageId ? 'B5' : '60');
                                        const backgroundColor = color ? `${color}${opacity}` : null;
                                        return (
                                            <td
                                                key={`slip-basin-${basinId}-stage-${id}`}
                                                className="stage-cell"
                                                style={{ width: `${80/slipStages.length}%` }}
                                                onClick={() => changeStage(basinId, { slip_stage_id: id })}
                                            >
                                                <span
                                                    className={`stage ${selected ? 'selected' : ''}`}
                                                    style={{
                                                        backgroundColor,
                                                    }}
                                                >
                                                    {description}
                                                </span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </DashboardBox>

            <ActionIconBtn
                disabled={!shouldSaveStages()}
                action={saveStages}
                label="Publicar"
                icon={FiArrowUpCircle}
                className="main-btn"
            />
        </div>
    );
}

export default BasinsStages;
