import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../../contexts/api-data';

export function useRadioBoxStates(radio) {
    const {
        manageRadios: {
            save: saveRadio,
        },
    } = useApiData();

    const { register, watch } = useForm({
        defaultValues: {
            name: radio.name || '',
            ip: radio.ip || '',
        },
    });
    const name = watch('name');
    const ip = watch('ip');
    const creating = !radio.name;
    const title = useMemo(() => name || '', [name]);

    function shouldSave() {
        const nameSet = name.length > 0;
        if (creating) {
            return nameSet;
        }
        const nameChanged = name !== radio.name;
        const ipChanged = ip !== radio.ip;
        return nameSet
            && (nameChanged || ipChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = radio;
            const radioToSend = {
                name,
                ip,
            };
            if (!creating) {
                radioToSend.id = id;
            }
            saveRadio(id, radioToSend);
        }
    }

    return {
        creating,
        register,
        save,
        shouldSave,
        title,
    };
};
