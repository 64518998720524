import React, { useEffect } from 'react';

import RoleBox from '../role-box';
import NewBtn from '../common/new-btn';
import { useApiData } from '../../../contexts/api-data';

import './styles.css';

function RolesList() {
    const {
        manageRoles: {
            add: addNewRole,
            fetch: fetchRoles,
            value: roles,
        },
        managePermissions: {
            fetch: fetchPermissions,
        },
    } = useApiData();

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    return (<>
        <div className="roles-list-header">
            <h3>Papéis</h3>
            <NewBtn
                newFunction={addNewRole}
            />
        </div>
        {roles && roles.map(role => (
            <RoleBox
                key={role.id}
                role={role}
            />
        ))}
    </>);
}

export default RolesList;
