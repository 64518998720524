import { toast } from 'react-toastify';

const { REACT_APP_DEV_DEBUG } = process.env;

export function logDebug(contentText) {
    if (REACT_APP_DEV_DEBUG) {
        toast(contentText, {
            type: 'default',
            autoClose: false,
            position: 'bottom-left',
            style: {
                overflow: 'unset',
                overflowWrap: 'anywhere',
            },
        });
    }
};
