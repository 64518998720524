import baseApi from '../../base';

export async function getManageForecastRegions() {
    const result = await baseApi.get('/manage/forecast-regions');
    return result.data;
};

export async function postManageForecastRegions(region) {
    const result = await baseApi.post('/manage/forecast-regions', region);
    return result.data;
};

export async function putManageForecastRegions(region) {
    const result = await baseApi.put(`/manage/forecast-regions/${region.id}`, region);
    return result.data;
};
