import { useEffect } from 'react';
import { useApiData } from '../../../../contexts/api-data';

export function useSatelliteSelectionInputState() {
    const { 
        satellites : {
            fetchAndSave,
            value: satellites
        }
     } = useApiData();

    useEffect(() => {
        fetchAndSave();
    }, [fetchAndSave]);

    const satelliteOptions = satellites.map(({ name, alias }) => {
        return {
            label: name,
            value: alias,
        }
    });

    return satelliteOptions;
};
