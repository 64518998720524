import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaCircle } from 'react-icons/fa';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { useApiData } from '../../contexts/api-data';

import './styles.css';

function LegendTable({ items, satelliteAlias, unit }) {
    const classUnit = unit === '°C' ? 'C' : 'P'
    return (
        <label className="tabLegenda">
            <input type="checkbox" /> 
            <div className="legenda-titulo"> Satélite ({satelliteAlias}) </div>
            {items.map(item => (
                <div key={item.value} className="legenda-item">
                    <div className="legenda-simb">
                        <FaCircle
                            className={`${satelliteAlias}_${item.value}${classUnit}`}
                        />
                    </div>
                    <div className="legenda-desc">
                        {item.greater && '> '}{item.value}{unit}
                        {item.description && ` - ${item.description}`}
                    </div>
                </div>
            ))}
        </label>
    );
}

function createLegendDiv(items, satelliteAlias, unit) {
    const div = L.DomUtil.create('div', 'info legend');
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <LegendTable
            items={items}
            satelliteAlias={satelliteAlias}
            unit={unit}
        />
    );
    return div;
}

const infraredItems = [
    {value: -10, greater: true},
    {value: -20},
    {value: -30},
    {value: -40},
    {value: -50},
    {value: -55},
    {value: -60},
    {value: -65},
    {value: -70},
    {value: -80},
    {value: -90},
];

const infraredLegend = L.control({ position: 'topright' });
infraredLegend.onAdd = () => createLegendDiv(infraredItems, 'Infravermelho', '°C');

const fogItems = [
    { value: 80, description: 'Fraco' },
    { value: 85, description: '' },
    { value: 90, description: '' },
    { value: 95, description: 'Forte', greater: true },
];

const fogLegend = L.control({ position: 'topright' });
fogLegend.onAdd = () => createLegendDiv(fogItems, 'Nevoeiro', '%');

const legends = {
    irc: infraredLegend,
    irf: infraredLegend,
    fog: fogLegend,
};

function SatelliteLegend() {
    const apiData = useApiData();
    const map = useMap();
  
    const {
        selectSatellites
    } = apiData

    useEffect(() => {
        map.removeControl(infraredLegend);
        map.removeControl(fogLegend);

        if (selectSatellites.selectedSatelliteProductType) {
            const productTypeAlias = selectSatellites.selectedSatelliteProductType.alias;
            const legend = legends[productTypeAlias];
            if (legend) {
                legend.addTo(map);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectSatellites]);

    return null;
}

export default SatelliteLegend;
