import React from 'react';

import { useRadarLastTelemetryMeasuresStates } from './states';

import './styles.css';

function RadarLastTelemetryMeasures({ radarId }) {
    const {
        radiosWithMeasures,
    } = useRadarLastTelemetryMeasuresStates({ radarId });

    return (
        <div className="radar-info-box">
            <h4>Tensão de Bateria</h4>
            <div className="battery-voltage">
                {radiosWithMeasures?.map(({ radio, measure }) => measure
                    ? (
                        <span key={radio.id}>
                            <b>{radio.name}:</b> {Math.floor(measure.battery_voltage*10)/10}
                        </span>
                    )
                    : null
                )}
            </div>
        </div>
    );
}

export default RadarLastTelemetryMeasures;
