import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useSatelliteSelectionInputState } from './states';

function MultipleSatelliteSelectionInput({ defaultValue, setValue }) {
    const satelliteSelectionOptions = useSatelliteSelectionInputState();
    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={satelliteSelectionOptions}
        />
    );
}

export default MultipleSatelliteSelectionInput;
