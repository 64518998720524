import { useEffect, useState } from 'react';

import { useApiData } from '../../../../contexts/api-data';

import datetimeUtils from '../../../../utils/datetime';

export function useStationDelayPeriodsInputStates({ defaultValue, setValue }) {
    const {
        manageStationInstitutions: {
            fetch: fetchStationInstitutions,
            value: stationInstitutions,
            loading: loadingInstitutions,
        },
    } = useApiData();

    const [stationInstitution, setStationInstitution] = useState(defaultValue?.[0]);
    const [delayPeriod, setDelayPeriod] = useState(datetimeUtils.msToMinutes(defaultValue?.[1]));

    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    function updateValue(newStationInstitutionId, newDelayPeriod) {
        if (newStationInstitutionId !== stationInstitution) {
            setStationInstitution(newStationInstitutionId);
        }
        if (newDelayPeriod !== delayPeriod) {
            setDelayPeriod(newDelayPeriod);
        }
        if (!newStationInstitutionId || !newDelayPeriod) {
            return;
        }
        setValue([Number(newStationInstitutionId), datetimeUtils.minutesToMs(newDelayPeriod)]);
    }

    const stationInstitutionsOptions = stationInstitutions?.map(
        ({ id: value, name: label }) => ({ value, label })
    );

    return {
        delayPeriod,
        stationInstitutionsOptions,
        loadingInstitutions,
        setStationInstitution,
        stationInstitution,
        updateValue,
    };
};
