import { useEffect } from 'react';
import { useApiData } from '../../../../contexts/api-data';

export function useSatelliteProductTypesSelectionInputState() {
    const { 
        satellitesProductTypes : {
            fetchAndSave,
            value: satelliteProductType,
        }
    } = useApiData();

    useEffect(() => {
        fetchAndSave();
    }, [fetchAndSave]);

    const stationsProductTypesOptions = satelliteProductType.map(({ name, id }) => {
        return {
            label: name,
            value: id.toString(),
        }
    });

    return stationsProductTypesOptions;
};
