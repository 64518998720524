import React from 'react';

import PrecipitationTable from './components/precipitation-table';
import WeatherTable from './components/weather-table';
import ImpactWaysTable from './components/impact-ways-table';
import RadarBox from './components/radar-box';
import BasinsStages from '../../components/basins-stages';

import { useStationsDashboardState } from './states';

import './styles.css';

function StationsDashboard() {
    const {
        institutionId,
        stations,
    } = useStationsDashboardState();

    return (
        <div className="stations-dashboard-page">
            <BasinsStages />
            
            <PrecipitationTable
                stations={stations}
            />

            <WeatherTable
                stations={stations}
            />

            <div className="impact-ways-radar-container">
                <ImpactWaysTable
                    institutionId={institutionId}
                    stations={stations}
                />
                <RadarBox />
            </div>
        </div>
    );
}

export default StationsDashboard;
