import React from 'react';
import {
    FiMenu,
    FiX,
} from 'react-icons/fi';

import { useApiData } from '../../contexts/api-data';
import { useAuth } from '../../contexts/auth';

import ContactPopup from '../../components/contact-popup';
import Map from '../../components/map';
import SatelliteSelection from './components/satellite';
import StationInstitutionSelection from './components/station-institution-selection';

import './styles.css';
import { Switch } from '../../components/common/switch';
import { useMapAlertState } from './states';
import { Tooltip } from '../../components/common/Tooltip';

function MapAlert() {
    const { settingsSet } = useAuth();
    const apiData = useApiData();

    const {
        radarOption,
        showLeftbar,
        setShowLeftbar,
        selectRadar, 
        selectedRadar,
        showNeighborhoodShapes,
        setShowNeighborhoodShapes,
        showLightnings,
        showAttentionPerimeters,
        toggleStaticPositionType,
        toggleShowLightnings,
        handleSwitchClick,
        staticPositionTypes,
        selectedStaticPositionTypes,
        staticPositionTypeAliases,
        shouldHideSwitchWithTypes,
        shouldHideSwitchWithoutRadius
    } = useMapAlertState(apiData, settingsSet);
    
    return (
        <div className="map-alert-page-content">
            <div className={`leftbar ${showLeftbar ? 'shown' : ''}`}>
                {radarOption.length > 0 && (<>
                    <div className="leftbar-title">Radares Meteorológicos</div>
                    <ul className="list-group">
                        {radarOption.map(radar => (                   
                            <li
                                key={`radar-${radar.alias}`}
                                className={`list-group-item list-group-item-secondary ${(selectedRadar  && selectedRadar.alias === radar.alias) ? 'li-selected' : ''}`}
                                onClick={() => selectRadar(radar.alias)}
                            >
                                {radar.name}
                            </li>
                        ))}
                    </ul>
                </>)}
                
                <SatelliteSelection />
                
                <StationInstitutionSelection />
                
                {staticPositionTypeAliases.length > 0 && staticPositionTypes && (<>
                    <div className="leftbar-title">Camadas</div>
                    <ul className="list-group">
                        {staticPositionTypes.filter(({alias}) => staticPositionTypeAliases.includes(alias))
                        .map(({name, alias}) => {
                            const isNeighborhood = alias === 'limites_bairros_rj';
                            const isLightning = alias === 'raios';
                            const isAttentionPerimeter = alias === 'perimetros';
                            
                            const selectedClass = isNeighborhood
                                ? showNeighborhoodShapes
                                : (isLightning
                                    ? showLightnings
                                    : (isAttentionPerimeter
                                        ? showAttentionPerimeters
                                        : (selectedStaticPositionTypes && Object.keys(selectedStaticPositionTypes).includes(alias))
                                    )                                        
                                );
                            const onClickFn = isNeighborhood
                                ? () => setShowNeighborhoodShapes(!showNeighborhoodShapes)
                                : (isLightning
                                    ? toggleShowLightnings
                                    : (isAttentionPerimeter
                                        ? ()=>{}
                                        : () => toggleStaticPositionType(alias)
                                     )
                                );
                            const layerLabel = isNeighborhood
                                ? 'Limites dos bairros'
                                : (isLightning
                                    ? 'Descargas Atmosféricas'
                                    : (isAttentionPerimeter
                                        ? 'Perímetros de Atenção'
                                        : name
                                    )
                                );
                            return (
                                <li
                                    key={`static-position-${alias}`}
                                    className={`list-group-item list-group-item-secondary ${selectedClass ? 'li-selected' : ''}`}
                                    onClick={onClickFn}
                                >
                                    <div className='layer-display-switch'>
                                        <div>
                                            {layerLabel}
                                        </div>
                                       <div onClick={(e) => handleSwitchClick(e)}>
                                        {selectedStaticPositionTypes
                                        && selectedClass 
                                        && !shouldHideSwitchWithTypes(alias)
                                        && !shouldHideSwitchWithoutRadius(alias) 
                                        && (
                                            <>
                                                <Tooltip text={'Exibir/Esconder perímetros'}>
                                                    <Switch 
                                                    handleToggle={(e)=> handleSwitchClick(e,alias)} 
                                                    id={alias}
                                                    isOn={selectedStaticPositionTypes[alias]?.showRanges || false}
                                                    />
                                                </Tooltip>
                                            </>
                                        )}
                                        </div>
                                        
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </>)}
            </div>

            <div
                onClick={() => setShowLeftbar(!showLeftbar)}
                className="toggle-left-bar"
            >
                {showLeftbar ? <FiX /> : <FiMenu />}
            </div>
            
            <div className="map-res">
                <Map
                    showNeighborhoodShapes={showNeighborhoodShapes}
                    showLightnings={showLightnings}
                    showAttentionPerimeters={true}
                />
            </div>
            <ContactPopup />
        </div>
    );
}

export default MapAlert;