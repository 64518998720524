import React from 'react';
import Popup from 'reactjs-popup';
import { FaBell } from 'react-icons/fa';

import MessagesList from '../messages-list';
import { useApiData } from '../../contexts/api-data';

import './styles.css';
import useInterval from '../../hooks/use-interval';

function Button() {
    const {
        realTimeMessages,
        retrieveRealTimeMessages,
    } = useApiData();

    useInterval(retrieveRealTimeMessages, 60000);

    return (
        <div className="notif a-menu">
            <FaBell />
            <div className="borda-redonda">{realTimeMessages ? realTimeMessages.length : 0}</div>
        </div>
    );
}

function MessagesPopup() {
    return (
        <Popup
            modal
            trigger={Button}
            className="messages-modal"
        >
            {close => (
                <div className="modal-dialog w-100 float-right mr-2 h-90" style={{ height: '90%' }}>
                    <div className="modal-content panel-body p-2 h-100">
                        <MessagesList
                            shouldRetrieveMessages={false}
                            close={close}
                        />
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default MessagesPopup;
