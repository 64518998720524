import React from 'react';

function StringSetting({ defaultValue, setValue, placeholder }) {
    return (
        <input
            type="text"
            value={defaultValue || ''}
            onChange={event => setValue(event.target.value)}
            placeholder={placeholder}
        />
    );
}

export default StringSetting;
