import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useSensorSelectionInputState } from './states';

function MultipleSensorsSelectionInput({ defaultValue, setValue }) {
    const sensorSelectionOptions = useSensorSelectionInputState();

    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={sensorSelectionOptions}
        />
    );
}

export default MultipleSensorsSelectionInput;
