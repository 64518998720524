import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

function createLegendDiv(component) {
    const div = L.DomUtil.create('div', 'info legend');
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(component);
    return div;
}

function MapLegend({ children, position }) {
    const map = useMap();

    useEffect(() => {
        const legend = L.control({ position });
        legend.onAdd = () => createLegendDiv(children);
        legend.addTo(map);

        return () => {
            map.removeControl(legend);
        };
    }, [map, children, position]);

    return null;
}

export default MapLegend;
