import React from 'react';

import { 
    units, 
    useDatetimePeriodState 
} from './states';

import './styles.css';

function DatetimePeriodSetting({ defaultValue, setValue }) {
    const state = useDatetimePeriodState(defaultValue, setValue);

    return (
        <div className="datetime-period-container">
            <input
                type="number"
                value={state.amount}
                onChange={event => state.setAmount(event.target.value)}
            />
            <select
                defaultValue={state.period}
                onChange={event =>
                    state.setPeriod(Number(event.target.value))
                }
            >
                {units.map(({ text, period }) => (
                    <option 
                        key={text} 
                        value={period}
                    >
                        {text}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default DatetimePeriodSetting;
