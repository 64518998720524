import React from 'react';
import { FiLoader } from 'react-icons/fi';

import './styles.css';

function Loading({ size, color }) {
    return (
        <FiLoader size={size} color={color} className="loading-spinner" />
    );
}

export default Loading;
