import React from 'react';

import {
    SelectionInput,
} from '../../../../components/common/custom-inputs';

import { useSearchFormStates } from './states';

import './styles.css';

function SearchForm({
    fetchMeasures,
    measures,
    radios,
    radarId,
}) {
    const {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        radiosOptions,
        radioId,
        setRadioId,
    } = useSearchFormStates({
        fetchMeasures,
        measures,
        radios,
        radarId,
    });

    return (
        <form
            className="radar-telemetry-search-form"
            onSubmit={searchMeasures}
        >
            <div className="form-group">
                <label htmlFor="datetime-start">Início:</label>
                <input
                    id="datetime-start"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeStartValue}
                    onChange={event => updateDatetimeStart(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="datetime-end">Fim:</label>
                <input
                    id="datetime-end"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeEndValue}
                    onChange={event => updateDatetimeEnd(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="select-radio">Rádio:</label>
                {radiosOptions && radioId && <SelectionInput
                    inputId="select-radio"
                    defaultValue={radioId}
                    isNullable={false}
                    options={radiosOptions}
                    setValue={setRadioId}
                />}
            </div>

            <button
                className="search-form-btn"
                type="submit"
            >
                Pesquisar
            </button>
        </form>
    );
}

export default SearchForm;
