import React from 'react';
import {
    FaSortDown,
    FaSortUp,
    FaSort,
} from 'react-icons/fa';
import { HiAdjustmentsHorizontal } from "react-icons/hi2";

import {
    useStaticPositionsState
} from './states';

import NewBtn from '../../common/new-btn';
import PostBtn from '../../common/post-btn';

import './styles.css';
import { PositionModal } from '../position-modal';


function StaticPositionsList() {
    const {
        staticPositionTypes,
        getStaticPositionTypeRadius,
        selectedStaticPositionTypeId,
        staticPositions,
        filterActive,
        sortOptions,
        isModalOpen,
        radius,
        selectedPosition,
        creatingPosition,
        setFilterActive,
        selectStaticPositionType,
        addNewStaticPosition,
        updateStaticPosition,
        shouldSave,
        save,
        changeSortOptions,
        exportListStaticPosition,
        importFile,
        positionToSend,
        handleIsModalOpen,
        handleChangeRadius,
        addRadius,
        removeRadius, 
        handleChangeStatus,
        shouldSaveRadius,
        saveRadius,
        variables,
    } = useStaticPositionsState();
    
    return (
        <>
        <div className="header">
            <h3>Posições Estáticas</h3>
            {selectedStaticPositionTypeId && (
                <div className="static-position-actions">
                    <NewBtn
                        label="Nova"
                        newFunction={addNewStaticPosition}
                    />
                    <PostBtn
                        label="Salvar"
                        shouldPost={shouldSave}
                        postFunction={save}
                    />
                    <NewBtn
                        label="Exportar"
                        newFunction={exportListStaticPosition}
                    />
                    <NewBtn
                        label="Importar"
                        newFunction={importFile}
                    />
                </div>
            )}
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="selected-static-position-type">Tipo: </label>
                <select
                    id="selected-static-position-type"
                    value={selectedStaticPositionTypeId}
                    onChange={selectStaticPositionType}
                >
                    <option value="" disabled>Selecione</option>
                    {staticPositionTypes.map(type => (
                        <option
                            key={type.id}
                            value={type.id}
                        >
                            {type.name} ({type.alias})
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="filter-active">Habilitadas: </label>
                <select
                    id="filter-active"
                    value={filterActive}
                    onChange={(event) => setFilterActive(Number(event.target.value))}
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                    <option value="-1">Todas</option>
                </select>
            </div>
        </div>
        <div className="static-positions-table">
            <div className="table-header">
                {Object.keys(variables).map(variableName => {
                    const { label } = variables[variableName];
                    return (
                        <div
                            key={variableName}
                            onClick={() => changeSortOptions(variableName)}
                        >
                            <span>
                                {sortOptions.variable !== variableName
                                    ? <FaSort />
                                    : (sortOptions.asc
                                        ? <FaSortDown />
                                        : <FaSortUp />)}
                            </span>
                            <span>{label}</span>
                        </div>
                    );
                })}
            </div>
            {(filterActive === -1
                ? staticPositions
                : staticPositions.filter(({ inactive }) => inactive === [true, false][filterActive])
            ).map(position => {
                const {
                    id,
                    tempId,
                    latitude,
                    longitude,
                    description,
                    inactive,
                    radius,
                } = position;
                const realId = id || tempId;
                return (
                    <div
                        key={`position-${realId}`}
                        className={`table-row ${positionToSend(position) ? 'position-to-send' : ''}`}
                    >
                        <div>
                            <input
                                type="number"
                                defaultValue={latitude}
                                onChange={(event) => updateStaticPosition(realId, 'latitude', event.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                type="number"
                                defaultValue={longitude}
                                onChange={(event) => updateStaticPosition(realId, 'longitude', event.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                defaultValue={description}
                                onChange={(event) => updateStaticPosition(realId, 'description', event.target.value)}
                                maxLength={90}
                            />
                        </div>
                        <div>
                            <button
                                data-id = {realId} 
                                title='options' 
                                onClick={(event)=>
                                    {
                                        handleIsModalOpen(event);
                                        getStaticPositionTypeRadius(realId)
                                    }
                                } 
                                type='button'
                                disabled={creatingPosition}
                                >
                                <HiAdjustmentsHorizontal 
                                    size={22} 
                                    className={radius ? 'has-customer-radius' : ''} 
                                />
                            </button>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={inactive}
                                onChange={(event) => updateStaticPosition(realId, 'inactive', event.target.checked)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
        <PositionModal 
            isOpen={isModalOpen} 
            setIsOpen={handleIsModalOpen} 
            radius={radius}
            position={selectedPosition}
            handleChangeRadius={handleChangeRadius}
            addRadius={addRadius}
            removeRadius={removeRadius} 
            changeStatus={handleChangeStatus}
            shouldSave={shouldSaveRadius}
            save={saveRadius}
        />
    </>);
}

export default StaticPositionsList;
