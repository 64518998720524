import { useEffect } from 'react';
import { useApiData } from '../../../../contexts/api-data';

export function useRadarSelectionInputState() {
    const {
        radars : {
            fetchAndSave,
            value: radars  
        }
    } = useApiData();

    useEffect(() => {
        fetchAndSave();
    }, [fetchAndSave]);

    const radarOptions = radars.map(({ name, alias }) => {
        return {
            label: name,
            value: alias,
        }
    });

    return radarOptions;
};
