import { useMemo } from 'react';

function getPeriod(key) {
    if (key.includes('precipitationtx')) {
        return 12*60*24*30 + Number(key.replace('precipitationtx', '').replace('min', ''));
    }
    const timePeriodStr = key.replace('precipitation', '').replace('tx', '');
    if (timePeriodStr.includes('min')) {
        return Number(timePeriodStr.replace('min', ''));
    }
    if (timePeriodStr.includes('h')) {
        return Number(timePeriodStr.replace('h', ''))*60;
    }
    if (timePeriodStr.includes('m')) {
        return Number(timePeriodStr.replace('m', ''))*60*24*30;
    }
    return 0;
}

export function useMeasuresChartStates(measures) {
    const chartData = useMemo(() => {
        if (!measures) {
            return null;
        }
        const variables = [];
        const content = measures.reduce((result, measure) => {
            if (!measures) {
                return result;
            }
            const { datetime } = measure;
            const precipitationMeasures = {};
            Object.keys(measure).forEach(key => {
                if (!key.includes('precipitation')) {
                    return;
                }
                if (!variables.includes(key)) {
                    variables.push(key);
                }
                precipitationMeasures[key] = measure[key];
            });
            result.push({
                ...precipitationMeasures,
                datetime,
            });
            return result;
        }, []).sort((a, b) => a.datetime - b.datetime);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => {
            return getPeriod(a) - getPeriod(b);
        });

        const chartLabels = [
            { type: 'string', label: 'Dia-Hora' },
            ...(variables.map(key => {
                const label = key.replace('precipitation', '');
                return { type: 'number', label };
            })),
        ];
        const chartValues = content.map(measure => {
            const { datetime } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} ${time}`;
            return [datetimeStr].concat(variables.map(key => {
                const value = key in measure ? `${measure[key]}` : '-';
                const annotation = `${key.replace('precipitation', '')} ${value}`;
                return {
                    value,
                    annotation,
                };
            }));
        });

        return {
            labels: chartValues?.map(item => item[0]) || [],
            datasets: chartLabels?.slice(1).map(({ label }, index) => {
                const data = chartValues.map(item => item[index+1]?.value);
                const annotations = chartValues.map(item => item[index+1]?.annotation);
                const color = `hsla(${index*20}, 50%, 50%, 1)`;
                return {
                    label,
                    backgroundColor: color,
                    data,
                    annotations,
                    borderColor: color,
                    borderWidth: 1.5,
                    opacity: 0.3,
                    hidden: index > 2,
                };
            }) || [],
        };
        // {
        //     label: caption?.label,
        //     backgroundColor: '#104E8B',
        //     data,
        //     annotations,
        //     borderColor: '#104E8B',
        //     borderWidth: 1,
        // }
    }, [measures]);

    return {
        chartData,
    };
};
