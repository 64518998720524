import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useSatelliteProductTypesSelectionInputState } from './states';

function MultipleSatelliteProductTypesSelectionInput({ defaultValue, setValue }) {
    const satelliteInstitutionOptions = useSatelliteProductTypesSelectionInputState();
    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={satelliteInstitutionOptions}
        />
    );
}

export default MultipleSatelliteProductTypesSelectionInput;
