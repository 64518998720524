import React from 'react';
import { GeoJSON } from 'react-leaflet';

function MapShapes({ jsonData, nameAttribute }) {
    return jsonData && (
        <GeoJSON
            data={jsonData}
            style={{
                weight: 1,
                opacity: 1,
                fillColor: '#000',
                color: '#000',
                fillOpacity: 0.0
            }}
            onEachFeature={(feature, layer) => {
                layer.bindPopup(`<table>
                    <tr>
                        <th style=background-color:#fff;>
                            ${feature.properties[nameAttribute]}
                            &emsp;&emsp;
                        </th>
                    </tr>
                </table>`);
            }}
        />
    );
}

export default MapShapes;
