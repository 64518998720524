import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import EntityBox from '../auth/entity-box';
import EntityRolesHeader from '../auth/entity-roles-header';
import EntityRoles from '../auth/entity-roles';
import CustomerSettings from '../customer-settings';

import { useApiData } from '../../../contexts/api-data';
import api from '../../../services/api';
import downloadFile from '../../../services/download-file';

function CustomerBox({ customer, rolesList }) {
    const {
        manageCustomers: { save: saveCustomer },
        manageWeatherVariables: {
            fetch: fetchManageWeatherVariable,
            value: weatherVariables,
        },
        manageForecastRegions: { value: forecastRegions },
        manageHarbors: { value: harbors },
        forecastModels: { value: forecastModels },
    } = useApiData();

    const { register, watch } = useForm({
        defaultValues: {
            name: customer.name || '',
            active: !customer.inactive,
        },
    });

    useEffect(() => {
        fetchManageWeatherVariable();
    }, [fetchManageWeatherVariable]);

    const initialCustomerRoles = useRef([]);
    const initialCustomerSettings = useRef(null);
    const [customerRoles, setCustomerRoles] = useState([]);
    const [customerSettings, setCustomerSettings] = useState(null);

    const name = watch('name');
    const active = watch('active');
    const creating = !customer.name;
    const title = useMemo(() => name || '*Nome', [name]);

    function shouldSaveCustomerBaseData() {
        const nameSet = name.length > 0;
        if (creating) {
            return nameSet;
        }
        const nameChanged = nameSet && name !== customer.name;
        const activeChanged = !active !== !!customer.inactive;
        return nameChanged || activeChanged;
    }

    function shouldSaveCustomerRoles() {
        return initialCustomerRoles.current.join(',') !== customerRoles.join(',');
    }

    function shouldSaveCustomerSettings() {
        return JSON.stringify(initialCustomerSettings.current) !== JSON.stringify(customerSettings);
    }

    function shouldSave() {
        return shouldSaveCustomerBaseData()
            || shouldSaveCustomerRoles()
            || shouldSaveCustomerSettings();
    }

    async function save() {
        if (!creating) {
            if (shouldSaveCustomerRoles()) {
                initialCustomerRoles.current = customerRoles;
                api.put('/manage/roles/grant-only', {
                    customer_id: customer.id,
                    role_ids: customerRoles,
                });
            }
            if (shouldSaveCustomerSettings()) {
                const newCustomerSettings = Object.keys(customerSettings)
                    .reduce((result, keyName) => {
                        const value = customerSettings[keyName];
                        if (value !== null) {
                            result[keyName] = typeof value === 'object'
                                ? value.filter(Boolean)
                                : value;
                        }
                        else {
                            result[keyName] = '';
                        }
                        return result;
                    }, {});
                initialCustomerSettings.current = newCustomerSettings;
                api.post(`/manage/customers/${customer.id}/settings`, newCustomerSettings);
                setCustomerSettings(newCustomerSettings);
            }
        }
        if (shouldSaveCustomerBaseData()) {
            const { id } = customer;
            const customerToSend = {
                name,
                inactive: !active,
            };
            if (!creating) {
                customerToSend.id = id;
            }
            await saveCustomer(id, customerToSend);
        }
    }

    async function getCustomerRoles() {
        const response = await api.get(`/manage/customers/${customer.id}/roles`);
        initialCustomerRoles.current = response.data;
        setCustomerRoles(response.data);
    }

    async function getCustomerSettings() {
        const response = await api.get(`/manage/customers/${customer.id}/settings`);
        initialCustomerSettings.current = response.data;
        setCustomerSettings(response.data);
    }

    async function getExtraInformation() {
        await Promise.all([
            getCustomerRoles(),
            getCustomerSettings(),
        ]);
    }

    function exportCustomerSettings(){
        const customerSettingVariablesUnit = exchangeVariablesUnitValues(customerSettings);

        const customerSettingClimateForecastRegion = exchangeClimateForecastRegionValues(customerSettingVariablesUnit);

        const customerSettingForecastRegion = exchangeForecastRegionValues(
            customerSettingClimateForecastRegion
        );

        const customerSettingHarbor = exchangeHarborValues(
            customerSettingForecastRegion
        );

        const customerSettingDownloadForecastModels = exchangeDownloadForecastModelsValues(customerSettingHarbor);

        const newCustomerSettings = exchangeVariableForecastModelsValues(
            customerSettingDownloadForecastModels
        );
        
        const fileName = `settings_${customer.name}.json`;
        const fileContent = JSON.stringify(newCustomerSettings, null, 4);
        const data = new Blob([fileContent], {
            type: 'data:text/json;charset=utf-8',
        });

        downloadFile(data,fileName);
    }

    function importCustomerSettings(){
        const input = document.createElement('input');
        input.type = 'file';

        input.addEventListener('change', (event) => {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const fileContent = JSON.parse(e.target.result);
                    const customerSettingVariablesUnit = exchangeVariablesUnitValues(fileContent);

                    const customerSettingClimateForecastRegion =
                        exchangeClimateForecastRegionValues(
                            customerSettingVariablesUnit
                        );

                    const customerSettingForecastRegion =
                        exchangeForecastRegionValues(
                            customerSettingClimateForecastRegion
                        );

                    const customerSettingHarbor = exchangeHarborValues(
                        customerSettingForecastRegion
                    );

                    const customerSettingDownloadForecastModels =
                        exchangeDownloadForecastModelsValues(
                            customerSettingHarbor
                        );

                    const newCustomerSettings =
                        exchangeVariableForecastModelsValues(
                            customerSettingDownloadForecastModels
                        );

                    setCustomerSettings(newCustomerSettings);
                };

                reader.readAsText(file);
            }
        });

        input.click();
    }

    function exchangeVariablesUnitValues(customerSettings) {
        if (!customerSettings.hasOwnProperty('VARIABLES_UNITS')) {
            return customerSettings;
        }
        const variableUnits = customerSettings.VARIABLES_UNITS;
        const newVariableUnits = variableUnits.map((variableUnit) => {
                const [variableId, unitId] = variableUnit.split(',');
                return { variableId, unitId };
            })
            .map(({ variableId, unitId }) => {
                const variableIdMatch = weatherVariables.find(
                    (weatherVariable) =>
                        weatherVariable.id === Number(variableId)
                );
                const unitIdMatch = variableIdMatch
                    ? variableIdMatch.type.units.find(
                          (unit) => unit.id === Number(unitId)
                      )
                    : null;

                if (unitIdMatch) {
                    return `${variableIdMatch.key},${unitIdMatch.label}`;
                } else {
                    const variableKeyMatch = weatherVariables.find(
                        (weatherVariable) => weatherVariable.key === variableId
                    );
                    const unitLabelMatch = variableKeyMatch
                        ? variableKeyMatch.type.units.find(
                              (unit) => unit.label === unitId
                          )
                        : null;

                    if (unitLabelMatch) {
                        return `${variableKeyMatch.id},${unitLabelMatch.id}`;
                    } else {
                        return null;
                    }
                }
            })
            .filter((value) => value !== undefined);

        if (newVariableUnits.length === variableUnits.length) {
            customerSettings.VARIABLES_UNITS = newVariableUnits;
        }
        return customerSettings;
    };
    
    function exchangeClimateForecastRegionValues(customerSetting) {
        if (!customerSetting.hasOwnProperty('CLIMATE_FORECAST_REGIONS')) {
            return customerSetting;
        }

        customerSetting.CLIMATE_FORECAST_REGIONS =
            customerSetting.CLIMATE_FORECAST_REGIONS.map(
                (climateForecastIdOrAlias) => {
                    const region = forecastRegions.find(
                        (fr) => fr.id === Number(climateForecastIdOrAlias)
                    );
                    if (!region) {
                        const aliasRegion = forecastRegions.find(
                            (fr) => fr.alias === climateForecastIdOrAlias
                        );
                        return aliasRegion ? aliasRegion.id.toString() : null;
                    }
                    return region.alias;
                }
            ).filter(Boolean);

        return customerSetting;
    }

    function exchangeForecastRegionValues(customerSetting) {
        if (!customerSetting.hasOwnProperty('FORECAST_REGIONS')) {
            return customerSetting;
        }

        customerSetting.FORECAST_REGIONS = customerSetting.FORECAST_REGIONS.map(
            (forecastIdOrAlias) => {
                const region = forecastRegions.find(
                    (fr) => fr.id === Number(forecastIdOrAlias)
                );
                if (!region) {
                    const aliasRegion = forecastRegions.find(
                        (fr) => fr.alias === forecastIdOrAlias
                    );
                    return aliasRegion ? aliasRegion.id.toString() : null;
                }

                return region.alias;
            }
        ).filter(Boolean);

        return customerSetting;
    }

    function exchangeHarborValues(customerSettings) {
        if (!customerSettings.hasOwnProperty('ASTRONOMIC_FORECASTS_HARBOR')) {
            return customerSettings;
        }

        const harborSettings = customerSettings.ASTRONOMIC_FORECASTS_HARBOR;
        const harbor = harbors.find(
            (harbor) => harbor.id === Number(harborSettings)
        );

        if (!harbor) {
            const aliasHarbor = harbors.find(
                (harbor) => harbor.name === harborSettings
            );
            customerSettings.ASTRONOMIC_FORECASTS_HARBOR = aliasHarbor
                ? aliasHarbor.id.toString()
                : null;
        }
        else {
            customerSettings.ASTRONOMIC_FORECASTS_HARBOR = harbor.name;
        }

        return customerSettings;
    }

    function exchangeDownloadForecastModelsValues(customerSetting) {
        if (customerSetting.hasOwnProperty('DOWNLOAD_FORECAST_MODELS')) {
            const modelAlias = [];
            const modelSettings = customerSetting.DOWNLOAD_FORECAST_MODELS;

            for (const modelId of modelSettings) {
                const model = forecastModels.find(
                    (model) => model.id === Number(modelId)
                );
                if(!model){
                    const model = forecastModels.find(
                        (model) => model.alias === modelId
                    );
                    modelAlias.push(model.id.toString());
                }else{
                    modelAlias.push(model.alias);
                }   
            }
            customerSetting.DOWNLOAD_FORECAST_MODELS = modelAlias;

            const newCustomerSettings = customerSetting;
            return newCustomerSettings;
        }
        return customerSetting;
    }

    function exchangeVariableForecastModelsValues(customerSetting) {
        if (!customerSetting.hasOwnProperty('VARIABLES_BY_FORECAST_MODELS')) {
             return customerSetting;
        };

        const variableForecastModels = customerSetting.VARIABLES_BY_FORECAST_MODELS;

        const variableForecastModelsAlias = variableForecastModels.map(
            (entry) => {
                const [variable, modelId_84h, modelId_15d] = entry.split(',');
                const id_84h = forecastModels.find(
                    (fm) => fm.id === Number(modelId_84h)
                );
                const id_15d = forecastModels.find(
                    (fm) => fm.id === Number(modelId_15d)
                );
                if(!id_84h && !id_15d){
                    const alias_84h = forecastModels.find(
                        (fm) => fm.alias === modelId_84h.toLowerCase()
                    );
                    const alias_15d = forecastModels.find(
                        (fm) => fm.alias === modelId_15d.toLowerCase()
                    );
                    if (!alias_84h && !alias_15d){
                        return `${variable},${modelId_84h},${modelId_15d}`
                    }else{
                        return `${variable},${alias_84h.id},${alias_15d.id}`;
                    } 
                    
                }else{
                    return `${variable},${id_84h.alias},${id_15d.alias}`;
                }
            }
        );
        customerSetting.VARIABLES_BY_FORECAST_MODELS = variableForecastModelsAlias;
        return customerSetting;
    }

    return (
        <EntityBox
            creating={creating}
            title={title}
            onOpen={getExtraInformation}
            titleMaxLength={40}
        >
            {customer.name?.toUpperCase() !== 'NIMBUS' && (<>
                <input
                    placeholder="*Nome"
                    maxLength={40}
                    {...register('name')}
                />
                {!creating && (
                    <div className="form-group checkbox-input">
                        <input
                            id={`customer-inactive-${customer.id}`}
                            type="checkbox"
                            {...register('active')}
                        />
                        <label htmlFor={`customer-inactive-${customer.id}`}>Ativo</label>
                    </div>
                )}
            </>)}
            <EntityRolesHeader
                title="Papéis"
                shouldSave={shouldSave}
                saveFunction={save}
                creating={creating}
                feedback={'Papéis somente podem ser associados após a criação do cliente'}
            />

            {!creating && (<>
                <EntityRoles
                    entityRoles={customerRoles}
                    setEntityRoles={setCustomerRoles}
                    rolesList={rolesList}
                />

                <CustomerSettings
                    customerSettings={customerSettings}
                    setCustomerSettings={setCustomerSettings}
                    shouldSave={shouldSave}
                    saveFunction={save}
                    exportFunction={exportCustomerSettings}
                    importFunction={importCustomerSettings}
                />
            </>)}
        </EntityBox>
    );
}

export default CustomerBox;
