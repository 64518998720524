import React, { useEffect } from 'react';
import { CustomerSelectionInput } from '../common/custom-inputs';
import { useApiData } from '../../contexts/api-data';

function SelectCustomerBox({ setSelectedCustomer }) {
    const apiData = useApiData();

    const {
        manageCustomers: { fetch: fetchCustomers },
    } = apiData;

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    return (
        <div className="select-box-container">
            <label htmlFor="select-customer">Cliente:</label>
            <CustomerSelectionInput
                showOnlyActive
                setValue={value => setSelectedCustomer(value)}
                inputId="select-customer"
            />
        </div>
    );
}

export default SelectCustomerBox;
