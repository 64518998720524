import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

import CitySelectionInput from '../city-selection-input';
import { useMultipleStateAndCitySelectionInputStates } from './states';

function MultipleStateAndCitySelectionInput({ defaultValue, setValue }) {
    const {
        statesArray,
        citiesByState,
        selectCity,
        addSlot,
    } = useMultipleStateAndCitySelectionInputStates({ defaultValue, setValue });

    return (<>
        {statesArray.map((state_id, index) => (
            <CitySelectionInput
                key={`multiple_state_city_${state_id}_${index}`}
                defaultStateOption={{ value: 0, label: 'Estado' }}
                defaultValue={[state_id, ...(citiesByState[state_id] || [])]}
                isStateNullable={true}
                selectCity={value => selectCity(value, index)}
                multiple={true}
            />
        ))}
        <Link
            to="#"
            onClick={addSlot}
            className="multiple-state-and-city-add-btn"
        >
            <FaPlus />
        </Link>
    </>);
}

export default MultipleStateAndCitySelectionInput;
