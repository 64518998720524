import React from 'react';

import EntityBox from '../../auth/entity-box';

import { useSystemMonitorPageState } from './states';

function SystemMonitor() {
    const {
        systemMonitor: {
            uploads,
        },
    } = useSystemMonitorPageState();

    return (
        <>
            <div className="header">
                <h3>Monitoramento do Sistema</h3>
            </div>
            <EntityBox
                title={'Uploads'}
                alwaysOpen={true}
            >
                <span>Quantidade de imagens: {uploads.imagesAmount}</span>
            </EntityBox>
        </>
    );
}

export default SystemMonitor;
