import { useCallback, useState } from 'react';

import {
    getManageStationSensors,
} from '../../../../services/api';

export function useStationSensorsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(
        async function (stationId) {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const stationSensors = await getManageStationSensors(stationId);
            setValue(stationSensors);
            setLoading(false);
        },
        [value, loading],
    );

    return {
        fetch,
        loading,
        value
    };
};
