import React, { useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import MapEffect from './map-effect';
import { useMapDrawerInputter } from '../../contexts/map-drawer-inputter';

import './styles.css';

function MapDrawer() {
    const mapRef = useRef();
    const { initialPosition } = useMapDrawerInputter();

    return initialPosition ? (
        <MapContainer
            center={initialPosition.center}
            zoom={initialPosition.zoom}
            style={{ height: "100%", width: "100%" }}
            zoomControl={true}
            whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MapEffect mapRef={mapRef} />
        </MapContainer>
    ) : null;
}

export default MapDrawer;
