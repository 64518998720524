import { useEffect } from 'react';

import {
    getLoggedUserId,
    getUserSelectedItems,
    getSelectedItems,
    setSelectedItems,
} from '../../../../utils/user';

import { useAuth } from '../../../auth';


function clearInvalidStaticPositions(updatedSelectedState, loggedUserId) {
    let allSelectedStaticPositions =
        getSelectedItems('selected_static_position_types') || {};
    allSelectedStaticPositions = {
        [loggedUserId]: Object.fromEntries(updatedSelectedState)
    };

    setSelectedItems(
        'selected_static_position_types',
        allSelectedStaticPositions
    );
}

export default function useStaticPositions() {
    const { settingsSet } = useAuth();
    const loggedUserId = getLoggedUserId();

    const {
        STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [],
    } = { ...settingsSet };


    useEffect(() => {
        const userSelectedStaticPositions = Object.entries(getUserSelectedItems('selected_static_position_types') || []) || [];
        const updatedSelectedState = userSelectedStaticPositions?.filter(
            ([alias]) => staticPositionTypeAliases?.includes(alias)
        );
        
        const updatedLocalStorageStateSet = new Set(updatedSelectedState);
        const userLocalStorageSelectedPositionsSet = new Set(
            userSelectedStaticPositions
        );

        const isDifferent =
            updatedLocalStorageStateSet.size !==
                userLocalStorageSelectedPositionsSet.size ||
            ![...updatedLocalStorageStateSet].every((alias) =>
                userLocalStorageSelectedPositionsSet.has(alias)
            );
        
        isDifferent &&
             clearInvalidStaticPositions(updatedSelectedState, loggedUserId);
    }, [loggedUserId, staticPositionTypeAliases]);
}
