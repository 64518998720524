import React from 'react';

import { SelectionInput } from '../../../../../common/custom-inputs';

import { useSearchFormStates } from './states';

import './styles.css';

function SearchForm({ fetchMeasures, loadingMeasures, stations }) {
    const {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        setStationId,
        stationsOptions,
        stationId,
    } = useSearchFormStates({ fetchMeasures, stations });

    return (
        <form
            className="stations-weather-search-form"
            onSubmit={searchMeasures}
        >
            <div className="form-group">
                <label htmlFor="datetime-start">Início:</label>
                <input
                    id="datetime-start"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeStartValue}
                    onChange={event => updateDatetimeStart(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="datetime-end">Fim:</label>
                <input
                    id="datetime-end"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    value={datetimeEndValue}
                    onChange={event => updateDatetimeEnd(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="select-station">Estação:</label>
                {stationsOptions && <SelectionInput
                    inputId="select-station"
                    defaultValue={stationId}
                    isNullable={false}
                    options={stationsOptions}
                    setValue={setStationId}
                />}
            </div>

            <button
                className={`search-form-btn ${(stationId && !loadingMeasures) ? '' : 'disabled'}`}
                disabled={!stationId || loadingMeasures}
                type="submit"
            >
                Pesquisar
            </button>
        </form>
    );
}

export default SearchForm;
