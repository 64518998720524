import React from 'react';

import DashboardBox from '../../../../components/dashboard-box';
import SearchForm from './../telemetry-measures-search-form';
import MeasuresChart from './../telemetry-measures-chart';
import MeasuresTable from './../telemetry-measures-table';

import { useTelemetryMeasuresBoxState } from './states';

function TelemetryMeasuresBox() {
    const {
        fetchMeasures,
        measures,
        radios,
        telemetryVariables,
        radarId,
    } = useTelemetryMeasuresBoxState();

    return (
        <DashboardBox title="Tensões de Bateria">
            <SearchForm
                fetchMeasures={fetchMeasures}
                measures={measures}
                radios={radios}
                radarId={radarId}
            />
            <MeasuresChart
                measures={measures}
                telemetryVariables={telemetryVariables}
            />
            <MeasuresTable
                measures={measures}
                telemetryVariables={telemetryVariables}
            />
        </DashboardBox>
    );
}

export default TelemetryMeasuresBox;
