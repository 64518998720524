import React from 'react';

import Loading from '../../components/loading';
import SearchForm from './components/search-form';
import MeasuresChart from './components/measures-chart';
import MeasuresTable from './components/measures-table';

import { useStationsWeatherMeasuresState } from './states';

import './styles.css';

function StationsWeatherMeasures() {
    const {
        fetchMeasures,
        lastMeasureDatetime,
        loadingMeasures,
        measures,
        stations,
        weatherVariables,
        stationId,
        stationName,
    } = useStationsWeatherMeasuresState();

    return (
        <div className="stations-weather-page">
            <h3>Últimos dados meteorológicos da estação</h3>
            <SearchForm
                fetchMeasures={fetchMeasures}
                lastMeasureDatetime={lastMeasureDatetime}
                measures={measures}
                stations={stations}
                weatherVariables={weatherVariables}
                initialStationId={stationId}
            />
            {loadingMeasures
                ? (<Loading size={32} color="#888" />)
                : (<>
                    <MeasuresChart
                        measures={measures}
                        weatherVariables={weatherVariables}
                    />
                    <MeasuresTable
                        measures={measures}
                        stationName={stationName}
                        weatherVariables={weatherVariables}
                    />
                </>)}
        </div>
    );
}

export default StationsWeatherMeasures;
