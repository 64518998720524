import React from 'react';
import UserBox from '../user-box';

import { Link } from 'react-router-dom';
import { useUsersListPageState } from './states';
import { CustomerSelectionInput } from '../../common/custom-inputs';

import './styles.css';

function UsersList() {

    const {
        addNewUser,
        changeSortOptions,
        filterActive,
        filterCustomerDefaultOption,
        filterCustomerId,
        filterName,
        roles,
        setFilterActive,
        setFilterCustomerId,
        setFilterName,
        sortOptions,
        sortUsers,
        users,
    } = useUsersListPageState();

    return (
        <>
            <div className="users-list-header">
                <h3>Usuários</h3>
                <Link
                    to="#"
                    className="new-user-btn"
                    onClick={addNewUser}
                >
                    Novo
                </Link>
            </div>

            <div className="list-filter">
                <div className="form-group">
                    <label htmlFor="selected-customers">Cliente: </label>
                    <CustomerSelectionInput
                        defaultValue={filterCustomerId}
                        defaultOption={filterCustomerDefaultOption}
                        setValue={(value) => setFilterCustomerId(Number(value))}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="filter-active">Habilitados: </label>
                    <select
                        id="filter-active"
                        value={filterActive}
                        onChange={(event) =>
                            setFilterActive(Number(event.target.value))
                        }
                    >
                        <option value="-1">Todos</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="name">Nome e Sobrenome: </label>
                    <input
                        id="name"
                        type="text"
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="sort-options">Ordenar: </label>
                    <select
                        id="sort-options"
                        value={sortOptions}
                        onChange={(event) =>
                            changeSortOptions(event.target.value)
                        }
                    >
                        <option value="name=">Nome (crescente)</option>
                        <option value="name=desc">Nome (decrescente)</option>
                    </select>
                </div>
            </div>

            {users &&
                users
                    .filter(({ inactive, name, customer_id }) => {
                        if (
                            filterActive !== -1 &&
                            inactive !== [true, false][filterActive]
                        ) {
                            return false;
                        }
                        if (filterName && !name?.includes(filterName)) {
                            return false;
                        }
                        if (
                            filterCustomerId > 0 &&
                            filterCustomerId !== customer_id
                        ) {
                            return false;
                        }
                        return true;
                    })
                    .sort(sortUsers)
                    .map((user) => (
                        <UserBox
                            key={user.id}
                            user={user}
                            rolesList={roles}
                        />
                    ))}
        </>
    );
}

export default UsersList;
