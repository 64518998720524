import baseApi from '../../base';

const baseResource = '/manage/variable-types';

export async function getManageVariableTypes() {
    const result = await baseApi.get(baseResource);
    return result.data;
};

export async function postManageVariableTypes(variableType) {
    const result = await baseApi.post(baseResource, variableType);
    return result.data;
};

export async function putManageVariableTypes(variableType) {
    const result = await baseApi.put(`${baseResource}/${variableType.id}`, variableType);
    return result.data;
};
