import React from 'react';
import {
    FiArrowUpCircle,
} from 'react-icons/fi';
import {
    LuCopyPlus,
} from 'react-icons/lu';
import {
    FaTrash,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';
import ActionIconBtn from '../../../../components/common/btn/action-icon-btn';
import { SelectionInput } from '../../../../components/common/custom-inputs';
import * as icons from '../../../../media/bulletin';
import { ConfirmModal } from '../../../../components/modals';

import {
    useDetailedForecastsStates,
} from './states';

import './styles.css';

function DetailedForecasts() {
    const {
        NULL_VALUE,
        addForecast,
        changeForecast,
        changeSynopticText,
        detailedForecasts,
        forecastsOptions,
        regionsOptions,
        removeForecast,
        save,
        setForecastRegionId,
        setTemperature,
        setTemperatureDate,
        shouldSave,
        synopticText,
        synopticTextTypes,
        temperatureDate,
        temperatureZones,
    } = useDetailedForecastsStates();

    return (
        <div className="detailed-forecasts-operation-page">
            <div className="detailed-forecasts-header">
                <h3>Boletim Meteorológico Detalhado</h3>
                {regionsOptions && regionsOptions.length > 0 && (
                    <ActionIconBtn
                        action={addForecast}
                        label="Novo Período"
                        icon={LuCopyPlus}
                        className="main-btn"
                    />
                )}
            </div>

            <DashboardBox
                className="detailed-forecasts-info-box"
            >
                <div className="detailed-forecasts-info-box-container">
                    <div className="last-update-datetime">
                        <span>Última atualização: </span>
                        <span>
                            {synopticText?.lastUpdateDatetimeString || '-'}
                        </span>
                    </div>
                    {(regionsOptions?.length > 1) && (
                        <div className="select-region-box-container">
                            <label htmlFor="select-region">Região:</label>
                            {regionsOptions?.length ?
                                <SelectionInput
                                    setValue={value => setForecastRegionId(Number(value))}
                                    defaultValue={regionsOptions[0].value}
                                    options={regionsOptions}
                                    inputId="select-region"
                                />
                                :
                                <select disabled id="select-region">
                                    <option>Nenhuma região encontrada</option>
                                </select>
                            }
                        </div>
                    )}
                </div>
            </DashboardBox>

            {detailedForecasts?.map(({
                id,
                date,
                period,
                sky,
                precipitation,
                color,
                icon,
                iconPath,
                windSpeed,
                windDirection,
                windRisk,
            }) => (
                <DashboardBox
                    key={`forecast-period-${id}`}
                    title=" "
                    className="forecast-period-box"
                >
                    <div className="forecast-period-container">
                        <div className="form-group">
                            <label htmlFor={`date-${id}`}>Data</label>
                            <input
                                type="date"
                                id={`date-${id}`}
                                value={date}
                                onChange={event => changeForecast(id, { date: event.target.value })}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor={`period-${id}`}>Período</label>
                            <select
                                id={`period-${id}`}
                                value={period}
                                onChange={event => changeForecast(id, { period: event.target.value })}
                            >
                                <option value={NULL_VALUE}>Selecione</option>
                                {forecastsOptions?.period?.map(({ label, inicio }) => (
                                    <option
                                        key={`period-${id}-${inicio}`}
                                        value={inicio}
                                    >
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="variable-box-form-group">
                            <h4>Condição</h4>
                            <div className="form-group">
                                <label htmlFor={`sky-${id}`}>Céu</label>
                                <select
                                    id={`sky-${id}`}
                                    value={sky}
                                    onChange={event => changeForecast(id, { sky: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.sky?.map(({ label }, index) => (
                                        <option
                                            key={`sky-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`precipitation-${id}`}>Chuva</label>
                                <select
                                    id={`precipitation-${id}`}
                                    className={color ? 'precipitation-colored' : ''}
                                    value={precipitation}
                                    onChange={event => changeForecast(id, { precipitation: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.precipitation?.map(({ label }, index) => (
                                        <option
                                            key={`precipitation-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-group checkbox-input">
                                    <input
                                        id={`color-${id}`}
                                        type="checkbox"
                                        checked={color}
                                        onChange={event => changeForecast(id, { color: event.target.checked })}
                                    />
                                    <label htmlFor={`color-${id}`}>
                                        Colorir?
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`icon-${id}`}>Ícone</label>
                                <select
                                    id={`icon-${id}`}
                                    value={icon}
                                    onChange={event => changeForecast(id, { icon: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.conditionIcon?.map(({ label, path }) => (
                                        <option
                                            key={`icon-${id}-${path}`}
                                            value={path}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                                <img
                                    className="condition-icon"
                                    src={icons[iconPath]}
                                    alt={icon}
                                />
                            </div>
                        </div>
                        <div className="variable-box-form-group">
                            <h4>Vento</h4>
                            <div className="form-group">
                                <label htmlFor={`windSpeed-${id}`}>Velocidade</label>
                                <select
                                    id={`windSpeed-${id}`}
                                    value={windSpeed}
                                    onChange={event => changeForecast(id, { windSpeed: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.windSpeed?.map(({ label }, index) => (
                                        <option
                                            key={`wind-speed-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`windDirection-${id}`}>Direção</label>
                                <select
                                    id={`windDirection-${id}`}
                                    value={windDirection}
                                    onChange={event => changeForecast(id, { windDirection: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.windDirection?.map(({ label }, index) => (
                                        <option
                                            key={`wind-direction-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`windRisk-${id}`}>Risco</label>
                                <select
                                    id={`windRisk-${id}`}
                                    value={windRisk}
                                    onChange={event => changeForecast(id, { windRisk: event.target.value })}
                                >
                                    <option value={NULL_VALUE}>Selecione</option>
                                    {forecastsOptions?.windRisk?.map(({ label }, index) => (
                                        <option
                                            key={`wind-risk-${id}-${index}`}
                                            value={index}
                                        >
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="forecast-period-actions">
                            <ConfirmModal
                                header="Remover período de previsão?"
                                trigger={(
                                    <button
                                        className="base-btn allowed-btn action-icon-btn delete-btn"
                                    >
                                        <FaTrash />
                                        Remover
                                    </button>
                                )}
                                onConfirm={() => removeForecast(id)}
                                positiveString="Remover"
                                negativeString="Cancelar"
                            />
                        </div>
                    </div>
                </DashboardBox>
            ))}

            <DashboardBox
                title="Máxima/Mínima"
                className="temperature-zones-box"
            >
                <div className="temperature-zones-container">
                    <div className="form-group temperature-date">
                        <label>Data</label>
                        <input
                            type="date"
                            value={temperatureDate}
                            onChange={event => setTemperatureDate(event.target.value)}
                        />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Zona</th>
                                <th>Máxima</th>
                                <th>Mínima</th>
                            </tr>
                        </thead>
                        <tbody>
                            {temperatureZones?.map(({
                                id,
                                name,
                                max_temperature,
                                min_temperature,
                            }) => (
                                <tr key={id}>
                                    <td>{name}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={max_temperature}
                                            onChange={event => setTemperature({
                                                id,
                                                max_temperature: event.target.value,
                                            })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={min_temperature}
                                            onChange={event => setTemperature({
                                                id,
                                                min_temperature: event.target.value,
                                            })}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </DashboardBox>

            <DashboardBox
                title="Quadro Sinótico do Tempo"
                className="synoptic-text-box"
            >
                <div className="synoptic-text-container">
                    <div className="form-group">
                        <label>Tipo</label>
                        <select
                            value={synopticText?.type_id || null}
                            onChange={event => changeSynopticText({ type_id: event.target.value })}
                        >
                            {synopticTextTypes?.map(({ id, name }) => (
                                <option
                                    key={id}
                                    value={id}
                                >
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <textarea
                        placeholder="Escreva seu texto aqui."
                        className="textarea"
                        value={synopticText?.text}
                        onChange={event => changeSynopticText({ text: event.target.value })}
                    ></textarea>
                </div>
            </DashboardBox>

            <div className="detailed-forecasts-actions">
                {regionsOptions && regionsOptions.length > 0 && (
                    <ActionIconBtn
                        disabled={!shouldSave()}
                        action={save}
                        label="Publicar"
                        icon={FiArrowUpCircle}
                        className="main-btn"
                    />
                )}
            </div>
        </div>
    );
}

export default DetailedForecasts;
