import React, { useEffect } from 'react';

import AlertTemplateBox from '../alert-template-box';
import NewBtn from '../../common/new-btn';
import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function AlertTemplatesList() {
    const {
        manageAlertTemplates: {
            add: addNewAlertTemplate,
            fetch: fetchAlertTemplates,
            value: alertTemplates,
        },
    } = useApiData();

    useEffect(() => {
        fetchAlertTemplates();
    }, [fetchAlertTemplates]);

    return (<>
        <div className="permissions-list-header">
            <h3>Templates de agenda</h3>
            <NewBtn
                newFunction={addNewAlertTemplate}
                label="Novo"
            />
        </div>
        {alertTemplates && alertTemplates.map(template => (
            <AlertTemplateBox
                key={template.id}
                template={template}
            />
        ))}
    </>);
}

export default AlertTemplatesList;
