import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
    WiMoonAltNew,
    WiMoonAltFirstQuarter,
    WiMoonAltFull,
    WiMoonAltThirdQuarter,
} from 'react-icons/wi';

import { getVariableLabel } from './forecasts-variables';

const moonPhasesLabels = [
    'Nova',
    'Minguante',
    'Cheia',
    'Crescente',
];

function getModelForecastsData({
    modelForecasts,
    daysFrequency,
    selectedVariable,
    selectedVariableKey,
}) {
    if (!(modelForecasts?.[0])) {
        return;
    }
    const forecasts = modelForecasts
        .filter(modelForecast => modelForecast.length > 0)
        .find(modelForecast => selectedVariableKey in Object.values(modelForecast)[0]);

    return forecasts && [
        [
            { type: 'string', label: daysFrequency ? 'Dia' : 'Dia-Hora' },
            ...(daysFrequency
                ? selectedVariable.daysFrequencyVars.reduce((result, _, i) => {
                    const label = getVariableLabel(selectedVariable, i);
                    return result.concat([
                        { type: 'number', label },
                        // { type: 'string', role: 'annotation' },
                    ]);
                }, [])
                : [{ type: 'number', label: getVariableLabel(selectedVariable) }]),
        ],
        ...forecasts.map(forecast => {
            const datetimeObject = new Date(forecast.datetime);
            const date = datetimeObject.toLocaleDateString().substring(0, 5);
            const hours = `${datetimeObject.getHours()}h`;
            return [
                `${date}${daysFrequency ? '' : ` ${hours}`}`,
                ...(daysFrequency
                    ? selectedVariable.getDaysFrequencyVars(forecast)
                        .reduce((result, current, i) => {
                            return result.concat({
                                value: current,
                                annotation: selectedVariable?.daysFrequencyAnnotation?.(forecast, i),
                            });
                        }, [])
                    : [forecast[selectedVariableKey]]),
            ];
        }),
    ];
}

function getAstronomicForecastsData({ astronomicForecasts }) {
    if (!astronomicForecasts) {
        return null;
    }
    const astronomicForecastsByDate = astronomicForecasts
        .sort((a, b) => Number(a.datetime) - Number(b.datetime))
        .reduce((result, forecast) => {
            const {
                datetime,
                tide_height,
                moon_phase,
            } = forecast;
            const datetimeObject = new Date(Number(datetime));
            const date = datetimeObject.toLocaleDateString().substring(0, 5);
            const time = datetimeObject.toLocaleTimeString().substring(0, 5);
            const dateArray = result[date] || [];
            dateArray.push({
                time,
                tide_height,
                moon_phase,
            });
            result[date] = dateArray;
            return result;
        }, {});
    const astronomicForecastsData = Object.keys(astronomicForecastsByDate)
        .map(date => {
            const dateArray = astronomicForecastsByDate[date];
            while (dateArray.length < 4) {
                dateArray.push({});
            }
            const moonPhaseIndex = Number(dateArray[0].moon_phase);
            const MoonPhaseIcon = [
                WiMoonAltNew,
                WiMoonAltFirstQuarter,
                WiMoonAltFull,
                WiMoonAltThirdQuarter,
            ][moonPhaseIndex];
            return [
                date,
                ...dateArray.slice(0, 4).map(({ time, tide_height }) => time
                    ? `${time}<br />${parseFloat(tide_height).toFixed(2)} m`
                    : ''),
                ReactDOMServer.renderToString(<MoonPhaseIcon title={moonPhasesLabels[moonPhaseIndex]} />),
            ];
        });
    return [
        [
            { type: 'string', label: 'Dia' },
            { type: 'string', label: '1a Maré' },
            { type: 'string', label: '2a Maré' },
            { type: 'string', label: '3a Maré' },
            { type: 'string', label: '4a Maré' },
            { type: 'string', label: 'Lua' },
        ],
        ...astronomicForecastsData,
    ];
}

export function useChartDataStates({
    modelForecasts,
    astronomicForecasts,
    daysFrequency,
    selectedVariable,
    selectedVariableKey,
}) {
    const data = useMemo(() => {
        if (selectedVariableKey === 'astronomic') {
            return getAstronomicForecastsData({ astronomicForecasts });
        }
        return getModelForecastsData({
            modelForecasts,
            daysFrequency,
            selectedVariable,
            selectedVariableKey,
        });
    }, [
        astronomicForecasts,
        modelForecasts,
        daysFrequency,
        selectedVariableKey,
        selectedVariable,
    ]);

    const chartTitle = useMemo(() => ((daysFrequency && selectedVariable)
        ? `* ${selectedVariable.daysFrequencyObservation}`
        : ''),
        [daysFrequency, selectedVariable]);

    return {
        data,
        chartTitle,
    };
};
