import React from 'react';

import { StationSelectionInput } from '../../../common/custom-inputs';

import { StateSelectionInput } from '../../../common/custom-inputs';
import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';
import { useForecastRegionBoxState } from './states';

import './styles.css';
import MapDrawerInputter from '../../../map-drawer-inputter';

function ForecastRegionBox({ forecastRegion }) {
    const {
        creating,
        name,
        getExtraInformation,
        inactive,
        updatedRegion,
        setName,
        alias,
        setAlias,
        saveRegion,
        shouldSave,
        state_id,
        setStateId,
        stationId,
        regionModels,
        setInactive,
        setStationId,
        setDrawsToSave,
        initialRegionModels,
    } = useForecastRegionBoxState({ forecastRegion });

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
            onOpen={getExtraInformation}
        >
            <div className="entity-box-form region-box-form">
                <div className="form-group">
                    <label htmlFor={`region-name-${updatedRegion.id}`}>Nome</label>
                    <input
                        id={`region-name-${updatedRegion.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={50}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`region-alias-${updatedRegion.id}`}>Identificador</label>
                    <input
                        id={`region-alias-${updatedRegion.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`region-station-id-${updatedRegion.id}`}>Estação Representativa</label>
                    <StationSelectionInput
                        defaultOption={{ label: 'Selecione' }}
                        defaultValue={stationId}
                        isNullable={true}
                        inputId={`region-station-id-${updatedRegion.id}`}
                        setValue={setStationId}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`region-state-id-${updatedRegion.id}`}>Estado</label>
                    <StateSelectionInput
                        defaultOption={{ label: 'Selecione' }}
                        defaultValue={state_id}
                        isNullable={true}
                        inputId={`region-state-id-${updatedRegion.id}`}
                        setValue={setStateId}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <input
                        id={`region-inactive-${updatedRegion.id}`}
                        type="checkbox"
                        defaultChecked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                    <label htmlFor={`region-inactive-${updatedRegion.id}`}>Desabilitar</label>
                </div>
            </div>

            <div className="entity-box-subheader">
                <h5>Retângulos por modelo</h5>
                <PostBtn
                    postFunction={saveRegion}
                    shouldPost={shouldSave}
                />
            </div>

            {creating ? (
                <span>Retângulos somente podem ser criados após a criação da região</span>
            ) : (
                <>
                    {regionModels?.length > 0 &&
                        initialRegionModels.current && (
                            <div className="map-drawer-box">
                                <MapDrawerInputter
                                    onDrawsChange={(drawsToSave) =>
                                        setDrawsToSave(drawsToSave)
                                    }
                                    initialRegionModels={
                                        initialRegionModels.current
                                    }
                                    stationId={stationId}
                                />
                            </div>
                        )}
                </>
            )}
        </EntityBox>
    );
}

export default ForecastRegionBox;
