import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import SatelliteBox from '../satellite-box';
import {
    getSatellites,
    getSatelliteInstitutions
}from '../../../../services/api';

import './styles.css';

const variablesTypes = {
    name: String,
    alias: String,
};

function SatellitesList() {

    const [satelliteInstitutions, setSatelliteInstitutions] = useState([]);
    const [satellites, setSatellites] = useState([]);
    const [filterActive, setFilterActive] = useState(1);
    const [sortOptions, setSortOptions] = useState('name=asc');

    useEffect(() => {
        (async function() {
            const response = await getSatelliteInstitutions();
            setSatelliteInstitutions(response.sort((a, b) => a.id - b.id));
        })();

        (async function() {
            const response = await getSatellites();
            setSatellites(response.sort((a, b) => a.id - b.id));
        })();
    }, []);

    function addNewSatellite() {
        const newSatellite = {
            tempId: Date.now(),
            inactive: false,
        };
        setSatellites([ newSatellite, ...satellites ]);
    }

    function updateSatellite(oldId, newObject) {
        const index = satellites.findIndex(satellite => satellite.id === oldId);
        if (index === -1) {
            return;
        }
        const newSatellites = [ ...satellites ];
        newSatellites[index] = newObject;
        setSatellites(newSatellites);
    }

    function sortSatellites() {
        const [variable, asc] = sortOptions.split('=');
        const newSatellites = [ ...satellites ];
        const varType = variablesTypes[variable];
        newSatellites.sort((a, b) => {
            if (varType === String) {
                const [x, y] = [a, b].map(c => (c[variable] || '').toLowerCase());
                return (x < y)
                    ? [1, -1][Number(Boolean(asc))]
                    : ((x > y)
                        ? [-1, 1][Number(Boolean(asc))]
                        : 0);
            }
            return asc
                ? a[variable]-b[variable]
                : b[variable]-a[variable];
        });
        return newSatellites;
    }

    return (<>
        <div className="header">
            <h3>Satélites</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewSatellite}
            />
        </div>
        <div className="list-filter">
            <div className="form-group">
                <label htmlFor="filter-active">Habilitados: </label>
                <select
                    id="filter-active"
                    value={filterActive}
                    onChange={(event) => setFilterActive(Number(event.target.value))}
                >
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                    <option value="-1">Todos</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="sort-options">Ordenar: </label>
                <select
                    id="sort-options"
                    value={sortOptions}
                    onChange={(event) => setSortOptions(event.target.value)}
                >
                    <option value="name=asc">Nome (crescente)</option>
                    <option value="name=">Nome (decrescente)</option>
                    <option value="alias=asc">Identificador (crescente)</option>
                    <option value="alias=">Identificador (decrescente)</option>
                </select>
            </div>
        </div>
        {sortSatellites().filter(({ inactive }) => {
            if (filterActive !== -1
                && inactive !== [true, false][filterActive]
            ) {
                return false;
            }
            return true;
        })
        .map(satellite => (
            <SatelliteBox
                key={satellite.id || satellite.tempId}
                satellite={satellite}
                satelliteInstitutions={satelliteInstitutions}
                updateSatellite={updateSatellite}
            />
        ))}
    </>);
}

export default SatellitesList;
