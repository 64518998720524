import React, { useEffect } from 'react';

import PermissionBox from '../permission-box';
import NewBtn from '../common/new-btn';
import { useApiData } from '../../../contexts/api-data';

import './styles.css';

function PermissionsList() {
    const {
        managePermissions: {
            add: addNewPermission,
            fetch: fetchPermissions,
            value: permissions,
        },
    } = useApiData();

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    return (<>
        <div className="permissions-list-header">
            <h3>Permissões</h3>
            <NewBtn
                newFunction={addNewPermission}
                label="Nova"
            />
        </div>
        {permissions && permissions.map(permission => (
            <PermissionBox
                key={permission.id}
                permission={permission}
            />
        ))}
    </>);
}

export default PermissionsList;
