import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

function BaseBtn(properties, ref) {
    const {
        label,
        className,
        ...rest
    } = properties;

    return (
        <Link
            to="#"
            {...rest}
            className={`${className} base-btn`}
            ref={ref}
        >
            {label}
        </Link>
    );
}

export default React.forwardRef(BaseBtn);
