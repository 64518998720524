import baseApi from '../../base';

export async function postManageStationMeasures(institutionId, stationMeasures) {
    await baseApi.post(
        `/v2/station-institutions/${institutionId}/measures`,
        stationMeasures
    );
};

export async function putStationsSlipStages(institutionId, stationsStages) {
    await baseApi.put(
        `/station-institutions/${institutionId}/slip-stages`,
        { data: stationsStages },
    );
};

export async function getHistoricStationMeasuresByVariable({
    stations,
    page,
    limit,
    variables,
}) {
    const query = [
        `stations=${stations.join(',')}`,
        `page=${page}`,
        `limit=${limit}`,
        `variables=${variables.join(',')}`,
    ].join('&');
    const response = await baseApi.get(`/stations/measures?${query}`);
    return response.data;
}
