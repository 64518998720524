import baseApi from '../../base';

export async function getManagePermissions() {
    const result = await baseApi.get('/manage/permissions');
    return result.data;
};

export async function postManagePermissions(permission) {
    const result = await baseApi.post('/manage/permissions', permission);
    return result.data;
};

export async function putManagePermissions(permission) {
    const result = await baseApi.put(`/manage/permissions/${permission.id}`, permission);
    return result.data;
};
