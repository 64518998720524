import React, { useState, useEffect } from 'react';

import RealTimeMessageForm from '../real-time-message-form';
import MultipleRealTimeMessageCodeSelectionInput from '../../../common/custom-inputs/multiple-real-time-code-selection-input';
import DeleteBtn from '../../../common/btn/delete-btn';
import { useApiData } from '../../../../contexts/api-data';
import api, { deleteRealTimeMessages } from '../../../../services/api';

import './styles.css';

function RealTimeMessagesList() {
    const [realTimeMessages, setRealTimeMessages] = useState([]);
    const [realTimeCodes, setRealTimeCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState('');
    const [selectedMessagesIds, setSelectedMessagesIds] = useState([]);

    const {
        retrieveCustomerSettingsByKeys,
    } = useApiData();

    function messageWarning(datetime) {
        const date = new Date(datetime);
        return `${date.toLocaleTimeString().substring(0, 5)}`;
    }

    useEffect(() => {
        (async function() {
            const response = await api.get('/real-time-messages');
            response.data.sort((a, b) => b.datetime - a.datetime);
  
            setRealTimeMessages(response.data);
        })();
    }, []);

    useEffect(() => {
        (async function() {
            const result = await retrieveCustomerSettingsByKeys('REAL_TIME_MESSAGE_CODES');
            setRealTimeCodes([...new Set(result.REAL_TIME_MESSAGE_CODES)].sort());
        })();
    }, [retrieveCustomerSettingsByKeys]);

    async function createRealTimeMessage(
        text,
        codesByCustomer,
        expiry_datetime,
        id,
        sendWhatsappMessage,
        template,
        template_args,
    ) {
        const whatsappMessages = [];
        const messages = codesByCustomer.map((code) => {

            const messageContent = template
            ? template_args.reduce((result, current, index) => (
                result.replace(template.inputs[index].replaceText, current)
            ), template.format)
            : text;

            const whatsappText = messageContent.replace(/\n/g, '\\n');
            whatsappMessages.push({
                    text: `\\n${whatsappText} \\n(Aviso válido até ${messageWarning(expiry_datetime)})`,
                    code,
            });

            return {
                code,
                text: messageContent,
                expiry_datetime,
            };
        });
        const response = await api.post('/manage/real-time-messages', messages);      
        setRealTimeMessages([
            ...response.data,
            ...realTimeMessages,
        ]);
        if (sendWhatsappMessage) {
            await api.post('/manage/bot/whatsapp-messages', whatsappMessages);
        }
    }

    async function updateRealTimeMessage(
        text,
        code,
        expiry_datetime,
        id,
        sendWhatsappMessage,
    ) {
        const message = {
            id,
            text,
            code,
            expiry_datetime,
        };

        await api.put(`/manage/real-time-messages/${id}`, [message]);
        
        const newRealTimeMessages = [...realTimeMessages];
        const index = newRealTimeMessages.findIndex(m => m.id === id);
        message.datetime = newRealTimeMessages[index].datetime;
        newRealTimeMessages[index] = message;    
        setRealTimeMessages(newRealTimeMessages);

        if (sendWhatsappMessage) {
            const whatsappText = text?.replace(/\n/g, '\\n');
            await api.post('/manage/bot/whatsapp-messages', [{
                text: `Retificação: ${whatsappText}`,
                code,
            }]);
        }
    }

    function toggleSelectedMessage(id) {
        const isSelected = selectedMessagesIds.includes(id);
        const newSelectedMessagesIds = isSelected
            ? selectedMessagesIds.filter(smId => smId !== id)
            : [ ...selectedMessagesIds, id ];
        setSelectedMessagesIds(newSelectedMessagesIds);
    }

    async function removeMessages() {
        await deleteRealTimeMessages(selectedMessagesIds);

        const newRealTimeMessages = realTimeMessages.filter(({ id }) => !selectedMessagesIds.includes(id));
        setRealTimeMessages(newRealTimeMessages);
        setSelectedMessagesIds([]);
    }

    function selectCode(codes) {
        const newCode = codes;

        if (newCode[1]?.length || newCode.length === 0) {
            setSelectedCode(newCode[1] || '');

            const newCodeMessagesIds = realTimeMessages
                .filter(({ code }) => !newCode || newCode[1]?.includes(code))
                .map(({ id }) => id);

            const newSelectedMessagesIds = selectedMessagesIds.filter((id) =>
                newCodeMessagesIds.includes(id)
            );
            setSelectedMessagesIds(newSelectedMessagesIds);

        } else {
            setSelectedCode([]);
        }
    }

    return (<>
            <h3>Alertas (Tempo Real)</h3>
            <h4>Novo</h4>
            <RealTimeMessageForm
                postFunction={createRealTimeMessage}
                codes={realTimeCodes}
            />
            <div className="real-time-messages-filter">
                <h4>Filtro</h4>
                <div className="form-group">
                    <div className="select-input">
                        <MultipleRealTimeMessageCodeSelectionInput
                            defaultCustomerOption={{
                                value: '',
                                label: 'Todos',
                            }}
                            inputId={'selected-code'}
                            selectRealTimeCodes={selectCode}
                            isCustomerNullable={true}
                            isRealTimeCodeNullable={true}
                            multiple={true}
                        />
                    </div>
                </div>
            </div>
            <div className="real-time-messages-title">
                <h4>Editar</h4>
                <DeleteBtn
                    onClick={removeMessages}
                    isAllowed={() => selectedMessagesIds.length > 0}
                    label="Remover"
                />
            </div>
            {realTimeMessages?.length > 0 &&
                realTimeMessages
                    .filter(
                        (message) =>
                            !selectedCode || selectedCode.includes(message.code)
                    )
                    .map((message) => (
                        <RealTimeMessageForm
                            key={message.id}
                            message={message}
                            postFunction={updateRealTimeMessage}
                            codes={realTimeCodes}
                            toggleSelectedMessage={toggleSelectedMessage}
                        />
                    ))}
        </>);
}

export default RealTimeMessagesList;
