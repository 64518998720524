import React from 'react';

import ArrayInput from '../array-input';

import { useVariableTypeUnitsInputStates } from './states';

const VariableTypeUnitsInput = ArrayInput(function ({ defaultValue, setValue }) {
    const {
        register,
    } = useVariableTypeUnitsInputStates(defaultValue, setValue);

    return (
        <div className="variable-type-unit">
            <input
                type="text"
                placeholder="*Unidade (mm, km/s, °F)"
                {...register('label')}
            />
            <input
                type="text"
                placeholder="*Fórmula (x, x*3.6, (x*9/5) + 32)"
                {...register('formula')}
            />
        </div>
    );
});

export default VariableTypeUnitsInput;
