import React, { useState, useEffect } from 'react';

import datetimeUtils from '../../../../utils/datetime';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import api from '../../../../services/api';

import './styles.css';

function StationConfigurationBox({ configuration }) {
    const [updatedConfiguration, setUpdatedConfiguration] = useState(configuration);
    
    const creating = !updatedConfiguration.name;
    const [name, setName] = useState(updatedConfiguration.name || '');
    const [inactive, setInactive] = useState(updatedConfiguration.inactive || false);

    const datetime_acquired = updatedConfiguration?.datetime_acquired
        ? datetimeUtils.getInputFormatDateFromDatetime(updatedConfiguration.datetime_acquired, true)
        : '';
    const [formatDatetimeAcquired, setFormatDatetimeAcquired] = useState('');

    useEffect(() => {
        if (datetime_acquired) {
            setFormatDatetimeAcquired(datetime_acquired);
        }
    }, [datetime_acquired]);

    function shouldSave() {
        const nameAndDateAreSet = name?.length > 0 && formatDatetimeAcquired?.length > 0;
        if (creating) {
            return nameAndDateAreSet;
        }
        const nameChanged = name !== updatedConfiguration.name;
        const formatDatetimeAcquiredChanged = formatDatetimeAcquired !==  datetime_acquired;
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedConfiguration.inactive);
        return nameAndDateAreSet && (
            nameChanged
            || formatDatetimeAcquiredChanged
            || inactiveChanged);
    }

    async function saveConfiguration() {
        if (shouldSave()) {
            const configurationToSend = {
                name,
                inactive,
            };
            configurationToSend.datetime_acquired = datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formatDatetimeAcquired);

            if (creating) {
                const response = await api.post('/manage/station-configurations', configurationToSend);
                configurationToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/station-configurations/${updatedConfiguration.id}`, configurationToSend);
                configurationToSend.id = updatedConfiguration.id;
            }
            setUpdatedConfiguration(configurationToSend);
        }
    }    

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form configuration-box-form">
                <div className="form-group">
                    <label htmlFor={`configuration-name-${updatedConfiguration.id}`}>Nome</label>
                    <input
                        id={`configuration-name-${updatedConfiguration.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={30}
                    />
                </div>

               <div className="form-group">
                   <label htmlFor={`configuration-datetime-acquired-${updatedConfiguration.id}`}>
                        Data de aquisição
                    </label>
                   <input
                       id={`configuration-datetime-acquired-${updatedConfiguration.id}`}
                       type="date"
                       value={formatDatetimeAcquired}
                       onChange={(event) => setFormatDatetimeAcquired(event.target.value)}                       
                   />
               </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`configuration-inactive-${updatedConfiguration.id}`}>Desabilitar</label>
                    <input
                        id={`configuration-inactive-${updatedConfiguration.id}`}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group">
                    <PostBtn
                        postFunction={saveConfiguration}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default StationConfigurationBox;
