import { useState, useEffect, useMemo } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useCitySelectionInputState({ defaultValue, selectCity }) {
    const {
        manageStates: {
            value: states,
        },
        manageCitiesByState: {
            fetchByState: fetchCitiesByState,
            loading: loadingCities,
            value: citiesByState,
        },
    } = useApiData();

    const [selectedState, setSelectedState] = useState(defaultValue?.[0] || 0);

    const statesOptions = useMemo(() => states.map(({ id, uf }) => ({
        value: id,
        label: uf,
    })), [states]);

    const citiesOptions = useMemo(() => (citiesByState[selectedState] || [])
        .map(({ id, name }) => ({
            value: id,
            label: name,
        })),
        [citiesByState, selectedState],
    );

    useEffect(() => {
        if (!selectedState) {
            return;
        }
        fetchCitiesByState(selectedState);
    }, [fetchCitiesByState, selectedState]);

    function selectState(stateIdString) {
        const state_id = Number(stateIdString);
        setSelectedState(state_id);
        selectCity([state_id]);
    }

    return {
        citiesByState,
        citiesOptions,
        loadingCities,
        selectedState,
        selectState,
        statesOptions,
    };
};
