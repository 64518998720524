import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

const options = [
    {
        value: 'precipitation',
        label: 'Precipitação',
    },
    {
        value: 'precipitation_accuracy',
        label: 'Acurácia de Precipitação',
    },
    {
        value: 'wind_speed',
        label: 'Velocidade do Vento',
    },
    {
        value: 'wind_direction',
        label: 'Direção do Vento',
    },
    {
        value: 'temperature',
        label: 'Temperatura',
    },
    {
        value: 'relative_humidity',
        label: 'Umidade Relativa',
    },
    {
        value: 'wind_gust',
        label: 'Rajada de Vento',
    },
    {
        value: 'pressure',
        label: 'Pressão (PNMM)',
    },
    {
        value: 'radiation',
        label: 'Radiação Solar',
    },
    {
        value: 'dew_point',
        label: 'Ponto de Orvalho',
    },
    {
        value: 'fog_fsi',
        label: 'Nevoeiro (FSI)',
    },
    {
        value: 'fog_vis',
        label: 'Nevoeiro (Vis)',
    },
    {
        value: 'significant_wave_height',
        label: 'Altura de Onda Significativa',
    },
    {
        value: 'mean_wave_period',
        label: 'Período Médio de Onda',
    },
    {
        value: 'mean_wave_direction',
        label: 'Direção Média de Onda',
    },
    {
        value: 'astronomic',
        label: 'Altura da Maré e Fase da Lua',
    },
].sort((a, b) => a.label.localeCompare(b.label));

function MultipleForecastsVariablesSelectionInput({ defaultValue, setValue }) {
    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
        />
    );
}

export default MultipleForecastsVariablesSelectionInput;
