import React from 'react';
import { Popup } from 'reactjs-popup';
import { FaInfoCircle } from 'react-icons/fa';

function TriggerLegend() {
    return (
        <Popup
            closeOnDocumentClick={true}
            closeOnEscape={true}
            className="precipitation-legend"
            position="bottom right"
            trigger={
                <span className="precipitation-legend-trigger">
                    <FaInfoCircle />
                </span>
            }
        >
            <div className="precipitation-legend-container">
                <div>
                    <span>#</span>
                    <span>Critério</span>
                </div>
                <div>
                    <span>1</span>
                    <span>até 5mm/h - 3 estações</span>
                </div>
                <div>
                    <span>2</span>
                    <span>entre 5,1 e 20mm/h - 2 estações</span>
                </div>
                <div>
                    <span>3</span>
                    <span>
                        &gt; 10mm/15min - 2 estações ou<br />
                        &gt; 25mm/h - 1 estação
                    </span>
                </div>
                <div>
                    <span>4</span>
                    <span>
                        &gt; 15mm/15min - 2 estações ou<br />
                        &gt; 25mm/30min - 1 estação
                    </span>
                </div>
                <div>
                    <span>5</span>
                    <span>
                        &gt; 25mm/15min - 2 estações ou<br />
                        &gt; 50mm/30min - 1 estação ou<br />
                        &gt; 60mm/h - 1 estação ou<br />
                        &gt; 220mm/24h - 1 estação
                    </span>
                </div>
            </div>
        </Popup>
    );
}

export default TriggerLegend;
