import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';
import { FakeSelectionInput } from '../../../common/custom-inputs/';

import { useSensorBoxState } from './states';

import './styles.css';

function SensorBox({ sensor }) {
    const {
        alias,
        calc_func,
        creating,
        data_position,
        data_length,
        formula,
        inactive,
        is_data,
        is_control,
        maximum_value,
        measure_sensor,
        minimum_value,
        name,
        saveConfiguration,
        setAlias,
        setCalcFunc,
        setDataPosition,
        setDataLength,
        setFormula,
        setInactive,
        setIsData,
        setIsControl,
        setMaximumValue,
        setMeasureSensor,
        setMinimumValue,
        setName,
        shouldSave,
        updatedSensor,
    } = useSensorBoxState({ sensor });

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="sensor-box-form">
                <div className="form-group">
                    <label htmlFor={`sensor-alias-${updatedSensor.id}`}>Identificador</label>
                    <input
                        id={`sensor-alias-${updatedSensor.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-name-${updatedSensor.id}`}>Nome</label>
                    <input
                        id={`sensor-name-${updatedSensor.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-type-${updatedSensor.id}`}>Tipo</label>
                    <FakeSelectionInput
                        optionLabels={["Controle", "Ambiental"]}
                        inputId={`sensor-type-${updatedSensor.id}`}
                        booleanVar={is_control}
                        setValue={setIsControl}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-min-value-${updatedSensor.id}`}>Valor Mínimo</label>
                    <input
                        id={`sensor-min-value-${updatedSensor.id}`}
                        value={minimum_value || ''}
                        placeholder="Valor mínimo"
                        type="number"
                        onChange={(event) => setMinimumValue(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-max-value-${updatedSensor.id}`}>Valor Máximo</label>
                    <input
                        id={`sensor-max-value-${updatedSensor.id}`}
                        value={maximum_value || ''}
                        placeholder="Valor máximo"
                        type="number"
                        onChange={(event) => setMaximumValue(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-category-${updatedSensor.id}`}>Categoria</label>
                    <FakeSelectionInput
                        optionLabels={["Dado", "Cabeçalho"]}
                        inputId={`sensor-category-${updatedSensor.id}`}
                        booleanVar={is_data}
                        setValue={setIsData}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-formula-${updatedSensor.id}`}>Fórmula</label>
                    <input
                        id={`sensor-formula-${updatedSensor.id}`}
                        value={formula}
                        placeholder="Entre com a fórmula"
                        onChange={(event) => setFormula(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-data-position-${updatedSensor.id}`}>Posição do dado</label>
                    <input
                        id={`sensor-data-position-${updatedSensor.id}`}
                        value={data_position}
                        type="number"
                        onChange={(event) => setDataPosition(Number(event.target.value))}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`sensor-data-length-${updatedSensor.id}`}>Tamanho do dado</label>
                    <input
                        id={`sensor-data-length-${updatedSensor.id}`}
                        value={Number(data_length) || ''}
                        type="number"
                        onChange={(event) => setDataLength(Number(event.target.value))}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`sensor-inactive-${updatedSensor.id}`}>Desabilitar</label>
                    <input
                        id={`sensor-inactive-${updatedSensor.id}`}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`sensor-calc-func-${updatedSensor.id}`}>CalcFunc</label>
                    <input
                        id={`sensor-calc-func-${updatedSensor.id}`}
                        type="checkbox"
                        checked={calc_func}
                        onChange={(event) => setCalcFunc(event.target.checked)}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`sensor-measure-sensor-${updatedSensor.id}`}>LeituraSensor</label>
                    <input
                        id={`sensor-measure-sensor-${updatedSensor.id}`}
                        type="checkbox"
                        checked={measure_sensor}
                        onChange={(event) => setMeasureSensor(event.target.checked)}
                    />
                </div>

                <div className="form-group">
                    <PostBtn
                        postFunction={saveConfiguration}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default SensorBox;
