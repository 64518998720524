import { useCallback, useState, useMemo } from 'react';

import {
    getManageVariableTypes,
    postManageVariableTypes,
    putManageVariableTypes,
} from '../../../../services/api';

export function useManageVariableTypesState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.name) return -1;
            if (!b.name) return 1;
            return a.name.localeCompare(b.name);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const variableTypes = await getManageVariableTypes();
            setValue(variableTypes);
            setLoading(false);
        },
        [value, loading],
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(typeId, variableType) {
        const index = value.findIndex(({ id }) => id === typeId);
        const creating = !variableType.id;
        if (variableType.units) {
            variableType.units.forEach(unit => {
                if (unit.creating) {
                    delete unit.id;
                    delete unit.creating;
                }
            });
        }
        const newVariableType = creating
            ? await postManageVariableTypes(variableType)
            : await putManageVariableTypes(variableType);
        const newValue = [...value];
        newValue[index] = newVariableType;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        value: valueSorted,
    };
};
