import React, { useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { Chart } from 'react-google-charts';
import { FaSquare } from 'react-icons/fa';

import { useApiData } from '../../contexts/api-data';
import { useShapes } from '../../contexts/shapes';
import { raToZoneMap } from '../../utils/zones';
import MapLegend from '../map-legend';

import './styles.css';

const forecastLabels = ['Sem chuva', 'Fraca', 'Moderada', 'Forte'];
const zoneForecastColors = ['#2AAD27', '#2A81CB', '#FFD326', '#ec8b0c'];

function raToForecastRegion(ra, forecastRegions) {
    const zoneAlias = raToZoneMap[ra];
    return forecastRegions?.find(({ alias }) => zoneAlias === alias);
}

function ZoneMapBox() {
    const {
        forecastRegions,
        forecasts,
        maxForecastPrecipitationClasses,
        selectedForecastRegion,
        selectForecastRegion,
    } = useApiData();
    const shapes = useShapes();

    const corForecastRegions = forecastRegions?.filter(({ alias }) => alias.startsWith('cor_rj'));

    const geoJSONLayer = useRef(null);

    const maxClasses = forecasts && corForecastRegions.map(region => ({
        ...maxForecastPrecipitationClasses?.[region.alias],
        region,
    }));

    const maxClassData = forecasts && maxClasses.map(({ datetime, precipitationClass, region }) => {
        const result = [
            region.name,
            forecastLabels[precipitationClass],
        ];
        if (precipitationClass > 0) {
            const hour = new Date(datetime).toLocaleTimeString().substr(0, 2);
            result.push(`${Number(hour)} h`);
        }
        else {
            result.push('');
        }
        return result;
    });

    function chartSelect({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length > 0) {
            const {
                id,
                alias,
            } = corForecastRegions[selection[0].row];
            selectForecastRegion(id);
            selectZone(alias);
        }
    }

    function selectMapZone(event) {
        const forecastRegion = raToForecastRegion(
            event.layer.feature.properties.CODAPNUM,
            corForecastRegions,
        );
        if (!forecastRegion) {
            return;
        }
        const {
            id,
            alias,
        } = forecastRegion;
        selectForecastRegion(id);
        selectZone(alias);
    }

    function selectZone(selectedZone) {
        geoJSONLayer.current.eachLayer(layer => {
            const zone = raToZoneMap[layer.feature.properties.CODAPNUM];
            const fillOpacity = [zone, 'cor_rj'].includes(selectedZone) ? 1.0 : 0.2;
            layer.setStyle({
                fillOpacity,
            });
        });
    }

    return (
        <div className="dashboard-box-reg">
            <div id="descricao-tit-1" className="db-titulo">Selecione a região (clique no mapa/tabela) </div>
            <div className="container-reg">
                <MapContainer
                    id="map2"
                    className="container-map"
                    center={[-22.91,-43.45]}
                    zoom={9.5}
                    dragging={false}
                    tap={false}
                    zoomControl={false}
                    scrollWheelZoom={false}
                    doubleClickZoom={false}
                >
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
                        id="mapbox/light-v9"
                        maxZoom={10}
                    />

                    <MapLegend position="topleft">
                        <div className="tabLegenda">
                            <div className="legenda-item">
                                <div className="legenda-simb">
                                    <FaSquare className="green" />
                                </div>
                                <div className="legenda-desc">Sem chuva</div>
                            </div>
                            <div className="legenda-item">
                                <div className="legenda-simb">
                                    <FaSquare className="blue" />
                                </div>
                                <div className="legenda-desc">Chuva fraca (0,2 a 5,0 mm/h)</div>
                            </div>
                            <div className="legenda-item">
                                <div className="legenda-simb">
                                    <FaSquare className="yellow" />
                                </div>
                                <div className="legenda-desc">Chuva Moderada (5,1 a 25,0 mm/h)</div>
                            </div>
                            <div className="legenda-item">
                                <div className="legenda-simb">
                                    <FaSquare className="orange" />
                                </div>
                                <div className="legenda-desc">Chuva Forte (acima de 25,0 mm/h)</div>
                            </div>
                        </div>
                    </MapLegend>

                    {shapes.neighborhood && maxClasses && selectedForecastRegion && (
                        <GeoJSON
                            data={shapes.neighborhood}
                            eventHandlers={{
                                click: selectMapZone,
                            }}
                            ref={geoJSONLayer}
                            style={(feature) => {
                                const zone = raToZoneMap[feature.properties.CODAPNUM];
                                const zoneMaxClass = maxClasses.find(maxClass => maxClass.region.alias === zone);
                                const { precipitationClass = 0 } = { ...zoneMaxClass };
                                const fillOpacity = [zone, 'cor_rj'].includes(selectedForecastRegion.alias) ? 1.0 : 0.2;
                                return {
                                    weight: 1,
                                    opacity: 1,
                                    fillColor: zoneForecastColors[precipitationClass],
                                    color: '#000',
                                    fillOpacity,
                                };
                            }}
                        />
                    )}
                </MapContainer>
                <div id="tabela-1">
                    <Chart
                        chartType="Table"
                        data={maxClassData && [
                            [
                                { type: 'string', label: 'Região' },
                                { type: 'string', label: 'Previsão de chuva' },
                                { type: 'string', label: 'Hora de ocorrência' },
                            ],
                            ...maxClassData,
                        ]}
                        options={{
                            showRowNumber: false,
                            width: '100%',
                            height: '100%',
                            allowHtml: true,
                            cssClassNames: {
                                headerCell: 'headerCell',
                            }
                        }}
                        chartEvents={[
                            {
                                eventName: 'select',
                                callback: chartSelect,
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}

export default ZoneMapBox;
