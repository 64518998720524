import React from 'react';

import BasinSlipStageBox from '../slip-stage-box';
import NewBtn from '../../common/new-btn';
import { useApiData } from '../../../../contexts/api-data';

function BasinSlipStagesList() {
    const {
        manageBasinSlipStages: {
            add,
            useFetchAndSocket,
            value: slipStages,
        },
    } = useApiData();

    useFetchAndSocket();

    return (<>
        <div className="basins-list-header">
            <h3>Estágios de escorregamento para Bacias</h3>
            <NewBtn
                newFunction={add}
                label="Novo"
            />
        </div>
        {slipStages && slipStages.map(stage => (
            <BasinSlipStageBox
                key={stage.id}
                stage={stage}
            />
        ))}
    </>);
}

export default BasinSlipStagesList;
