import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiData } from '../../../../../contexts/api-data';

const variablesTypes = {
    name: String,
    code: String,
    delay: Number,
};

export function useStationMonitorListStates() {
    const {
        manageCitiesByState: {
            value: citiesByState,
        },
        manageStates: {
            fetch: fetchManageStates,
        },
        manageStations: {
            fetchByInstitution,
            value: originalStations,
        },
        manageStationConfigurations: {
            fetch: fetchStationConfigurations,
        },
        manageStationInstitutions: {
            value: stationInstitutions,
            fetch: fetchStationInstitutions,
        },        
        manageStationProtocols: {
            fetch: fetchStationProtocols,
        },
        retrieveNeighborhoodsByCity,
        sensors: {
            fetch: fetchSensors,
        },
    } = useApiData();

    useEffect(() => {
        fetchStationProtocols();
    }, [fetchStationProtocols]);

    useEffect(() => {
        fetchStationConfigurations();
    }, [fetchStationConfigurations]);

    useEffect(() => {
        fetchSensors();
    }, [fetchSensors]);

    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);

    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);
    
    const [stations, setStations] = useState(null);
    const [selectedStationInstitution, setSelectedStationInstitution] = useState(null);
    const [filterCity, setFilterCity] = useState({});
    const [filterNeighborhoodId, setFilterNeighborhoodId] = useState(0);
    const [filterActive, setFilterActive] = useState(1);
    const [sortOptions, setSortOptions] = useState('name=asc');

    const sortStations = (sortOptions) => {
        const [variable, asc] = sortOptions.split('=');
        const varType = variablesTypes[variable];

        return (a, b) => {
            const valueA = a[variable];
            const valueB = b[variable];

            if (valueA === undefined && valueB !== undefined) return 1;
            if (valueA !== undefined && valueB === undefined) return -1;
            if (valueA === undefined && valueB === undefined) return 0;

            if (varType === String) {
                const [x, y] = [valueA.toLowerCase(), valueB.toLowerCase()];
                if (x < y) return asc ? -1 : 1;
                if (x > y) return asc ? 1 : -1;
                return 0;
            }
            return asc ? valueA - valueB : valueB - valueA;
        };
    };

    const selectStationInstitution = useCallback(async (institutionId) => {
        fetchByInstitution(institutionId, false);
        const selectedStationInstitution = stationInstitutions.find(
            ({id}) => id === Number(institutionId))
        setSelectedStationInstitution(selectedStationInstitution);
        setFilterNeighborhoodId(0);
    }, [fetchByInstitution, stationInstitutions]);

    useEffect(() => {
        if (stationInstitutions?.length > 0 && !selectedStationInstitution) {
            selectStationInstitution(stationInstitutions?.[0].id);
        }
    }, [selectedStationInstitution, selectStationInstitution, stationInstitutions]);

    useEffect(() => {
        if (!stations && !originalStations) {
            return;
        }
        setStations(originalStations.sort(sortStations(sortOptions)))
    }, [originalStations, setStations, stations, sortOptions]);

    useEffect(() => {
        if (!filterCity.city_id) {
            return;
        }
        retrieveNeighborhoodsByCity(filterCity.city_id);
    }, [filterCity, retrieveNeighborhoodsByCity]);

    function changeSortOptions(newSortOptions) {
        setSortOptions(newSortOptions);
        setStations(stations.sort(sortStations(newSortOptions)));
    }

    const filterCitiesIds = useMemo(
        () => (citiesByState[filterCity.state_id] || []).map(({ id }) => id),
        [citiesByState, filterCity],
    );

    const stationInstitutionOptions = stationInstitutions?.map(({ id, name }) => ({
        value: id,
        label: `${name}`
    }));

    const filterStations = (station) => {
        const {
            inactive,
            neighborhood_id,
            city_id,
            is_delayed,
        } = station;

        if (!is_delayed) {
            return false;
        }

        if (filterActive !== -1 && inactive !== [true, false][filterActive]) {
            return false;
        }
    
        if (filterNeighborhoodId > 0 && filterNeighborhoodId !== neighborhood_id) {
            return false;
        }
    
        const { state_id, city_id: filterCityId } = filterCity;
    
        if ((filterCityId > 0 && filterCityId !== city_id) || 
            (state_id > 0 && !filterCitiesIds.includes(city_id))) {
            return false;
        }

        return true;
    };

    return {
        changeSortOptions,
        filterActive,
        filterStations,
        selectStationInstitution,
        selectedStationInstitution,
        setFilterActive,
        setFilterCity,
        sortOptions,
        stations,
        stationInstitutionOptions,
    }
};
