import React from 'react';

import SelectionInput from '../selection-input';

import { useApiData } from '../../../../contexts/api-data';

function StateSelectionInput({
    defaultOption,
    defaultValue,
    isNullable,
    inputId,
    setValue,
    usingStates,
}) {
    const {
        manageStates: {
            value: states,
        },
    } = useApiData();

    const filteredStates = usingStates ? states?.filter(state => usingStates.includes(state.id)) : states;

    const options = filteredStates?.map(({ id, name }) => ({
        value: id,
        label: `${name}`,
    }));

    return states?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : null;
}

export default StateSelectionInput;
