import React from 'react'

import './styles.css'
import { FaX } from 'react-icons/fa6'
import { RadiusInput } from '../radius-input'
import PostBtn from '../../common/post-btn'
export function PositionModal({isOpen, setIsOpen, radius, handleChangeRadius, addRadius, removeRadius, position, changeStatus, shouldSave, save}) {
    if (!isOpen) return null;
    return (
        <>
            {isOpen && position &&(  
                <div className='position-modal-container' onClick={() => setIsOpen(false)}> 
                    <div className='position-modal-content' onClick={(e) => e.stopPropagation()}>
                        <div className='position-modal-header-area'>
                            <button onClick={setIsOpen}>
                                <FaX  />
                            </button>
                        </div>
                        <div className='position-modal-checkbox-area'>
                            <div>
                                <input type="checkbox" onChange={()=> changeStatus('show_icon')} checked={position.show_icon} className='position-modal-checkbox'  />
                                <label htmlFor="checkbox">Exibir ícone</label>
                            </div>
                            <div>
                                <input type="checkbox" onChange={()=> changeStatus('show_ranges')} checked={position.show_ranges} className='position-modal-checkbox'/>
                                <label htmlFor="checkbox">Exibir perímetro</label>
                            </div> 
                        </div>
                        <div className='position-modal-radius-area'>
                        {radius && radius.length > 0 ? (
                            <RadiusInput
                                radiusValues={radius}
                                handleChangeRadius={handleChangeRadius}
                                addRadius={addRadius}
                                removeRadius={removeRadius}
                            />
                        ) : (
                            <RadiusInput
                                radiusValues={[]}
                                addRadius={addRadius} 
                            />
                        )}
                        </div>
                        <div className='position-modal-save'>
                            <PostBtn
                                shouldPost={shouldSave}
                                postFunction={save}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

