import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';

import { useApiData } from '../../contexts/api-data';

import MapLegend from '../map-legend';

import './styles.css';

function StationsLegend() {
    const { selectedStationInstitution:stationInstitution } = useApiData();

    return stationInstitution && (
        <MapLegend position="topright">
            <label className="tabLegenda">
                <input type="checkbox" /> 
                <div className="legenda-titulo"> Estações Meteorológicas ({stationInstitution.name})</div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="dark-gray" /></div>
                    <div className="legenda-desc">Sem medição</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="gray" /></div>
                    <div className="legenda-desc">Atrasada</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="green" /></div>
                    <div className="legenda-desc">Sem chuva</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="blue" /></div>
                    <div className="legenda-desc">Chuva fraca (0,2 a 5,0 mm/h)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="yellow" /></div>
                    <div className="legenda-desc">Chuva Moderada (5,1 a 25,0 mm/h)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="orange" /></div>
                    <div className="legenda-desc">Chuva Forte (25,1 a 50,0 mm/h)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaMapMarkerAlt className="red" /></div>
                    <div className="legenda-desc">Chuva Muito Forte (acima de 50,0 mm/h)</div>
                </div>
            </label>
        </MapLegend>
    );
}

export default StationsLegend;
