import { useCallback, useState, useMemo } from 'react';

import {
    getRadios,
    postManageRadios,
    putManageRadios,
} from '../../../../services/api';

export function useManageRadiosState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            return (a.name || '').localeCompare(b.name);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const radios = await getRadios();
            setValue(radios);
            setLoading(false);
        },
        [value, loading],
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(radioId, radio) {
        const index = value.findIndex(({ id }) => id === radioId);
        // creating
        if (!radio.id) {
            radio = await postManageRadios(radio);
        }
        // updating
        else {
            await putManageRadios(radio);
        }
        const newValue = [...value];
        newValue[index] = radio;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        value: valueSorted,
    };
};
