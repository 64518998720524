import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import datetimeUtils from '../../../../../../utils/datetime';
import { getCalendarMessages } from '../../../../../../services/api';
import { MultipleCustomersAndRegionsSelectionInput } from '../../../../../common/custom-inputs';

import './styles.css';

function CalendarMessagesSearchForm({ setMessages, forecastRegions }) {
    const { register, handleSubmit, watch } = useForm();
    const dateEndDefaultValue = datetimeUtils.getInputFormatDateFromDatetime(Date.now());
    const dateStartDefaultValue = datetimeUtils.getInputFormatDateFromDatetime(datetimeUtils.addNDays(Date.now(), -30));
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [csv, setCsv] = useState(false);

    const dateStart = watch('dateStart');
    const dateEnd = watch('dateEnd');
    const runDateStart = watch('runDateStart');
    const runDateEnd = watch('runDateEnd');

    function selectRegions(regionsByCustomer) {
        const newRegions = regionsByCustomer?.[1];
        const aliases = (newRegions || []).map(id => {
            const region = forecastRegions.find(r => r.id === Number(id));
            return region?.alias;
        }).filter(Boolean);
        setSelectedCodes(aliases);
    }

    async function searchMessages(formData) {
        const codes = formData.codeCheckbox ? selectedCodes.join(',') : null;
        const datetimeStart = formData.dateStartCheckbox            
            ? datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formData.dateStart)
            : null;
        const datetimeEnd = formData.dateEndCheckbox            
            ? datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formData.dateEnd)
            : null;
        const runDatetimeStart = formData.runDateStartCheckbox            
            ? datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formData.runDateStart)
            : null;
        const runDatetimeEnd = formData.runDateEndCheckbox            
            ? datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(formData.runDateEnd)
            : null;
        
        const calendarMessagesObject = await getCalendarMessages({
            codes,
            datetimeStart,
            datetimeEnd,
            runDatetimeStart,
            runDatetimeEnd,
            csv,
        });

        const allMessages = Object.keys(calendarMessagesObject).reduce((result, datetime) => {
            return result.concat(calendarMessagesObject[datetime]);
        }, []);

        setMessages(allMessages || []);
    }

    return (<>
        <form onSubmit={handleSubmit(searchMessages)} className="calendar-messages-search-form">
            <div className="form-group">
                <input
                    id="code-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                    {...register('codeCheckbox')}
                />
                <label htmlFor="region">Região</label>
                <MultipleCustomersAndRegionsSelectionInput
                    selectRegion={selectRegions}
                />
            </div>
            <div className="form-group">
                <input
                    id="date-start-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                    {...register('dateStartCheckbox')}
                />
                <label htmlFor="date-start">Data de previsão (inicial)</label>
                <input
                    id="date-start"
                    type="date"
                    defaultValue={dateStartDefaultValue}
                    {...register('dateStart')}
                    max={dateEnd}
                />
            </div>
            <div className="form-group">
                <input
                    id="date-end-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                    {...register('dateEndCheckbox')}
                />
                <label htmlFor="date-end">Data de previsão (final)</label>
                <input
                    id="date-end"
                    type="date"
                    defaultValue={dateEndDefaultValue}
                    {...register('dateEnd')}
                    min={dateStart}
                />
            </div>

            <div className="form-group">
                <input
                    id="run-date-start-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                    {...register('runDateStartCheckbox')}
                />
                <label htmlFor="run-date-start">Data de postagem (inicial)</label>
                <input
                    id="run-date-start"
                    type="date"
                    defaultValue={dateStartDefaultValue}
                    {...register('runDateStart')}
                    max={runDateEnd}
                />
            </div>
            <div className="form-group">
                <input
                    id="run-date-end-checkbox"
                    type="checkbox"
                    defaultChecked={true}
                    {...register('runDateEndCheckbox')}
                />
                <label htmlFor="run-date-end">Data de postagem (final)</label>
                <input
                    id="run-date-end"
                    type="date"
                    defaultValue={dateEndDefaultValue}                    
                    {...register('runDateEnd')}
                    min={runDateStart}
                />
            </div>
            <div className="form-submit-area">
                <button
                    className="base-btn search-btn"
                    type="submit"
                    onClick={() => setCsv(false)}
                >
                    Buscar
                </button>
                <label> ou </label>
                <button
                    className="base-btn search-btn"
                    type="submit"
                    onClick={() => setCsv(true)}
                >
                    Baixar Tabela
                </button>
            </div>
        </form>
    </>);
}

export default CalendarMessagesSearchForm;
