import React, { useMemo, useRef, useState, useEffect } from 'react';

import datetimeUtils from '../../../../utils/datetime';

import { useApiData } from '../../../../contexts/api-data';

import {
    FaCheckCircle,
    FaTimesCircle,
} from 'react-icons/fa';

const TrueIcon = () => <FaCheckCircle color='hsl(120, 50%, 50%)' />;
const FalseIcon = () => <FaTimesCircle color='hsl(0, 50%, 50%)' />;

const EDITABLE_VARIABLES = [
    'battery_voltage',
    'charging_current',
    'modem_signal',
    'panel_voltage',
    'reset',
    'counter',
    'variance',
    'atmospheric_pressure',
    'dew_point',
    'relative_humidity',
    'temperature',
    'thermal_sensation',
    'wind_direction',
    'wind_speed',
    'precipitation',
]

const RATE_FACTOR = {
    precipitation5min: 12,
    precipitation15min: 4,
}

export function useStationMeasureWithSensorBoxStates(
        selectedStation,
        stationMeasure,
        phoneOperators,
        broadcastChannels,
        institutionId,
    ) {
    const {
        manageStationTelemetryMeasures: {
            update: updateStationTelemetryMeasures,
        },
        manageStationMeasures: {
            update: updateStationMeasures,
        },
        manageWeatherVariables: {
            value: weatherVariables,
        },
        manageTelemetryVariables: {
            value: telemetryVariables,
        },
    } = useApiData();

    const {code: stationCode, name: stationName} = selectedStation;
    const {sensorDatetimes, reorder} = stationMeasure;

    const initialMeasures = useRef([]);
    const initialTelemetryMeasures = useRef([]);

    const [updatedStationMeasure, setUpdatedStationMeasure] = useState(stationMeasure);

    const [updatedMeasures, setUpdatedMeasures] = useState(null);
    const updatedPrecipitationMeasures = updatedMeasures?.filter(m => m.key.includes('precipitation'));
    const updatedWeatherMeasures = updatedMeasures?.filter(m => !m.key.includes('precipitation'));

    const [updatedTelemetryMeasures, setUpdatedTelemetryMeasures] = useState(null);

    const formatDatetime = datetimeUtils.getFormatDatetimeFromDatetime(updatedStationMeasure.datetime, true);
    const formatDatetimeSent = datetimeUtils.getFormatDatetimeFromDatetime(sensorDatetimes.datetime_sent, true);
    const formatDatetimeReceived = datetimeUtils.getFormatDatetimeFromDatetime(sensorDatetimes.datetime_received, true);
    const operator = phoneOperators?.find(({ id }) => id === sensorDatetimes?.phone_operator_id);
    const channel = broadcastChannels?.find(({ id }) => id === sensorDatetimes?.broadcast_channel_id);

    const title = useMemo(() => stationName && formatDatetime
        ? `Estação: ${stationName}, Data de leitura: ${formatDatetime}` : '',
    [stationName, formatDatetime]);

    const ssmId = sensorDatetimes.id;
    const ssmDatetimeReceivedInputId = `station-sensor-measure-datetime-received-${ssmId}`;
    const ssmDatetimeSentInputId = `station-sensor-measure-datetime-sent-${ssmId}`;
    const ssmDatetimeInputId = `station-sensor-measure-datetime-${ssmId}`;
    const ssmChannelInputId = `station-sensor-measure-channel-${ssmId}`;
    const ssmOperatorInputId = `station-sensor-measure-operator-${ssmId}`;
    const ssmReorderInputId = `station-sensor-measure-reorder-${ssmId}`;
    const stationSensorValueInputId = `station-sensor-value-${ssmId}`;

    useEffect(() => {
        if (!telemetryVariables || !weatherVariables) {
          return ;
        }

        const variables = weatherVariables.concat(telemetryVariables)

        const filteredMeasures = Object.keys(updatedStationMeasure).reduce((result, key) => {
            if (EDITABLE_VARIABLES.some(editableVariable => key.includes(editableVariable))) {
                const variable = variables.find(v => v.key === key);
                const {
                    label,
                    type,
                } = variable;
                const value = String(updatedStationMeasure[key]);

                const measure = {
                    key,
                    label,
                    value,
                    type,
                }
                if (type) {
                    const unit = type.units.find(u => u.formula === 'x');
                    measure.label = `${label} (${unit.label})`;
                }
                result.push(measure);
            }
            return result;
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));

        const newMeasures = filteredMeasures.filter(m => m.type);
        const newTelemetryMeasures = filteredMeasures.filter(m => !m.type);

        if (JSON.stringify(newMeasures) !== JSON.stringify(updatedMeasures)) {
            setUpdatedMeasures(newMeasures);
            if (!initialMeasures.current.length) {
                initialMeasures.current = newMeasures;
            }
        }
        if (JSON.stringify(newTelemetryMeasures) !== JSON.stringify(updatedTelemetryMeasures)) {
            setUpdatedTelemetryMeasures(newTelemetryMeasures);
            if (!initialTelemetryMeasures.current.length) {
                initialTelemetryMeasures.current = newTelemetryMeasures;
            }
        }
    }, [updatedStationMeasure,
        updatedMeasures,
        updatedTelemetryMeasures,
        weatherVariables,
        telemetryVariables]);

    async function setMeasures(key, value) {
        const newValues = {
            [key]: value
        }
        if (key === 'precipitation5min') {
            newValues.precipitationtx5 = value * RATE_FACTOR[key];
        }
        if (key === 'precipitation15min') {
            newValues.precipitationtx15 = value * RATE_FACTOR[key];
        }
        setUpdatedStationMeasure((prevUpdatedStationMeasure) => ({
            ...prevUpdatedStationMeasure,
            ...newValues,
        }));
    }

    function shouldSaveMeasures() {
        const allMandatorySet = updatedMeasures.every(item => item.value !== '');
        const valuesChanged = JSON.stringify(updatedMeasures) !== JSON.stringify(initialMeasures.current);
        return allMandatorySet && valuesChanged;
    }

    function shouldSaveTelemetryMeasures() {
        const allMandatorySet = updatedTelemetryMeasures.every(item => item.value !== '');
        const valuesChanged = JSON.stringify(updatedTelemetryMeasures) !== JSON.stringify(initialTelemetryMeasures.current);
        return allMandatorySet && valuesChanged;
    }

    function shouldSave() {
        return shouldSaveMeasures() || shouldSaveTelemetryMeasures();
    }

    async function save() {
        const {
            datetime,
            sensorDatetimes,
        } = stationMeasure;
        if (shouldSaveMeasures()) {
            const measuresToSend = {
                station_code: stationCode,
                datetime,
                sensorDatetimes,
                ...updatedMeasures.reduce((acc, { key, value }) => {
                    acc[key] = value;
                    return acc;
                    }, {}),    
            };
            updateStationMeasures(institutionId, measuresToSend);
            initialMeasures.current = updatedMeasures;
            setUpdatedStationMeasure((prevUpdatedStationMeasure) => ({
                ...prevUpdatedStationMeasure,
                ...measuresToSend,
            }));

        }
        if (shouldSaveTelemetryMeasures()) {
            const telemetryMeasuresToSend = {
                station_code: stationCode,
                datetime,
                sensorDatetimes,
                ...updatedTelemetryMeasures.reduce((acc, { key, value }) => {
                    acc[key] = value;
                    return acc;
                    }, {}),
            };
            updateStationTelemetryMeasures(institutionId, telemetryMeasuresToSend)
            initialTelemetryMeasures.current =updatedTelemetryMeasures;            
            setUpdatedStationMeasure((prevUpdatedStationMeasure) => ({
                ...prevUpdatedStationMeasure,
                ...telemetryMeasuresToSend,
            }));
        }
    }

    return {
        channel,
        FalseIcon,
        formatDatetime,
        formatDatetimeSent,
        formatDatetimeReceived,
        operator,
        reorder,
        ssmDatetimeReceivedInputId,
        ssmDatetimeSentInputId,
        ssmDatetimeInputId,
        ssmChannelInputId,
        ssmOperatorInputId,
        ssmReorderInputId,
        stationSensorValueInputId,
        save,
        sensorDatetimes,
        setMeasures,
        shouldSave,
        title,
        TrueIcon,
        updatedPrecipitationMeasures,
        updatedTelemetryMeasures,
        updatedWeatherMeasures,
    }
};
