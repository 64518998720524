import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useRealTimeAlertTemplateBoxStates } from './states';

import './styles.css';

function RealTimeAlertTemplateBox({ template }) {
    const {
        creating,
        register,
        save,
        shouldSave,
        title,
    } = useRealTimeAlertTemplateBoxStates(template);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="real-time-template-box-form">
                <input
                    placeholder="*Nome"
                    {...register('name')}
                />
                <textarea
                    placeholder="*Formato (Previsão de %INPUT|10% às %INPUT|5%. Impacto: %INPUT%)"
                    {...register('format')}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default RealTimeAlertTemplateBox;
