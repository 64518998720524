import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import StationConfigurationBox from '../configuration-box';
import api from '../../../../services/api';

function StationConfigurationsList() {
    const [stationConfigurations, setStationConfigurations] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/manage/station-configurations');
            setStationConfigurations(response.data);
        })();
    }, []);

    function addNewConfiguration() {
        const id = stationConfigurations?.length ?
            Math.max(...stationConfigurations.map(protocol => protocol.id)) + 1
            : 1;
        setStationConfigurations([ ...stationConfigurations, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Configurações de estações do Alerta Rio</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewConfiguration}
            />
        </div>
        {stationConfigurations && stationConfigurations.map(configuration => (
            <StationConfigurationBox
                key={configuration.id}
                configuration={configuration}
            />
        ))}
    </>);
}

export default StationConfigurationsList;
