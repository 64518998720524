import React, { createContext, useContext } from 'react';

import {
    useMapPositionState,
    useStaticPositions,
    useShowLightnings,
    useShowAttentionPerimeters,
    useRadarAndSatelliteSelection
} from './states'

import { useApiData } from '../api-data';
import { useShapes } from '../shapes';


const MapContext = createContext();

export const useMap = () => useContext(MapContext);

export const MapProvider = ({ children, radar }) => {
    const apiData = useApiData();

    const {
        selectedRadarOrSatellite,
    } = apiData;

    const shapes = useShapes();

    const mapPosition = useMapPositionState();
    useRadarAndSatelliteSelection(radar);
    useStaticPositions();
    useShowLightnings();
    useShowAttentionPerimeters();

    return (
        <MapContext.Provider value={{
            mapPosition,
            selectedRadarOrSatellite,
            shapes
        }}>
            {children}
        </MapContext.Provider>
    );
};
