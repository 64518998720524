import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

function NewBtn({ newFunction, label }) {
    return (
        <Link
            to="#"
            className="new-btn"
            onClick={newFunction}
        >
            {label || 'Novo'}
        </Link>
    )
}

export default NewBtn;
