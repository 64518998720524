import baseApi from '../../base';

export async function getSatelliteProductTypes() {
    const result = await baseApi.get('/satellite-product-types');
    return result.data;
};

export async function postSatelliteProductTypes(productType) {
    const result = await baseApi.post('/manage/satellite-product-types', productType);
    return result.data;
};

export async function putSatelliteProductTypes(updatedProductType, productType) {
    const result = await baseApi.put(`/manage/satellite-product-types/${updatedProductType.id}`, productType);
    return result.data;
};