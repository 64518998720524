import React from 'react';

import TelemetryMeasuresBox from './components/telemetry-measures-box';
import EnergyEventsBox from './components/energy-events-box';

import './styles.css';

function RadarDashboard() {
    return (
        <div className="radar-dashboard-page">
            <h3>Informações técnicas do Radar</h3>
            <div className="tables-boxes">
                <TelemetryMeasuresBox />
                <EnergyEventsBox />
            </div>
        </div>
    );
}

export default RadarDashboard;
