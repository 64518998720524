import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import {
    postSatellites,
    putSatellites
} from '../../../../services/api';

import './styles.css';

function SatelliteBox({ satellite, satelliteInstitutions, updateSatellite }) {
    const [updatedSatellite, setUpdatedSatellite] = useState(satellite);
    const { register, watch } = useForm({
        defaultValues: {
            ...updatedSatellite,
        },
    });
    
    const creating = !updatedSatellite.name;
    
    const name = watch('name');
    const alias = watch('alias');
    const minor_latitude = watch('minor_latitude');
    const minor_longitude = watch('minor_longitude');
    const major_latitude = watch('major_latitude');
    const major_longitude = watch('major_longitude');
    const time_frequency = watch('time_frequency');
    const institution_id = watch('institution_id');
    const inactive = watch('inactive');

    function shouldSave() {
        const allMandatorySet = name?.length > 0
            && alias?.length > 0
            && minor_latitude?.length > 0
            && minor_longitude?.length > 0
            && major_latitude?.length > 0
            && major_longitude?.length > 0
            && major_longitude?.length > 0
            && Number(institution_id) > 0
            && time_frequency;
        if (creating) {
            return allMandatorySet;
        }
        const nameChanged = name !== updatedSatellite.name;
        const codeChanged = alias !== updatedSatellite.alias;
        const minorLatitudeChanged = Number(minor_latitude) !== Number(updatedSatellite.minor_latitude);
        const minorLongitudeChanged = Number(minor_longitude) !== Number(updatedSatellite.minor_longitude);
        const majorLatitudeChanged = Number(major_latitude) !== Number(updatedSatellite.major_latitude);
        const majorLongitudeChanged = Number(major_longitude) !== Number(updatedSatellite.major_longitude);
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedSatellite.inactive);
        const institutionChanged = Number(institution_id) !== Number(updatedSatellite.institution_id);
        const timeFrequencyChanged = Number(time_frequency) !== Number(updatedSatellite.time_frequency);
        return allMandatorySet && (
            nameChanged
            || codeChanged
            || minorLatitudeChanged
            || minorLongitudeChanged
            || majorLatitudeChanged
            || majorLongitudeChanged
            || inactiveChanged
            || institutionChanged
            || timeFrequencyChanged);
    }

    async function saveSatellite() {
        if (shouldSave()) {
            const satelliteToSend = {
                name,
                alias,
                minor_latitude,
                minor_longitude,
                major_latitude,
                major_longitude,
                time_frequency,
                institution_id: Number(institution_id),
                inactive,
            };
            if (creating) {
                const response = await postSatellites(satelliteToSend);
                satelliteToSend.id = response.id;
            }
            else {
                await putSatellites(updatedSatellite, satelliteToSend);
                satelliteToSend.id = updatedSatellite.id;
            }
            const oldId = updatedSatellite.id;
            setUpdatedSatellite(satelliteToSend);
            updateSatellite(oldId, satelliteToSend);
        }
    }

    const satelliteId = updatedSatellite.id || updatedSatellite.tempId;
    
    return (
        <EntityBox
            creating={creating}
            title={`${name || '*Nome'} (${alias || '*Identificador'})`}
        >
            <div className="entity-box-form satellite-box-form">
                <div className="form-group">
                    <label htmlFor={`satellite-name-${satelliteId}`}>*Nome</label>
                    <input
                        id={`satellite-name-${satelliteId}`}
                        placeholder="Nome"
                        maxLength={30}
                        {...register('name')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`satellite-alias-${satelliteId}`}>*Identificador</label>
                    <input
                        id={`satellite-alias-${satelliteId}`}
                        placeholder="Identificador"
                        maxLength={30}
                        {...register('alias')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`satellite-institution-id-${satelliteId}`}>*Instituição</label>
                    <select
                        id={`satellite-institution-id-${satelliteId}`}
                        {...register('institution_id')}
                    >
                        <option value="0" disabled>Selecione</option>
                        {satelliteInstitutions.map(institution => (
                            <option
                                key={institution.id}
                                value={institution.id}
                            >
                                {institution.name} ({institution.alias})
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor={`satellite-time-frequency-${satelliteId}`}>*Frequência dos dados (min)</label>
                    <input
                        id={`satellite-time-frequency-${satelliteId}`}
                        type="number"
                        {...register('time_frequency')}
                    />
                </div>

                <div className="rectangle">
                    <span className="title">Retângulo</span>

                    <div className="map-point">
                        <label htmlFor={`satellite-minor-latitude-${satelliteId}`}>*Superior-esquerdo</label>
                        <div className="form-group">
                            <label htmlFor={`satellite-minor-latitude-${satelliteId}`}>Latitude:</label>
                            <input
                                id={`satellite-minor-latitude-${satelliteId}`}
                                type="number"
                                {...register('minor_latitude')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`satellite-minor-longitude-${satelliteId}`}>Longitude:</label>
                            <input
                                id={`satellite-minor-longitude-${satelliteId}`}
                                type="number"
                                {...register('minor_longitude')}
                            />
                        </div>
                    </div>

                    <div className="map-point">
                        <label htmlFor={`satellite-major-latitude-${satelliteId}`}>*Inferior-direito</label>
                        <div className="form-group">
                            <label htmlFor={`satellite-major-latitude-${satelliteId}`}>Latitude:</label>
                            <input
                                id={`satellite-major-latitude-${satelliteId}`}
                                type="number"
                                {...register('major_latitude')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`satellite-major-longitude-${satelliteId}`}>Longitude:</label>
                            <input
                                id={`satellite-major-longitude-${satelliteId}`}
                                type="number"
                                {...register('major_longitude')}
                            />
                        </div>
                    </div>

                </div>

                {!creating && (
                    <div className="form-group checkbox-group">
                        <label>
                            <input
                                id={`satellite-inactive-${satelliteId}`}
                                type="checkbox"
                                {...register('inactive')}
                            />
                            Desabilitar
                        </label>
                    </div>
                )}

                <PostBtn
                    postFunction={saveSatellite}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default SatelliteBox;
