import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import ClimateForecastsControl from '../climate-forecasts-control';
import StaticPositionsMarkers from '../static-positions-markers';
import { useAuth } from '../../contexts/auth';
import { useApiData } from '../../contexts/api-data';

function ClimateMap() {
    const { settingsSet } = useAuth();
    const {
        retrieveStaticPositions,
        staticPositionTypes,
    } = useApiData();

    const [staticPositions, setStaticPositions] = useState([]);
    
    const {
        INITIAL_MAP_POSITION,
        INITIAL_MAP_ZOOM = 8,
        STATIC_POSITION_TYPE_ALIASES,
    } = { ...settingsSet };
    
    const initialMapZoom = Number(INITIAL_MAP_ZOOM);
    const [initialLatitude, initialLongitude] = INITIAL_MAP_POSITION
        ? INITIAL_MAP_POSITION.split(',').map(Number)
        : [null, null];

    useEffect(() => {
        if (!STATIC_POSITION_TYPE_ALIASES || !staticPositionTypes) {
            return;
        }
        (async function() {
            const staticPositions = (await Promise.all(STATIC_POSITION_TYPE_ALIASES
                .filter(alias => alias.startsWith('obra'))
                .map(alias => retrieveStaticPositions(alias))))
                .reduce((result, current) => result.concat(current), []);
            setStaticPositions(staticPositions);
        })();
    }, [retrieveStaticPositions, STATIC_POSITION_TYPE_ALIASES, staticPositionTypes]);

    return (initialLatitude && initialLongitude && initialMapZoom) ? (
        <MapContainer
            className="climate-map"
            center={[initialLatitude, initialLongitude]}
            zoom={initialMapZoom}
            zoomControl={false}
            style={{ width: '100%', height: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={17}
            />

            <ClimateForecastsControl />

            <StaticPositionsMarkers staticPositions={staticPositions} />
        </MapContainer>
    ) : null;
}

export default ClimateMap;
