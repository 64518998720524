import baseApi from '../../base';

export async function filterRadarEnergyEvents({
    radar_id,
    datetimeStart,
    datetimeEnd,
}) {
    const queryObject = {
        datetimeStart,
        datetimeEnd,
    };
    const query = Object.keys(queryObject).map(key => {
        return `${key}=${queryObject[key]}`;
    }).join('&');
    const url = `/radars/${radar_id}/energy-events?${query}`;
    const result = await baseApi.get(url);
    return result.data.data;
};

export async function getRadarLastEnergyEvent(radar_id) {
    const url = `/radars/${radar_id}/last-energy-event`;
    const result = await baseApi.get(url);
    return result.data;
};
