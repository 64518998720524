import { useState, useMemo, useEffect, useCallback } from 'react';

import {
    putStationsSlipStages,
} from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import { useSocket } from '../../../../hooks/use-socket';

export const slipStages = [
    {
        level: 1,
        label: 'Baixo',
        color: '#008200',
    },
    {
        level: 2,
        label: 'Médio',
        color: '#E3D000',
    },
    {
        level: 3,
        label: 'Alto',
        color: '#FF0000',
    },
    {
        level: 4,
        label: 'Muito Alto',
        color: '#000000',
    },
];

export function useStationsSlipStagesStates() {
    const {
        manageStations: {
            fetchByInstitution,
            value: rawStations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
    } = useApiData();

    const [allStations, setAllStations] = useState(null);
    const [stationsStages, setStationsStages] = useState(null);
    const [acceptedChanges, setAcceptedChanges] = useState(false);

    const stations = allStations ? allStations.filter(({ inactive }) => !inactive) : null;

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    useEffect(() => {
        if (!rawStations) return;
        setAllStations(rawStations
            .filter(({ inactive }) => !inactive)
            .sort((a, b) => Number(a.code) - Number(b.code))
        );
    }, [institutionId, rawStations]);

    useEffect(() => {
        if (stations || !institutionId) return;       
        fetchByInstitution(institutionId);
    }, [fetchByInstitution, institutionId, stations]);

    useSocket(
        'stations-weather-measures',
        useCallback((data) => {
            setAllStations(
                stations => {
                    if (!stations) {
                        return null;
                    }
                    return stations.map(station => {
                        const { id } = station;
                        const newMeasure = data.find(({ station_id }) => station_id === id)?.variables;
                        if (newMeasure) {
                            const {
                                SLIP_STAGE_LEVEL,
                                AUTOMATIC_SLIP_STAGE,
                                SLIP_STAGE_PERIOD,
                            } = newMeasure;
                            station.measures = {
                                ...station.measures,
                                SLIP_STAGE_LEVEL,
                                AUTOMATIC_SLIP_STAGE,
                                SLIP_STAGE_PERIOD,
                            };
                        }
                        return station;
                    })
                }
            );
        }, []),
    );

    useSocket(
        'stations-slip-stages',
        useCallback((data) => {
            setAllStations(
                stations => {
                    if (!stations) {
                        return null;
                    }
                    return stations.map(station => {
                        const newMeasure = data.find(({ station_code }) => station_code === station.code);
                        if (newMeasure) {
                            const {
                                slip_stage_level,
                            } = newMeasure;
                            station.measures.SLIP_STAGE_LEVEL = slip_stage_level;
                        }
                        return station;
                    })
                }
            );
        }, []),
    );

    function changeStage(station_code, slip_stage_level) {
        const newStationsStages = stationsStages ? [...stationsStages] : [];
        const stationIndex = newStationsStages.findIndex(s => s.station_code === station_code);
        if (stationIndex === -1) {
            newStationsStages.push({
                station_code,
                slip_stage_level,
            });
        }
        else if (slip_stage_level) {
            newStationsStages[stationIndex].slip_stage_level = slip_stage_level;
        }
        setStationsStages(newStationsStages);
    }

    function stationDiffer(station) {
        const oldStation = stations.find(({ code }) => code === station.station_code);
        return Number(station.slip_stage_level) !== Number(oldStation.measures?.SLIP_STAGE_LEVEL);
    }

    function shouldSaveStages() {
        if (!stationsStages || stationsStages.length === 0) {
            return false;
        }
        return stationsStages.some(stationDiffer);
    }

    async function saveStages() {
        if (!shouldSaveStages()) {
            return;
        }
        const stationsToSend = stationsStages.filter(stationDiffer);
        await putStationsSlipStages(institutionId, stationsToSend);
        setStationsStages(null);
        setAcceptedChanges(false);
    }

    function shouldAcceptChanges() {
        return !acceptedChanges && stations?.some(({ measures }) => {
            const {
                AUTOMATIC_SLIP_STAGE,
                SLIP_STAGE_LEVEL,
            } = { ...measures };
            if (!AUTOMATIC_SLIP_STAGE && !SLIP_STAGE_LEVEL) {
                return false;
            }
            return Number(AUTOMATIC_SLIP_STAGE) !== Number(SLIP_STAGE_LEVEL);
        });
    }

    async function acceptChanges() {
        if (!shouldAcceptChanges()) {
            return;
        }
        const stationsToChange = stations.filter(({ measures }) => {
            const {
                AUTOMATIC_SLIP_STAGE,
                SLIP_STAGE_LEVEL,
            } = { ...measures };
            if (!AUTOMATIC_SLIP_STAGE && !SLIP_STAGE_LEVEL) {
                return false;
            }
            return Number(AUTOMATIC_SLIP_STAGE) !== Number(SLIP_STAGE_LEVEL);
        }).map(({ code, measures }) => {
            const {
                AUTOMATIC_SLIP_STAGE,
            } = { ...measures };
            return {
                station_code: code,
                slip_stage_level: AUTOMATIC_SLIP_STAGE,
            };
        });
        setStationsStages(stationsToChange);
        setAcceptedChanges(true);
    }

    return {
        acceptChanges,
        stations,
        stationsStages,
        slipStages,
        changeStage,
        saveStages,
        shouldAcceptChanges,
        shouldSaveStages,
    };
};
