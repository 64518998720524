import React, { Fragment } from 'react';
import { LayerGroup, Marker, Popup as MapPopup } from 'react-leaflet';
import Leaflet from 'leaflet';

import { useApiData } from '../../contexts/api-data';

import alagaIconUrl from '../../media/alaga-icon.png';
import cameraIconUrl from '../../media/camera-icon.png';
import obraIconUrl from '../../media/obra-icon.png';

const rawIcons = [
    {
        iconUrl: alagaIconUrl,
        iconSize: [35, 25],
    },
    {
        iconUrl: cameraIconUrl,
        iconSize: [25, 25],
    },
    {
        iconUrl: obraIconUrl,
        iconSize: [30, 30],
    },
];

const [
    alagaIcon,
    cameraIcon,
    obraIcon,
] = rawIcons.map(({ iconUrl, iconSize }) => Leaflet.icon({
    iconUrl,
    iconSize,
    popupAnchor: [1, -4],
}));

function StaticPositionsMarkers({ staticPositions }) {
    const apiData = useApiData();
    const positions = staticPositions || apiData.staticPositions;

    const aliasIcons = {
        camera: cameraIcon,
        alaga: alagaIcon,
        obra: obraIcon,
    };
    const idAliasMap = {};
    apiData.staticPositionTypes && apiData.staticPositionTypes.forEach(type => {
        idAliasMap[type.id] = type.alias;
    });

    return positions && positions.length
        ? (
            <LayerGroup>
                {positions.map(staticPosition => {
                    const staticPositionTypeAlias = idAliasMap[staticPosition.type_id];
                    const iconAlias = ['obra', 'alaga', 'camera'].find(prefix => (
                        staticPositionTypeAlias.startsWith(prefix)
                    ));
                    const icon = aliasIcons[iconAlias];

                    return (
                        <Fragment key={staticPosition.id}>
                            {staticPosition.show_icon &&(
                                <Marker
                                    key={staticPosition.id}
                                    icon={icon}
                                    position={[staticPosition.latitude, staticPosition.longitude]}
                                    >
                                    <MapPopup>
                                        {staticPosition.description}
                                    </MapPopup>
                                </Marker>
                            )}
                        </Fragment>
                    );
                })
            }
            </LayerGroup>
        )
        : null;
}

export default StaticPositionsMarkers;
