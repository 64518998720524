import downloadFile from '../../../download-file';
import baseApi from '../../base';

import datetimeUtils from '../../../../utils/datetime';

function getDownloadQuery({
    datetimeStart,
    datetimeEnd,
    variables,
}) {
    const queryObject = {
        datetimeStart,
        datetimeEnd,
        csv: true,
        variables: variables.join(','),
    };
    return Object.keys(queryObject).reduce((result, param) => {
        return result.concat(`${param}=${queryObject[param]}`);
    }, []).join('&');
}

function getDownloadFilename({
    datetimeStart,
    datetimeEnd,
    station,
}) {
    const [start, end] = [
        ['i', datetimeStart],
        ['f', datetimeEnd],
    ].map(([prefix, datetimeInMs]) => {
        const datetime = datetimeUtils.getFilenameFormatDatetimeFromDatetime(datetimeInMs, true);
        return `${prefix}${datetime}`;
    });
    return `${start}_${end}_${station.name}.csv`;
}

export async function downloadStation(queryParams) {
    const station_id = queryParams.station.id;
    const query = getDownloadQuery(queryParams);
    const filename = getDownloadFilename(queryParams);
    const result = await baseApi.get(
        `/v2/stations/${station_id}/measures?${query}`,
        { responseType: 'blob' },
    );
    downloadFile(result.data, filename);
};

export async function getManageStationByInstitutions(institution_id) {
    const response = await baseApi.get(`/manage/station-institutions/${institution_id}/stations`);
    return response.data;
};

export async function getV2ManageStationByInstitutions(institution_id, filterByCity) {
    const url = `/v2/station-institutions/${institution_id}/stations`;
    const query = filterByCity ? `?filter_by_city=${filterByCity}` : ''
    const response = await baseApi.get(`${url}${query}`);
    return response.data;
};

export async function filterStationWeatherMeasures({
    stationId,
    datetimeStart,
    datetimeEnd,
    variables,
}) {
    const queryObject = {
        datetimeStart,
        datetimeEnd,
        variables,
    };
    const query = Object.keys(queryObject).map(key => {
        return `${key}=${queryObject[key]}`;
    }).join('&');
    const url = `/v2/stations/${stationId}/measures?${query}`;
    const result = await baseApi.get(url);
    return result.data.data;
};

export async function filterStationTelemetryMeasures({
    stationId,
    datetimeStart,
    datetimeEnd,
    variables,
}) {
    const queryObject = {
        datetimeStart,
        datetimeEnd,
        variables,
    };
    const query = Object.keys(queryObject).map(key => {
        return `${key}=${queryObject[key]}`;
    }).join('&');
    const url = `/stations/${stationId}/telemetry-measures?${query}`;
    const result = await baseApi.get(url);
    return result.data.data;
};

export async function getManageStations(){
    const result = await baseApi.get('/manage/stations');
    return result.data;
};

export async function postManageStations(stations) {
    await baseApi.post('/manage/stations', stations);
};
