import React, { useEffect, useState } from 'react';

import datetimeUtils from '../../utils/datetime';

import './styles.css';

const weekDays = [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
];

function CalendarDates({ days, datetime, setDatetime, messages }) {
    const [dates, setDates] = useState([]);

    useEffect(() => {
        const newDates = [];
        const nowMs = Date.now();
        const now = datetimeUtils.getInputFormatDateFromDatetime(nowMs);
        const todayMs = datetimeUtils.getUTCZeroHourDatetimeFromInputFormatDate(now);
        const todayWeekDay = (new Date(nowMs)).getDay();
        const increment = (7 - ((days+todayWeekDay) % 7)) % 7;
        const totalDays = days + increment;
        for (let i=-todayWeekDay; i<totalDays; i++) {
            const currentDayUTCMs = datetimeUtils.addNDays(todayMs, i);
            const currentDay = new Date(datetimeUtils.addNDays(nowMs, i));
            const date = currentDay.toLocaleDateString().substr(0, 5);
            const hasMessages = messages[currentDayUTCMs]?.length > 0;
            newDates.push({
                datetime: currentDayUTCMs,
                date,
                hasMessages,
                disabled: i < 0 || i >= days,
            });
        }
        setDates(newDates);
    }, [days, messages]);

    return (
        <div className="calendar-dates">
            {weekDays.map(weekDay => (
                <span key={weekDay} className="week-day">{weekDay}</span>
            ))}
            {dates.map(({ datetime: currentDatetime, date, hasMessages, disabled }) => {
                const hasMessagesClass = hasMessages ? 'has-messages' : '';
                const selectedClass = currentDatetime === datetime ? 'selected' : '';
                const disabledClass = disabled ? 'disabled' : '';
                return (
                    <button
                        key={currentDatetime}
                        onClick={() => !disabled && setDatetime(currentDatetime)}
                        className={`date-btn ${hasMessagesClass} ${selectedClass} ${disabledClass}`}
                    >
                        {date}
                    </button>
                );
            })}
        </div>
    );
}

export default CalendarDates;
