import { useEffect, useState } from 'react';

import { useAuth } from '../../../contexts/auth';
import datetimeUtils from '../../../utils/datetime';
import { getAstronomicForecasts } from '../../../services/api';

export function useAstronomicForecastsRetrieval({ periodInMs, forecastsVariables }) {
    const {
        settingsSet,
    } = useAuth();
    const [forecasts, setForecasts] = useState([]);

    const {
        ASTRONOMIC_FORECASTS_HARBOR: harborId,
    } = { ...settingsSet };

    useEffect(() => {
        if (!('astronomic' in forecastsVariables) || !harborId) {
            return;
        }
        const now = Date.now();
        const today = datetimeUtils.getInputFormatDateFromDatetime(now);
        const datetimeStart = datetimeUtils.getZeroHourDatetimeFromInputFormatDate(today);
        const datetimeEnd = datetimeStart + periodInMs;

        (async function() {
            const forecasts = await getAstronomicForecasts(harborId, datetimeStart, datetimeEnd);
            setForecasts(forecasts);
        })();
    }, [
        harborId,
        forecastsVariables,
        periodInMs,
    ]);

    return {
        forecasts,
    };
};
