import React from 'react';
import { Chart } from 'react-google-charts';

import { useApiData } from '../../contexts/api-data';
import useInterval from '../../hooks/use-interval';

function ForecastChartBox() {
    const {
        forecasts,
        retrieveForecastsForCor,
        selectForecastTime,
        selectedForecastRegion,
    } = useApiData();

    useInterval(retrieveForecastsForCor, 60000);

    const barColors = [
        '#2AAD27',
        '#2A81CB',
        '#FFD326',
        '#CB8427',
    ];

    const data = forecasts && forecasts[selectedForecastRegion?.alias] && [
        [
            { type: 'string', label: '' },
            { type: 'number', label: '' },
            { type: 'string', role: 'style' },
        ],
        ...forecasts[selectedForecastRegion?.alias].map(({ datetime, precipitationClass }) => {
            const hour = new Date(datetime).toLocaleTimeString().substr(0, 2);
            return [
                `${Number(hour)} h`,
                precipitationClass,
                `color:${barColors[precipitationClass]}; opacity:0.75`,
            ];
        }),
    ];

    function chartSelect({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length > 0) {
            selectForecastTime(selection[0].row);
        }
    }

    return (
        <div className="dashboard-box7">
            <div id="descricao-tit-3" className="db-titulo">
                Previsão de chuva para as próximas 12 horas na região {selectedForecastRegion?.name}
            </div>
            <div id="container2">
                {data && <Chart
                    chartType="ColumnChart"
                    data={data}
                    options={{
                        title: '* Clique sobre as barras para exibir a descrição da previsão em formato de texto e facilitar o seu compartilhamento.',
                        titleTextStyle: {
                            color: 'red',
                            bold: true,
                        },
                        colors: ['#104E8B'],
                        legend: { position: 'none' },
                        width: '99%',
                        height: '99%',
                        hAxis: {
                            title: 'Hora',
                        },
                        vAxis: {
                            ticks: [
                                { v: 0, f: 'Sem chuva' },
                                { v: 1, f: 'Fraca' },
                                { v: 2, f: 'Moderada' },
                                { v: 3, f: 'Forte' },
                            ],
                        },
                    }}
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: chartSelect,
                        }
                    ]}
                />}
            </div>
        </div>
    );
}

export default ForecastChartBox;
