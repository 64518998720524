import { useCallback, useState } from 'react';

import {
    getStationExtraDataByStation,
    postStationExtraData,
} from '../../../../services/api';

export function useStationExtraData(stationId) {
    const [extraData, setExtraData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialExtraData, setInitialExtraData] = useState(null);

    const fetch = useCallback(async () => {
        if (extraData || loading) {
            return;
        }
        setLoading(true);
        const newExtraData = await getStationExtraDataByStation(stationId);
        setExtraData(newExtraData);
        setInitialExtraData(newExtraData);
        setLoading(false);
    }, [extraData, stationId, loading]);

    function updateExtraData(key, value) {
        setExtraData(old => ({
            ...old,
            [key]: value,
        }));
    }

    function shouldSave() {
        return JSON.stringify(extraData || null) !== JSON.stringify(initialExtraData);
    }

    async function save() {
        await postStationExtraData(stationId, extraData);
        setInitialExtraData(extraData);
    }

    return {
        extraData,
        fetch,
        loading,
        save,
        shouldSave,
        updateExtraData,
    };
};
