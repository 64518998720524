import jwt_decode from 'jwt-decode';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import AuthError from '../../components/auth-error';
import AuthTemplate from '../../components/auth-template';
import { useAuth } from '../../contexts/auth';
import { useQuery } from '../../utils/hooks/query';

import './styles.css';

function ResetPassword() {
    const {
        isAuthenticated,
        resetPassword,
        sendResetPasswordEmail,
    } = useAuth();
    const history = useHistory();
    const { code } = useQuery();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [sendingRequest, setSendingRequest] = useState(false);
    
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [requestSuccess, setRequestSuccess] = useState(false);

    const getResetPasswordCodeError = useCallback(() => {
        try {
            const { email, id, exp } = jwt_decode(code);
            if (Date.now() >= exp*1000) {
                return ['Código de redefinição expirado.', null];
            }
            if (!email || !id) {
                throw new Error();
            }
            const user = { email, id };
            return [false, user];
        }
        catch {
            return ['Código de redefinição inválido.', null];
        }
    }, [code]);

    // Redirection rules
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
            return ;
        }

        if (!code) {
            return ;
        }
        const [error, user] = getResetPasswordCodeError();
        if (error) {
            setCodeError(error);
            return;
        }

        setEmail(user.email);
    }, [isAuthenticated, history, code, getResetPasswordCodeError]);

    function redirectToLogin() {
        setTimeout(() => history.push('/login'), 5000);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        // errors
        if (
            (!code
            // send email
            ? [
                [!email, setEmailError, 'E-mail é obrigatório'],
            ]
            // reset password
            : [
                [!password, setPasswordError, 'Senha é obrigatória'],
                [(password !== confirmPassword), setConfirmPasswordError, 'Senhas não combinam'],
            ])
            .map(([comparison, setStateFn, message]) => {
                setStateFn(comparison ? message: '');
                return comparison;
            })
            .some(value => value)
        ) {
            return;
        }
        
        setSendingRequest(true);
        
        const result = !code
            ? await sendResetPasswordEmail(email)
            : await resetPassword(code, password);
        if (result.errors) {
            const errorsCodes = result.errors.map(({ code }) => code);
            
            const [, message] = [
                ['ERROR_SEND_RESET_PASSWORD_EMAIL_USER_NOT_FOUND', 'Usuário não encontrado'],
                ['ERROR_SEND_RESET_PASSWORD_EMAIL_FAILED', 'Envio do e-mail falhou. Contate nossa equipe'],
                ['INTERNAL_ERROR', 'Erro no servidor. Contate nossa equipe'],
            ].find(([errorCode]) => errorsCodes.includes(errorCode));

            setEmailError(message);
        }
        else {
            setRequestSuccess(true);
            code && redirectToLogin();
        }
        
        setSendingRequest(false);
    }

    return (
        <AuthTemplate title="Redefinir Senha" loading={sendingRequest}>
            {requestSuccess
                ? (
                    <div className="request-result">
                        <span className="request-success">
                            {!code
                                ? 'Uma mensagem foi enviada para o seu e-mail com o link de redefinição'
                                : 'Sua senha foi alterada com sucesso!'}
                        </span>
                        {code && <span>Redirecionando para a tela de Login...</span>}
                    </div>
                )
                : codeError
                    ? (
                        <div className="request-result">
                            <span>Houve um erro com o código de convite:</span>
                            <AuthError error={codeError} />
                        </div>
                    )
                    : (
                        <form
                            className="reset-password-form"
                            onSubmit={handleSubmit}
                        >
                            <span className="mandatory-info">Campos com * são obrigatórios!</span>
                            <div>
                                <label htmlFor="email">* E-mail</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    disabled={code}
                                />
                                <AuthError error={emailError} />
                            </div>
                            {code && <>
                                <div className="form-group">
                                    <label htmlFor="password">* Nova Senha</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={event => setPassword(event.target.value)}
                                    />
                                    <AuthError error={passwordError} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirm-password">* Confirmar Nova Senha</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="confirm-password"
                                        value={confirmPassword}
                                        onChange={event => setConfirmPassword(event.target.value)}
                                    />
                                    <AuthError error={confirmPasswordError} />
                                </div>
                            </>}
                            <Link
                                className="back-to-login-link"
                                to="/login"
                            >
                                Voltar para login
                            </Link>
                            <button type="submit" className="btn">
                                {!code ? 'Solicitar Redefinição' : 'Alterar Senha'}
                            </button>
                        </form>
                    )
            }
        </AuthTemplate>
    );
}

export default ResetPassword;
