import { useState } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useBasinsStagesStates() {
    const {
        manageBasins: {
            useFetchAndSocket: useBasinsFetchAndSocket,
            changeStages,
            value: allBasins,
        },
        manageBasinPrecipitationStages: {
            useFetchAndSocket: usePrecipitationStagesFetchAndSocket,
            value: precipitationStages,
        },
        manageBasinSlipStages: {
            useFetchAndSocket: useSlipStagesFetchAndSocket,
            value: slipStages,
        },
    } = useApiData();

    const [basinsStages, setBasinsStages] = useState(null);

    useBasinsFetchAndSocket();
    
    usePrecipitationStagesFetchAndSocket();

    useSlipStagesFetchAndSocket();

    const basins = allBasins ? allBasins.filter(({ inactive }) => !inactive) : null;

    function changeStage(basinId, { precipitation_stage_id, slip_stage_id }) {
        const newBasinsStages = basinsStages ? [...basinsStages] : [];
        const basinIndex = newBasinsStages.findIndex(({ id }) => basinId === id);
        if (basinIndex === -1) {
            newBasinsStages.push({
                id: basinId,
                precipitation_stage_id,
                slip_stage_id,
            });
        }
        else {
            if (precipitation_stage_id) {
                newBasinsStages[basinIndex].precipitation_stage_id = precipitation_stage_id;
            }
            if (slip_stage_id) {
                newBasinsStages[basinIndex].slip_stage_id = slip_stage_id;
            }
        }
        setBasinsStages(newBasinsStages);
    }

    function basinDiffer(basin) {
        const oldBasin = basins.find(({ id }) => id === basin.id);
        return basin.precipitation_stage_id !== oldBasin.precipitation_stage_id
            || basin.slip_stage_id !== oldBasin.slip_stage_id;
    }

    function shouldSaveStages() {
        if (!basinsStages || basinsStages.length === 0) {
            return false;
        }
        return basinsStages.some(basinDiffer);
    }

    async function saveStages() {
        if (!shouldSaveStages()) {
            return;
        }
        const basinsToSend = basinsStages.filter(basinDiffer);
        await changeStages(basinsToSend);
        setBasinsStages(null);
    }

    return {
        basins,
        basinsStages,
        precipitationStages,
        slipStages,
        changeStage,
        saveStages,
        shouldSaveStages,
    };
};
