import React, { useEffect } from 'react';

import StationInstitutionCard from '../institution-card';

import { useApiData } from '../../../../contexts/api-data';
import { useAuth } from '../../../../contexts/auth';

import './styles.css';

function DownloadStationMeasurements() {
    const {
        manageStationInstitutions: {
            value: stationInstitutions,
            fetch: fetchStationInstitutions,
        }
    } = useApiData();

    const { settingsSet } = useAuth();
    const {
        STATION_INSTITUTION_ALIASES = [],
    } = { ...settingsSet };

    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    const stationInstitutionsToShow = (stationInstitutions || []).filter(
        ({ alias }) => STATION_INSTITUTION_ALIASES.includes(alias)
    );

    return (<>
        <h3>Instituições de Estações</h3>
        <div className="download-station-institutions-grid">
            {stationInstitutionsToShow.map(institution => (
                <StationInstitutionCard
                    key={institution.id}
                    institution={institution}
                />
            ))}
        </div>
    </>);
}

export default DownloadStationMeasurements;
