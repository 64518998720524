import React, { useMemo } from 'react';

import SelectionInput from '../selection-input';
import { useApiData } from '../../../../contexts/api-data';

function CustomerSelectionInput({
    defaultValue,
    defaultOption,
    setValue,
    isNullable = true,
    inputId,
    showOnlyActive = false
}) {
    const {
        manageCustomers: {
            value: customers,
        },
    } = useApiData();

    const options = useMemo(() => {
        return customers
            ?.filter(customer => !showOnlyActive || !customer.inactive)
            .map(({ id, name }) => ({
                value: id,
                label: `${name}`
            }));
    }, [customers, showOnlyActive]);

    return options?.length ? (
        <SelectionInput
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            setValue={setValue}
            options={options}
            isNullable={isNullable}
            inputId={inputId}
        />
    ) : null;
}

export default CustomerSelectionInput;
