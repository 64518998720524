import { useState } from 'react';

import {
    getMockApiSetting,
    mockApiOptions,
    setMockApiSetting,
} from '../../../../services/api/mocks/utils';

export function useMockApiSettingState() {
    const [setting, setSetting] = useState(getMockApiSetting);

    function updateSetting(keys) {
        setMockApiSetting(keys);
        setSetting(keys);
    }

    return {
        mockApiOptions,
        setting,
        updateSetting,
    };
};
