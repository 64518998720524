import jwt_decode from 'jwt-decode';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthError from '../../components/auth-error';
import AuthTemplate from '../../components/auth-template';
import { useAuth } from '../../contexts/auth';
import { useQuery } from '../../utils/hooks/query';

import './styles.css';

function Register() {
    const { isAuthenticated, register } = useAuth();
    const history = useHistory();
    const queryParams = useQuery();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [registering, setRegistering] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [inviteCodeError, setInviteCodeError] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const getInviteCodeError = useCallback(() => {
        try {
            const { id, email, name, exp } = jwt_decode(queryParams.code);
            if (Date.now() >= exp*1000) {
                return ['Código de convite expirado.', null];
            }
            if (!id || !email) {
                throw new Error();
            }
            return [false, { id, email, name }];
        }
        catch {
            return ['Código de convite inválido.', null];
        }
    }, [queryParams]);

    // Redirection rules
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
            return ;
        }
        if (id) {
            return ;
        }
        const [error, user] = getInviteCodeError();

        if (error) {
            setInviteCodeError(error);
            return;
        }
        setId(user.id);
        setName(user.name);
        setEmail(user.email);
    }, [isAuthenticated, history, id, getInviteCodeError]);

    function redirectToLogin() {
        setTimeout(() => history.push('/login'), 5000);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        
        const errors = [
            [!email, setEmailError, 'E-mail é obrigatório'],
            [!password, setPasswordError, 'Senha é obrigatória'],
            [(password !== confirmPassword), setConfirmPasswordError, 'Senhas não combinam'],
        ].map(([comparison, setStateFn, message]) => {
            setStateFn(comparison ? message: '');
            return comparison;
        });
        
        if (errors.some(value => value)) {
            return;
        }
        
        setRegistering(true);
        
        const result = await register(queryParams.code, id, email, name, password);
        if (result.errors) {
            const errorsCodes = result.errors.map(({ code }) => code);
            [
                ['ERROR_REGISTER_DUPLICATE_EMAIL', setEmailError, 'E-mail já cadastrado'],
            ].forEach(([errorCode, setStateFn, message]) => {
                const error = errorsCodes.includes(errorCode);
                setStateFn(error ? message : '');
            });
        }
        else {
            setRegisterSuccess(true);
            redirectToLogin();
        }
        
        setRegistering(false);
    }

    return (
        <AuthTemplate title="Registro" loading={registering}>
            {registerSuccess
                ? (
                    <div className="register-result">
                        <span className="register-success">Registro efetuado com sucesso!</span>
                        <span>Redirecionando para a tela de Login...</span>
                    </div>
                )
                : inviteCodeError
                    ? (
                        <div className="register-result">
                            <span>Houve um erro com o código de convite:</span>
                            <AuthError error={inviteCodeError} />
                        </div>
                    )
                    : (
                        <form onSubmit={handleSubmit}>
                            <span className="mandatory-info">Campos com * são obrigatórios!</span>
                            <div>
                                <label htmlFor="email">* E-mail</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    value={email}
                                    disabled={true}
                                />
                                <AuthError error={emailError} />
                            </div>
                            <div>
                                <label htmlFor="name">Nome e Sobrenome (máximo 30 caracteres)</label>
                                <input
                                    className="form-control"
                                    id="name"
                                    maxLength={40}
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password">* Senha</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <AuthError error={passwordError} />
                            </div>
                            <div>
                                <label htmlFor="confirm-password">* Confirmar Senha</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="confirm-password"
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                />
                                <AuthError error={confirmPasswordError} />
                            </div>
                            <button type="submit" className="btn">Registrar</button>
                        </form>
                    )
            }
        </AuthTemplate>
    );
}

export default Register;
