import {useEffect, useRef, useState} from 'react';
import { useSatelliteState } from './satellites';
import { useSatelliteProductTypesState } from './satellites-product-types';
import { useAuth } from '../../../../contexts/auth';
import * as userUtils from '../../../../utils/user';
import { 
    getSatelliteProducts,
} from '../../../../services/api'; 

export function useSelectedSatellites(){
    const { settingsSet } = useAuth();
    const satelliteProductsTimeoutIndex = useRef();
    const [selectedSatellite, setSelectedSatellite] = useState(null);
    const [satelliteProducts, setSatelliteProducts] = useState(null);
    const [selectedSatelliteProductType, setSelectedSatelliteProductType] = useState(null);

    const {
        fetchAndSave,
        value: satellites
    } = useSatelliteState();

    const {
        fetchAndSave: fetchAndSaveProductTypes,
        value: satelliteProductTypes
    } = useSatelliteProductTypesState();

    async function selectSatelliteAndProduct(satelliteAlias, satelliteProductTypeAlias){
        const { 
            SATELLITE_ALIASES = [],
            SATELLITE_PRODUCT_TYPES_ID = []
         } = { settingsSet };

        if (!satelliteAlias && !satelliteProductTypeAlias) {

            const selectedSatelliteProductTypeAlias = userUtils.getSelectedSatelliteProductType(satelliteProductTypeAlias);
            const selectedSatellitesProductType = satelliteProductTypes.find(({ alias }) => alias === selectedSatelliteProductTypeAlias);
            const firstSatelliteProductType = SATELLITE_PRODUCT_TYPES_ID.find(satelliteProductTypeAlias => {
                const satelliteProductType = satelliteProductTypes.find(({alias}) => alias === satelliteProductTypeAlias);
                return satelliteProductType;
            })

            satelliteProductTypeAlias = !selectedSatellitesProductType ? firstSatelliteProductType : selectedSatelliteProductTypeAlias;

            const selectedSatelliteAlias = userUtils.getSelectedSatellite(satelliteAlias);
            const selectedSatellites = satellites.find(({ alias }) => alias === selectedSatelliteAlias);
            const firstActiveSatelliteAlias = SATELLITE_ALIASES.find(satelliteAlias => {
                const satellite = satellites.find(({ alias }) => alias === satelliteAlias);
                return !satellite.inactive;
            });
            satelliteAlias = (!selectedSatellites || selectedSatellites.inactive) ? firstActiveSatelliteAlias : selectedSatelliteAlias;
        }

        const satellite = satellites.find(satellite => satellite.alias === satelliteAlias);
        const satelliteProductType = satelliteProductTypes.find(satelliteProductType => satelliteProductType.alias === satelliteProductTypeAlias)

        if (!satellite || !satelliteProductType) {
            return;
        }

        userUtils.setSelectedSatelliteProductType(satelliteProductTypeAlias);
        userUtils.setSelectedRadarsOrSatellite(satelliteAlias);
        userUtils.setSelectedSatellite(satelliteAlias)

        if (satelliteProductsTimeoutIndex.current) {
            clearTimeout(satelliteProductsTimeoutIndex.current);
        }

        await listSatelliteProducts(satellite, satelliteProductType);

        setSelectedSatellite(satellite);
        setSelectedSatelliteProductType(satelliteProductType);
    }

    const listSatelliteProducts = async (satellite,satelliteProductType) => {
        const result = await getSatelliteProducts(satellite,satelliteProductType);
        setSatelliteProducts(result.reverse());
        const timeout = Number(satellite.time_frequency) * 60000; // in minutes
        
        satelliteProductsTimeoutIndex.current = setTimeout(() => listSatelliteProducts(satellite, satelliteProductType), timeout);
    }

    function resetSatellite() {
        setSelectedSatellite(null);
        setSatelliteProducts(null);
        setSelectedSatelliteProductType(null);
    }

    useEffect(() => {
         if (satellites && satellites.length && settingsSet) {
            selectSatelliteAndProduct();
        }
        fetchAndSave();
        fetchAndSaveProductTypes();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satellites, settingsSet, fetchAndSave,fetchAndSaveProductTypes]);

    return{
        satelliteProducts,
        selectSatelliteAndProduct,
        selectedSatellite,
        satellites,
        selectedSatelliteProductType,
        resetSatellite
    }
}