import React, { Fragment } from 'react';

import PostBtn from '../../common/post-btn';
import MockApiSetting from '../mock-api-setting';

import {
    settingsKeys,
    useSystemSettingsPageState,
} from './states';

import './styles.css';

function SystemSettings() {
    const {
        systemSettings,
        initialSettings,
        save,
        shouldSave,
        updateSystemSettings,
    } = useSystemSettingsPageState();

    return (<>
        <div className="header">
            <h3>Configurações do Sistema</h3>
            {initialSettings && (
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            )}
        </div>

        <div className="system-settings-container">
            {initialSettings && settingsKeys.map(({ key, label, SettingType }) => (
                <Fragment key={key}>
                    <span>{label}</span>
                    <SettingType
                        defaultValue={systemSettings[key]}
                        setValue={(newValue) => updateSystemSettings(key, newValue)}
                    />
                </Fragment>
            ))}
            <h3>Debug</h3>
            <MockApiSetting />
        </div>
    </>);
}

export default SystemSettings;
