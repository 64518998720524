import { useCallback, useState } from 'react';

import { getSystemSettings } from '../../../services/api';

export function useSystemSettingsState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function() {
        if (value || loading) {
            return;
        }
        setLoading(true);
        const settings = await getSystemSettings();
        setValue(settings);
        setLoading(false);
    }, [value, loading]);

    return {
        fetch,
        loading,
        set: setValue,
        value,
    };
};
