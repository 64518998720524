import baseApi from '../../base';

const baseResource = '/temperature-zones';
const baseManageResource = `/manage${baseResource}`;

export async function getTemperatureZones(forecast_region_id) {
    const result = await baseApi.get(`${baseResource}?forecast_region_id=${forecast_region_id}`);
    return result.data;
};

export async function getManageTemperatureZones() {
    const result = await baseApi.get(baseManageResource);
    return result.data;
};

export async function postManageTemperatureZones(temperatureZone) {
    const result = await baseApi.post(baseManageResource, temperatureZone);
    return result.data;
};

export async function putManageTemperatureZones(temperatureZone) {
    const result = await baseApi.put(`${baseManageResource}/${temperatureZone.id}`, temperatureZone);
    return result.data;
};

export async function patchTemperatureZones(data) {
    const result = await baseApi.patch(baseResource, data);
    return result.data;
};
