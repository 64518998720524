import baseApi from '../../base';

export async function getStationExtraDataByStation(stationId) {
    const result = await baseApi.get(`/stations/${stationId}/extra-data`);
    return result.data;
};

export async function getStationExtraDataByInstitution(institutionId) {
    const result = await baseApi.get(`/station-institutions/${institutionId}/stations/extra-data`);
    return result.data;
};

export async function postStationExtraData(stationId, extraData) {
    await baseApi.post(`/manage/stations/${stationId}/extra-data`, extraData);
};
