import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { StateSelectionInput } from '../../../common/custom-inputs';
import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import api from '../../../../services/api';

import './styles.css';

function RadarBox({ radar, radarInstitutions, updateRadar }) {
    const [updatedRadar, setUpdatedRadar] = useState(radar);
    const { register, watch } = useForm({
        defaultValues: {
            ...updatedRadar,
        },
    });
    
    const creating = !updatedRadar.name;
    const [state_id, setStateId] = useState(updatedRadar.state_id || null);
    
    const name = watch('name');
    const alias = watch('alias');
    const central_latitude = watch('central_latitude');
    const central_longitude = watch('central_longitude');
    const minor_latitude = watch('minor_latitude');
    const minor_longitude = watch('minor_longitude');
    const major_latitude = watch('major_latitude');
    const major_longitude = watch('major_longitude');
    const radius = watch('radius');
    const time_frequency = watch('time_frequency');
    const institution_id = watch('institution_id');
    const inactive = watch('inactive');

    function shouldSave() {
        const allMandatorySet = name?.length > 0
            && alias?.length > 0
            && central_latitude?.length > 0
            && central_longitude?.length > 0
            && minor_latitude?.length > 0
            && minor_longitude?.length > 0
            && major_latitude?.length > 0
            && major_longitude?.length > 0
            && major_longitude?.length > 0
            && radius?.length > 0
            && Number(institution_id) > 0
            && time_frequency
            && Number(state_id) > 0;
        if (creating) {
            return allMandatorySet;
        }
        const nameChanged = name !== updatedRadar.name;
        const codeChanged = alias !== updatedRadar.alias;
        const centralLatitudeChanged = Number(central_latitude) !== Number(updatedRadar.central_latitude);
        const centralLongitudeChanged = Number(central_longitude) !== Number(updatedRadar.central_longitude);
        const minorLatitudeChanged = Number(minor_latitude) !== Number(updatedRadar.minor_latitude);
        const minorLongitudeChanged = Number(minor_longitude) !== Number(updatedRadar.minor_longitude);
        const majorLatitudeChanged = Number(major_latitude) !== Number(updatedRadar.major_latitude);
        const majorLongitudeChanged = Number(major_longitude) !== Number(updatedRadar.major_longitude);
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedRadar.inactive);
        const institutionChanged = Number(institution_id) !== Number(updatedRadar.institution_id);
        const timeFrequencyChanged = Number(time_frequency) !== Number(updatedRadar.time_frequency);
        const radiusChanged = Number(radius) !== Number(updatedRadar.radius);
        const stateIdChanged = Number(state_id) !== Number(updatedRadar.state_id);
        return allMandatorySet && (
            nameChanged
            || codeChanged
            || centralLatitudeChanged
            || centralLongitudeChanged
            || minorLatitudeChanged
            || minorLongitudeChanged
            || majorLatitudeChanged
            || majorLongitudeChanged
            || inactiveChanged
            || institutionChanged
            || radiusChanged
            || stateIdChanged
            || timeFrequencyChanged);
    }

    async function saveRadar() {
        if (shouldSave()) {
            const radarToSend = {
                name,
                alias,
                central_latitude,
                central_longitude,
                minor_latitude,
                minor_longitude,
                major_latitude,
                major_longitude,
                radius,
                time_frequency,
                state_id: Number(state_id),
                institution_id: Number(institution_id),
                inactive,
            };
            if (creating) {
                const response = await api.post('/manage/radars', radarToSend);
                radarToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/radars/${updatedRadar.id}`, radarToSend);
                radarToSend.id = updatedRadar.id;
            }
            const oldId = updatedRadar.id;
            setUpdatedRadar(radarToSend);
            updateRadar(oldId, radarToSend);
        }
    }

    const radarId = updatedRadar.id || updatedRadar.tempId;
    
    return (
        <EntityBox
            creating={creating}
            title={`${name || '*Nome'} (${alias || '*Identificador'})`}
        >
            <div className="entity-box-form radar-box-form">
                <div className="form-group">
                    <label htmlFor={`radar-name-${radarId}`}>*Nome</label>
                    <input
                        id={`radar-name-${radarId}`}
                        placeholder="Nome"
                        maxLength={30}
                        {...register('name')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`radar-alias-${radarId}`}>*Identificador</label>
                    <input
                        id={`radar-alias-${radarId}`}
                        placeholder="Identificador"
                        maxLength={30}
                        {...register('alias')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`radar-institution-id-${radarId}`}>*Instituição</label>
                    <select
                        id={`radar-institution-id-${radarId}`}
                        {...register('institution_id')}
                    >
                        <option value="0" disabled>Selecione</option>
                        {radarInstitutions.map(institution => (
                            <option
                                key={institution.id}
                                value={institution.id}
                            >
                                {institution.name} ({institution.alias})
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor={`radar-state-id-${radarId}`}>*Estado</label>
                    <StateSelectionInput
                        defaultOption={{ label: 'Selecione' }}
                        defaultValue={state_id}
                        isNullable={false}
                        inputId={`radar-state-id-${radarId}`}
                        setValue={setStateId}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`radar-time-frequency-${radarId}`}>*Frequência dos dados (min)</label>
                    <input
                        id={`radar-time-frequency-${radarId}`}
                        type="number"
                        {...register('time_frequency')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`radar-radius-${radarId}`}>*Raio (em km)</label>
                    <input
                        id={`radar-radius-${radarId}`}
                        type="number"
                        {...register('radius')}
                    />
                </div>

                <div className="rectangle">
                    <span className="title">Retângulo</span>

                    <div className="map-point">
                        <label htmlFor={`radar-minor-latitude-${radarId}`}>*Inferior-esquerdo</label>
                        <div className="form-group">
                            <label htmlFor={`radar-minor-latitude-${radarId}`}>Latitude:</label>
                            <input
                                id={`radar-minor-latitude-${radarId}`}
                                type="number"
                                {...register('minor_latitude')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`radar-minor-longitude-${radarId}`}>Longitude:</label>
                            <input
                                id={`radar-minor-longitude-${radarId}`}
                                type="number"
                                {...register('minor_longitude')}
                            />
                        </div>
                    </div>

                    <div className="map-point">
                        <label htmlFor={`radar-central-latitude-${radarId}`}>*Central</label>
                        <div className="form-group">
                            <label htmlFor={`radar-central-latitude-${radarId}`}>Latitude:</label>
                            <input
                                id={`radar-central-latitude-${radarId}`}
                                type="number"
                                {...register('central_latitude')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`radar-central-longitude-${radarId}`}>Longitude:</label>
                            <input
                                id={`radar-central-longitude-${radarId}`}
                                type="number"
                                {...register('central_longitude')}
                            />
                        </div>
                    </div>

                    <div className="map-point">
                        <label htmlFor={`radar-major-latitude-${radarId}`}>*Superior-direito</label>
                        <div className="form-group">
                            <label htmlFor={`radar-major-latitude-${radarId}`}>Latitude:</label>
                            <input
                                id={`radar-major-latitude-${radarId}`}
                                type="number"
                                {...register('major_latitude')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor={`radar-major-longitude-${radarId}`}>Longitude:</label>
                            <input
                                id={`radar-major-longitude-${radarId}`}
                                type="number"
                                {...register('major_longitude')}
                            />
                        </div>
                    </div>

                </div>

                {!creating && (
                    <div className="form-group checkbox-input">
                        <label htmlFor={`radar-inactive-${radarId}`}>Desabilitar</label>
                        <input
                            id={`radar-inactive-${radarId}`}
                            type="checkbox"
                            {...register('inactive')}
                        />
                    </div>
                )}

                <PostBtn
                    postFunction={saveRadar}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default RadarBox;
