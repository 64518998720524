import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useRadarSelectionInputState } from './states';

function MultipleRadarsSelectionInput({ defaultValue, setValue }) {
    const radarSelectionOptions = useRadarSelectionInputState().sort((a,b) => a.label.localeCompare(b.label));

    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={radarSelectionOptions}
        />
    );
}

export default MultipleRadarsSelectionInput;
