import React from 'react';
import {
    FaRegUserCircle,
} from 'react-icons/fa';

import { getLoggedUser } from '../../utils/user';

import CronosLogo from '../cronos-logo';
import DateComponent from '../date/index';

import './styles.css';

function Header({ children }) {
    const loggedUser = getLoggedUser();

    return (
        <header>
            <span className="header-left">
                <CronosLogo lightColor={true} fontSize={'16px'} />
                <DateComponent />
            </span>

            {children}

            <span>
                <span id="welcome-box">
                    <FaRegUserCircle /> Bem vindo(a), <span className="username">
                        {loggedUser?.user?.name || loggedUser?.user?.username}
                    </span>
                </span>
            </span>
        </header>
    );
}

export default Header;
