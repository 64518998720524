import React, { useEffect } from 'react';
import { StateSelectionInput } from '../common/custom-inputs';
import { useApiData } from '../../contexts/api-data';

function SelectStateBox({ usingStates, setSelectedState }) {
    const apiData = useApiData();

    const {
        manageStates: {
            fetch: fetchManageStates,
        }
    } = apiData;

    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);

    return usingStates?.length > 0 ? (
        <div className="select-box-container">
            <label htmlFor="select-state">Estado:</label>
            <StateSelectionInput
                isNullable
                inputId="select-state"
                setValue={value => setSelectedState(value)}
                usingStates={usingStates}
            />
        </div>
    ) : null;
}

export default SelectStateBox;
