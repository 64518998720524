import baseApi from '../../base';

const baseResource = '/stations/highest-precipitations'

export async function getStationHighestPrecipitations() {
    const result = await baseApi.get(baseResource);
    return result.data;
};

export async function postStationHighestPrecipitations(precipitations) {
    const result = await baseApi.post(baseResource, { precipitations });
    return result.data;
};
