import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import { useAlertTemplateBoxStates } from './states';

import './styles.css';

function AlertTemplateBox({ template }) {
    const {
        creating,
        register,
        save,
        shouldSave,
        title,
    } = useAlertTemplateBoxStates(template);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="permission-box-form">
                <input
                    placeholder="*Nome"
                    {...register('name')}
                />
                <input
                    placeholder="*Formato (Previsão de %INPUT|10% às %INPUT|5%. Impacto: %INPUT%)"
                    {...register('format')}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default AlertTemplateBox;
