import { useEffect } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useSystemMonitorPageState() {
    const {
        systemMonitor: { 
            fetchImagesAmount, 
            value: systemMonitor,
        },
    } = useApiData();

    useEffect(() => {
        fetchImagesAmount();
    }, [fetchImagesAmount]);

    return {
        systemMonitor,
    };
};
