export const CLIMATE_FORECASTS = 'CLIMATE_FORECASTS';
export const FORECASTS = 'FORECASTS';
export const FORECASTS_DOWNLOAD = 'FORECASTS_DOWNLOAD';
export const LEGACY_FORECASTS = 'LEGACY_FORECASTS';
export const MAP_ALERT = 'MAP_ALERT';
export const OPERATION = 'OPERATION';
export const RADAR_DASHBOARD = 'RADAR_DASHBOARD';
export const STATION_MEASUREMENTS_DOWNLOAD = 'STATION_MEASUREMENTS_DOWNLOAD';
export const STATION_PRECIPITATION_HISTORY = 'STATION_PRECIPITATION_HISTORY';
export const STATION_WEATHER_HISTORY = 'STATION_WEATHER_HISTORY';
export const STATION_TELEMETRY_HISTORY = 'STATION_TELEMETRY_HISTORY';

export const pageSelectionOptions = [
    {
        value: LEGACY_FORECASTS,
        label: 'Previsão para o COR',
    },
    {
        value: FORECASTS,
        label: 'Previsão para obras',
    },
    {
        value: CLIMATE_FORECASTS,
        label: 'Previsão climática',
    },
    {
        value: FORECASTS_DOWNLOAD,
        label: 'Download de previsões',
    },
    {
        value: STATION_MEASUREMENTS_DOWNLOAD,
        label: 'Download de medidas de estações',
    },
    {
        value: STATION_WEATHER_HISTORY,
        label: 'Estações - dados meteorológicos',
    },
    {
        value: STATION_PRECIPITATION_HISTORY,
        label: 'Estações - dados pluviométricos',
    },
    {
        value: STATION_TELEMETRY_HISTORY,
        label: 'Estações - dados telemétricos',
    },
    {
        value: RADAR_DASHBOARD,
        label: 'Dashboard de radar',
    },
    {
        value: MAP_ALERT,
        label: 'Mapas e Alertas',
    },
    {
        value: OPERATION,
        label: 'Operação',
    },
].sort((a,b) => a.label.localeCompare(b.label));
