import React from 'react';

import { useApiData } from '../../contexts/api-data';
import { useAuth } from '../../contexts/auth';
import { useSelectionRegionBoxState } from './states';

import DashboardBox from '../dashboard-box';
import SelectCustomerBox from '../select-customer-box';
import SelectStateBox from '../select-state-box';
import { SelectionInput } from '../common/custom-inputs';

import './styles.css';

function SelectRegionBox() {
    const { permissionsSet, isMaster } = useAuth();
    const hasManageCustomersPermission =
        permissionsSet?.includes('MANAGE_CUSTOMERS') || isMaster;
    const {
        selectedForecastRegion,
    } = useApiData();

    const {
        selectedCustomer,
        selectState,
        selectCustomer,
        regionOptions,
        stateOptions,
        selectRegion,
    } = useSelectionRegionBoxState(hasManageCustomersPermission);

    return (
        <DashboardBox>
            <div className="select-region-box-container form-grop">
                {hasManageCustomersPermission && (
                    <>
                        <SelectCustomerBox
                            setSelectedCustomer={selectCustomer}
                        />
                        <SelectStateBox
                            key={selectedCustomer || "default-key"}
                            setSelectedState={selectState}
                            usingStates={stateOptions}
                        />
                    </>
                )}

                <label htmlFor="select-region">Região:</label>
                {regionOptions?.length && selectedForecastRegion ? (
                    <SelectionInput
                        key={selectedForecastRegion.id}
                        setValue={selectRegion}
                        defaultValue={selectedForecastRegion.id}
                        options={regionOptions}
                        inputId="select-region"
                    />
                ) : (
                    <select disabled id="select-region">
                        <option> Nenhuma região encontrada </option>
                    </select>
                )}
            </div>
        </DashboardBox>
    );
}

export default SelectRegionBox;
