import React from 'react';

import CronosLogo from '../cronos-logo';
import Loading from '../loading';

import './styles.css';

import nimbusLogoImg from '../../media/nb-logo.jpg';

function AuthTemplate({ children, loading, title }) {
    return (
        <div className="auth-container">
            <div className="auth-main">
                <div className="auth-form-container">
                    <div className="auth-form-header">
                        <img className="nimbus-logo" src={nimbusLogoImg} alt="Nimbus Logo" />
                        <CronosLogo fontSize={'20px'} />
                    </div>

                    <div className="auth-title">
                        <h3>{title}</h3>
                    </div>
                        
                    {loading ? (
                        <div className="auth-loading">
                            <Loading size={32} color="#888" />
                        </div>
                    ) : children}
                </div>
                <div className="auth-footer">
                    Desenvolvido por Nimbus Meteorologia
                </div>
            </div>
            <div className="auth-banner"></div>
        </div>
    );
}

export default AuthTemplate;
