import React from 'react';

import SelectionInput from '../selection-input';

import {
    variableSelectionOptions,
    useVariableByModelInputStates,
} from './states';

import './styles.css';

function VariableByModelInput({
        defaultValue,
        setValue,
        inputId,
        isNullable = true,
    }) {
    const {
        dailyModel,
        hourlyModel,
        modelOptions,
        updateValue,
        variableModel,
    } = useVariableByModelInputStates({ defaultValue, setValue });

    return (
        <div className="variable-by-model-input">
            <SelectionInput
                defaultOption={{ value: 0, label: 'Variável' }}
                defaultValue={variableModel}
                setValue={value => updateValue(value, hourlyModel, dailyModel)}
                options={variableSelectionOptions}
                isNullable={isNullable}
                inputId={inputId}
            />
            {modelOptions.length > 0 && (
                <SelectionInput
                    defaultOption={{ value: 0, label: '84 horas' }}
                    defaultValue={hourlyModel}
                    setValue={value => updateValue(variableModel, value, dailyModel)}
                    options={modelOptions}
                    isNullable={isNullable}
                />
            )}
            {modelOptions.length > 0 && (
                <SelectionInput
                    defaultOption={{ value: 0, label: '15 dias' }}
                    defaultValue={dailyModel}
                    setValue={value => updateValue(variableModel, hourlyModel, value)}
                    options={modelOptions}
                    isNullable={isNullable}
                />
            )}
        </div>
    );
}

export default VariableByModelInput;
