import { useCallback, useState, useMemo } from 'react';
import {
    getManageCustomers,
    postManageCustomers,
    putManageCustomers,
} from '../../../../services/api';

export function useManageCustomersState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.name) return -1;
            if (!b.name) return 1;
            if (a.name.toUpperCase() === 'NIMBUS') return -1;
            if (b.name.toUpperCase() === 'NIMBUS') return 1;
            return a.name.localeCompare(b.name);
        });
    }, [value])

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const customers = await getManageCustomers();
            setValue(customers);
            setLoading(false);
        },
        [value, loading]
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id, inactive: false }]);
    }

    async function save(customerId, customer) {
        const index = value.findIndex(({ id }) => id === customerId);
        // creating
        if (!customer.id) {
            customer = await postManageCustomers(customer);
        }
        // updating
        else {
            await putManageCustomers(customer);
        }
        const newValue = [...value];
        newValue[index] = customer;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        value: valueSorted,
    };
};
