import React from 'react';
import { FaCircle } from 'react-icons/fa';

import MapLegend from '../map-legend';

import './styles.css';

function ClimateForecastsLegend() {
    return (
        <MapLegend position="topright">
            <label className="tabLegenda">
                <input type="checkbox" /> 
                <div className="legenda-titulo">Legenda</div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaCircle className="green" /></div>
                    <div className="legenda-desc">Sem chuva</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaCircle className="blue" /></div>
                    <div className="legenda-desc">Chuva fraca (0,2 a 5,0 mm/dia)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaCircle className="yellow" /></div>
                    <div className="legenda-desc">Chuva Moderada (5,1 a 25,0 mm/dia)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaCircle className="orange" /></div>
                    <div className="legenda-desc">Chuva Forte (25,1 a 50,0 mm/dia)</div>
                </div>
                <div className="legenda-item">
                    <div className="legenda-simb"><FaCircle className="red" /></div>
                    <div className="legenda-desc">Chuva Muito Forte (acima de 50,0 mm/dia)</div>
                </div>
            </label>
        </MapLegend>
    );
}

export default ClimateForecastsLegend;
