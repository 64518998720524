import React from 'react';
import { useMultipleRealTimeMessageCodeSelectionInputState } from './state';
import MultipleSelectionInput from '../multiple-selection-input';

import './styles.css';

function MultipleRealTimeMessageCodeSelectionInput({
    selectRealTimeCodes,
    inputId,
    multiple,
    isReset,
}) {
    const {
        realTimeMessageCodeOptions,
        customerOptions,
        handleCustomerChange,
        handleRealTimeMessageCodesChange,
        selectedCustomerId,
        customersByCodes
    } = useMultipleRealTimeMessageCodeSelectionInputState({
        selectRealTimeCodes,
        isReset,
    });

    return (
        <div className="real-time-selection-input">
            {customersByCodes?.length && (
                <MultipleSelectionInput
                    defaultLabel="Selecione o cliente"
                    setValue={(customerId) => handleCustomerChange(customerId)}
                    options={customerOptions}
                    showOnlyItemsAmount
                />
            )}
            {realTimeMessageCodeOptions?.length &&
                (multiple ? (
                    <MultipleSelectionInput
                        defaultLabel="Selecione o código"
                        setValue={(realTimeMessageCodes) =>
                            handleRealTimeMessageCodesChange([
                                selectedCustomerId,
                                realTimeMessageCodes,
                            ])
                        }
                        options={realTimeMessageCodeOptions}
                        inputId={inputId}
                        showOnlyItemsAmount
                    />
                ) : (
                    []
                ))}
        </div>
    );
}

export default MultipleRealTimeMessageCodeSelectionInput;
