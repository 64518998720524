import React from 'react';

import {
    MultipleSelectionInput,
    SelectionInput,
} from '../../../../components/common/custom-inputs';

import { useSearchFormStates } from './states';

import './styles.css';

function SearchForm({
    fetchMeasures,
    lastMeasureDatetime,
    measures,
    stations,
    telemetryVariables,
    initialStationId,
}) {
    const {
        maximumDatetimeValue,
        minimumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        stationsOptions,
        setStationId,
        variables,
        setVariables,
        telemetryVariablesOptions,
    } = useSearchFormStates({
        fetchMeasures,
        lastMeasureDatetime,
        measures,
        stations,
        initialStationId,
        telemetryVariables,
    });

    return (
        <form
            className="stations-telemetry-search-form"
            onSubmit={searchMeasures}
        >
            <div className="form-group">
                <label htmlFor="datetime-start">Início:</label>
                <input
                    id="datetime-start"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    min={minimumDatetimeValue}
                    value={datetimeStartValue}
                    onChange={event => updateDatetimeStart(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="datetime-end">Fim:</label>
                <input
                    id="datetime-end"
                    type="datetime-local"
                    max={maximumDatetimeValue}
                    min={minimumDatetimeValue}
                    value={datetimeEndValue}
                    onChange={event => updateDatetimeEnd(event.target.value)}
                />
            </div>

            <div className="form-group">
                <label>Variáveis:</label>
                {telemetryVariablesOptions ? (
                    <MultipleSelectionInput
                        defaultValue={variables}
                        setValue={setVariables}
                        options={telemetryVariablesOptions}
                        showOnlyItemsAmount={true}
                    />
                ) : null}
            </div>

            <div className="form-group">
                <label htmlFor="select-station">Estação:</label>
                {(stationsOptions && !isNaN(initialStationId)) && <SelectionInput
                    inputId="select-station"
                    defaultValue={initialStationId}
                    isNullable={false}
                    options={stationsOptions}
                    setValue={setStationId}
                />}
            </div>

            <button
                className="search-form-btn"
                type="submit"
            >
                Pesquisar
            </button>
        </form>
    );
}

export default SearchForm;
