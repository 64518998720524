import React, { useEffect }  from 'react';

import ContactPopup from '../../components/contact-popup';
import DashboardMapBox from '../../components/dashboard-map-box';
import MessagesBox from '../../components/messages-box';
import LegacyForecastChartBox from '../../components/legacy-forecast-chart-box';
import ForecastsChartBox from '../../components/forecasts-chart-box';

import { useAuth } from '../../contexts/auth';
import { useApiData } from '../../contexts/api-data';

import './styles.css';

function Dashboard() {
    const { settingsSet } = useAuth();

    const { PAGES = [] } = {
        ...settingsSet,
    };

    const {
        staticPositionTypes,
        selectStaticPositionTypes
    } = useApiData();

    const eightyFourHours = 84;
    const newForecastsPage = PAGES.includes('FORECASTS');
    
    useEffect(() => {
        if (staticPositionTypes && staticPositionTypes.length) {
            selectStaticPositionTypes();
        }
    }, [staticPositionTypes, selectStaticPositionTypes]);
    
    return (
        <div className="main-dashboard-page">
            <DashboardMapBox />

            <MessagesBox />

            {newForecastsPage
                ? <ForecastsChartBox
                    periodInHours={eightyFourHours}
                />
                : <LegacyForecastChartBox />}

            <ContactPopup />
        </div>
    );
}

export default Dashboard;
