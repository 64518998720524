import { useState, useEffect, useMemo } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useVariableUnitInputStates({ defaultValue, setValue }) {
    const {
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();
    const defaultValueSplitted = defaultValue?.split(',');
    const [variable, setVariable] = useState(defaultValueSplitted?.[0]);
    const [unit, setUnit] = useState(defaultValueSplitted?.[1]);
    const selectedVariable = useMemo(() => {
        return weatherVariables?.find(({ id }) => id === Number(variable));
    }, [weatherVariables, variable]);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    function updateValue(newVariable, newUnit) {
        if (newVariable !== variable) {
            setVariable(newVariable);
        }
        if (newUnit !== unit) {
            setUnit(newUnit);
        }
        if (!newVariable || !newUnit) {
            return;
        }
        setValue(`${newVariable},${newUnit}`);
    }

    return {
        selectedVariable,
        variable,
        unit,
        updateValue,
        weatherVariables,
    };
};
