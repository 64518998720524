import React from 'react';
import { Popup } from 'reactjs-popup';
import { FaInfoCircle } from 'react-icons/fa';

function PrecipitationColorLegend() {
    return (
        <Popup
            closeOnDocumentClick={true}
            closeOnEscape={true}
            className="precipitation-legend"
            position="bottom left"
            trigger={
                <span className="precipitation-legend-trigger">
                    <FaInfoCircle />
                </span>
            }
        >
            <div className="precipitation-legend-container">
                <div>
                    <span className="legend-atrasada"></span>
                    <span>Atrasada<br />(+18 min)</span>
                </div>
                <div>
                    <span className="legend-fraca"></span>
                    <span>Chuva Fraca<br />(0,2 a 5,0mm/h)</span>
                </div>
                <div>
                    <span className="legend-moderada"></span>
                    <span>Chuva Moderada<br />(5,1 a 25,0mm/h)</span>
                </div>
                <div>
                    <span className="legend-forte"></span>
                    <span>Chuva Forte<br />(25,1 a 50,0mm/h)</span>
                </div>
                <div>
                    <span className="legend-muito-forte"></span>
                    <span>Chuva Muito Forte<br />(acima a 50,0mm/h)</span>
                </div>
            </div>
        </Popup>
    );
}

export default PrecipitationColorLegend;
