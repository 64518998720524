import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

function MultipleStationsSelectionInput({
    defaultValue,
    setValue,
    stations,
    showOnlyItemsAmount,
}) {
    const station = stations || [];

    const options = station.map(({ id, name, code }) => ({
        value: id,
        label: `${name} (${code})`,
    }));

    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={options}
            showOnlyItemsAmount={showOnlyItemsAmount}
        />
    );
}

export default MultipleStationsSelectionInput;
