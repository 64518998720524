import React, { useState } from 'react';

import CalendarMessagesSearchForm from './form';
import CalendarMessagesSearchTable from './table';

import { useApiData } from '../../../../../contexts/api-data';

function CalendarMessagesSearch() {
    const [messages, setMessages] = useState([]);

    const {
        manageForecastRegions: {
            value: forecastRegions,
        },
    } = useApiData();

    return (<>
        <h3>Banco de Dados - Mensagens de Agenda</h3>
        <CalendarMessagesSearchForm
            setMessages={setMessages}
            forecastRegions={forecastRegions}
        />
        <CalendarMessagesSearchTable
            messages={messages}
            forecastRegions={forecastRegions}
        />
    </>);
}

export default CalendarMessagesSearch;
