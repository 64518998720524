import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useApiData } from '../../../../contexts/api-data';

export function useBasinPrecipitationStageBoxStates(stage) {
    const {
        manageBasinPrecipitationStages: {
            remove: removeStage,
            save: saveStage,
        },
    } = useApiData();
    const { register, watch } = useForm({
        defaultValues: {
            level: stage.level || 0,
            description: stage.description || '',
            color: stage.color || '#964B00',
        },
    });
    const level = watch('level');
    const description = watch('description');
    const color = watch('color');
    const creating = !stage.description;
    const title = useMemo(() => `(${level}) ${description}`, [description, level]);

    function shouldSave() {
        const levelAndDescriptionSet = !isNaN(level) && description.length > 0;
        if (creating) {
            return levelAndDescriptionSet;
        }
        const levelChanged = level !== stage.level;
        const descriptionChanged = (description || '') !== (stage.description || '');
        const colorChanged = (color || '') !== (stage.color || '');
        return levelAndDescriptionSet && (levelChanged || descriptionChanged || colorChanged);
    }

    async function save() {
        if (shouldSave()) {
            const { id } = stage;
            const stageToSend = {
                level,
                description,
                color,
            };
            if (!creating) {
                stageToSend.id = id;
            }
            saveStage(id, stageToSend);
        }
    }

    async function deleteStage() {
        if (creating) {
            return;
        }
        await removeStage(stage.id);
    }

    return {
        creating,
        deleteStage,
        register,
        save,
        shouldSave,
        title,
    };
};
