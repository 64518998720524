import { useState, useEffect } from 'react';

export function useMultipleStateAndCitySelectionInputStates({ defaultValue, setValue }) {
    const [citiesByState, setCitiesByState] = useState({});
    const [statesArray, setStatesArray] = useState([]);
    const [defaultValueSet, setDefaultValueSet] = useState(false);

    useEffect(() => {
        if (
            !defaultValue?.length
            || defaultValueSet
        ) {
            return;
        }
        const newCitiesByState = defaultValue.reduce((result, setting) => {
            const [state_id, city_id] = setting.split(',');
            result[state_id] = [ ...(result[state_id] || []), Number(city_id) ];
            return result;
        }, {});
        setCitiesByState(newCitiesByState);
        setStatesArray(Object.keys(newCitiesByState).map(Number));
        setDefaultValueSet(true);
    }, [defaultValue, defaultValueSet]);

    function updateValue(newCitiesByState) {
        const newValue = Object.keys(newCitiesByState).reduce((result, stateId) => {
            const cities = newCitiesByState[stateId];
            if (!cities.length) {
                return result;
            }
            return result.concat(cities.map(cityId => `${stateId},${cityId}`));
        }, []);
        
        setValue(newValue);
    }

    function selectCity([stateId, ...cities], stateIndex) {
        const newCitiesByState = {
            ...citiesByState,
        };
        const oldStateId = statesArray[stateIndex];
        if (oldStateId !== stateId) {
            if (oldStateId !== 0) {
                newCitiesByState[oldStateId] = [];
            }
            const newStatesArray = [...statesArray];
            newStatesArray[stateIndex] = stateId;
            setStatesArray(newStatesArray);
        }
        if (Number(stateId) !== 0) {
            newCitiesByState[stateId] = cities;
        }
        setCitiesByState(newCitiesByState);
        updateValue(newCitiesByState);
    }

    function addSlot() {
        setStatesArray([...statesArray, 0]);
    }

    return {
        statesArray,
        citiesByState,
        selectCity,
        addSlot,
    };
};
