import { useCallback, useState, useMemo, useEffect, useRef } from 'react';

import { useApiData } from '../../../contexts/api-data';

import { useAuth } from '../../../contexts/auth';

import useInterval from '../../../hooks/use-interval';
import datetimeUtils from '../../../utils/datetime';

const oneHour = 60 * 60 * 1000;
const oneDay = 24 * oneHour;

export function useModelForecastsRetrieval({ periodInMs, daysFrequency, forecastsVariables }) {
    const {
        forecastModels: {
            fetchAndSave: fetchAndSaveForecastModels,
            fetch: fetchForecastModels,
        },
        retrieveForecasts,
        selectedForecastRegion,
    } = useApiData();

    useEffect(() => {
        fetchAndSaveForecastModels();
    }, [fetchAndSaveForecastModels]);

    const { settingsSet } = useAuth();

    const { VARIABLES_BY_FORECAST_MODELS = [] } = {
        ...settingsSet,
    };

    const variablesQuerysByModelsRef = useRef(null);
    const forecastsVariablesRef = useRef(null);

    const variablesQuerysByModels = useMemo(() => {
        if (!VARIABLES_BY_FORECAST_MODELS?.length) {
            return {};
        }
        return VARIABLES_BY_FORECAST_MODELS.reduce((result, value) => {
            const [variable, hourlyModel, dailyModel] = value.split(',');
            const modelId = daysFrequency ? dailyModel : hourlyModel;
            const query = daysFrequency
                ? forecastsVariables[variable]?.query
                : 'abs';
            if (![hourlyModel, dailyModel].includes('ast') && forecastsVariables[variable]) {
                if (!(modelId in result)) {
                    result[modelId] = {};
                }
                result[modelId][variable] = query;
            }
            return result;
        }, {});
    }, [VARIABLES_BY_FORECAST_MODELS, forecastsVariables, daysFrequency]);

    const [lastModelsRuns, setLastModelsRuns] = useState(null);
    const [forecasts, setForecasts] = useState(null);
    const [lastRegionAlias, setLastRegionAlias] = useState(null);
    const fetchingForecasts = useRef(false);
    const frequency = useMemo(() => daysFrequency ? oneDay : oneHour, [daysFrequency]);

    const retrieveLastModelsRuns = useCallback(async () => {
        const models = await fetchForecastModels();
        const newLastModelsRuns = Object.keys(variablesQuerysByModels).map(modelId => {
            return models.find(({ id }) => id === parseInt(modelId))?.last_run;
        });
        if (!lastModelsRuns || lastModelsRuns.toString() !== newLastModelsRuns.toString()) {
            setLastModelsRuns(newLastModelsRuns);
        }
        return newLastModelsRuns;
    }, [fetchForecastModels, variablesQuerysByModels, lastModelsRuns]);

    const retrieveForecastsPeriodically = useCallback(async () => {
        // if (fetchingForecasts.current || forecasts) {
        if (fetchingForecasts.current) {
            return;
        }
        // no model variables to show/retrieve
        if (Object.values(variablesQuerysByModels)?.every(varsQuery => Object.keys(varsQuery).length === 0)) {
            return;
        }
        const now = Date.now();
        const today = datetimeUtils.getInputFormatDateFromDatetime(now);
        const datetimeStart = datetimeUtils.getZeroHourDatetimeFromInputFormatDate(today);
        const datetimeEnd = datetimeStart + periodInMs;

        // just selected different region
        if ((selectedForecastRegion?.alias || null) !== lastRegionAlias) {
            setLastRegionAlias(selectedForecastRegion?.alias || null);
        }
        // variablesQuerysByModels changed
        else if (JSON.stringify(variablesQuerysByModels) !== JSON.stringify(variablesQuerysByModelsRef.current)) {
            variablesQuerysByModelsRef.current = variablesQuerysByModels;
        }
        // forecastsVariables changed
        else if (JSON.stringify(forecastsVariables) !== JSON.stringify(forecastsVariablesRef.current)) {
            forecastsVariablesRef.current = forecastsVariables;
        }
        else if (forecasts) {
            const firstDatetimes = Object.values(forecasts).map(forecast => forecast?.[0].datetime);
            if (firstDatetimes.some(firstDatetime => firstDatetime && firstDatetime >= datetimeStart)) {
                const lastDatetimes = Object.values(forecasts).map(forecast => forecast?.[forecast.length-1].datetime);
                if (lastDatetimes.some(lastDatetime => lastDatetime >= datetimeEnd)) {
                    // data is up-to-date
                    return;
                }
                // source is late
                // should get last forecast model run to see if there is new data
                const latestModelsRuns = await retrieveLastModelsRuns();
                if (!lastModelsRuns || lastModelsRuns.toString() === latestModelsRuns.toString()) {
                    // no new values
                    return;
                }
            }
        }

        // should get everything
        fetchingForecasts.current = true;

        const variableForecasts = await Promise.all(
            Object.keys(variablesQuerysByModels).map(modelId => retrieveForecasts({
                modelId,
                datetimeStart,
                datetimeEnd,
                frequency,
                ...variablesQuerysByModels[modelId],
            }))
        );

        fetchingForecasts.current = false;

        setForecasts(variableForecasts
            .filter(variableForecast => variableForecast?.length > 0));
    }, [
        periodInMs,
        frequency,
        retrieveForecasts,
        retrieveLastModelsRuns,
        lastModelsRuns,
        forecasts,
        selectedForecastRegion,
        lastRegionAlias,
        variablesQuerysByModels,
        forecastsVariables,
    ]);

    useInterval(retrieveForecastsPeriodically, 10 * 60 * 1000); // 10 minutes

    return { forecasts };
};
