import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useMultipleCustomerAndRegionsSelectionInputState } from './state';

import './styles.css';

function MultipleCustomersAndRegionsSelectionInput({
    selectRegion,
    inputId,
    disabled,
    isReset,
    setIsReset,
}) {
    const {
        selectedCustomerIds,
        handleCustomerChange,
        handleRegionChange,
        regionsByCustomer,
        regionsOptions,
        customerOptions,
    } = useMultipleCustomerAndRegionsSelectionInputState({
        selectRegion,
        isReset,
        setIsReset,
    });

    return (
        <div className="multiple-customers-and-regions-selection-input">
            {regionsByCustomer?.length > 0 && (
                <MultipleSelectionInput
                    defaultLabel="Selecione o cliente"
                    setValue={(customerId) => handleCustomerChange(customerId)}
                    options={customerOptions}
                    disabled={disabled}
                    showOnlyItemsAmount
                />
            )}
            {regionsOptions?.length > 0 &&
                <MultipleSelectionInput
                    defaultLabel="Selecione a região"
                    setValue={(regions) =>
                        handleRegionChange([
                            selectedCustomerIds,
                            regions,
                        ])
                    }
                    options={regionsOptions}
                    inputId={inputId}
                    disabled={disabled}
                    showOnlyItemsAmount
                />}
        </div>
    );
}

export default MultipleCustomersAndRegionsSelectionInput;
