import React from 'react';

import './styles.css';

function StationExtraDataBox({ stationId, extraData, updateExtraData }) {
    const impactWayInputId = `impact-way-${stationId}`;

    return extraData ? (
        <div className="station-extra-data-box">
            <h3>Informações adicionais</h3>
            <div className="form-group impact-way">
                <label htmlFor={impactWayInputId}>Via urbana impactada</label>
                <input
                    id={impactWayInputId}
                    value={extraData.IMPACT_WAY || ''}
                    type="text"
                    onChange={(event) => updateExtraData('IMPACT_WAY', event.target.value)}
                />
            </div>
        </div>
    ) : null;
}

export default StationExtraDataBox;
