import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import {
    postSatelliteInstitutions,
    putSatelliteInstitutions
} from '../../../../services/api';

import './styles.css';

function SatelliteInstitutionBox({ institution }) {
    const [updatedInstitution, setUpdatedInstitution] = useState(institution);
    const { register, watch } = useForm({
        defaultValues: {
            name: updatedInstitution.name || '',
            alias: updatedInstitution.alias || '',
        },
    });

    const name = watch('name');
    const alias = watch('alias');
    
    const creating = !updatedInstitution.name;

    function shouldSave() {
        const nameAndAliasAreSet = name?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndAliasAreSet;
        }
        const nameChanged = name !== updatedInstitution.name;
        const aliasChanged = alias !== updatedInstitution.alias;
        return nameAndAliasAreSet && (nameChanged || aliasChanged);
    }

    async function saveInstitution() {
        if (shouldSave()) {
            const institutionToSend = {
                name,
                alias,
            };
            if (creating) {
                const response = await postSatelliteInstitutions(institutionToSend);
                institutionToSend.id = response.id;
            }
            else {
                await putSatelliteInstitutions(updatedInstitution, institutionToSend);
                institutionToSend.id = updatedInstitution.id;
            }
            setUpdatedInstitution(institutionToSend);
        }
    }
    
    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form institution-box-form">
                <div className="form-group">
                    <label htmlFor={`institution-name-${updatedInstitution.id}`}>Nome</label>
                    <input
                        id={`institution-name-${updatedInstitution.id}`}
                        placeholder="Nome"
                        maxLength={30}
                        {...register('name')}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`institution-alias-${updatedInstitution.id}`}>Identificador</label>
                    <input
                        id={`institution-alias-${updatedInstitution.id}`}
                        placeholder="Identificador"
                        maxLength={30}
                        {...register('alias')}
                    />
                </div>

                <PostBtn
                    postFunction={saveInstitution}
                    shouldPost={shouldSave}
                />
            </div>
        </EntityBox>
    );
}

export default SatelliteInstitutionBox;
