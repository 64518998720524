import { useCallback, useState, useMemo } from 'react';

import {
    getTelemetryVariables,
    postManageTelemetryVariables,
    putManageTelemetryVariables,
} from '../../../../services/api';

export function useManageTelemetryVariablesState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => {
            if (!a.key) return -1;
            if (!b.key) return 1;
            return a.label.localeCompare(b.label);
        });
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const telemetryVariables = await getTelemetryVariables();
            setValue(telemetryVariables);
            setLoading(false);
        },
        [value, loading],
    );

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(variableId, variable) {
        const index = value.findIndex(({ id }) => id === variableId);
        // creating
        if (!variable.id) {
            variable = await postManageTelemetryVariables(variable);
        }
        // updating
        else {
            await putManageTelemetryVariables(variable);
        }
        const newValue = [...value];
        newValue[index] = variable;
        setValue(newValue);
    }

    return {
        add,
        fetch,
        loading,
        save,
        value: valueSorted,
    };
};
