import React from 'react';
import { LayerGroup, Marker, Popup as MapPopup } from 'react-leaflet';
import Leaflet from 'leaflet';

import useInterval from '../../hooks/use-interval';
import { useApiData } from '../../contexts/api-data';

import lightningIconUrl from '../../media/lightning.png';

const lightningIcon = Leaflet.icon({
    iconUrl: lightningIconUrl,
    iconSize: [20, 20],
});

function LightningsMarkers() {
    const {
        lightnings,
        retrieveLightnings,
    } = useApiData();

    useInterval(retrieveLightnings, 60 * 1000); // 1 minute

    return lightnings ? (
        <LayerGroup>
            {lightnings.map(({ id, datetime, latitude, longitude, energy }) => {
                const datetimeObject = new Date(Number(datetime));
                const date = datetimeObject.toLocaleDateString().substr(0, 5);
                const time = datetimeObject.toLocaleTimeString().substr(0, 5);

                return (
                    <Marker
                        key={id}
                        icon={lightningIcon}
                        position={[latitude, longitude]}
                    >
                        <MapPopup>
                            <b>Data e Hora:</b> {time} - {date} <br/>
                            <b>Latitude:</b> {parseFloat(latitude).toFixed(2)} <br/>
                            <b>Longitude:</b> {parseFloat(longitude).toFixed(2)} <br/>
                        </MapPopup>
                    </Marker>
                );
            })}
        </LayerGroup>
    ) : null;
}

export default LightningsMarkers;
