import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export function useVariableTypeUnitsInputStates(unit, setUnit) {
    const { register, watch } = useForm({
        defaultValues: {
            label: unit.label,
            formula: unit.formula,
        },
    });
    const label = watch('label');
    const formula = watch('formula');

    useEffect(() => {
        const labelSet = label?.length > 0;
        const formulaSet = formula?.length > 0;
        if (
            !labelSet || !formulaSet
            || (label === unit.label && formula === unit.formula)
        ) {
            return;
        }
        setUnit({
            ...unit,
            label,
            formula,
        });
    }, [label, formula, unit, setUnit]);

    return {
        register,
    };
};
