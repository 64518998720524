import React from 'react';

import ClimateMap from '../../components/climate-map';

import './styles.css';

function ClimateForecasts() {
    return (
        <div className="climate-map-res">
            <ClimateMap />
        </div>
    );
}

export default ClimateForecasts;
