import React from 'react';

import SelectionInput from '../selection-input';

import { useStationDelayPeriodsInputStates } from './states';

import './styles.css';

function StationDelayPeriodsInput({ defaultValue, setValue }) {
    const {
        delayPeriod,
        stationInstitutionsOptions,
        loadingInstitutions,
        setStationInstitution,
        stationInstitution,
        updateValue,
    } = useStationDelayPeriodsInputStates({ defaultValue, setValue });

    return (
        <div className="station-delay-periods-input-container">
            {stationInstitutionsOptions && <SelectionInput
                defaultValue={stationInstitution}
                setValue={value => setStationInstitution(value)}
                options={stationInstitutionsOptions}
                isNullable={true}
                disabled={loadingInstitutions}
            />}
            {stationInstitution && <input
                type="number"
                value={delayPeriod || ''}
                onChange={event => updateValue(stationInstitution, event.target.value)}
                placeholder={'Minutos'}
                disabled={loadingInstitutions}
            />}
        </div>
    );
}

export default StationDelayPeriodsInput;
