// state.js
import { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../../../../services/api';
import downloadFile from '../../../../../services/download-file';

const valueLength = value => value && value.length > 0;

const variables = {
    latitude: {
        varType: Number,
        isValid: valueLength,
        label: 'Latitude',
    },
    longitude: {
        varType: Number,
        isValid: valueLength,
        label: 'Longitude',
    },
    description: {
        varType: String,
        isValid: valueLength,
        label: 'Descrição',
    },
    options: {
        varType: Boolean,
        isValid: _ => true,
        label: 'Personalizar',
    },
    inactive: {
        varType: Boolean,
        isValid: _ => true,
        label: 'Remover',
    },
};

const isPositionValid = (position) => (
    Object.keys(variables).every(variableName => {
        const { isValid } = variables[variableName];
        return isValid(position[variableName]);
    })
);

export function useStaticPositionsState() {
    const [staticPositionTypes, setStaticPositionTypes] = useState([]);
    const [selectedStaticPositionTypeId, setSelectedStaticPositionTypeId] = useState('');
    const originalStaticPositions = useRef([]);
    const [staticPositions, setStaticPositions] = useState([]);
    const [filterActive, setFilterActive] = useState(1);
    const [sortOptions, setSortOptions] = useState({
        variable: 'description',
        asc: true,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [radius, setRadius] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [editingPosition, setEditingPosition] = useState(null);
    const [creatingPosition, setCreatingPosition] = useState(false);

    useEffect(() => {
        (async function() {
            const response = await api.get('/static-position-types');
            setStaticPositionTypes(response.data);
        })();
    }, []);

    const selectStaticPositionType = async (event) => {
        const typeId = event.target.value;
        const response = await api.get(`/manage/static-position-types/${typeId}/static-positions`);
        originalStaticPositions.current = response.data;
        sortPositions(sortOptions, response.data);
        setSelectedStaticPositionTypeId(typeId);
    };

    const addNewStaticPosition = () => {
        setCreatingPosition(true);
        const newStaticPosition = {
            tempId: Date.now(),
            inactive: false,
        };
        setStaticPositions([newStaticPosition, ...staticPositions]);
    };

    const updateStaticPosition = (id, variableName, newValue) => {
        const newStaticPositions = [...staticPositions];
        const index = newStaticPositions.findIndex(old => [old.id, old.tempId].includes(id));
        newStaticPositions[index] = { ...newStaticPositions[index] };
        const position = newStaticPositions[index];
        const originalPosition = originalStaticPositions.current.find(old => old.id === id);
        position[variableName] = newValue;
        if (originalPosition) {
            const anyChanged = Object.keys(variables).some(variable => {
                const { varType } = variables[variable];
                return varType(originalPosition[variable]) !== varType(position[variable]);
            });
            position.update = isPositionValid(position) && anyChanged;
        }
        setStaticPositions(newStaticPositions);
    };

    const positionToSend = (position) => {
        const {
            id,
            inactive,
            update,
        } = position;
        if (id) {
            return update;
        }
        return !inactive && isPositionValid(position);
    };

    const getPositionsToSend = () => {
        return staticPositions.filter(positionToSend);
    };

    const shouldSave = () => {
        return getPositionsToSend().length > 0;
    };

    const save = async () => {
        if (shouldSave()) {
            const data = getPositionsToSend();

            const result = await api.post(
                `/manage/static-position-types/${selectedStaticPositionTypeId}/static-positions`,
                { data },
            );

            const { current: originalPositions } = originalStaticPositions;

            result.data.forEach((newPosition) => {

                if (newPosition.tempId) {
                    delete newPosition.tempId;
                    originalPositions.push(newPosition);
                } else {
                    const index = originalPositions.findIndex(
                        ({ id }) => id === newPosition.id
                    );
                    delete newPosition.update;
                    originalPositions[index] = newPosition;
                }

            });   
            sortPositions(sortOptions, originalPositions);
            setCreatingPosition(false);
        }
    };

    const changeSortOptions = (variable) => {
        const asc = sortOptions.variable === variable ? !sortOptions.asc : true;
        const newSortOptions = { variable, asc };
        setSortOptions(newSortOptions);
        sortPositions(newSortOptions);
    };

    const sortPositions = ({ variable, asc }, currentPositions = null) => {
        const positions = [...(currentPositions || staticPositions)];
        const { varType } = variables[variable];
        positions.sort((a, b) => {
            if (varType === String) {
                const [x, y] = [a, b].map(c => c[variable].toLowerCase());
                return (x < y)
                    ? [1, -1][Number(asc)]
                    : ((x > y)
                        ? [-1, 1][Number(asc)]
                        : 0);
            }
            return asc
                ? a[variable] - b[variable]
                : b[variable] - a[variable];
        });
        setStaticPositions(positions);
    };

    const exportListStaticPosition = () => {
        const newListStaticPosition = staticPositions.map(
            ({ id, ...listStaticPosition }) => listStaticPosition
        );

        const staticPositionType = staticPositionTypes.find(
            (item) => item.id === Number(selectedStaticPositionTypeId)
        );

        const fileName = staticPositionType
            ? `static_positions_${staticPositionType.name}.json`
            : 'static_positions.json';

        const fileContent = JSON.stringify(newListStaticPosition, null, 4);
        const data = new Blob([fileContent], {
            type: 'data:text/json;charset=utf-8',
        });

        return downloadFile(data, fileName);
    };

    const readFileAsJSON = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const fileContent = JSON.parse(e.target.result);
                    resolve(fileContent);
                } catch (error) {
                    console.error('Error parsing JSON: ', error);
                    resolve(null);
                }
            };

            reader.readAsText(file);
        });
    };

    const combinePositionsAndFilterUniquePositions = (existingPositions, newPosition) => {
        const combinedArrayPositions = [...existingPositions, ...newPosition];

        const filterUniquePositions = combinedArrayPositions.filter(
            (position, index, self) =>
                self.findIndex(
                    (existingPosition) =>
                        existingPosition.latitude === position.latitude &&
                        existingPosition.longitude === position.longitude
                ) === index
        );

        return filterUniquePositions;
    };

    const importFile = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('change', async (event) => {
            const file = event.target.files[0];

            if (file) {
                const fileContent = await readFileAsJSON(file);
                const combineStaticPositions =
                    combinePositionsAndFilterUniquePositions(
                        staticPositions,
                        fileContent
                    );
                const newStaticPositions = combineStaticPositions.map(staticPosition => {
                    if (!staticPosition.hasOwnProperty('id')) {
                        const { type_id, ...positions } = staticPosition;
                        return { tempId: Math.random(Date.now()), ...positions };
                    }
                    return staticPosition;
                });

                setStaticPositions(newStaticPositions);
            }
        });

        input.click();
    };

    const getStaticPositionTypeRadius = useCallback(async (id) => {
        const position = (await api.get(`/manage/static-positions/${id}`)).data;
        const typeId = selectedStaticPositionTypeId;

        const positionType = staticPositionTypes
            .find(({ id: positionTypeId }) => String(positionTypeId) === typeId);
        
        const allRadius = position.radius? position.radius : positionType?.radius;
        setSelectedPosition(position);
        setEditingPosition({ ...position, radius: allRadius });
        setRadius(allRadius);
    }, [selectedStaticPositionTypeId, staticPositionTypes]);

    const handleIsModalOpen = async () => {
        setIsModalOpen(state => !state);
        setRadius({})
     
    };
    const handleChangeRadius = useCallback((index, value) => {
        setRadius(prevRadius => (
            prevRadius.map((item, i) => i === index ? value : item)
        ));
    }, []);

    const addRadius = () => {
        setRadius(prev => prev?.length ? [...prev, '']: ['']);
    };

    const removeRadius = (index) => {
        setRadius(radius.filter((_, i) => i !== index));
    };

    function handleChangeStatus(statusName){
        setSelectedPosition({
            ...selectedPosition,
            [statusName]: !selectedPosition[statusName]
        })
    }

    function shouldSaveRadius() {
        if(selectedPosition && editingPosition) {
            const {show_icon: icon, show_ranges: ranges} = selectedPosition;
            const showIcon = icon !== editingPosition.show_icon;
            const showRanges = ranges !== editingPosition.show_ranges;
            
            let radiusChanged = checkRadiusChanged();
            return (showIcon || showRanges || radiusChanged);
        }
    }

    function checkRadiusChanged(){
        const currentRadius = radius || [];
        const initialRadius = editingPosition.radius || [];
        let radiusChanged = false;
        if(currentRadius.length !== initialRadius.length) {
            radiusChanged = true; 
        }else{
            radiusChanged = currentRadius.some(() => {
                const updatedRadiusValues = currentRadius || [];
                const initialRadiusValues = initialRadius || [];
                if (updatedRadiusValues.length !== initialRadiusValues.length) {
                    return true;
                }
                
                return updatedRadiusValues.some((value, index) => value !== initialRadiusValues[index]);
            });
        }
        return radiusChanged;  
    }
    
    async function saveRadius() {
       if(!shouldSaveRadius()) return;
       try {
        const typeId = selectedStaticPositionTypeId;
        let data =[{...selectedPosition}]
        if(checkRadiusChanged()) {
            data =[{
                ...selectedPosition,
                radius: radius.length ? radius : null
            }]
        }
        await api.post(`/manage/static-position-types/${typeId}/static-positions`, { data });
         
        const updatedPositions = staticPositions.map(pos => {
            if (pos.id === selectedPosition.id) {
                return { ...pos, radius: radius.length ? radius : null };
            }
            return pos;
        });

        setStaticPositions(updatedPositions);
       } catch (error) {
        console.error(error);
       } finally {
           handleIsModalOpen();  
       }
    }

    return {
        staticPositionTypes,
        getStaticPositionTypeRadius,
        selectedStaticPositionTypeId,
        staticPositions,
        filterActive,
        sortOptions,
        isModalOpen,
        radius,
        selectedPosition,
        editingPosition,
        creatingPosition,
        setFilterActive,
        setIsModalOpen,
        setRadius,
        setEditingPosition,
        setSelectedStaticPositionTypeId,
        setSelectedPosition,
        setCreatingPosition,
        selectStaticPositionType,
        addNewStaticPosition,
        updateStaticPosition,
        shouldSave,
        save,
        changeSortOptions,
        exportListStaticPosition,
        importFile,
        positionToSend,
        handleIsModalOpen,
        handleChangeRadius,
        addRadius,
        removeRadius, 
        handleChangeStatus,
        shouldSaveRadius,
        saveRadius,
        variables
    };
}
