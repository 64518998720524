import { useLocation } from 'react-router-dom';

function useQuery() {
    const searchParams = new URLSearchParams(useLocation().search);
    const queryParams = {};
    searchParams.forEach((value, key) => {
        queryParams[key] = value;
    });
    return queryParams;
}

export { useQuery };
