import React, { useMemo } from 'react';
// import ReactDOMServer from 'react-dom/server';

import { useApiData } from '../../../../contexts/api-data';
import { useAuth } from '../../../../contexts/auth';

const availableVariables = [
    'dew_point',
    'temperature',
    'relative_humidity',
    'wind_speed',
    'wind_direction',
    'thermal_sensation',
    'atmospheric_pressure',
];

export function useWeatherTableStates(stations) {
    const { settingsSet } = useAuth();
    const { VARIABLES_UNITS } = { ...settingsSet };

    const {
        manageWeatherVariables: {
            value: weatherVariables,
        },
    } = useApiData();

    const tableData = useMemo(() => {
        if (!stations || !weatherVariables) {
            return null;
        }
        const variables = [];
        const measures = stations.reduce((result, {
            id: station_id,
            code,
            name,
            measures,
            inactive,
            is_weather,
        }) => {
            if (!measures || inactive || !is_weather) {
                return result;
            }
            const { datetime } = measures;
            const weatherMeasures = {};
            Object.keys(measures).forEach(key => {
                if (!availableVariables.includes(key)) {
                    return;
                }
                if (!variables.find(v => v.key === key)) {
                    const weatherVariable = weatherVariables.find(v => v.key === key);
                    variables.push(weatherVariable);
                }
                weatherMeasures[key] = measures[key];
            });
            if (Object.keys(weatherMeasures).length > 0) {
                result.push({
                    ...weatherMeasures,
                    datetime,
                    station_id,
                    code,
                    name,
                });
            }
            return result;
        }, []);

        if (variables.length === 0) {
            return null;
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const variablesUnits = VARIABLES_UNITS?.map(value => value.split(',').map(Number)) || [];

        const tableHeader = ['N°', 'Estação', 'Hora da Leitura'].concat(
            variables.map(({ id, label }) => {
                const variableUnit = variablesUnits.find(vu => vu[0] === id);
                const units = weatherVariables.find(wv => wv.id === id).type.units;
                const unit = units.find(u => (
                    !variableUnit ? u.formula === 'x' : u.id === variableUnit[1]
                )) || units.find(u => u.formula === 'x');
                return `${label} (${unit.label})`;
            }),
        );
        const tableContent = measures.map(measure => {
            const { datetime, name, code } = measure;
            const dateObject = new Date(Number(datetime));
            const date = dateObject.toLocaleDateString('pt-BR');
            const time = dateObject.toLocaleTimeString('pt-BR').substring(0, 5);
            const datetimeStr = `${date} - ${time}`;
            return [
                Number(code),
                name,
                {
                    v: datetime,
                //     f: ReactDOMServer.renderToString(
                    f:
                        <a
                            href={`/estacoes/meteorologicos?stationId=${measure.station_id}&lastMeasureDatetime=${measure.datetime}`}
                            className="access-link"
                        >
                            {datetimeStr}
                        </a>
                //     ),
                },
                // datetimeStr,
            ].concat(variables.map(({ key }) => {
                if (isNaN(measure[key])) {
                    return measure[key];
                }
                const v = Number(Number(measure[key]).toFixed(2));
                return {
                    v,
                    f: Number(v).toLocaleString('pt-BR'),
                };
            }));
        });

        return [
            tableHeader,
            ...tableContent,
        ];
    }, [stations, weatherVariables, VARIABLES_UNITS]);

    return {
        tableData,
    };
};
