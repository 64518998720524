import baseApi from '../../base';

export async function getStationInstitutions() {
    const result = await baseApi.get(`/station-institutions`);
    return result.data;
};

export async function postManageStationInstitutions(institution) {
    const result = await baseApi.post('/manage/station-institutions', institution);
    return result.data;
};

export async function putManageStationInstitutions(institutionId, institution) {
    const result = await baseApi.put(`/manage/station-institutions/${institutionId}`, institution);
    return result.data;
};
