import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useApiData } from '../../../contexts/api-data';
import { logDebug } from '../../../utils/debug';

import './styles.css';

import CustomerSettingsBox from './customer-settings-box';

import {
    generalSettings,
    pagesSettings,
} from './all-settings';

function CustomerSettings({
    customerSettings,
    setCustomerSettings,
    shouldSave,
    saveFunction,
    exportFunction,
    importFunction,
}) {
    const {
        forecastModels: {
            fetchAndSave: fetchForecastModels,
        },
        manageForecastRegions: {
            fetch: fetchManageForecastRegions,
        },
        manageHarbors: {
            fetch: fetchManageHarbors,
        },
        manageStates: {
            fetch: fetchManageStates,
        },
    } = useApiData();

    const [visiblePage, setVisiblePage] = useState(0);
    const [updatedPagesSettings, setUpdatedPageSettings] = useState(pagesSettings || null);

    useEffect(() => {
        fetchManageForecastRegions();
    }, [fetchManageForecastRegions]);

    useEffect(() => {
        fetchForecastModels();
    }, [fetchForecastModels]);

    useEffect(() => {
        fetchManageHarbors();
    }, [fetchManageHarbors]);

    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);

    function setSetting(key, newValue) {
        if (newValue) {
            logDebug(`${key}: ${newValue.toString()}`);
        }
        setCustomerSettings({
            ...customerSettings,
            [key]: newValue,
        });
    }

    useEffect(() => {
        const newPageSettings = pagesSettings.map((ps, index) => {
            ps.isVisible = index === visiblePage;
            return ps;
        });

        setUpdatedPageSettings(newPageSettings);
    }, [visiblePage]);

    return (<>
        {customerSettings && <CustomerSettingsBox
            label={'Configurações gerais (Todos os campos são obrigatórios)'}
            alwaysOpen={true}
            settings={generalSettings}
            customerSettings={customerSettings}
            setSetting={setSetting}
        />}

        <div className='info-save'>
            <span className='red'> (*) Campos obrigatórios para o funcionamento de cada página. </span>

                <div className="info-save">
                    <Link
                        to="#"
                        className={`save-btn ${shouldSave() ? '' : 'disabled'}`}
                        onClick={saveFunction}
                    >
                        Salvar
                    </Link>
                    <Link
                        to="#"
                        className={`save-btn`}
                        onClick={exportFunction}
                    >
                        Exportar
                    </Link>
                    <Link
                        to="#"
                        className={`save-btn`}
                        onClick={importFunction}
                    >
                        Importar
                    </Link>
                </div>
       </div>

        <div className='page-settings'>
            <div className='page-settings-menu'>
                {updatedPagesSettings.length && updatedPagesSettings.map(({ isVisible, label }, index) => {
                    return (
                        <li
                            key={index}
                            className={`${isVisible ? 'active' : ''}`}
                            onClick={() => setVisiblePage(index)}
                        >
                            { label }
                        </li>
                    );
                })}
            </div>

            <div className='page-settings-content'>
                {updatedPagesSettings.map(pageSettings => {
                    const {
                        isVisible,
                        label,
                        settings,
                    } = pageSettings;

                    return isVisible && customerSettings && (
                        <CustomerSettingsBox
                            key={label}
                            label={label}
                            alwaysOpen={true}
                            settings={settings}
                            customerSettings={customerSettings}
                            setSetting={setSetting}
                        />
                    );
                })}
            </div>
        </div>
    </>);
}

export default CustomerSettings;
