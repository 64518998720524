import { useCallback, useState, useMemo, useEffect } from 'react';

import {
    getBasinPrecipitationStages,
    postManageBasinPrecipitationStages,
    putManageBasinPrecipitationStages,
    deleteManageBasinPrecipitationStages,
} from '../../../../services/api';
import { useSocket as useSocketBase } from '../../../../hooks/use-socket';

export function useManageBasinPrecipitationStagesState() {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const valueSorted = useMemo(() => {
        return value?.sort((a, b) => a.level - b.level);
    }, [value]);

    const fetch = useCallback(
        async function () {
            if (value || loading) {
                return;
            }
            setLoading(true);
            const stages = await getBasinPrecipitationStages();
            setValue(stages);
            setLoading(false);
        },
        [value, loading],
    );

    function useSocket() {
        useSocketBase('basin-precipitation-stage', (stage) => {
            setValue(old => {
                const newValue = [...old];
                if (stage.deleted) {
                    return newValue.filter(({ id }) => id !== stage.id);
                }
                const index = newValue.findIndex(({ id }) => id === stage.id);
                if (index === -1) {
                    newValue.push(stage);
                }
                else {
                    newValue[index] = {
                        ...newValue[index],
                        ...stage,
                    };
                }
                return newValue;
            });
        });
    }

    function useFetchAndSocket() {
        useEffect(() => {
            fetch();
        }, []);

        useSocket();
    }

    function add() {
        const id = Date.now();
        setValue([...value, { id }]);
    }

    async function save(stageId, stage) {
        const index = value.findIndex(({ id }) => id === stageId);
        // creating
        if (!stage.id) {
            stage = await postManageBasinPrecipitationStages(stage);
        }
        // updating
        else {
            await putManageBasinPrecipitationStages(stage);
        }
        const newValue = [...value];
        newValue[index] = stage;
        setValue(newValue);
    }

    async function remove(stageId) {
        await deleteManageBasinPrecipitationStages(stageId);
        setValue(old => old.filter(({ id }) => id !== stageId));
    }

    return {
        add,
        fetch,
        loading,
        remove,
        save,
        useSocket,
        useFetchAndSocket,
        value: valueSorted,
    };
};
