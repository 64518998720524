import React, { useState } from 'react';
// import { Chart } from 'react-google-charts';
import {
    FaLongArrowAltDown,
    FaLongArrowAltUp,
} from 'react-icons/fa';

import DashboardBox from '../../../../components/dashboard-box';
import { useMeasuresTableStates } from './states';

import './styles.css';

function MeasuresTable({ measures, stationName, weatherVariables }) {
    const {
        tableData,
    } = useMeasuresTableStates(measures, weatherVariables);

    const [sortOptions, setSortOptions] = useState({ index: 0, order: 'DESC' });

    function toggleSort(index) {
        if (sortOptions.index !== index) {
            setSortOptions({
                index,
                order: 'ASC',
            });
            return;
        }
        const order = sortOptions.order === 'ASC' ? 'DESC' : 'ASC';
        setSortOptions({
            index,
            order,
        });
    }

    return (
        <DashboardBox
            title={`Registros Meteorológicos da estação - ${stationName || ''}`}
            className="stations-weather-measures-table-box"
        >
            {tableData && <div className="stations-weather-measures-table-container">
                {/* <Chart
                    chartType={'Table'}
                    data={tableData}
                    options={{
                        width: '100%',
                        allowHtml: true,
                    }}
                    className="stations-weather-measures-table"
                    chartLanguage="pt-BR"
                /> */}
                <table
                    className="stations-weather-measures-table"
                    style={{ width: '100%' }}
                >
                    <thead>
                        <tr>
                            {tableData[0].map((headerLabel, index) => (
                                <th
                                    key={`header-${index}`}
                                    onClick={() => toggleSort(index)}
                                >
                                    {headerLabel}
                                    {sortOptions.index === index
                                        ? (sortOptions.order === 'ASC'
                                            ? <FaLongArrowAltUp />
                                            : <FaLongArrowAltDown />
                                        )
                                        : null}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData
                            .slice(1)
                            .sort((a, b) => {
                                const [x, y] = [a, b].map(row => {
                                    const rowCell = row[sortOptions.index];
                                    if (typeof rowCell === 'object') {
                                        return rowCell.v;
                                    }
                                    return rowCell;
                                });
                                if ([null, undefined].includes(x)) {
                                    return sortOptions.order === 'DESC' ? 1 : -1;
                                }
                                if ([null, undefined].includes(y)) {
                                    return sortOptions.order === 'DESC' ? -1 : 1;
                                }
                                if ([x, y].some(isNaN)) {
                                    return sortOptions.order === 'DESC'
                                        ? y.localeCompare(x)
                                        : x.localeCompare(y);
                                }
                                return sortOptions.order === 'DESC'
                                    ? y - x
                                    : x - y;
                            })
                            .map((row, rowIndex) => (
                                <tr key={`row-${rowIndex}`}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={`cell-${rowIndex}-${cellIndex}`}>
                                            {typeof cell === 'object'
                                                ? cell.f
                                                : cell}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>}
        </DashboardBox>
    );
}

export default MeasuresTable;
