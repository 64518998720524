import React from 'react';

const SELECTION_INPUT_NULL_VALUE = 'SELECTION_INPUT_NULL_VALUE';

function SelectionInput({
    defaultOption,
    defaultValue,
    setValue,
    options,
    isNullable,
    inputId,
    disabled,
    nullLabel = 'Selecione',
}) {
    function changeValue(event) {
        const { value } = event.target;
        const newValue = value === SELECTION_INPUT_NULL_VALUE
            ? null
            : value;
        setValue(newValue);
    }

    const nullOption = {
        value: SELECTION_INPUT_NULL_VALUE,
        label: nullLabel,
        ...defaultOption,
    };

    return (
        <select
            id={inputId}
            defaultValue={defaultValue || SELECTION_INPUT_NULL_VALUE}
            onChange={changeValue}
            disabled={disabled}
        >
            <option
                value={nullOption.value}
                disabled={!isNullable}
            >
                {nullOption.label}
            </option>
            {options?.length && options.map(option => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export default SelectionInput;
