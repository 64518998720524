import { useEffect, useState, useRef, useMemo } from 'react';

import api, {
    getManageStationByInstitutions,    
} from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import downloadFile from '../../../../services/download-file';

import './styles.css';

const variablesTypes = {
    name: String,
    code: String,
};

export function useStationsListStates() {
    const {
        manageCitiesByState: {
            value: citiesByState,
        },
        neighborhoodsByCity,
        retrieveNeighborhoodsByCity,
        manageStates: {
            fetch: fetchManageStates,
        },
        manageStationConfigurations: {
            fetch: fetchStationConfigurations,
        },
        manageStationInstitutions: {
            fetch: fetchStationInstitutions,
            value: stationInstitutions,
        },
        manageStationProtocols: {
            fetch: fetchStationProtocols,
        },
        sensors: {
            fetch: fetchSensors,
        },
    } = useApiData();

    useEffect(() => {
        fetchStationProtocols();
    }, [fetchStationProtocols]);

    useEffect(() => {
        fetchStationConfigurations();
    }, [fetchStationConfigurations]);

    useEffect(() => {
        fetchSensors();
    }, [fetchSensors]);

    useEffect(() => {
        fetchManageStates();
    }, [fetchManageStates]);

    const [stationsNeighborhoodsIds, setStationsNeighborhoodsIds] = useState([]);
    const [selectedStationInstitutionId, setSelectedStationInstitutionId] = useState('');
    const originalStationsList = useRef([]);
    const [stations, setStations] = useState([]);
    const [filterCity, setFilterCity] = useState({});
    const [filterCityId, setFilterCityId] = useState(0);
    const [filterNeighborhoodId, setFilterNeighborhoodId] = useState(0);
    const [filterActive, setFilterActive] = useState(1);
    const [sortOptions, setSortOptions] = useState('name=asc');
    const [shouldPostImport, setShouldPostImport] = useState(null);

    useEffect(() => {
        fetchStationInstitutions();
    }, [fetchStationInstitutions]);

    useEffect(() => {
        if (!filterCity.city_id) {
            return;
        }
        retrieveNeighborhoodsByCity(filterCity.city_id);
    }, [filterCity, retrieveNeighborhoodsByCity]);

    const stationsNeighborhoods = useMemo(
        () => neighborhoodsByCity[filterCityId]
            ? neighborhoodsByCity[filterCityId].filter(n => stationsNeighborhoodsIds.includes(n.id))
            : [],
        [filterCityId, neighborhoodsByCity, stationsNeighborhoodsIds],
    );

    async function selectStationInstitution(institutionId) {
        const newStations = await getManageStationByInstitutions(institutionId);
        originalStationsList.current = newStations;
        const neighborhoodsIds = [];
        newStations.forEach(({ neighborhood_id }) => {
            if (neighborhood_id && !neighborhoodsIds.includes(neighborhood_id)) {
                neighborhoodsIds.push(neighborhood_id);
            }
        });
        setStationsNeighborhoodsIds(neighborhoodsIds);
        sortStations(sortOptions, newStations);
        setSelectedStationInstitutionId(institutionId);
        setFilterNeighborhoodId(0);
        setFilterCityId(0);
    }

    function addNewStation() {
        const newStation = {
            tempId: Date.now(),
            inactive: false,
        };
        setStations([ newStation, ...stations ]);
    }

    function changeSortOptions(newSortOptions) {
        setSortOptions(newSortOptions);
        sortStations(newSortOptions);
    }

    function sortStations(sortOptions, currentStations=null) {
        const [variable, asc] = sortOptions.split('=');
        const newStations = [ ...(currentStations || stations) ];
        const varType = variablesTypes[variable];
        newStations.sort((a, b) => {
            if (varType === String) {
                const [x, y] = [a, b].map(c => c[variable].toLowerCase());
                return (x < y)
                    ? [1, -1][Number(Boolean(asc))]
                    : ((x > y)
                        ? [-1, 1][Number(Boolean(asc))]
                        : 0);
            }
            return asc
                ? a[variable]-b[variable]
                : b[variable]-a[variable];
        });
        setStations(newStations);
    }

    const filterCitiesIds = useMemo(
        () => (citiesByState[filterCity.state_id] || [])
            .map(({ id }) => id),
        [citiesByState, filterCity],
    );

    const shouldSave = () => {
        const stationsWithoutIds = stations.filter(
            (item) => typeof item.id === 'undefined');
        if(stationsWithoutIds){
            return shouldPostImport !== null;
        }
    };

    const getFileName = (selectedStationInstitutionId) => {
        const stationInstitution = stationInstitutions.find(
            (institution) =>
                Number(selectedStationInstitutionId) === institution.id
        );
        if(stationInstitution){
            return `stations_${stationInstitution.name}.json`;
        }
        return;
    };

    const exportListStation = (stations) => {
        if (stations?.length) {
            const newListStations = stations.map((station) => {
                return {
                    altitude: station.altitude,
                    city: station.city,
                    city_id: station.city_id,
                    code: station.code,
                    inactive: station.inactive,
                    institution_id: station.institution_id,
                    last_measure_datetime_id: station.last_measure_datetime_id,
                    latitude: station.latitude,
                    longitude: station.longitude,
                    name: station.name,
                    neighborhood_id: station.neighborhood_id,
                    time_frequency: station.time_frequency,
                };
            });
            const fileContent = JSON.stringify(newListStations, null, 4);
            const data = new Blob([fileContent], {
                type: 'data:text/json;charset=utf-8',
            });
            const fileName = getFileName(selectedStationInstitutionId);
            setStations(stations);
            return downloadFile(data, fileName);
        }
    };
    const importFile = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('change', async (event) => {
            const file = event.target.files[0];

            if (file) {
                try {
                    const fileContent = await readFileAsJSON(file);
                    fileContent.forEach(station => {
                        station.institution_id = Number(selectedStationInstitutionId);
                    });
                    const uniqueObjectStations = combineAndFilterStations(
                        stations,
                        fileContent
                    );

                    const existStationsForSave = uniqueObjectStations.some(
                        (obj) => !obj.hasOwnProperty('id')
                    );

                    if (existStationsForSave) {
                        setShouldPostImport(uniqueObjectStations);
                        setStations(uniqueObjectStations);
                    }
                } catch (error) {
                    console.error('Error reading the file:', error);
                }
            }
        });
        input.click();
    };

    const readFileAsJSON = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const fileContent = JSON.parse(e.target.result);
                    resolve(fileContent);
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsText(file);
        });
    };

    const combineAndFilterStations = (existingStations, newStations) => {
        const combinedArrayStations = existingStations.concat(newStations);
        const uniqueObjectStations = combinedArrayStations.filter(
            (station, index, self) => self.findIndex(s => s.code === station.code) === index
        );
        return uniqueObjectStations;
    };

    async function saveStations() {
        if (shouldSave()) {
            const stationWithoutIds = stations.filter(
                (station) => typeof station.id === 'undefined'
            );

            const response = await api.post('/manage/stations',
                stationWithoutIds
            );
            setShouldPostImport(null);
            setStations(stations);
            return response.data;
        }
    }

    return {
        addNewStation,
        changeSortOptions,
        exportListStation,
        filterActive,
        filterCity,
        filterCitiesIds,
        filterNeighborhoodId,
        importFile,
        saveStations,
        selectStationInstitution,
        selectedStationInstitutionId,
        shouldSave,
        setFilterActive,
        setFilterCity,
        setFilterNeighborhoodId,
        sortOptions,
        stations,
        stationInstitutions,
        stationsNeighborhoods,
    };
}
