import { useEffect, useState, useCallback, useMemo } from 'react';

import { useApiData } from '../../../contexts/api-data';
import { useQuery } from '../../../utils/hooks/query';
import {
    filterStationWeatherMeasures,
} from '../../../services/api';

export function useStationsWeatherMeasuresState() {
    const {
        manageStations: {
            fetchByInstitution,
            value: stations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();

    const {
        stationId,
        lastMeasureDatetime,
    } = useQuery();

    const [measures, setMeasures] = useState(null);
    const [loadingMeasures, setLoadingMeasures] = useState(false);
    const [stationName, setStationName] = useState(null);

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        if (stations || !institutionId) {
            return;
        }
        fetchByInstitution(institutionId);
    }, [fetchByInstitution, institutionId, stations]);

    const initialStationId = useMemo(() => {
        if (stationId) {
            return stationId;
        }
        if (!stations?.length) {
            return null;
        }
        const firstStation = stations.find(({ inactive, is_weather }) => !inactive && is_weather);
        return firstStation?.id;
    }, [stationId, stations]);

    const initialLastMeasureDatetime = useMemo(() => {
        if (lastMeasureDatetime) {
            return lastMeasureDatetime;
        }
        if (!stations?.length) {
            return null;
        }
        return stations[0]?.measures?.datetime;
    }, [lastMeasureDatetime, stations]);

    const fetchMeasures = useCallback(({
        stationId,
        datetimeStart,
        datetimeEnd,
        variables,
    }) => {
        if (loadingMeasures || !weatherVariables || !datetimeStart || !datetimeEnd || !stations) {
            return;
        }
        (async function () {
            setLoadingMeasures(true);
            setMeasures(null);
            const newMeasures = await filterStationWeatherMeasures({
                stationId,
                datetimeStart,
                datetimeEnd,
                variables,
            });
            const station = stations.find(({ id }) => id === Number(stationId));
            setStationName(station.name);
            newMeasures.sort((a, b) => b.datetime - a.datetime);
            setMeasures(newMeasures);
            setLoadingMeasures(false);
        })();
    }, [loadingMeasures, weatherVariables, stations]);

    return {
        fetchMeasures,
        lastMeasureDatetime: initialLastMeasureDatetime,
        loadingMeasures,
        measures,
        stations,
        weatherVariables,
        stationId: initialStationId,
        stationName,
    };
};
