import React from 'react';

import BaseBtn from '../base-btn';

import './styles.css';

function AllowedBtn(properties, ref) {
    const {
        isAllowed,
        className,
        onClick,
        ...rest
    } = properties;

    function isAllowedFinal() {
        return !isAllowed || isAllowed();
    }

    function onClickFinal() {
        if (isAllowedFinal() && onClick) {
            onClick();
        }
    }

    return (
        <BaseBtn
            {...rest}
            className={`${className} allowed-btn ${isAllowedFinal() ? '' : 'disabled'}`}
            onClick={onClickFinal}
            ref={ref}
        />
    );
}

export default React.forwardRef(AllowedBtn);
