import {
    createServer,
    Model,
    Serializer,
} from 'miragejs';

import {
    getMockApiSetting,
} from './utils';

const baseURL = process.env.REACT_APP_BASE_API_URL;

const serializer = Serializer.extend({
    root: false,
    embed: true,
});

function setSaveDBToLocalStorage(server) {
    server.pretender.handledRequest = function(verb) {
        if (!['get', 'head'].includes(verb.toLowerCase())) {
            localStorage.setItem('MOCKED_DB', JSON.stringify(server.db.dump()));
        }
    };
}

function loadDBFromLocalStorage(server) {
    const dbData = localStorage.getItem('MOCKED_DB');

    if (dbData) {
        server.db.loadData(JSON.parse(dbData));
    }
}

export function mockMirage() {
    const mockApiSetting = getMockApiSetting();
    if (mockApiSetting.length === 0) {
        return;
    }
    createServer({
        models: {
            alertTemplates: Model,
            calendarMessages: Model,
        },

        serializers: {
            alertTemplates: serializer,
            calendarMessages: serializer,
        },
        
        routes() {
            this.urlPrefix = baseURL;

            const mockApiSetting = getMockApiSetting();

            if (mockApiSetting.includes('MANAGE_ALERT_TEMPLATES')) {
                this.get('/manage/alert-templates', 'alertTemplates');
                this.post('/manage/alert-templates', (schema, request) => {
                    const attrs = JSON.parse(request.requestBody);
                    const id = Date.now();
                    attrs.id = id;
            
                    return schema.alertTemplates.create(attrs).attrs;
                });
                this.put('/manage/alert-templates/:id', (schema, request) => {
                    const attrs = JSON.parse(request.requestBody);
                    delete attrs.id;

                    const dbTemplate = schema.alertTemplates.find(request.params.id);
                    dbTemplate.update(attrs);
            
                    return dbTemplate.attrs;
                });
            }

            if (mockApiSetting.includes('MANAGE_CALENDAR_MESSAGES')) {
                this.get('/manage/calendar-messages', (schema, request) => {
                    const messages = schema.calendarMessages.all().models;
                    return messages.reduce((result, message) => {
                        const { datetime } = message;
                        result[datetime] = (result[datetime] || []).concat([message]);
                        return result;
                    }, {});
                });
                this.post('/manage/calendar-messages', (schema, request) => {
                    const attrs = JSON.parse(request.requestBody);
                    const id = Date.now();
                    attrs.id = id;
                    attrs.calendar_message_id = id;
            
                    return schema.calendarMessages.create(attrs).attrs;
                });
                this.put('/manage/calendar-messages/:id', (schema, request) => {
                    const attrs = JSON.parse(request.requestBody);
                    delete attrs.id;

                    const dbTemplate = schema.calendarMessages.find(request.params.id);
                    dbTemplate.update(attrs);
            
                    return dbTemplate.attrs;
                });
            }

            this.passthrough();
        },

        seeds(server) {
            setSaveDBToLocalStorage(server);
            loadDBFromLocalStorage(server);
        },
    });
};
