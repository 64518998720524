import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import './styles.css';

const ConfirmModal = ({
    open,
    onClose,
    onConfirm,
    onDeny,
    header,
    body,
    showCloseButton = false,
    positiveString = 'sim',
    negativeString = 'não',
    trigger,
}) => {
    return (
        <Popup
            modal
            trigger={trigger}
            open={open}
            className="modal modal-confirm"
            onClose={onClose}
            closeOnDocumentClick
        >
            {close => (
                <div className="modal-confirm">
                    {showCloseButton && (
                        <button className="close" onClick={close}>&times;</button>
                    )}

                    <div className="header">{ header ? header : 'Confirmar ação?' }</div>
                    {body && (
                        <div className="body">{ body } </div>
                    )}
                    <div className="actions">
                        <button
                            className="base-btn delete-btn"
                            onClick={() => {
                                onConfirm();
                                close();
                            }}
                        >
                            {positiveString}
                        </button>
                        <button
                            className="base-btn cancel-btn"
                            onClick={() => {
                                onDeny && onDeny();
                                close();
                            }}
                        >
                            {negativeString}
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default ConfirmModal;