import { useEffect, useState } from 'react';

import { useApiData } from '../../../../contexts/api-data';
import { postSystemSettings } from '../../../../services/api';

import {
    StationDelayPeriodsInput,
} from '../../../common/custom-inputs';

import {
    ArraySetting,
    DatetimePeriodSetting,
    NumberSetting,
    StringSetting,
} from '../../settings';

export const settingsKeys = [
    {
        key: 'FORECASTS_MAXIMUM_STORAGE_PERIOD',
        label: 'Previsões expiram em',
        SettingType: DatetimePeriodSetting,
    },
    {
        key: 'STATION_MEASUREMENTS_MAXIMUM_STORAGE_PERIOD',
        label: 'Medidas de estações expiram em',
        SettingType: DatetimePeriodSetting,
    },
    {
        key: 'CALENDAR_MESSAGES_MAXIMUM_STORAGE_PERIOD',
        label: 'Mensagens de agenda expiram em',
        SettingType: DatetimePeriodSetting,
    },
    {
        key: 'RADAR_MEASURES_MAXIMUM_STORAGE_PERIOD',
        label: 'Leituras telemétricas de radares expiram em',
        SettingType: DatetimePeriodSetting,
    },
    {
        key: 'RADAR_PRODUCTS_MAXIMUM_ROWS',
        label: 'Quantidade máxima de produtos de radar',
        SettingType: NumberSetting,
    },
    {
        key: 'SATELLITE_PRODUCT_TYPES_MAXIMUM_ROWS',
        label: 'Quantidade máxima de produtos de satélite',
        SettingType: NumberSetting,
    },
    {
        key: 'RDO_SETTINGS',
        label: 'Configurações do RDO',
        SettingType: StringSetting,
    },
    {
        key: 'STATION_DELAY_PERIODS',
        label: 'Tempo limite para estações atrasadas',
        SettingType: ArraySetting(StationDelayPeriodsInput),
    },
];

export function useSystemSettingsPageState() {
    const {
        systemSettings: {
            fetch,
            loading,
            set: setSystemSettings,
            value: systemSettings,
        },
    } = useApiData();

    const [initialSettings, setInitialSettings] = useState(null);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        if (loading || !systemSettings || initialSettings) {
            return;
        }

        setInitialSettings(systemSettings);
    }, [initialSettings, loading, systemSettings]);

    function shouldSave() {
        return settingsKeys.some(({ key }) =>
            JSON.stringify(systemSettings[key]) !== JSON.stringify(initialSettings[key])
        );
    }

    function save() {
        setInitialSettings(systemSettings);
        postSystemSettings(systemSettings);
    }

    function updateSystemSettings(key, value) {
        setSystemSettings({
            ...systemSettings,
            [key]: value,
        });
    }

    return {
        systemSettings,
        initialSettings,
        save,
        shouldSave,
        updateSystemSettings,
    };
};
