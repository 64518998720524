import React from 'react';

import MultipleSelectionInput from '../multiple-selection-input';

import { useStaticPositionTypeSelectionInputState } from './states';

function MultipleStaticPositionTypesSelectionInput({ defaultValue, setValue }) {
    const staticPositionTypeOptions = useStaticPositionTypeSelectionInputState().sort((a,b) => a.label.localeCompare(b.label));

    return (
        <MultipleSelectionInput
            defaultValue={defaultValue}
            setValue={setValue}
            options={staticPositionTypeOptions}
        />
    );
}

export default MultipleStaticPositionTypesSelectionInput;
