import { useEffect } from 'react';
import { useAuth } from '../../../auth';
import {
    getLoggedUserId,
    getSelectedItems,
    setSelectedItems,
} from '../../../../utils/user';


export default function useShowAttentionPerimeters () {
    const loggedUserId = getLoggedUserId();
    const { settingsSet } = useAuth();
    const {
        STATIC_POSITION_TYPE_ALIASES: staticPositionTypeAliases = [],
    } = { ...settingsSet };

    useEffect(() => {
        const canShowPerimeters = staticPositionTypeAliases.includes('perimetros');
        
        const selectedAttentionPerimeters = getSelectedItems('show_attention_perimeters') || {};
        if (selectedAttentionPerimeters[loggedUserId] && !canShowPerimeters) {
            selectedAttentionPerimeters[loggedUserId] = canShowPerimeters;
            setSelectedItems('show_attention_perimeters', selectedAttentionPerimeters);
        }
    }, [loggedUserId, staticPositionTypeAliases]);

}