import React from 'react';

import CopyButton from '../copy-button';
import { useApiData } from '../../contexts/api-data';

function ForecastDescriptionBox() {
    const {
        selectedForecast,
        selectedForecastRegion,
    } = useApiData();

    let forecastText;

    if (selectedForecast) {
        const { datetime, precipitationClass, precipitation_accuracy } = selectedForecast;
        const forecastLabels = ['Sem chuva', 'Fraca', 'Moderada', 'Forte'];
        const forecast = precipitationClass > 0
            ? `Previsão de chuva ${forecastLabels[precipitationClass].toLowerCase()}`
            : 'Sem previsão de chuva';
        const hour = new Date(datetime).toLocaleTimeString().substr(0, 2);
        const timeAndPlace = `${precipitationClass > 0
            ? `às ${parseInt(hour)}`
            : 'nas próximas 12'
            } horas na região ${selectedForecastRegion?.name}`;
        const accuracy = `com ${Number(precipitation_accuracy)}% de probabilidade de acerto`;

        forecastText = [forecast, timeAndPlace, accuracy];
    }

    return (
        <div className="dashboard-box-desc">
            <div id="descricao-tit-2" className="db-titulo">
                Descrição da previsão para {selectedForecastRegion?.name}
            </div>
            <textarea
                id="msg-text-transf"
                className="copytextarea"
                style={{
                    zIndex: -1,
                    position: 'relative',
                    width: 0,
                    height: 0,
                    display: 'block !important',
                }}
            ></textarea>                            
            <div className="subtitle">Completa</div> 
            <div className="descricao">
                {forecastText && (
                    <div className="mensagem">
                        <span id="msg-txt-1">
                            {`${forecastText.join(' ')}.`}
                        </span>
                        <CopyButton value={forecastText.join(' ')} />
                    </div>
                )}
            </div>

            <div className="subtitle">Resumida</div>
            <div className="descricao">
                {forecastText && (
                    <div className="mensagem">
                        <span id="msg-txt-2">
                            {`${forecastText.slice(0, 2).join(' ')}.`}
                        </span>
                        <CopyButton value={forecastText.slice(0, 2).join(' ')} />
                    </div>
                )}
            </div> 
        </div> 
    );
}

export default ForecastDescriptionBox;
