import { useCallback, useState } from 'react';

import { getForecastsRunDatetimes } from '../../../../services/api';

export function useForecastsRunDatetimesByModel() {
    const [value, setValue] = useState({});

    const fetch = useCallback(async function(modelId) {
        const result = await getForecastsRunDatetimes(modelId);
        setValue(oldState => ({
            ...oldState,
            [modelId]: result,
        }));
    }, []);

    return {
        fetch,
        value,
    };
};
