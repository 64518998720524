import { useState, useCallback, useMemo } from 'react';

import {
    filterRadarEnergyEvents,
} from '../../../../services/api';
import { useAuth } from '../../../../contexts/auth';

export function useRadarBoxState() {
    const {
        settingsSet,
    } = useAuth();
    const {
        DASHBOARD_RADAR,
    } = { ...settingsSet };
    const [events, setEvents] = useState(null);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const radarId = useMemo(() => DASHBOARD_RADAR, [DASHBOARD_RADAR]);

    const fetchEvents = useCallback(({
        radarId,
        datetimeStart,
        datetimeEnd,
    }) => {
        if (loadingEvents || !datetimeStart || !datetimeEnd || !radarId) {
            return;
        }
        (async function () {
            setLoadingEvents(true);
            const newEvents = await filterRadarEnergyEvents({
                radar_id: radarId,
                datetimeStart,
                datetimeEnd,
            });
            newEvents.sort((a, b) => b.datetime - a.datetime);
            setEvents(newEvents);
            setLoadingEvents(false);
        })();
    }, [loadingEvents]);

    return {
        fetchEvents,
        events,
        radarId,
    };
};
