import BasinsStages from './basins-stages';
import DetailedForecasts from './detailed-forecasts';
import ExtendedForecasts from './extended-forecasts';
import StationHighestPrecipitations from './station-highest-precipitations';
import StationsSlipStages from './stations-slip-stages';

export const operationSections = [
    {
        id: 'bacias',
        section: 'Bacias',
        pages: [
            {
                id: 'bacias-estagios',
                title: '- Estágios',
                component: BasinsStages,
                permission: 'WRITE_BASIN_STAGES',
            },
        ],
    },
    {
        id: 'zonas-pluviometricas',
        section: 'Zonas Pluviométricas',
        pages: [
            {
                id: 'zonas-pluviometricas-estagios',
                title: '- Estágios',
                component: StationsSlipStages,
                permission: 'WRITE_STATION_SLIP_STAGES',
            },
            {
                id: 'zonas-pluviometricas-maiores-chuvas',
                title: '- Maiores Chuvas',
                component: StationHighestPrecipitations,
                permission: 'WRITE_STATION_HIGHEST_PRECIPITATIONS',
            },
        ],
    },
    {
        id: 'boletim-meteorologico',
        section: 'Boletim Meteorológico',
        pages: [
            {
                id: 'boletim-detalhado',
                title: '- Detalhado',
                component: DetailedForecasts,
                permission: 'WRITE_DETAILED_BULLETIN',
            },
            {
                id: 'boletim-estendido',
                title: '- Estendido',
                component: ExtendedForecasts,
                permission: 'WRITE_EXTENDED_BULLETIN',
            },
        ],
    },
];
