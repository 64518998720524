import React from 'react';

function NumberSetting({ defaultValue, setValue }) {
    return (
        <input
            className="number-setting"
            type="number"
            value={defaultValue || ''}
            onChange={(event) => setValue(event.target.value)}
            maxLength={50}
        />
    );
}

export default NumberSetting;
