export const zones = ['rj', 'zc', 'zn', 'zo', 'zs'];

export const zoneNames = {
    rj: 'Rio de Janeiro',
    zc: 'Centro',
    zn: 'Zona Norte',
    zo: 'Zona Oeste',
    zs: 'Zona Sul',
};

export const titles = {
    rj: 'o município do Rio de Janeiro',
    zc: 'o Centro',
    zn: 'a Zona Norte',
    zo: 'a Zona Oeste',
    zs: 'a Zona Sul',
};

export const raToZoneMap = {
    1: 'cor_rj_zc',
    2: 'cor_rj_zs',
    3: 'cor_rj_zn',
    4: 'cor_rj_zo',
};
