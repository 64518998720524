import { useEffect, useState, useCallback, useMemo } from 'react';

import { useApiData } from '../../../contexts/api-data';
import { useQuery } from '../../../utils/hooks/query';
import {
    filterStationTelemetryMeasures,
} from '../../../services/api';

export function useStationsTelemetryMeasuresState() {
    const {
        manageStations: {
            fetchByInstitution,
            value: stations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
        manageTelemetryVariables: {
            fetch: fetchTelemetryVariables,
            value: originalTelemetryVariables,
        },
    } = useApiData();

    const {
        stationId,
        lastMeasureDatetime,
    } = useQuery();

    const [measures, setMeasures] = useState(null);
    const [loadingMeasures, setLoadingMeasures] = useState(false);
    const [telemetryVariables, setTelemetryVariables] = useState(null);
    const [stationName, setStationName] = useState(null);

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    useEffect(() => {
        fetchTelemetryVariables();
    }, [fetchTelemetryVariables]);

    useEffect(() => {
        if (!originalTelemetryVariables) {
            return ;
        }
        setTelemetryVariables(originalTelemetryVariables
            .filter(({key}) => key !== 'variance'));
    }, [setTelemetryVariables, originalTelemetryVariables]);

    useEffect(() => {
        if (stations || !institutionId) {
            return;
        }
        fetchByInstitution(institutionId);
    }, [fetchByInstitution, institutionId, stations]);

    const initialStationId = useMemo(() => {
        if (stationId) {
            return stationId;
        }
        if (!stations?.length) {
            return null;
        }
        return stations[0].id;
    }, [stationId, stations]);

    const initialLastMeasureDatetime = useMemo(() => {
        if (lastMeasureDatetime) {
            return lastMeasureDatetime;
        }
        if (!stations?.length) {
            return null;
        }
        return stations[0]?.measures?.datetime;
    }, [lastMeasureDatetime, stations]);

    const fetchMeasures = useCallback(({
        stationId,
        datetimeStart,
        datetimeEnd,
        variables,
    }) => {
        if (loadingMeasures || !telemetryVariables || !datetimeStart || !datetimeEnd || !stations) {
            return;
        }
        (async function () {
            setLoadingMeasures(true);
            setMeasures(null);
            const newMeasures = await filterStationTelemetryMeasures({
                stationId,
                datetimeStart,
                datetimeEnd,
                variables,
            });
            const station = stations.find(({ id }) => id === Number(stationId));
            setStationName(station.name);
            setMeasures(newMeasures
                .sort((a, b) => b.datetime - a.datetime)
                .map(measure => {
                    measure.port = Number(measure?.port) ? 'Aberta' : 'Fechada';
                    return measure;
                })
            );
            setLoadingMeasures(false);
        })();
    }, [loadingMeasures, telemetryVariables, stations]);

    return {
        fetchMeasures,
        lastMeasureDatetime: initialLastMeasureDatetime,
        loadingMeasures,
        measures,
        stations,
        telemetryVariables,
        stationId: initialStationId,
        stationName,
    };
};
