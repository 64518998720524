import React from 'react';

import EntityBox from '../auth/entity-box';
import PostBtn from '../common/post-btn';

import { usePermissionBoxStates } from './states';

import './styles.css';

function PermissionBox({ permission }) {
    const {
        creating,
        register,
        save,
        shouldSave,
        title,
    } = usePermissionBoxStates(permission);

    return (
        <EntityBox
            creating={creating}
            title={title}
        >
            <div className="permission-box-form">
                <input
                    placeholder="Nome"
                    {...register('key')}
                />
                <input
                    placeholder="Descrição"
                    {...register('description')}
                />
                <PostBtn
                    shouldPost={shouldSave}
                    postFunction={save}
                />
            </div>
        </EntityBox>
    );
}

export default PermissionBox;
