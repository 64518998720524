import React, { useEffect, useState } from 'react';

import NewBtn from '../../common/new-btn';
import SensorBox from '../sensor-box';
import api from '../../../../services/api';

function SensorsList() {
    const [sensors, setSensors] = useState(null);
    
    useEffect(() => {
        (async function() {
            const response = await api.get('/sensors');
            setSensors(response.data);
        })();
    }, []);

    function addNewSensors() {
        const id = sensors?.length ? Math.max(...sensors.map(sensor => sensor.id)) + 1 : 1;
        setSensors([ ...sensors, { id } ]);
    }

    return (<>
        <div className="header">
            <h3>Sensores das estações</h3>
            <NewBtn
                label="Novo"
                newFunction={addNewSensors}
            />
        </div>
        {sensors && sensors.map(sensor => (
            <SensorBox
                key={sensor.id}
                sensor={sensor}
            />
        ))}
    </>);
}

export default SensorsList;
