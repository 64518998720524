import React from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';
import {RadiusInput} from '../radius-input'
import { useStaticPositionTypeState } from './states';

import './styles.css';

function StaticPositionTypeBox({ type }) {
    const {
        updatedType,
        creating,
        name,
        setName,
        alias,
        setAlias,
        radius,
        shouldSave,
        saveType,
        handleChangeRadius,
        addRadius,
        removeRadius
    } = useStaticPositionTypeState(type);
  
    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form type-box-form">
                <div className="form-group">
                    <label htmlFor={`type-name-${updatedType.id}`}>Nome</label>
                    <input
                        id={`type-name-${updatedType.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`type-alias-${updatedType.id}`}>Identificador</label>
                    <input
                        id={`type-alias-${updatedType.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                        maxLength={30}
                    />
                </div>

                <PostBtn
                    postFunction={saveType}
                    shouldPost={shouldSave}
                />
            </div>
            <div className="system-settings-container radius-container">
                {radius.length > 0 ? (
                    <RadiusInput
                        radiusValues={radius}
                        handleChangeRadius={handleChangeRadius}
                        addRadius={addRadius}
                        removeRadius={removeRadius}
                    />
                    ) : (
                        <RadiusInput
                            radiusValues={[]}
                            addRadius={addRadius}
                        />
                )}
            </div>
        </EntityBox>
    );
}

export default StaticPositionTypeBox;