import { useEffect } from 'react';

import { useApiData } from '../../../contexts/api-data';

export function useCustomersListPageState() {
    const {
        manageCustomers: {
            add: addNewCustomer,
            fetch: fetchCustomers,
            value: customers,
        },
        manageRoles: {
            value: roles,
            fetch: fetchRoles,
        },
    } = useApiData();

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    return {
        addNewCustomer,
        customers,
        roles,
    };
};
