import { useEffect } from 'react';

import { useApiData } from '../../../../contexts/api-data';

export function useRadarSelectionInputState() {
    const {
        radars : {
            fetchAndSave,
            value: radars  
        }
    } = useApiData();

    useEffect(() => {
        fetchAndSave();
    }, [fetchAndSave]);

    const radarsOptions = radars && radars.map(({ name, id }) => {
        return {
            label: name,
            value: id,
        }
    });

    return {
        radarsOptions,
    };
};
