import { useState } from 'react';

import { useApiData } from '../../../../../../contexts/api-data';

import datetimeUtils from '../../../../../../utils/datetime';

export function useSearchFormStates({ fetchMeasures, stations }) {
    const {
        manageWeatherVariables: {
            value: weatherVariables,
        },
        manageTelemetryVariables: {
            value: telemetryVariables,
        },
    } = useApiData();

    const now = Date.now();
    const defaultDatetimeStart = datetimeUtils.addNDays(now, -1);
    const maximumDatetimeValue = datetimeUtils.getInputFormatDatetimeFromDatetime(now);
    const [datetimeStartValue, setDatetimeStartValue] = useState(
        datetimeUtils.getInputFormatDatetimeFromDatetime(defaultDatetimeStart)
    );
    const [datetimeEndValue, setDatetimeEndValue] = useState(datetimeUtils.getInputFormatDatetimeFromDatetime(now));

    const [stationId, setStationId] = useState(null);
    const stationsOptions = stations
        ?.filter(({ inactive }) => !inactive)
        ?.map(({ id, name }) => ({
            value: id,
            label: name,
        }));

    async function searchMeasures(event) {
        event.preventDefault();
        if (!stationId || !weatherVariables.length || !telemetryVariables.length) {
            return ;
        }
        const datetimeStart = (new Date(datetimeStartValue)).getTime();
        const datetimeEnd = (new Date(datetimeEndValue)).getTime();
        fetchMeasures({ stationId, datetimeStart, datetimeEnd });
    }

    function updateDatetimeStart(newDatetimeStartValue) {
        setDatetimeStartValue(newDatetimeStartValue);
        if (newDatetimeStartValue < datetimeEndValue) {
            return;
        }
        const datetimeStart = (new Date(newDatetimeStartValue)).getTime();
        const newDatetimeEndValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeStart, 1));
        setDatetimeEndValue(newDatetimeEndValue);
    }

    function updateDatetimeEnd(newDatetimeEndValue) {
        setDatetimeEndValue(newDatetimeEndValue);
        if (datetimeStartValue < newDatetimeEndValue) {
            return;
        }
        const datetimeEnd = (new Date(newDatetimeEndValue)).getTime();
        const newDatetimeStartValue = datetimeUtils.getInputFormatDatetimeFromDatetime(datetimeUtils.addNDays(datetimeEnd, -1));
        setDatetimeStartValue(newDatetimeStartValue);
    }

    return {
        maximumDatetimeValue,
        datetimeStartValue,
        datetimeEndValue,
        updateDatetimeStart,
        updateDatetimeEnd,
        searchMeasures,
        setStationId,
        stationsOptions,
        stationId,
    };
};
