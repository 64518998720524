import React from 'react';

import Loading from '../../components/loading';
import SearchForm from './components/search-form';
import MeasuresChart from './components/measures-chart';
import MeasuresTable from './components/measures-table';

import { useStationsTelemetryMeasuresState } from './states';

import './styles.css';

function StationsTelemetryMeasures() {
    const {
        fetchMeasures,
        lastMeasureDatetime,
        loadingMeasures,
        measures,
        stations,
        telemetryVariables,
        stationId,
        stationName,
    } = useStationsTelemetryMeasuresState();

    return (
        <div className="stations-telemetry-page">
            <h3>Últimos dados telemétricos da estação</h3>
            <SearchForm
                fetchMeasures={fetchMeasures}
                lastMeasureDatetime={lastMeasureDatetime}
                measures={measures}
                stations={stations}
                telemetryVariables={telemetryVariables}
                initialStationId={stationId}
            />
            {loadingMeasures
                ? (<Loading size={32} color="#888" />)
                : (<>
                    <MeasuresChart
                        measures={measures}
                        telemetryVariables={telemetryVariables}
                    />
                    <MeasuresTable
                        measures={measures}
                        stationName={stationName}
                        telemetryVariables={telemetryVariables}
                    />
                </>)}
        </div>
    );
}

export default StationsTelemetryMeasures;
