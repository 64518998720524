import React from 'react';

import { MultipleSelectionInput } from '../../../common/custom-inputs';

import {
    useMockApiSettingState,
} from './states';

function MockApiSetting() {
    const {
        mockApiOptions,
        setting,
        updateSetting,
    } = useMockApiSettingState();

    return (<>
        <span>Mock da API</span>
        {setting && <MultipleSelectionInput
            defaultValue={setting}
            setValue={updateSetting}
            options={mockApiOptions}
        />}
    </>);
}

export default MockApiSetting;
