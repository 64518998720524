import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { useMapDrawerInputter } from '../../contexts/map-drawer-inputter';
import L from 'leaflet';

function MapEffect({ mapRef }) {
    const {
        addDraw,
        selectDraw,
        updateDraw,
        draws,
        filteredModelsOptions,
        selectedDrawIds,
        selectedDraw,
        allowChangeModel,
        selectedModel,
        initialDrawsRef
    } = useMapDrawerInputter();

    const map = useMap();

    const editableLayersRef = useRef(new L.FeatureGroup());

    const addDrawRef = useRef(addDraw);
    const selectDrawRef = useRef(selectDraw);
    const updateDrawRef = useRef(updateDraw);

    const drawControlRef = useRef(null);
    const selectedDrawRef = useRef(null);
    const drawsRef = useRef(null);
    const allowChangeModelRef = useRef();
    const selectedModelRef = useRef();

    useEffect(() => {
        allowChangeModelRef.current = allowChangeModel;
    }, [allowChangeModel]);

    useEffect(() => {
        selectedDrawRef.current = selectedDraw;
    }, [selectedDraw]);

    useEffect(() => {
        drawsRef.current = draws;
    }, [draws]);

    useEffect(() => {
        selectedModelRef.current = selectedModel
    }, [selectedModel])

    // NOTE: Main map creator
    useEffect(() => {
        if (!map || mapRef.current) return;
        const editableLayers = editableLayersRef.current;

        map.addLayer(editableLayers);

        L.drawLocal.draw.handlers.rectangle.tooltip.start = 'Clique e arraste para desenhar um retângulo';

        L.drawLocal.draw.toolbar.actions.text = 'Cancelar';
        L.drawLocal.draw.toolbar.actions.title = 'Cancelar criação de retângulo';
        L.drawLocal.draw.toolbar.buttons.rectangle = 'Desenhar um retângulo personalizado';

        L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Clique em cancelar para desfazer alterações';
        L.drawLocal.edit.handlers.edit.tooltip.text = 'Arraste as arestas para redimensionar, ou mova o ponto central';

        L.drawLocal.edit.toolbar.actions.cancel.text = 'Cancelar';
        L.drawLocal.edit.toolbar.actions.cancel.title = 'Cancelar as alterações';
        L.drawLocal.edit.toolbar.actions.save.text = 'Confirmar';
        L.drawLocal.edit.toolbar.actions.save.title = 'Confirmar alterações';
        L.drawLocal.edit.toolbar.buttons.edit = 'Editar camadas';

        map.on(L.Draw.Event.CREATED, (event) => {
            const { layer } = event;
            editableLayers.addLayer(layer);

            layer.on('click', () => {
                selectDrawRef.current(layer?._leaflet_id);
            });

            const drawModel = {
                model_id: selectedModelRef.current.id,
                model_name: selectedModelRef.current.name,
            };
            if (layer instanceof L.Polygon) {
                addDrawRef.current(layer.getBounds(), 'Rectangle', layer, drawModel);
            }
        });

        map.on(L.Draw.Event.EDITED, (event) => {
            const { layers } = event;
            layers.eachLayer((layer) => {
                const drawId = layer?._leaflet_id;
                const newData = [];

                if (layer instanceof L.Rectangle) {
                    const bounds = layer.getBounds();
                    newData.type = 'Rectangle';
                    newData.data = bounds;
                }

                updateDrawRef.current(newData, drawId);
            });
        });

        return () => {
            map.off(L.Draw.Event.CREATED);
            map.off(L.Draw.Event.EDITED);
            map.removeControl(drawControlRef.current);
            editableLayers.clearLayers();
        };
    }, [map, mapRef]);

    // NOTE: Enable edit only selected layers
    useEffect(() => {
        function hideOtherLayers(selectedLayerId) {
            if(!selectedLayerId) return;
            editableLayersRef.current.eachLayer(layer => {
                if (layer?._leaflet_id !== selectedLayerId) {
                    editableLayersRef.current.removeLayer(layer?._leaflet_id);
                }
            });
        }

        function showAllLayers() {
            drawsRef.current.forEach(draw => {
                editableLayersRef.current.addLayer(draw?.layer);
            })
        }

        map.on(L.Draw.Event.EDITSTART, () => {
            hideOtherLayers(selectedDrawRef.current?.layer?._leaflet_id);
        });

        map.on(L.Draw.Event.EDITED, () => {
            showAllLayers();
        });

        map.on(L.Draw.Event.EDITSTOP, () => {
            showAllLayers();
        });

        return () => {
            map.off(L.Draw.Event.EDITSTART);
            map.off(L.Draw.Event.EDITSTOP);
        }
    }, [draws, map]);

    // NOTE: Draw controll buttons
    useEffect(() => {
        if (!map) return;

        const editableLayers = editableLayersRef.current;

        const removeCreatedLayer = (event) => {
            const { layer } = event;
            editableLayers.removeLayer(layer);
        };

        if (filteredModelsOptions?.length > 0) {
            map.off(L.Draw.Event.CREATED, removeCreatedLayer);
        } else {
            map.on(L.Draw.Event.CREATED, removeCreatedLayer);
        }

        if (drawControlRef.current) {
            map.removeControl(drawControlRef.current);
        }
        const rectangleOption = filteredModelsOptions.length > 0 && allowChangeModel;
        const editOption = selectedDraw?.layer?._leaflet_id ? true : false;

        drawControlRef.current = new L.Control.Draw({
            position: 'topright',
            draw: {
                polyline: false,
                polygon: false,
                circle: false,
                marker: false,
                rectangle: rectangleOption,
                circlemarker: false,
            },
            edit: {
                featureGroup: editableLayers,
                remove: false,
                edit: editOption
            },
        });

        map.addControl(drawControlRef.current);

        return () => {
            map.off(L.Draw.Event.CREATED, removeCreatedLayer);
            if (drawControlRef.current) {
                map.removeControl(drawControlRef.current);
            }
        };
    }, [map, filteredModelsOptions, selectedDraw, allowChangeModel]);

    // NOTE: Initial Draws
    useEffect(() => {
        if (!map) return;
        if(initialDrawsRef.current) {
            const editableLayers = editableLayersRef.current;
            editableLayers.clearLayers();
            initialDrawsRef.current.forEach((draw) => {
                const bounds = [
                    [draw.data[0]._southWest.lat, draw.data[0]._southWest.lng],
                    [draw.data[0]._northEast.lat, draw.data[0]._northEast.lng],
                ];
                const drawModel = {
                    model_id: draw.model_id,
                    model_name: draw.model_name,
                };

                if(!isNaN(bounds[0]) && !isNaN(bounds[1])) {
                    const rectangle = L.rectangle(bounds, { weight: 1 });
                    editableLayers.addLayer(rectangle);
                    draw.layer = rectangle;
    
                    rectangle.addTo(map);
                    addDrawRef.current(
                        draw.layer.getBounds(),
                        'Rectangle',
                        draw.layer,
                        drawModel
                    );
    
                    rectangle.on('click', () => {
                        selectDrawRef.current(rectangle?._leaflet_id);
                    });
                }
            });
        }
    }, [initialDrawsRef, map]);

    // NOTE: draws get edited by inputs
    useEffect(() => {
        draws.forEach((draw) => {
            const layer = map?._layers[draw.layer?._leaflet_id];
            if(
                draw.type === 'rectangle' &&
                draw.data &&
                Array.isArray(draw.data) &&
                draw.data[0]?._northEast &&
                !isNaN(draw.data[0]?._northEast.lat) &&
                draw.data[0]?._southWest
            ) {
                if (layer) {
                    const bounds = [
                        [draw.data[0]._southWest.lat, draw.data[0]._southWest.lng], // Canto inferior esquerdo
                        [draw.data[0]._southWest.lat, draw.data[0]._northEast.lng], // Canto inferior direito
                        [draw.data[0]._northEast.lat, draw.data[0]._northEast.lng], // Canto superior direito
                        [draw.data[0]._northEast.lat, draw.data[0]._southWest.lng], // Canto superior esquerdo
                        [draw.data[0]._southWest.lat, draw.data[0]._southWest.lng], // Fechando o polígono, voltando ao canto inferior esquerdo
                    ];

                    layer.setLatLngs(bounds);
                    layer.redraw();
                } else {
                    const bounds = [
                        [draw.data[0]?._southWest.lat, draw.data[0]?._southWest.lng],
                        [draw.data[0]?._northEast.lat, draw.data[0]?._northEast.lng],
                    ];
                    if(bounds[0] && bounds[1]) {
                        const newRectangle = L.rectangle(bounds, { weight: 1 });

                        editableLayersRef.current.addLayer(newRectangle);
                        draw.layer = newRectangle;
                    }
                }
            }
        });

        const currentLayerIds = [];
        editableLayersRef.current.eachLayer(layer => {
            if (layer?._leaflet_id) {
                currentLayerIds.push(layer?._leaflet_id);
            }
        });

        const drawIds = draws.map(draw => draw.layer?._leaflet_id);
        const layersToRemove = currentLayerIds.filter(id => !drawIds.includes(id));
        layersToRemove.forEach(layerId => {
            const layer = editableLayersRef.current.getLayer(layerId);
            if (layer) {
                editableLayersRef.current.removeLayer(layer);
            }
        });

        editableLayersRef.current.eachLayer(layer => {
            layer.redraw();
            layer.on('click', () => {
                selectDrawRef.current(layer?._leaflet_id);
            });
        });
    }, [draws, map]);

    useEffect(() => {
        editableLayersRef.current.eachLayer(layer => {
            if (selectedDrawIds.includes(layer?._leaflet_id)) {
                layer.setStyle({
                    color: '#f0ad4e',
                    fillColor: '#f0ad4e',
                    fillOpacity: 0.5,
                    weight: 5,
                });
            } else {
                layer.setStyle({
                    color: '#1e90ff',
                    fillColor: '#1e90ff',
                    fillOpacity: 0.2,
                    weight: 1,
                });
            }
        });
    }, [selectedDrawIds]);

    return null;
}

export default MapEffect;
