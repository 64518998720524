import React, { useState, useEffect, useRef, useMemo } from 'react';

import PostBtn from '../../common/post-btn';
import { SelectionInput } from '../../../common/custom-inputs';

import datetimeUtils from '../../../../utils/datetime';
import { MultipleCustomersAndRegionsSelectionInput } from '../../../common/custom-inputs';
import { useApiData } from '../../../../contexts/api-data';

import './styles.css';

function CalendarMessageForm({
    message,
    postFunction,
    runDatetime,
    datetime,
    selected,
    toggleSelectedMessage,
    forecastRegions,
}) {
    const {
        manageAlertTemplates: {
            value: alertTemplates,
            fetch: fetchAlertTemplates,
        }
    } = useApiData();

    useEffect(() => {
        fetchAlertTemplates();
    }, [fetchAlertTemplates]);

    const lastRunDatetime = message?.run_datetime || runDatetime + 10800000;
    const [regions, setRegions] = useState(message?.forecast_region_id || []);
    const [text, setText] = useState(message?.text || '');
    const [template_id, setTemplateId] = useState(message?.template_id);
    const template = useMemo(() => {
        if (!template_id || !alertTemplates) {
            return null;
        }
        const originalTemplate = alertTemplates.find(({ id }) => Number(id) === Number(template_id));
        const texts = [];
        const inputs = [];
        const parts = originalTemplate?.format?.split('%INPUT')?.reduce((result, current, index) => {
            const percentIndex = current.indexOf('%');
            if (index !== 0) {
                const inputObject = {};
                if (percentIndex > 0) {
                    inputObject.size = Number(current.substring(1, percentIndex));
                }
                result.push(inputObject);
                inputs.push({
                    ...inputObject,
                    replaceText: `%INPUT${current.substring(0, percentIndex+1)}`,
                });
            }
            const text = current.substring(percentIndex !== -1 ? percentIndex+1 : 0);
            result.push(text);
            texts.push(text);
            return result;
        }, []);
        return {
            ...originalTemplate,
            parts,
            texts,
            inputs,
        }
    }, [template_id, alertTemplates]);
    const [template_args, setTemplateArgs] = useState(message?.template_args || []);
    const [inputFormatDatetime, setInputFormatDatetime] = useState('');
    const [inputFormatRunDatetime, setInputFormatRunDatetime] = useState('');
    const [sendWhatsappMessage, setSendWhatsappMessage] = useState(message);
    const [isResetRegions, setIsResetRegions] = useState(false);

    const textInput = useRef();

    useEffect(() => {
        if (!inputFormatRunDatetime) {
            const formatRunDatetime = datetimeUtils.getInputFormatDateFromDatetime(lastRunDatetime, true);
            setInputFormatRunDatetime(formatRunDatetime)
        }
    }, [inputFormatRunDatetime, lastRunDatetime]);

    function updateTemplateArgs(template) {
        if (!template) {
            setTemplateArgs([]);
            return;
        }
        const inputAmount = template.inputs.length;
        if (!inputAmount) {
            setTemplateArgs([]);
            return;
        }
        const args = [...(new Array(inputAmount)).keys()].map(() => '');
        setTemplateArgs(args);
    }

    useEffect(() => {
        updateTemplateArgs(template);
    }, [template]);

    useEffect(() => {
        if (datetime) {
            const formatDatetime = datetimeUtils.getInputFormatDateFromDatetime(datetime, true);
            setInputFormatDatetime(formatDatetime);
        }
    }, [datetime]);

    useEffect(() => {
        if (!textInput.current) {
            return;
        }
        textInput.current.style.height = '10px';
        textInput.current.style.height = `${textInput.current.scrollHeight}px`;
    }, [text]);

    function shouldPostMessage() {
        // updating
        if (message) {
            const textChanged = text.length > 0 && text !== message.text;
            return textChanged;
        }
        // creating
        return regions.length > 0 &&
            (text.length > 0
            || (template_id && template_args.every(Boolean)));
    }

    function resetOnCreating() {
        if (!message) {
            setText('');
            setRegions([]);
            setIsResetRegions(true);
            updateTemplateArgs(template);
        }
    }

    async function postMessage() {
        if (!shouldPostMessage()) {
            return;
        }

        const datetimeToSend = message?.datetime || datetime;
        await postFunction(
            datetimeToSend,
            regions,
            text,
            message?.id,
            template,
            template_args,
            sendWhatsappMessage,
        );

        resetOnCreating();
    }

    const inputIdSufix = `${message?.id || 'new'}-${message?.run_datetime || 'item'}`;
    const textInputId = `text-${inputIdSufix}`;
    const regionsInputId = `regions-${inputIdSufix}`;
    const sendWhatsappMessageInputId = `send-whatsapp-message-${
        message?.id || ''
    }`;
    const templateInputId = `template-${inputIdSufix}`;

    function selectRegions(regionsByCustomer) {
        const newRegions = regionsByCustomer?.[1];
        setRegions(newRegions || []);
    }

    const selectedRegion = message
        ? forecastRegions.find(({ id }) => id === Number(message.forecast_region_id))
        : null;

    return (
        <div className="calendar-message-form">
            {message && (
                <div className="toggle-selected">
                    <label>
                        <input
                            type="checkbox"
                            checked={selected || false}
                            onChange={() => toggleSelectedMessage(message.id)}
                        />
                        Selecionar
                    </label>
                </div>
            )}

            {!message && (
                <div className="form-group">
                    <label htmlFor="datetime">Data de Previsão</label>
                    <input
                        id="datetime"
                        type="date"
                        value={inputFormatDatetime}
                        disabled={true}
                    />
                </div>
            )}

            <div className="form-group region-selection">
                <label htmlFor={regionsInputId}>
                    Região
                </label>
                {message
                    ? (
                        <select
                            id={regionsInputId}
                            value={Number(message.forecast_region_id || 0)}
                            disabled
                        >
                            <option
                                value={selectedRegion?.id}
                            >
                                {selectedRegion?.name}
                            </option>
                        </select>
                    ) : (
                        <MultipleCustomersAndRegionsSelectionInput
                            selectRegion={selectRegions}
                            inputId={regionsInputId}
                            isReset={isResetRegions}
                            setIsReset={setIsResetRegions}
                        />
                    )}
            </div>

            <div className="sendCalendar">
                {!message && (
                    <div className="toggle-selected">
                        <label htmlFor={sendWhatsappMessageInputId}>
                            <input
                                type="checkbox"
                                id={sendWhatsappMessageInputId}
                                defaultChecked={sendWhatsappMessage}
                                onChange={(event) =>
                                    setSendWhatsappMessage(
                                        event.target.checked
                                    )
                                }
                            />
                            Enviar por WhatsApp
                        </label>
                    </div>
                )}
            </div>

            {!message && (
                <div className="form-group">
                    <label htmlFor={templateInputId}>Template</label>
                    {alertTemplates && (
                        <SelectionInput
                            nullLabel="Sem template"
                            defaultValue={template_id}
                            isNullable={true}
                            inputId={templateInputId}
                            options={alertTemplates.map(({ id, name }) => ({ label: name, value: id }))}
                            setValue={setTemplateId}
                        />
                    )}
                </div>
            )}

            <div className="form-group">
                <label htmlFor={textInputId}>Texto da mensagem </label>
                {(!template || message)
                    ? (
                        <textarea
                            className="textarea"
                            rows={2}
                            id={textInputId}
                            value={text}
                            onChange={event => setText(event.target.value)}
                            ref={textInput}
                        ></textarea>
                    )
                    : (
                        <div className="template-format">
                            {template.parts.map((part, index) => {
                                if (typeof part === 'object') {
                                    const realIndex = Math.floor(index/2);
                                    return (
                                        <input
                                            id={realIndex === 0 ? textInputId : `part-input-${textInputId}-${realIndex}`}
                                            key={`part-input-${textInputId}-${realIndex}`}
                                            value={template_args[realIndex] || ''}
                                            onChange={event => {
                                                const newArgs = [...template_args];
                                                newArgs[realIndex] = event.target.value;
                                                setTemplateArgs(newArgs);
                                            }}
                                            {...part}
                                        />
                                    );
                                }
                                return (
                                    <span key={`part-${textInputId}-${index}`}>{part}</span>
                                );
                            })}
                        </div>
                    )
                }
            </div>
            <div className="form-group">
                <PostBtn
                    shouldPost={shouldPostMessage}
                    postFunction={postMessage}
                    label={message ? 'Salvar' : 'Criar'}
                />
                <label>Data de Postagem: {inputFormatRunDatetime} </label>
            </div>
        </div>
    );
}

export default CalendarMessageForm;
