import React, { useState, useRef, useEffect, useMemo} from 'react';

import PostBtn from '../../common/post-btn';
import MultipleRealTimeMessageCodeSelectionInput from '../../../common/custom-inputs/multiple-real-time-code-selection-input';

import './styles.css';
import { useApiData } from '../../../../contexts/api-data';
import { SelectionInput } from '../../../common/custom-inputs';

function RealTimeMessageForm({
    message,
    postFunction,
    codes,
    toggleSelectedMessage,
}) {
    const [text, setText] = useState(message?.text || '');
    const code = message?.code || '';
    const [selectedCodes, setSelectedCodes] = useState([]);
    const defaultValidPeriod = '00:30';
    const initialValidPeriod = message
        ? validPeriodFromMessage()
        : defaultValidPeriod;
    const [validPeriod, setValidPeriod] = useState(initialValidPeriod);
    const [sendWhatsappMessage, setSendWhatsappMessage] = useState(!message);
    const [isResetRealTimeMessageCodes, setIsResetRealTimeMessageCodes] = useState(false);
    const [template_args, setTemplateArgs] = useState(message?.template_args || []);
    const [template_id, setTemplateId] = useState(message?.template_id);

    
    const inputIdSufix = `${message?.id || 'new'}-${message?.run_datetime || 'item'}`;
    const templateInputId = `template-${inputIdSufix}`;
    

    const {
        manageRealTimeAlertTemplates: {
            value: alertTemplates,
            fetch: fetchAlertTemplates,
        }
    } = useApiData();

    const template = useMemo(() => {
        if (!template_id || !alertTemplates) {
            return null;
        }
        const originalTemplate = alertTemplates.find(({ id }) => Number(id) === Number(template_id));
        const texts = [];
        const inputs = [];
        const parts = originalTemplate?.format
            ?.split(/(%INPUT(?:\|\d+)?%|\n)/) // Adjusted regex to capture %INPUT|size% and %INPUT%
            ?.reduce((result, current, index) => {
                if (current.startsWith('%INPUT')) {
                    const inputObject = {};
                    const sizeMatch = current.match(/%INPUT(?:\|(\d+))?%/);
                    if (sizeMatch && sizeMatch[1]) {
                        inputObject.size = Number(sizeMatch[1]);
                    }
                    result.push(inputObject);
                    inputs.push({
                        ...inputObject,
                        replaceText: current,
                    });
                } else if (current === '\n') {
                    result.push('\n');
                } else {
                    result.push(current);
                    texts.push(current);
                }
                return result;
            }, []);
    
        return {
            ...originalTemplate,
            parts,
            texts,
            inputs,
        };
    }, [template_id, alertTemplates]);
    
    function updateTemplateArgs(template) {
        if (!template) {
            setTemplateArgs([]);
            return;
        }
        const inputAmount = template.inputs.length;
        if (!inputAmount) {
            setTemplateArgs([]);
            return;
        }
        const args = [...(new Array(inputAmount)).keys()].map(() => '');
        setTemplateArgs(args);
    }
 
    useEffect(() => {
        updateTemplateArgs(template);
    }, [template]);

    useEffect(() => {
        fetchAlertTemplates();
    }, [fetchAlertTemplates]);

    function filteredArgs() {
        return template_args.filter(value => value !== null && value !== "" && value !== undefined)
    }
    function shouldPostMessage() {
        // updating
        if (message) {
            const textChanged = text?.length > 0 && text !== message?.text;
            const codeChanged = code?.length > 0 && code !== message?.code;
            const validPeriodChanged = validPeriod !== validPeriodFromMessage();
            return textChanged || codeChanged || validPeriodChanged;
        }
        // creating
        return selectedCodes.length > 0 && validPeriod.length > 0 &&
        (text.length > 0 || (template_id && filteredArgs().every(Boolean)));
    }

    function resetOnCreating() {
        if (!message) {
            setText('');
            setSelectedCodes([]);
            setValidPeriod(defaultValidPeriod);
            setIsResetRealTimeMessageCodes(!isResetRealTimeMessageCodes);
            setTemplateArgs([]);
        }
    }

    function validPeriodFromMessage() {
        const msDifference = message.expiry_datetime - message.datetime;
        const oneMinute = 60 * 1000;
        const oneHour = 60 * oneMinute;
        const hour = Math.min(23, Math.floor(msDifference/oneHour));
        const minute = Math.floor((msDifference%oneHour) / oneMinute);
        return [hour, minute].map(x => `${x}`.padStart(2, '0')).join(':');
    }

    function msFromValidPeriod(datetime=null) {
        const [hour, minute] = validPeriod.split(':').map(Number);
        const validPeriodInMs = (hour * 60 + minute) * 60 * 1000;
        const initialDatetime = datetime || Date.now();
        return initialDatetime + validPeriodInMs;
    }

    async function postMessage() {
        if (!shouldPostMessage()) {
            return;
        }

        const expiryDatetime = msFromValidPeriod(message?.datetime);
        const args = filteredArgs();
        try {
            await postFunction(
                text,
                message ? code : selectedCodes,
                expiryDatetime,
                message?.id,
                sendWhatsappMessage,
                template,
                args
            ); 
        } catch (error) {
            console.error(error);
        }finally{
            resetOnCreating();
        }
  
    }

    function getMessageTime() {
        if (!message) return '';

        const datetimeObject = new Date(Number(message.datetime));
        const hour = datetimeObject.getHours();
        const minute = datetimeObject.getMinutes();
        const time = [hour, minute].map(x => `${x}`.padStart(2, '0')).join(':');
        return `(criada às ${time})`;
    }

    function selectCodes(codesByCustomer) {
        const newCodes = codesByCustomer?.[1];
        setSelectedCodes(newCodes || []);
    }

    const textInputId = `text-${message?.id || ''}`;
    const codeInputId = `code-${message?.id || ''}`;
    const validPeriodInputId = `valid-period-${message?.id || ''}`;
    const sendWhatsappMessageInputId = `send-whatsapp-message-${message?.id || ''}`;
    const textInput = useRef();

    useEffect(() => {
        textInput.current.style.height = '10px';
        textInput.current.style.height = `${textInput.current.scrollHeight}px`;
    }, [text]);

    return (<>
        <div>
            {message && (
                <div className="toggle-selected">
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => toggleSelectedMessage(message.id)}
                        />
                        Selecionar
                    </label>
                </div>
            )}
            <div className="real-time-message-form">
            {!message && (
                <div className="form-group template-input">
                    <label htmlFor={templateInputId}>Template</label>
                    {alertTemplates && (
                        <SelectionInput
                            nullLabel="Sem template"
                            defaultValue={template_id}
                            isNullable={true}
                            inputId={templateInputId}
                            options={alertTemplates.map(({ id, name }) => ({ label: name, value: id }))}
                            setValue={setTemplateId}
                        />
                    )}
                </div>
            )}
            <div className={!template_id ? "form-group" : "template-active"}>
                <label htmlFor={textInputId}>Texto da mensagem {getMessageTime()} </label>
                {(!template || message)
                    ? (
                        <textarea
                            className="textarea"
                            rows={2}
                            id={textInputId}
                            value={text}
                            onChange={event => setText(event.target.value)}
                            ref={textInput}
                        ></textarea>
                    )
                    : (
                        <div className="template-format">
                            {template.parts.map((part, index) => {
                                
                                if (typeof part === 'object') {
                                    const realIndex = Math.floor(index/2);
                                    return (
                                        <input
                                            id={realIndex === 0 ? textInputId : `part-input-${textInputId}-${realIndex}`}
                                            key={`part-input-${textInputId}-${realIndex}`}
                                            value={template_args[realIndex] || ''}
                                            onChange={event => {
                                                const newArgs = [...template_args];
                                                newArgs[realIndex] = event.target.value;
                                                setTemplateArgs(newArgs);
                                            }}
                                            {...part}
                                        />
                                    );
                                }
                                if (part === '\n') {
                                    return <div style={{ width: '100%' }} key={`br-${index}`} />;
                                }
                                return (
                                    <span key={`part-${textInputId}-${index}`}>{part}</span>
                                );
                            })}
                        </div>
                    )
                }
            </div>

                <div className="form-group">
                    <label htmlFor={codeInputId}>Código</label>
                    {message ? (
                        <select
                            id={codeInputId}
                            value={code}
                            disabled
                        >
                        <option
                            value=""
                            disabled={true}
                        >
                            Selecione
                        </option>
                            {codes.map((code) => (
                                <option
                                    key={code}
                                    value={code}
                                >
                                    {code}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <div className="select-input">
                            <MultipleRealTimeMessageCodeSelectionInput
                                inputId={codeInputId}
                                selectRealTimeCodes={selectCodes}
                                isReset={isResetRealTimeMessageCodes}
                                multiple={true}
                                setIsReset={setIsResetRealTimeMessageCodes}
                            />
                        </div>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor={validPeriodInputId}>Expira em (hh:mm)</label>
                    <input
                        id={validPeriodInputId}
                        type="time"
                        value={validPeriod}
                        onChange={event => setValidPeriod(event.target.value)}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={sendWhatsappMessageInputId}>
                        {message ? 'Retificar' : 'Enviar'} por Whatsapp
                    </label>
                    <input
                        type="checkbox"
                        id={sendWhatsappMessageInputId}
                        defaultChecked={sendWhatsappMessage}
                        onChange={event => setSendWhatsappMessage(event.target.checked)}
                    />
                </div>

                <PostBtn
                    shouldPost={shouldPostMessage}
                    postFunction={postMessage}
                    label={message ? 'Salvar' : 'Criar'}
                />
            </div>
        </div>
    </>);
}

export default RealTimeMessageForm;
