import React from 'react';
import { LayerGroup, Rectangle } from 'react-leaflet';

import { useAuth } from '../../contexts/auth';

function LightningsBoundaries() {
    const { settingsSet } = useAuth();

    const { LIGHTNINGS_VIEW_AREAS } = { ...settingsSet };

    return LIGHTNINGS_VIEW_AREAS ? (
        <LayerGroup>
            {LIGHTNINGS_VIEW_AREAS.map((boundary, i) => {
                if (!boundary) {
                    return null;
                }
                const [
                    initial_latitude,
                    initial_longitude,
                    final_latitude,
                    final_longitude,
                ] = boundary.split(',').map(Number);

                return (
                    <Rectangle
                        key={i}
                        bounds={[
                            [initial_latitude, initial_longitude],
                            [final_latitude, final_longitude],
                        ]}
                        pathOptions={{
                            color: 'black',
                            weight: 0.5,
                            fill: false,
                            dashArray: '2',
                        }}
                    />
                );
            })}
        </LayerGroup>
    ) : null;
}

export default LightningsBoundaries;
