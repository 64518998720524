import React from 'react';

import { useApiData } from '../../contexts/api-data';

import './styles.css';

function BasinsStages({ vertical=false }) {
    const {
        manageBasins: {
            useFetchAndSocket: useBasinsFetchAndSocket,
            value: allBasins,
        },
        manageBasinPrecipitationStages: {
            useFetchAndSocket: usePrecipitationStagesFetchAndSocket,
            value: precipitationStages,
        },
        manageBasinSlipStages: {
            useFetchAndSocket: useSlipStagesFetchAndSocket,
            value: slipStages,
        },
    } = useApiData();

    const basins = allBasins?.filter(({ inactive }) => !inactive);

    useBasinsFetchAndSocket();

    usePrecipitationStagesFetchAndSocket();

    useSlipStagesFetchAndSocket();

    return (
        <div
            className={`basins-stages ${vertical ? 'vertical' : ''}`}
        >
            {basins?.map(({ id, name, precipitation_stage_id, slip_stage_id }) => {
                const precipitationStage = precipitationStages?.find(({ id }) => id === precipitation_stage_id);
                const slipStage = slipStages?.find(({ id }) => id === slip_stage_id);
                return (
                    <div
                        key={`basin-${id}`}
                        className="basin-info"
                    >
                        <span className="basin-name">{name}</span>
                        <div className="basin-stages-info">
                            <span className="precipitation-stage">
                                <span>Condições de Chuva:</span>
                                <span style={{
                                    color: precipitationStage?.color,
                                }}>
                                    {precipitationStage?.description}
                                </span>
                            </span>
                            <span className="slip-stage">
                                <span>Probabilidade de Escorregamento:</span>
                                <span style={{
                                    color: slipStage?.color,
                                }}>
                                    {slipStage?.description}
                                </span>
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default BasinsStages;
