import baseApi from '../../base';

export async function getSatelliteInstitutions() {
    const result = await baseApi.get('/manage/satellite-institutions');
    return result.data;
};

export async function postSatelliteInstitutions(institution) {
    const result = await baseApi.post('/manage/satellite-institutions', institution);
    return result.data;
};

export async function putSatelliteInstitutions(updatedInstitution, institution) {
    const result = await baseApi.put(`/manage/satellite-institutions/${updatedInstitution.id}`, institution);
    return result.data;
};