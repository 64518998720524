import React from 'react';

import './styles.css';

function DashboardBox({ title, children, className }) {
    return (
        <div className={`dashboard-box ${className || ''}`}>
            {title && (
                <div className="dashboard-box-title">
                    {title}
                </div>
            )}
            <div className="dashboard-box-content">
                {children}
            </div>
        </div>
    );
}

export default DashboardBox;
