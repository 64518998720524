import React from 'react';
import { LayerGroup, Marker, Popup as MapPopup } from 'react-leaflet';

import { useStationsMarkersStates } from './states';

import './styles.css';

function StationsMarkers() {
    const {
        stations,
    } = useStationsMarkersStates();

    return stations && (
        <LayerGroup>
            {stations.map(({
                stationName,
                riverName,
                code,
                position,
                measures,
                icon,
                date,
                time,
            }) => (
                <Marker
                    key={code}
                    icon={icon}
                    position={position}
                >
                    <MapPopup>
                        <b>Código:</b> {code} <br />
                        <b>Nome:</b> {stationName} &nbsp; {riverName && `(Rio ${riverName})`}
                        {measures && (<>
                            <br />
                            <b>Horário:</b> {time} - {date}
                            <div className="measures-values">
                                {measures.map(({ key, label, value }) => (
                                    <span key={key}>
                                        <b>{label}:</b> {value}
                                    </span>
                                ))}
                            </div>
                        </>)}
                    </MapPopup>
                </Marker>
            ))}
        </LayerGroup>
    );
}

export default StationsMarkers;
