import baseApi from '../../base';

export async function filterRadarTelemetryMeasures({
    radar_id,
    radio_id,
    datetimeStart,
    datetimeEnd,
    variables,
}) {
    const queryObject = {
        radio_id,
        datetimeStart,
        datetimeEnd,
        variables,
    };
    const query = Object.keys(queryObject).map(key => {
        return `${key}=${queryObject[key]}`;
    }).join('&');
    const url = `/radars/${radar_id}/telemetry-measures?${query}`;
    const result = await baseApi.get(url);
    return result.data.data;
};
