import React, { useState } from 'react';
import {
    FaChevronDown,
    FaChevronUp,
} from 'react-icons/fa';
import './styles.css';

function EntityBox({
    creating,
    title,
    setTitle,
    editable,
    onOpen,
    onClose,
    children,
    alwaysOpen,
    titleMaxLength,
    titleIcon,
}) {
    const [open, setOpen] = useState(creating || alwaysOpen);
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };
    
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    function openFunction() {
        if (creating || alwaysOpen) {
            return;
        }
        const functionToExecute = open ? onClose : onOpen;
        functionToExecute && functionToExecute();
        setOpen(!open);
    }

    return (
        <div className="entity-box">
            <div
                className={`entity-box-header ${alwaysOpen ? 'not-clickable' : ''}`}
                onClick={openFunction}
            >
                <div className="entity-box-title">
                    {titleIcon && (
                        <titleIcon.icon
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            className={titleIcon.className}
                        />
                    )}
                    {isHovering && (
                      <div className='popup-info'> {titleIcon.popup} </div>
                    )}
        
                    {(editable && open) || (setTitle && creating)
                        ? (
                            <input
                                value={title}
                                placeholder="Nome"
                                onChange={(event) => setTitle(event.target.value)}
                                onClick={(event) => event.stopPropagation()}
                                maxLength={titleMaxLength}
                            />
                        )
                        : title
                    }
                </div>
                {!alwaysOpen && (open ? <FaChevronUp /> : <FaChevronDown />)}
            </div>
            {open && (
                <div className="entity-box-content">
                    {children}
                </div>
            )}
        </div>
    );
}

export default EntityBox;
