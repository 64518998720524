import React, { useState } from 'react';

import EntityBox from '../../auth/entity-box';
import PostBtn from '../../common/post-btn';

import api from '../../../../services/api';

import './styles.css';

function PhoneOperatorBox({ phoneOperator }) {
    const [updatedPhoneOperator, setUpdatedPhoneOperator] = useState(phoneOperator);
    
    const creating = !updatedPhoneOperator.name;
    const [alias, setAlias] = useState(updatedPhoneOperator.alias || '');
    const [name, setName] = useState(updatedPhoneOperator.name || '');
    const [inactive, setInactive] = useState(updatedPhoneOperator.inactive || false);

    function shouldSave() {
        const nameAndAliasAreSet = name?.length > 0 && alias?.length > 0;
        if (creating) {
            return nameAndAliasAreSet;
        }
        const nameChanged = name !== updatedPhoneOperator.name;
        const aliasChanged = alias !== updatedPhoneOperator.alias;
        const inactiveChanged = Boolean(inactive) !== Boolean(updatedPhoneOperator.inactive);   
        return nameAndAliasAreSet && (
            nameChanged
            || aliasChanged
            || inactiveChanged);
    }

    async function saveConfiguration() {
        if (shouldSave()) {
            const phoneOperatorToSend = {
                alias,
                name,
                inactive,
            };
            if (creating) {
                const response = await api.post('/manage/phone-operators', phoneOperatorToSend);
                phoneOperatorToSend.id = response.data.id;
            }
            else {
                await api.put(`/manage/phone-operators/${updatedPhoneOperator.id}`, phoneOperatorToSend);
                phoneOperatorToSend.id = updatedPhoneOperator.id;
            }
            setUpdatedPhoneOperator(phoneOperatorToSend);
        }
    }    

    return (
        <EntityBox
            creating={creating}
            title={name || '*Nome'}
        >
            <div className="entity-box-form phone-operator-box-form">
                <div className="form-group">
                    <label htmlFor={`phone-operator-alias-${updatedPhoneOperator.id}`}>Identificador</label>
                    <input
                        id={`phone-operator-alias-${updatedPhoneOperator.id}`}
                        value={alias}
                        placeholder="Identificador"
                        onChange={(event) => setAlias(event.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`phone-operator-name-${updatedPhoneOperator.id}`}>Nome</label>
                    <input
                        id={`phone-operator-name-${updatedPhoneOperator.id}`}
                        value={name}
                        placeholder="Nome"
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <label htmlFor={`phone-operator-inactive-${updatedPhoneOperator.id}`}>Desabilitar</label>
                    <input
                        id={`phone-operator-inactive-${updatedPhoneOperator.id}`}
                        type="checkbox"
                        checked={inactive}
                        onChange={(event) => setInactive(event.target.checked)}
                    />
                </div>

                <div className="form-group">
                    <PostBtn
                        postFunction={saveConfiguration}
                        shouldPost={shouldSave}
                    />
                </div>
            </div>
        </EntityBox>
    );
}

export default PhoneOperatorBox;
